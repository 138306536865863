'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { getDietsFromTags } from '../../../utils/Diets';
//import './TagParams.scss';
//
const translateTagMap = {
    'Basic' : 'Simple Recipes',
};

export default class TagParams extends Component {
    static propTypes = {
    };

    removeDiet = (params, diet) => {
        const selectedDietAvoidances = [];

        getDietsFromTags((params.filters && params.filters.tags) || []).forEach(d => {
            d.avoidances.forEach(avoidance => {
                if (selectedDietAvoidances.indexOf(avoidance) === -1) {
                    selectedDietAvoidances.push(avoidance);
                }
            });
        });

        // Ensure the avoidances filter is initialized
        params.filters['!ingredient_tags'] = params.filters['!ingredient_tags'] || [];

        // When removing a diet, we want to also remove avoidances that it doesn't share with any other
        // selected diets. So for instance, if I had 'Vegetarian' and 'Vegan' selected,
        // then I unselected Vegan, I would still have all of vegetarian avoidances selected.
        // Loop through the diet's avoidances and if the avoidance is NOT present in
        // the currently selectedDietAvoidances, then we should remove that avoidance from
        // the parameters.

        diet.avoidances.forEach(avoidance => {
            if (selectedDietAvoidances.indexOf(avoidance) === -1) {
                // Remove the avoidance from params.
                let i = params.filters['!ingredient_tags'].indexOf(avoidance);

                if (i != -1) {
                    params.filters['!ingredient_tags'].splice(i, 1);
                }
            }
        });

        return params;
    }

    removeTag = (tag) => {
        const { params, onChangeParams } = this.props;

        params.filters      = params.filters || {};
        params.filters.tags = (params.filters.tags || []).filter(t => t !== tag);

        // Is this a diet tag?
        const diet = getDietsFromTags([tag])[0];

        if (diet) {
            this.removeDiet(params, diet);
        }

        onChangeParams(params, true);
    }

    renderTag = (tag) => {
        return (
            <div className="param-item" key={tag} onClick={() => this.removeTag(tag)}>
                {translateTagMap[tag] || tag} <i className="icon-close-x" />
            </div>
        );
    }

    render() {
        const ignoreTags = ['Advanced', 'Exclude from Global Search'];
        const { params = {} } = this.props;

        // Filter out tags we're not going to manage
        const filterTags = ((params.filters && params.filters.tags) || []).filter(tag => !ignoreTags.includes(tag));

        return (
            <span>
                {filterTags.map(this.renderTag)}
            </span>
        );
    }
}
