import React from 'react';
import PropTypes from 'prop-types';

/**
 * ButtonLink component
 *
 * @param {Object} props - The component props
 * @param {string} [props.title] - The button title for accessibility
 * @param {Function} [props.onClick] - Click handler function
 * @param {React.ReactNode} [props.children] - The button content
 * @param {boolean} [props.isLoading=false] - Whether the button shows a loading state
 * @param {boolean} [props.disabled=false] - Whether the button is disabled
 * @param {string} [props.dataTestId] - The test ID for testing
 * @param {boolean} [props.underline=true] - Controls if the link has an underline
 * @param {string} [props.color='default'] - The link color (e.g., 'raspberry')
 * @param {string} [props.size='default'] - The button size ('small', 'medium', or 'large')
 * @returns {JSX.Element} The rendered ButtonLink component
 */
const ButtonLink = ({
    title,
    onClick,
    children,
    isLoading = false,
    disabled = false,
    dataTestId,
    underline = true,
    color = 'default',
    size = 'default',
    ...props
}) => {
    const actualSize = size === 'default' ? 'medium' : size;
    const actualColor = color === 'default' ? 'raspberry' : color;

    const linkClass = underline ? `el-link-${actualColor}-btn` : `el-link-no-underline-${actualColor}-btn`;

    const buttonClasses = [`el-${actualSize}-btn`, linkClass].join(' ');

    return (
        <button
            title={title}
            onClick={onClick}
            disabled={disabled}
            data-testid={dataTestId}
            className={buttonClasses}
            {...props}
        >
            {children}
        </button>
    );
};

ButtonLink.propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.node,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    dataTestId: PropTypes.string,
    underline: PropTypes.bool,
    color: PropTypes.oneOf(['default', 'raspberry', 'gray', 'orange', 'green', 'grayish-blue']),
    size: PropTypes.oneOf(['default', 'small', 'medium', 'large']),
};

ButtonLink.defaultProps = {
    isLoading: false,
    disabled: false,
    underline: false,
    color: 'default',
    size: 'default',
};

export default ButtonLink;
