'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Alert.scss';
import Dropdown from '../../../pro/components/Widgets/Dropdown.react';


export default class Alert extends Component {
    static propTypes = {
        type: PropTypes.string,
        iconType: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.node,
        hintDescription: PropTypes.node,
        onClickButton: PropTypes.func,
        buttonText: PropTypes.node,
        dontWrapChildElements: PropTypes.bool,
    };

    getAlertIcon = () => {
        const { type, iconType } = this.props;
        const iconName = iconType || type;

        switch (iconName) {
            case "no-icon":
                return "";
            case "info":
                return "icon-info";
            case "warning":
                return "icon-warning2";
            case "success":
                return "icon-check";
            case "no-network":
                return "feather feather-wifi-off";
            case "error":
                return "icon-remove-circle-outline";
            case "question":
            default:
                return iconType || "icon-help";
        }
    }


    render = () => {
        const { title, type, children, iconType, description, hintDescription, onClickButton, buttonText, className, dontWrapChildElements } = this.props;
        const icon = <div className="el-alert--icon"><i className={this.getAlertIcon(type)} /></div>;
        const elementIcon = hintDescription
                          ? <Dropdown className="el-alert--hint" button={icon}>{hintDescription}</Dropdown>
                          : <div className="el-alert--hint">{icon}</div>;

        return (
            <div className={classNames("el-alert", type, className)} data-has-icon={iconType !== "no-icon"} data-has-button={typeof(onClickButton) !== "undefined"} >
                {elementIcon}
                {title ? <h6>{title}</h6> : null}
                {description ? <span>{description}</span> : null}
                {children && !dontWrapChildElements ? <span>{children}</span> : null}
                {children && dontWrapChildElements ? children : null}
                {onClickButton ? <button className="action-button" onClick={onClickButton}>{buttonText}</button> : null}
            </div>
        );
    }
}
