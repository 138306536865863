'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import styles from './Dropdown.scss';

export default class Dropdown extends Component {
    static propTypes = {
        alwaysVisible: PropTypes.bool,
        showAbove: PropTypes.bool,
    };

    static defaultProps = {
        alwaysVisible: false,
        showAbove: false,
    };

    static childContextTypes = {
        toggleMenu: PropTypes.func,
        closeMenu: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            isDropdownVisible: false,
        };
    }

    getChildContext = () => {
        return {
            toggleMenu: this.toggleDropdown,
            closeMenu: this.closeDropdown,
        };
    }

    closeDropdown = () => {
        this.setState({isDropdownVisible: false});
    }

    onOuterAction = (ev) => {
        if (!this.container || !this.dropdown) {
            return;
        }

        const isOuterAction  = (
            !this.container.contains(ev.target) &&
            !this.dropdown.contains(ev.target)
        );

        if (isOuterAction) {
            this.closeDropdown();
        }
    }

    toggleDropdown = () => {
        const { isDropdownVisible } = this.state;

        this.setState({
            isDropdownVisible: !isDropdownVisible
        });

        if (!process.browser) {
            return;
        }

        // Are we transitioning from hidden to visible? Start listening to touch events everywhere else.
        if (!isDropdownVisible) {
            window.addEventListener(`mousedown`, this.onOuterAction);
            window.addEventListener(`touchstart`, this.onOuterAction);
        } else {
            window.removeEventListener(`mousedown`, this.onOuterAction);
            window.removeEventListener(`touchstart`, this.onOuterAction);
        }
    }

    realizeContainer = (container) => {
        this.container = container;
    }

    realizeDropdown = (dropdown) => {
        this.dropdown = dropdown;
    }

    render() {
        const { isDropdownVisible } = this.state;
        const { button, alwaysVisible, showAbove } = this.props;

        return (
            <span className="dropdown-container" data-above={showAbove} data-state={isDropdownVisible || alwaysVisible} ref={this.realizeContainer}>
                <button className="dropdown-btn" onClick={this.toggleDropdown}>
                    {button}
                </button>

                <div className="dropdown-dropdown" ref={this.realizeDropdown}>
                    <div className="triangle"></div>
                    <div className="triangle2"></div>
                    <div className="dropdown-content">
                        <button className="close-btn" onClick={this.closeDropdown}>
                            <i className="icon-close-x" />
                        </button>
                        {this.props.children}
                    </div>
                </div>
            </span>
        );
    }
}
