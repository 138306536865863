"use strict";

import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

import { getConfig } from "./Env";
import { isValidURL } from "./Util";
import UserStore from "../stores/UserStore";

const SessionRecorder = {
    isRecording: false,
    wasRecorded: false,
    currentLocation: null,

    config: function (history) {
        if (!isAppIdDefined()) {
            return;
        }

        this.currentLocation = history.getCurrentLocation();
        history.listen((location) => this.onLocationChange(location));
    },

    onLocationChange: function (location) {
        this.currentLocation = location;
    },

    start: function () {
        if (!isAppIdDefined()) {
            return;
        }

        if (this.isRecording || this.wasRecorded) {
            return;
        }

        const logRocketAppID = getConfig("logrocket_app_id");

        LogRocket.init(logRocketAppID, {
            shouldSendData: () =>
                this.shouldSendData(null, this.currentLocation),
        });

        this.isRecording = true;
        this.wasRecorded = true;
    },

    stop: function () {
        if (!isAppIdDefined()) {
            return;
        }

        if (!this.isRecording) {
            return;
        }

        LogRocket.identify(null);
        LogRocket.startNewSession(); // Ensure a new session when init again
        LogRocket.uninstall(); // Problem: We can't to restart monitoring, its necessary reload the page.

        setupLogRocketReact(LogRocket);

        this.isRecording = false;
    },

    identify: function (user) {
        if (!isAppIdDefined()) {
            return;
        }

        let tags = [];

        if (user.is_internal) {
            tags.push('Eatlove_Internal');
        }

        LogRocket.identify(user.uuid, {
            name: user.name,
            email: user.email,
            release: getConfig("build_number"),
            tags: tags.join(',')
        });

        setupLogRocketReact(LogRocket);
    },

    shouldSendData: function (user = null, location = null) {
        if (!isAppIdDefined()) {
            return false;
        }

        const userToVerify = user ?? UserStore.getUser();

        if (!!userToVerify) {
            if (userToVerify.practice_type === "dietetics" && !userToVerify.is_internal) {
                return false;
            }

            if (location?.pathname === "/new-account" && !userToVerify.practice_type) {

                return true;
            }

            return !!userToVerify.links?.logRocket?.app_id;
        }

        if (location?.pathname === "/new-account") {
            return true;
        }

        return false;
    },

    getSessionURL: function () {
        return isValidURL(LogRocket.sessionURL) ? LogRocket.sessionURL : null;
    },
};

const isAppIdDefined = function () {
    return (
        getConfig("logrocket_app_id") !== undefined &&
        getConfig("logrocket_app_id") !== null
    );
};

export default SessionRecorder;
