'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import moment from 'moment';

import Popup from '../../Widgets/Popup.react';

import Alert from '../../Widgets/Alert/Alert.react';
import FoodUnitsSelector from '../../Foods/FoodUnitsSelector.react';
import SearchFoodsModal from '../../Foods/SearchFoodsModal.react';
import { createNewDocument, updateCachedDocuments } from '../../../utils/Content';

import { getConfig } from '../../../utils/Env';
import AuthStore from '../../../stores/AuthStore';
import UserStore from '../../../stores/UserStore';
import BoardStore from '../../../stores/BoardStore';
import BoardActions from '../../../actions/BoardActions';

import allUnits from '../../../tables/units';

import './QuickLogAiConfirmationModal.scss';

export default class QuickLogAiConfirmationModal extends Component {
    static propTypes = {
        closeModal: PropTypes.func,
        items: PropTypes.array,
        onConfirm: PropTypes.func,
        mealType: PropTypes.string,
    };

    static contextTypes = {};

    constructor(props) {
        super(props);
        const user = UserStore.getUser();

        this.state = {
            user,
            working: false,
            swapIndex: null,
            swapModalOpen: false,
            items: this.props.items || [],
            confirmError: null,
        };
    }

    componentDidMount() {
        this.setErrorMessages();
    }

    getItemsWithErrorMessages = () => {
        let { items } = this.state;

        const updatedItems = items.map((item, index) => {
            let errorMessage = '';

            if (item.meal.logged_unit == '--' && (!item.measurement?.amount || item.measurement?.amount <= 0)) {
                errorMessage = 'Please enter an amount and unit';
            } else if (item.meal.logged_unit == '--') {
                errorMessage = 'Please select a unit';
            } else if (!item.measurement?.amount || item.measurement?.amount <= 0) {
                errorMessage = 'Please enter an amount';
            } else if (!item.meal.logged_grams && !item.meal.logged_milliliters) {
                errorMessage = 'Please choose another size';
            }

            item.errorMessage = errorMessage;

            return item;
        });

        return updatedItems;
    };

    setErrorMessages = () => {
        const updatedItems = this.getItemsWithErrorMessages();

        this.setState({ items: updatedItems });
    };

    onChangeAmount = (portion, unit_description, amount, grams, milliliters, index) => {
        let { items } = this.state;

        items[index].meal.logged_amount = amount;
        items[index].meal.logged_grams = grams;
        items[index].meal.logged_milliliters = milliliters;
        items[index].meal.logged_unit = unit_description;

        items[index].measurement = {
            amount,
            unit_of_measure: unit_description,
        };

        this.setState({ items: items, swapModalOpen: false }, this.setErrorMessages);
    };

    onClickConfirm = async () => {
        this.setState({ working: true });

        const { closeModal, mealType, onConfirm } = this.props;
        const { items } = this.state;

        const updatedItems = this.getItemsWithErrorMessages();

        const hasErrors = updatedItems.some((item) => item.errorMessage);

        if (hasErrors) {
            this.setState({ confirmError: 'Please correct the highlighted areas above', working: false });
            return;
        }

        const processedItems = await Promise.all(
            updatedItems.map(async (item) => {
                if (item.unverifiedFood) {
                    const food = await this.storeNewFood(this.getFood(item));
                    this.addFoodToFavorites(food);
                    updateCachedDocuments([food]);
                    item.food = food;
                }
                return item;
            })
        );

        await onConfirm(mealType, processedItems);
        closeModal();
    };

    storeNewFood = async (food) => {
        food = await createNewDocument('food', food, 'food/1');

        return food;
    };

    addFoodToFavorites = (food) => {
        console.log('food to add to favorites', food);
        const lastBoard = BoardStore.getDefaultBoard();
        const newItem = {
            resource_id: food.uuid,
            resource_type: food.type,
        };

        if (lastBoard.links) {
            // Board already exists, just add to it.
            BoardActions.addToBoard(lastBoard, [newItem]);
        } else {
            lastBoard.contents = lastBoard.contents || [];
            lastBoard.contents.push(newItem);

            // Board does not exist, create a whole new one
            BoardActions.upsertBoards([lastBoard]);
        }
    };

    getFood = (item) => {
        const { user } = this.state;
        const { ingredient, measurement } = item;

        const unit = allUnits[user.language].find(
            (u) =>
                (u.alts && u.alts.includes(measurement.unit_of_measure)) ||
                u.description == measurement.unit_of_measure ||
                u.plural == measurement.unit_of_measure
        );

        let food = {
            product_type: 'UGC',
            name: ingredient,
            gtin_upc: [],
            language: (user && user.language) || 'en',
            brand_name: '',
            category: null,
            nutrients: { values: {}, available: {} },
            refuse: 0,
            serving_unit: 'g',
        };

        const package_size = 1;
        const package_description = 'default package';
        const serving_amount = 1;
        const serving_description = 'serving';
        const serving_size = 1;

        const serving_size_float = parseFloat(serving_size);

        let milliliters, grams, pkg_milliliters, pkg_grams, serving_size_unit;

        if (unit.type == 'mass') {
            grams = serving_size_float * measurement.amount * unit.grams;
            pkg_grams = package_size * measurement.amount * unit.grams;
            serving_size_unit = 'g';
        }

        if (unit.type == 'volume') {
            milliliters = serving_size_float * measurement.amount * unit.milliliters;
            pkg_grams = package_size * measurement.amount * unit.milliliters;
            serving_size_unit = 'ml';
        }

        food.serving_description = `${serving_amount} ${serving_description} (${serving_size_float} ${serving_size_unit})`;
        food.serving_unit = serving_size_unit;
        food.milliliters_per_serving = milliliters;
        food.grams_per_serving = grams;

        food.default_unit = {
            amount: serving_amount,
            description: serving_description,
            grams,
            milliliters,
        };

        food.units = [
            {
                amount: serving_amount,
                description: serving_description,
                grams,
                milliliters,
            },
        ];

        food.packaging = [
            {
                description: package_description,
                display_mode: 'prefix-of',
                amount: 1,
                grams: pkg_grams,
                milliliters: pkg_milliliters,
                mode: 'discrete',
                units: 'metric',
            },
        ];

        return food;
    };

    onSelectFood = (food) => {
        let { items, swapIndex } = this.state;

        items[swapIndex].food = food;
        items[swapIndex].ingredient = food.pretty_name;
        items[swapIndex].unverifiedFood = false;

        this.setState({ items: items, swapModalOpen: false });
    };

    onClickSwap = (index) => {
        this.setState({ swapIndex: index, swapModalOpen: true });
    };

    onClickRemove = (index) => {
        let { items } = this.state;

        items.splice(index, 1);

        this.setState({ items });
    };

    closeSwapModal = () => {
        this.setState({ swapIndex: null, swapModalOpen: false });
    };

    renderSwapModal = () => {
        const { isSwappingFood } = this.state;

        return (
            <SearchFoodsModal
                closeModal={this.closeSwapModal}
                modalTitle="Change food"
                inhibitSearchOnMount={false}
                allowedTypes={['food']}
                onSelectFood={this.onSelectFood}
                selectButtonText="Add food"
                createCustomButtonCopy="Create New Food"
            />
        );
    };

    renderItem = (item, index) => {
        const { user, items } = this.state;

        const title = item.content?.pretty_name || item.content?.name || item.content?.title || item.ingredient;

        return (
            <div key={index} className="item-container">
                <div className="quick-log-food">
                    <p className="p4">
                        {item.content?.brand_name ? <em>{item.content.brand_name}&nbsp;</em> : null}
                        {title}
                    </p>

                    {item.unverifiedFood ? (
                        <Popup
                            positionClassName="el-popup-left-bottom"
                            button={
                                <span className="food-warning">
                                    <i className="icon-warning" />
                                </span>
                            }
                        >
                            <div className="food-not-found">
                                Nutritional info for this food is unavailable. Please try swapping with a similar item
                                from our database to track it.
                                <button
                                    onClick={() => this.onClickSwap(index)}
                                    className="el-medium-btn el-raspberry-btn"
                                >
                                    Swap
                                </button>
                            </div>
                        </Popup>
                    ) : null}

                    <button onClick={() => this.onClickSwap(index)} className="el-medium-btn el-link-raspberry-btn">
                        swap
                    </button>

                    {user.role === 'admin' && item.content?.type === 'food' ? (
                        <a href={`/admin/foods/${item.content.uuid}`} target="_blank">
                            <button className="el-medium-btn el-link-raspberry-btn">admin</button>
                        </a>
                    ) : null}

                    {user.role === 'admin' && item.content?.type === 'recipe' ? (
                        <a href={`/publisher/recipe/${item.content.uuid}`} target="_blank">
                            <button className="el-medium-btn el-link-raspberry-btn">admin</button>
                        </a>
                    ) : null}

                    {items.length > 1 ? (
                        <button onClick={() => this.onClickRemove(index)} className="remove-food-btn">
                            <i className="icon-close-x" />
                        </button>
                    ) : null}
                </div>

                <FoodUnitsSelector
                    defaultSizeHeader={'Unit'}
                    showAbove={items.length > 1 && index == items.length - 1}
                    errorMessage={item.errorMessage}
                    food={item.content}
                    meal={item.meal}
                    unverified={item.unverifiedFood}
                    onChangeAmount={(portion, unit, amount, grams, milliliters) =>
                        this.onChangeAmount(portion, unit, amount, grams, milliliters, index)
                    }
                />
            </div>
        );
    };

    render = () => {
        const { working, swapModalOpen, items, confirmError } = this.state;
        const { closeModal } = this.props;

        return (
            <Modal
                isOpen={true}
                className="el-modal el-modal2 quick-log-ai-confirmation"
                overlayClassName="el-modal-overlay"
                onRequestClose={closeModal}
                closeModal={closeModal}
                closeTimeoutMS={250}
            >
                <div className="el-modal-container el-modal2-container">
                    <header>
                        <h2>Quick Add Foods</h2>
                    </header>
                    <div className="el-modal-body-container el-modal2-body-container el-fonts el-form quick-log-ai-confirmation">
                        {working ? (
                            <div
                                className="el-modal-loading el-fonts ingredients-loader"
                                data-testid="ingredients-loader"
                            >
                                <h4>Please wait</h4>
                                <i className="icon-spinner2" />
                                <span className="loading-text">Processing your food items...</span>
                            </div>
                        ) : (
                            items.map(this.renderItem)
                        )}
                    </div>
                    {confirmError ? <Alert type="error" description={confirmError} /> : null}
                    <footer>
                        <button className="el-modal-cancel-btn" onClick={closeModal}>
                            cancel
                        </button>
                        <button disabled={working} className="el-modal-ok-btn" onClick={this.onClickConfirm}>
                            {working ? 'working...' : 'confirm'}
                        </button>
                    </footer>
                </div>
                {swapModalOpen ? this.renderSwapModal() : null}
            </Modal>
        );
    };
}
