'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import indexBy from 'lodash.indexby';

import SearchFoodsModal from '../../Foods/SearchFoodsModal.react';
import LogPortionsModal from '../../Planner/Modals/LogPortionsModal.react';

import FoodUnitsSelector from '../../Foods/FoodUnitsSelector.react';
import Combobox from '../../../pro/components/Widgets/Combobox.react';
import Alert from '../../Widgets/Alert/Alert.react';

import AuthStore from '../../../stores/AuthStore';

import {
    getRecipeEventProperties,
    getBestUnitForFood,
    fetchDocumentById,
    fetchDocumentsById,
    getContentForIngredient,
} from '../../../utils/Content';
import Analytics from '../../../utils/Analytics';
import { getConfig } from '../../../utils/Env';

import './EditIngredientModal.scss';

export default class EditIngredientModal extends Component {
    static propTypes = {
        ingredient: PropTypes.object,
    };

    static contextTypes = {
        profile: PropTypes.object,

        recipe: PropTypes.object,
        details: PropTypes.object,

        recipes: PropTypes.object,
        foods: PropTypes.object,

        editSessionId: PropTypes.string,
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            ...this.getStateFromProps(props, context),
        };
    }

    componentDidMount = () => {
        this.syncAssets();
    };

    syncAssets = async () => {
        const { ingredient } = this.state;
        const { foods, recipes } = this.context;

        let content = (ingredient.food?.uuid && foods[ingredient.food?.uuid]) ||
                      (ingredient.recipes?.uuid && recipes[ingredient.recipe?.uuid]);

        if (ingredient.food?.uuid && !content) {
            content = await fetchDocumentById(ingredient.food.uuid);
            this.setState({content});
        } else if (ingredient.recipe?.uuid && !content) {
            content = await fetchDocumentById(ingredient.recipe.uuid);
            this.setState({content});
        }
    };

    getStateFromProps = (props, context) => {
        const { ingredient } = props;

        const ingredientCopy = JSON.parse(JSON.stringify(ingredient));

        const verified =
            (ingredientCopy.food && ingredientCopy.food.verified) ||
            (ingredientCopy.recipe && ingredientCopy.recipe.verified);

        const food = ingredient.food && context.foods[ingredient.food.uuid];
        const subrecipe = ingredient.recipe && context.subrecipes[ingredient.recipe.uuid];
        const content = food || subrecipe;

        const newState = {
            verified,
            content,
            ingredient: ingredientCopy,
        };

        return newState;
    };

    onChangeIngredientText = (ev) => {
        const { ingredient } = this.state;

        ingredient.ingredient = ev.target.value;

        this.setState({ ingredient });
    };

    onChangePrepStepTerms = (terms) => {
        const { ingredient } = this.state;

        ingredient.prep_step = terms;

        this.setState({ ingredient });
    };

    onSelectPrepStepOption = (option) => {
        const { ingredient } = this.state;

        ingredient.prep_step = option;

        this.setState({ ingredient });

        this.refs.prep_step.setTypeAhead(option);
    };

    onSave = () => {
        const { onChangeIngredient, closeModal } = this.props;
        const { ingredient, dirty } = this.state;

        if (dirty) {
            onChangeIngredient(ingredient);
        }

        closeModal();
    };

    onSelectFood = (food) => {
        this.setState({ logPortionsFor: [food] });
    };

    onRemoveIngredient = () => {
        this.closeModal();

        const { onRemoveIngredient } = this.props;

        onRemoveIngredient();
    };

    setFoodMapping = (ingredient, food, unit) => {
        delete ingredient.recipe;

        ingredient.ingredient = food.pretty_name || food.name;
        ingredient.percent_yield = food.percent_yield || 100;
        ingredient.refuse = food.refuse || 0;

        ingredient.food = {
            uuid: food.uuid,
            name: food.name,
            automatic: false,
            verified: true,
        };

        if (unit) {
            ingredient.food.unit_amount = unit.amount;
            ingredient.food.unit_grams = unit.grams;
            ingredient.food.unit_milliliters = unit.milliliters;
            ingredient.food.unit_description = unit.description;
        }

        return ingredient;
    }

    onSelectPortions = (contents, portions) => {
        const { recipe, details, editSessionId } = this.context;
        let { ingredient } = this.state;

        const content = contents[0];
        const { logged_unit, logged_amount, logged_grams, logged_milliliters, unit } = portions[content.uuid];

        Analytics.swapIngredientFoodInRecipe({
            'Food Name': content.name,
            'Food UUID': content.uuid,
            Type: content.type,

            'Replaced Ingredient': ingredient.ingredient,
            'Replaced Food Name': ingredient.food?.name,
            'Replaced Food UUID': ingredient.food?.uuid,

            Amount: logged_amount,
            Unit: logged_unit,
            Grams: logged_grams,
            milliliters: logged_milliliters,

            'Edit Session ID': editSessionId,
            ...getRecipeEventProperties(recipe, details),
        });

        ingredient.measurement = {
            amount: logged_amount,
            unit_of_measure: logged_unit,
        };

        ingredient.grams = logged_grams;
        ingredient.milliliters = logged_milliliters;
        ingredient.automatic = false;
        ingredient.verified = true;

        // Should always have something...
        if (content.type === 'food') {
            ingredient = this.setFoodMapping(ingredient, content, unit);
        }

        this.setState({ingredient, isSwapModalOpen: false, logPortionsFor: null, dirty: true}, this.syncAssets);
    };

    closeModal = () => {
        const { closeModal } = this.props;

        closeModal();
    };

    onSwapIngredient = () => {
        this.setState({isSwapModalOpen: true});
    }

    closeSwapIngredientModal = () => {
        this.setState({isSwapModalOpen: false});
    }

    closeLogPortionsModal = () => {
        this.setState({logPortionsFor: null});
    }

    onChangeFoodUnit = (portion, unit_of_measure, amount, grams, milliliters, unit) => {
        let { ingredient } = this.state;
        const { extraAnalyticsProps } = this.props;

        const traits = {
            Ingredient: ingredient.ingredient,
            'Old Amount': ingredient.measurement?.amount,
            'Old Unit': ingredient.measurement?.unit_of_measure,
            'Old Grams': ingredient.grams,
            'Old Milliliters': ingredient.milliliters,
            ...extraAnalyticsProps,
        };

        ingredient.measurement = { amount, unit_of_measure };
        ingredient.grams = grams;
        ingredient.milliliters = milliliters;

        if (ingredient.food && unit) {
            ingredient.food.unit_amount = unit.amount;
            ingredient.food.unit_grams = unit.grams;
            ingredient.food.unit_milliliters = unit.milliliters;
            ingredient.food.unit_description = unit.description;

            traits['Amount'] = ingredient.measurement.amount;
            traits['Unit'] = ingredient.measurement.unit_of_measure;
            traits['Grams'] = ingredient.grams;
            traits['Milliliters'] = ingredient.milliliters;
        }

        if (ingredient.food?.uuid) {
            traits['Food UUID'] = ingredient.food.uuid;
        }

        this.setState({ ingredient, dirty: true });
        Analytics.editIngredientAmountInRecipe(traits);
    };

    renderSwapIngredientModal = () => {
        const { ingredient, isSwapModalOpen } = this.state;
        const { recipe, details, profile, editSessionId } = this.context;

        if (!isSwapModalOpen) {
            return;
        }

        return (
            <SearchFoodsModal closeModal={this.closeSwapIngredientModal}
                defaultLanguage={recipe?.language || 'en'}
                allowedTypes={['food']}
                profile={profile}
                modalTitle="Swap an Ingredient"
                inhibitSearchOnMount={false}
                defaultTerms={ingredient.ingredient}
                extraAnalyticsProps={{
                    'Edit Session ID': editSessionId,
                    ...getRecipeEventProperties(recipe, details),
                }}
                onSelectFood={this.onSelectFood} />
        );
    }

    renderLogPortionsModal = () => {
        const { profile } = this.context;
        const { ingredient, logPortionsFor } = this.state;

        if (!logPortionsFor) {
            return null;
        }

        const defaultPortions = [];

        logPortionsFor.forEach((content) => {
            if (
                (content.serving_unit == 'g' && ingredient.food?.unit_grams) ||
                (content.serving_unit == 'ml' && ingredient.food?.unit_milliliters)
            ) {
                const portion = {
                    food_uuid: content.uuid,
                    logged_portion: 1,
                    logged_unit: ingredient.food.unit_description,
                    logged_amount: ingredient.measurement.amount,
                };

                const bestUnit = getBestUnitForFood(
                    ingredient.food?.unit_description || ingredient.measurement.unit_of_measure,
                    content
                );

                if (bestUnit?.milliliters && content.serving_unit === 'ml') {
                    portion.logged_milliliters = bestUnit.milliliters * ingredient.measurement.amount;
                } else if (bestUnit?.grams) {
                    portion.logged_grams = bestUnit.grams * ingredient.measurement.amount;
                }

                defaultPortions.push(portion);
            }
        });

        return (
            <LogPortionsModal
                closeModal={this.closeLogPortionsModal}
                profile={profile}
                modalTitle="How much?"
                ctaText="Swap Food"
                contents={logPortionsFor}
                onSelectPortions={this.onSelectPortions}
                defaultPortions={defaultPortions}
            />
        );
    };

    disableSaveBtn = (disableSaveBtn) => {
        this.setState({disableSaveBtn});
    }

    render = () => {
        const { ingredient, content, dirty, disableSaveBtn } = this.state;

        const { measurement = {} } = ingredient;
        const { amount } = measurement;

        const prepStepOpts = (content?.advance_prep || []).map((prep) => ({
            label: prep.prep_step,
            value: prep.prep_step,
        }));

        return (
            <>
            <Modal
                isOpen={true}
                className="el-modal el-modal2 edit-ingredient-modal"
                overlayClassName="el-modal-overlay"
                contentLabel="Edit recipe ingredient"
                closeTimeoutMS={250}
            >
                <div className="el-modal-container el-modal2-container">
                    <div className="el-modal-body-container el-modal2-body-container el-fonts">
                        {ingredient.food && !(ingredient.grams || ingredient.milliliters) ?
                            <Alert type="warning">We were unable to determine this ingredient's nutrition.<br /><em>Please confirm the Amount and Size below.</em></Alert>
                        : null}

                        <div className="el-labeled-input ingredient-text">
                            <label className="ingredient-text-label">Recipe Ingredient</label>
                            <input type="text" value={ingredient.ingredient} onChange={this.onChangeIngredientText} />
                        </div>

                        <div className="mapped-food">
                            {ingredient.food ? (<>
                                <label>Matched Grocery Item</label>
                                <p className="p3">
                                    {[content?.brand_name, content?.pretty_name || ingredient.food?.name].filter((v) => v).join(' ')}
                                    <button
                                        className="swap-btn el-medium-btn el-link-no-underline-raspberry-btn"
                                        onClick={this.onSwapIngredient}
                                    >
                                        swap
                                    </button>
                                </p>
                            </>) : null}

                            {ingredient.recipe ? (<>
                                <label>Foundational Recipe</label>
                                <p className="p3">
                                    {ingredient.recipe.title}
                                    <button
                                        className="swap-btn el-medium-btn el-link-no-underline-raspberry-btn"
                                        onClick={this.onSwapIngredient}
                                    >
                                        swap
                                    </button>
                                </p>
                            </>) : null}

                            {!ingredient.food && !ingredient.recipe ? (
                                <p className="p3">
                                    <em>None</em>
                                    <button
                                        className="map-btn el-small-btn el-link-no-underline-raspberry-btn"
                                        onClick={this.onSwapIngredient}
                                    >
                                        Link a grocery item for nutritional analysis
                                    </button>
                                </p>
                            ) : null}
                        </div>

                        <FoodUnitsSelector
                            data-error={ingredient.food && !(ingredient.grams || ingredient.milliliters)}
                            food={content || { serving_unit: 'g', grams_per_serving: ingredient.grams }}
                            meal={{
                                logged_portion: 1,
                                logged_unit:
                                    ingredient.food?.unit_description || ingredient.measurement?.unit_of_measure,
                                logged_amount: amount,
                            }}
                            onChangeAmount={this.onChangeFoodUnit}
                            disableSaveBtn={this.disableSaveBtn}
                        />

                        <div className="el-labeled-input prep-step">
                            <label className="prep-label">
                                Prep step <em>(optional)</em>
                            </label>
                            <Combobox
                                className="el-combobox-container"
                                options={prepStepOpts}
                                value={ingredient.prep_step}
                                defaultValue={ingredient.prep_step}
                                onSelectOption={this.onSelectPrepStepOption}
                                onChangeTerms={this.onChangePrepStepTerms}
                                ref="prep_step"
                            />
                        </div>

                    </div>

                    <footer>
                        <button className="el-modal-cancel-btn" onClick={this.onRemoveIngredient}>
                            remove
                        </button>

                        <button className="el-modal-ok-btn" disabled={disableSaveBtn} onClick={this.onSave}>
                            {dirty ? <><i className="icon-logo" />save</> : 'done'}
                        </button>
                    </footer>
                </div>
            </Modal>

            {this.renderSwapIngredientModal()}
            {this.renderLogPortionsModal()}
            </>
        );
    };
}
