'use strict';

export default {
    dietetics: [
        {text: ["EatLove has allowed us to be there for our patients even when we are not actually in a session. It is a tool they can continuously learn from, and it helps discussions become more in depth in a way that is fun and easy to understand. It also brings up new ideas for cooking that expand the culinary world for our patients and for us!"],
         user: {name: "Jessica Tupa, MS, RD, LD",
                type: "Health System",
                location: "CommuniCare Health Centers",
                photo: "https://static.chewba.info/images/0a967d63-90ab-4507-9c87-25a08e45e1b0.png"
               }
        },
        {text: ["EatLove has been instrumental in my accountability programs for client retention. With just 1-2 hours per week, this income streams averages $2,000 per month in revenue for me without adding additional work to my plate."],
         user: {name: "Cari Riker, RD, IBS Dietitian",
                type: "Private Practice",
                location: "",
                photo: "https://static.chewba.info/images/7552dc56-c253-4fa0-95e7-eafc3b744f39.png"
               }
        },
        {text: ["EatLove ensures that our clients receive enticing, practical suggestions on what and how much to eat to help bring their nutrition prescriptions to life. The platform is a great space for clients and clinicians to collaborate on individualized plans, adapt in real-time, and monitor ongoing progress."],
         user: {name: "Danielle Aldous, RD, CDE, CBE",
                type: "Private Practice",
                location: "Leaf Weight Management",
              photo: "https://static.chewba.info/images/e0134845-1af1-4141-8ee2-623774434111.png"
               }
        },
        {text: ["If you are looking for a meal planning service that doesn’t rely on you to make every meal plan, make more money, and get back the time you need to bring on more clients, I encourage you to give EatLove a try, Last year I made over $80,000 from private clients alone. A big factor in that was my ability to promote the customized meal planning services I give to my clients."],
         user: {name: "Jen Hernandez, RDN, CSR, LDN",
                type: "Private Practice",
                location: "Owner of Plant-Powered Kidneys Inc.",
                photo: "https://static.chewba.info/images/ac60c63e-1cb9-4c52-88e3-d3e04270158d.jpg"
              }
        }
    ],
    fitness: [
        {text: ["I incorporate EatLove into my membership packages for $79/month with a 6 month minimum commitment. The commitment is required because I teach lifestyles and it takes more than a few weeks to build the foundation for a new lifestyle. This model enables me to plan my client programs and project my monthly recurring revenue. In total, I spend 1 hour per client per month in EatLove, and can easily work with 25 or more clients."],
         user: {name: "Sharon Chamberlin",
                location: "ACE-Certified Personal Fitness Trainer, Owner and Founder of Catalyst 4 Fitness",
                photo: "https://static.chewba.info/images/0a0c93dc-f987-42e3-b405-5ca80f794b78.png"
               }
        },
        {text: ["In just 2-3 hours a week, EatLove helps me manage 123 clients with their engagement dashboard that shows active and disengaged reports. Even with a smaller time commitment, I am able to charge $75 per person per month because EatLove makes it so easy to scale. EatLove is one of the best apps I’ve seen to make sure my members are compliant with their nutritional goals.\
                If you are trying to help someone with their nutrition without spending tons of money or taking more time away from other tasks as a business owner, this is where you want to be. EatLove has helped me so much in making sure my clients are compliant and getting results."],
         user: {name: "Shedrik Cole",
                location: "Anytime Fitness Owner ",
                photo: "https://static.chewba.info/images/4db3bd2a-1fd7-492c-a914-cf6b26c63c72.png"
               }
        },
        {text: ["I’ve been using EatLove with my clients for one year and it's been a great asset to keep clients eating healthy. I highly recommend any trainer or owner to use EatLove and give your clients the attention they deserve in the highly competitive market of personal training and health coaching."],
         user: {name: "Jesse Garcia",
                location: "Trainer, Diakadi San Francisco",
                photo: "https://static.chewba.info/images/pro-fitness/fa27bb43-99f7-4f31-b515-1f7f36cc7f68.jpg"
               }
        },
        {text: ["Our members expect a complete wellness solution so we partnered with EatLove two years ago to offer members scalable, personalized nutrition programs and haven’t looked back. We’ve earned a significant return on our investment by launching new premium plus memberships without adding more overhead and it has been surprisingly straightforward. The EatLove team has been wonderful to work with and provided high quality member support and education."],
         user: {name: "Allison Rand, VP of Marketing",
                type: "Fitness Gyms, 80 Locations",
                location: "YouFit",
                photo: "https://static.chewba.info/images/601fc24a-cec7-4dcd-a938-d416f6a92ee8.png"
                }
        },
        {text: ["The inclusion of EatLove in FITNESS SF\'s member offering has been an amazing benefit for our members. Traditionally, gyms have largely overlooked the nutrition component, but we see enormous potential for growth in this area and our partnership has allowed us to make significant strides forward. Our app users are highly engaged with EatLove, and we promote onboarding to our new members for a strong start. Additionally, we offer monthly nutrition webinars members and have other exciting projects in progress."],
         user: {name: "Troy Macfarland, Director of Marketing",
                type: "Fitness Gyms",
                location: "Fitness SF",
                photo: "https://static.chewba.info/images/a0ab6932-ffe7-4630-94ac-f44e2570506e.png"
               }
        },
    ],
    consumer: [
        {text: ["This is the tool you've been waiting for. Seven months ago a registered dietitian at my gym introduced me to EatLove. The benefits have been huge and my weight is finally under control (down 19 pounds now!)"],
         user: {name: "Kyle R.",
                location: "Training Client",
                photo: "https://static.chewba.info/images/pro-fitness/dcfc34bd-cb60-4f7b-841c-9acf6fa0738b.jpg"
               }
        },
        {text: ["EatLove may have genuinely changed my life. I do NOT cook, but even I can manage these recipes. You get a grocery list and daily meals scheduled with an eye to your nutrition goals. Get the most bang for your buck (bye, moldy unused food!)"],
         user: {name: "Stephanie S.",
                location: "Client",
                photo: "https://static.chewba.info/images/7c216309-61cd-4a30-92b5-5943c34ae2cb.jpg"
               }
        }
    ],

};
