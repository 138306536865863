'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import Popup from './Popup.react';
import UserStore from '../../stores/UserStore';
import { roundForHumansByUnit } from '../../utils/Math';
import allNutrients from '../../tables/nutrients';
import Analytics from '../../utils/Analytics';

import './VariancesPopup.scss';

const nutrKeys = {
    '208': {
        Units: '',
        NutrDesc: 'Calories',
    },
    'VEG': {
        Units: ' servings',
        NutrDesc: "Vegetables",
    },
    'FRU': {
        Units: ' servings',
        NutrDesc: "Fruit",
    },
}

export default class VariancesPopup extends Component {
    static propTypes = {
        popupTitle: PropTypes.string,
        variances: PropTypes.array,
        avoidances: PropTypes.array,
    };

    static defaultProps = {
        popupTitle: 'This meal is...',
        positionClass: "el-popup-right-bottom",

    };

    static contextTypes = {
        startReplaceMeal: PropTypes.func,
        startAddMeal: PropTypes.func,
        isMobile: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            variances: this.massageVariances(props.variances),
            isMismatched: false,
            isOverLimits: false,
        };
    }

    componentDidMount = () => {
        this.syncState(this.props, this.context);
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        this.syncState(nextProps, nextContext);
    }

    massageVariances = (variances) => {
        return (variances || []).filter(variance => {
            const nutrient = nutrKeys[variance.nutrNo] || allNutrients[variance.nutrNo];

            if (!nutrient) {
                return false;
            }

            const { value, min, max } = variance;

            if (min && value < min) {
                let roundedUnder = roundForHumansByUnit(min - value, nutrient.Units);

                if (!roundedUnder) {
                    return false;
                }
            }

            if (max && value > max) {
                let roundedOver = roundForHumansByUnit(value - max, nutrient.Units);

                if (!roundedOver) {
                    return false;
                }
            }

            return true;
        });
    }

    syncState = (props, context) => {
        let { avoidances, variances, disclaimers } = props || this.props;

        variances = this.massageVariances(variances);
        let isMismatched = (variances || []).length > 0 ||
                           (avoidances || []).length > 0 ||
                           (disclaimers || []).length > 0;
        let isOverLimits = (variances || []).filter(miss => miss.value > miss.max).length > 0;

        this.setState({isMismatched, isOverLimits, variances});
    }

    renderVariance = (variance, i) => {
        const nutrient = nutrKeys[variance.nutrNo] || allNutrients[variance.nutrNo];

        if (!nutrient) {
            return;
        }

        const { value, min, max } = variance;

        if (value < min) {
            let roundedUnder = roundForHumansByUnit(min - value, nutrient.Units);

            return (
                <li key={i}>
                    <em>{roundedUnder}{nutrient.Units}</em>
                    {nutrient.NutrDesc}
                    <span className="under">under</span>
                </li>
            );
        }

        if (value > max) {
            let roundedOver = roundForHumansByUnit(value - max, nutrient.Units);

            return (
                <li key={i}>
                    <em>{roundedOver}{nutrient.Units}</em>
                    {nutrient.NutrDesc}
                    <span className="over">over</span>
                </li>
            );
        }

        return null;
    }

    onShowVariances = () => {
        Analytics.viewNutritionDeviations({'Context': this.props.contextName});
    }

    renderActionButton = () => {
        const { variances, isMismatched, isOverLimits } = this.state;
        const { meals, mealType, date } = this.props;
        const { startAddMeal, startReplaceMeal } = this.context;

        if (!variances.length || !meals || !mealType || !date) {
            return;
        }

        let button = <button className="add-swap-btn" onClick={() => startReplaceMeal(meals[0])}>swap {mealType}</button>

        if (!isOverLimits) {
            button = <button className="add-swap-btn" onClick={() => startAddMeal(date, mealType)}>add dish</button>
        }

        return (
            <footer>{button}</footer>
        );
    }

    renderDisclaimer = (key, i) => {
        if (key === 'Grab & Go') {
            return (
                <div className="disclaimer grab-n-go-disclaimer" key={i}>
                    <h3>Nutrition Disclaimer</h3>
                    <p>Please note that nutrition info on this menu item is estimated. Check ingredients before purchasing to ensure any of your avoidances are not included. Menu items may change without notice and not be available in all locations. Some nutrition and allergen data are not available for grab and go foods. Your nutrition profile may include parameters that are not supported in this feature due to limited data availability.</p>
                </div>
            );
        }

        if (key === 'Restaurant Dish') {
            return (
                <div className="disclaimer restaurant-dish-disclaimer" key={i}>
                    <h3>Nutrition Disclaimer</h3>
                    <p>Restaurant food is generally not a healthy choice. Please note that nutrition info on this menu item is estimated. Check ingredients before purchasing to ensure any of your avoidances are not included. Menu items may change without notice and not be available in all locations. Some nutrition and allergen data are not available for grab and go foods. Your nutrition profile may include parameters that are not supported in this feature due to limited data availability.</p>
                </div>
            );
        }

        if (key === 'Branded Food') {
            return (
                <div className="disclaimer grab-n-go-disclaimer" key={i}>
                    <h3>Nutrition Disclaimer</h3>
                    <p>Please note that nutrition info on this item is estimated. Check ingredients before purchasing to ensure any of your avoidances are not included. Menu items may change without notice and not be available in all locations. Some nutrition and allergen data are not available for all products, please verify product does not contain your allergens before consuming. Your nutrition profile may include parameters that are not supported in this feature due to limited data availability.</p>
                </div>
            );
        }

        return
    }

    render = () => {
        const { avoidances, popupTitle, disclaimers, positionClass } = this.props;
        const { variances, isMismatched } = this.state;
        const { isMobile } = this.context;

        const { inhibit_swap = false, hide_nutrition = false } = UserStore.getPreferences();

        if (!isMismatched || hide_nutrition) {
            return <span />;
        }

        return  (
            <Popup positionClassName={positionClass} className="el-popup-dark variances-popup" button={<i className="icon-warning5" title="Click to see details" />}
                onShowDropdown={this.onShowVariances}>
                <div className="variances-popup-content">
                    {variances && variances.length > 0 ?
                        <div>
                            <h3>{popupTitle}</h3>
                            <ul>
                                {variances.map(this.renderVariance).filter(v => v)}
                            </ul>
                        </div>
                    : null}

                    {avoidances && avoidances.length > 0 ?
                        <p>Contains foods avoided by your profile: {avoidances.join(', ')}</p>
                    : null}

                    {inhibit_swap ? null : this.renderActionButton()}
                    {disclaimers ? this.renderDisclaimer(disclaimers[0]) : null}
                </div>
            </Popup>
        );
    }
}
