'use strict';

export default [
    {
        mealType: 'Dinner',
        plural: 'Dinners',
        threshold: 100,
        prop: 'dinners',
        tags: {
            main: 'Main Dish',
            side: 'Side Dish',
        },
    },
    {
        mealType: 'Lunch',
        plural: 'Lunches',
        prop: 'lunches',
        threshold: 75,
        tags: {
            main: 'Lunch',
            side: 'Lunch Side Dish',
        },
    },
    {
        mealType: 'Breakfast',
        plural: 'Breakfasts',
        prop: 'breakfasts',
        threshold: 50,
        tags: {
            main: 'Breakfast',
            side: 'Breakfast Side Dish',
        },
    },
    {
        mealType: 'Snack',
        plural: 'Snacks',
        prop: 'snacks',
        threshold: 20,
        tags: {
            main: 'Snack',
        },
    },
];
