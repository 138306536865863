'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from 'react-modal';

import allConditions from '../../../../../tables/conditions';

import modalStyles from '../../../../../jsx-styles/modals';
import '../../../Widgets/Select.scss';
import './AddConditions.scss';

export default class Conditions extends Component {
    static propTypes = {
    };

    constructor(props) {
        super(props);

        this.state = {
            selectedConditions: [],
            isModalOpen: false,
            search: '',
            other: '',
        };
    }

    focused = null;

    autofocus = (el) => {
        if (el && !this.focused) {
            this.focused = el;
            el.focus();
            el.select();
        }

        // Component unmounted. Goodbye.
        if (!el) {
            this.focused = null;
        }
    }

    onOuterAction = (ev) => {
        if (!this.container || !this.dropdown) {
            return;
        }

        const isOuterAction  = (
            !this.container.contains(ev.target) &&
            !this.dropdown.contains(ev.target)
        );

        if (isOuterAction) {
            this.closeDropdown();
        }
    }

    showModal = () => {
        this.setState({isModalOpen: true});
    }

    closeModal = () => {
        this.setState({isModalOpen: false, selectedConditions: [], search: '', other: ''});
    }

    addConditions = () => {
        const conditions = this.state.selectedConditions.map(name => {
            return allConditions.filter(condition => condition.name === name)[0];
        });

        if (this.state.other) {
            conditions.push(this.state.other);
        }

        this.props.onAddConditions(conditions);

        this.closeModal();
    }

    matchFilters = (condition) => {
        const { currentConditions = [] } = this.props;
        const { search } = this.state;

        if (currentConditions.includes(condition.name)) {
            return false;
        }

        // Do we have a search parameter active?
        if (search) {
            const index = [
                condition.name,
            ].map(s => s.toLowerCase()).join(' ');

            return index.indexOf(search.toLowerCase()) !== -1;
        }

        return true;
    }

    toggleCondition = (condition) => {
        const { selectedConditions } = this.state;

        if (selectedConditions.includes(condition.name)) {
            selectedConditions.splice(selectedConditions.indexOf(condition.name), 1);
        } else {
            selectedConditions.push(condition.name);
        }

        this.setState({selectedConditions});
    }

    renderAddConditionsModal = () => {
        const { isModalOpen, search, other, selectedConditions } = this.state;
        const { currentConditions = []  } = this.props;

        if (!isModalOpen) {
            return null;
        }
        const alphaSortCmp = (a, b) => a.name.localeCompare(b.name);

        const conditions = allConditions.filter(this.matchFilters)
                                        .sort(alphaSortCmp);

        return (
            <Modal isOpen={true}
                onRequestClose={this.closeModal}
                closeModal={this.closeModal}
                className="add-remove-nutrients-modal"
                contentLabel="Add Conditions"
                style={modalStyles.largeSquareModal}
                closeTimeoutMS={250}>

                <div className="add-remove-nutrients-container editor-modal-container add-conditions-container">
                    <header className="modal-header">
                        <h2>Add Conditions</h2>
                        <button className="close-btn" onClick={this.closeModal}>
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>
                    </header>

                    <div className="search">
                        <input type="text" placeholder="Search" value={search}
                            ref={this.autofocus}
                            onChange={ev => this.setState({search: ev.target.value})} />
                    </div>

                    <div className="editor-scrollable">
                        <ul>
                            {conditions.map((condition, i) => {
                                return (
                                    <li key={i}>
                                        <label className="global-search-checkbox">
                                            <input type="checkbox" checked={selectedConditions.includes(condition.name)}
                                                onChange={e => this.toggleCondition(condition)} />
                                            <span>{condition.name}</span>
                                        </label>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>

                    <div className="other">
                        <input type="text" placeholder="Other" value={other} onChange={ev => this.setState({other: ev.target.value})} />
                    </div>

                    <footer>
                        <button className="cancel" onClick={this.closeModal}>Cancel</button>
                        <button className="accept" onClick={this.addConditions}>Add</button>
                    </footer>
                </div>
            </Modal>
        );
    }

    render() {
        return (
            <span className="add-conditions add-remove-nutrients">
                <button onClick={this.showModal}>
                    Add Condition
                </button>

                {this.renderAddConditionsModal()}
            </span>
        );
    }
}
