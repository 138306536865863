'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import DrawerMenu from '../../pro/components/Widgets/DrawerMenu.react';

import './PresentMenu.scss';

export default class PresentMenu extends Component {

    static contextTypes = {
        user: PropTypes.object,

        startAddMeal: PropTypes.func,
        startReplaceMeal: PropTypes.func,
        startRepeatMeal: PropTypes.func,
        editMealBatches: PropTypes.func,
        showMealDetails: PropTypes.func,
        startRescheduleMeal: PropTypes.func,
        onRemoveMeals: PropTypes.func,
    };

    render() {
        const { user } = this.context;
        const { inhibit_swap = false, rd_override = false, inhibit_add_swap_modes, default_swap_mode } = (user && user.preferences) || {};
        const { date, mealType, meals, primary } = this.props;
        const { startAddMeal, startReplaceMeal, editMealBatches,
                showMealDetails, startRescheduleMeal, startRepeatMeal, onRemoveMeals } = this.context;
        const isChangeServingsDisabled = !(primary && primary.meal_type === 'fresh'),
              isBatchCookDisabled = isChangeServingsDisabled;

        const addSwapModes = ['smart-choices', 'restaurants', 'favorites', 'ingredients', 'browser', 'use-own-recipe', 'create-custom']
        const allowedModes = addSwapModes.filter((mode) => !inhibit_add_swap_modes?.includes(mode))
        const defaultMode = allowedModes.includes(default_swap_mode) ? default_swap_mode : allowedModes[0]

        const onClickEatOut = () => {
            return primary
                 ? startReplaceMeal(primary, {defaultMode: 'restaurants'})
                 : startAddMeal(date, mealType, {defaultMode: 'restaurants'});
        }

        const onClickStoreBought = () => {
            return primary
                 ? startReplaceMeal(primary, {defaultMode: 'store'})
                 : startAddMeal(date, mealType, {defaultMode: 'store'});
        }

        const onClickChooseFavorites = () => {
            return primary
                 ? startReplaceMeal(primary, {defaultMode: 'favorites'})
                 : startAddMeal(date, mealType, {defaultMode: 'favorites'});
        }

        return (
            <DrawerMenu key="ellipsis" button={<i className="icon-ellipsis2" />}
                className="ellipsis-menu-btn" modalClassName="feed-present-menu" menuTitle="More Options">
                <div className="feed-menu-content">
                    {startAddMeal && allowedModes.length ?
                        <button className="menu-item add-food" onClick={() => startAddMeal(date, mealType, {defaultMode, allowedModes})}>
                            Add food <i className="icon-add-circle-outline2" />
                        </button>
                    : null}

                    {editMealBatches && (!inhibit_swap || rd_override) ?
                        <button disabled={isBatchCookDisabled} className="menu-item edit-meal-servings" onClick={() => editMealBatches(primary)}>
                            Batch Cook <i className="icon-stacks" />
                        </button>
                    : null}

                    {startRescheduleMeal && (!inhibit_swap || rd_override) ?
                        <button disabled={!primary} className="menu-item reschedule" onClick={() => startRescheduleMeal(meals)}>
                            Reschedule <i className="icon-calendar7" />
                        </button>
                    : null}

                    {primary.meal_type !== 'leftover' && startRepeatMeal && (!inhibit_swap || rd_override) ?
                        <button disabled={!primary} className="menu-item reschedule" onClick={() => startRepeatMeal(meals)}>
                            Repeat Meal <i className="icon-calendar7" />
                        </button>
                    : null}

                    {showMealDetails ?
                        <button disabled={primary ? false : true} className="menu-item view-details" onClick={() => showMealDetails(meals)}>
                            View meal details <i className="icon-pencil-notebook" />
                        </button>
                    : null}

                    {editMealBatches && (!inhibit_swap || rd_override) ?
                        <button disabled={isChangeServingsDisabled} className="menu-item change-participants" onClick={() => editMealBatches(primary)}>
                            Change servings <i className="icon-silverware4" />
                        </button>
                    : null}

                    {onRemoveMeals && (!inhibit_swap || rd_override) ?
                        <button disabled={!primary} className="menu-item skip-meal" onClick={() => onRemoveMeals(meals)}>
                            remove this meal <i className="icon-trash-can4" />
                        </button>
                    : null}
                </div>
            </DrawerMenu>
        );
    }
}
