"use strict";

import { Component } from "react";
import PropTypes from "prop-types";

import ImgResized from "../../Widgets/ImgResized.react";
import SmartFraction from "../../Widgets/SmartFraction.react";

import { roundForHumans, inflectUnitOfMeasure } from "../../../utils/Math";

import "./CardAbstract.scss";

export default class FoodCard extends Component {
    static propTypes = {
        food: PropTypes.object,
    };

    static contextTypes = {
        addSwapContext: PropTypes.object,
        user: PropTypes.object,

        onSelectFood: PropTypes.func,
        showFoodDetails: PropTypes.func,
    };

    onClick = () => {
        const { onSelectFood, showFoodDetails } = this.context;
        const { food, organicRanking, searchRanking, resultType, searchTerm, sortBy, sortOrder } = this.props;

        if (onSelectFood) {
            onSelectFood(food, undefined, undefined, undefined, undefined, undefined, {organicRanking, searchRanking, resultType, term: searchTerm, sortBy, sortOrder});
        } else if (showFoodDetails) {
            showFoodDetails(food);
        }
    };

    render = () => {
        const { food } = this.props;
        const { user = {} } = this.context;
        const { hide_nutrition = false } = user.preferences || {};

        let calories = (food.nutrients && food.nutrients.values && food.nutrients.values[208]) || 0;
        let serving_size = food.serving_unit === "ml" ? food.milliliters_per_serving : food.grams_per_serving;
        let servingDescription = serving_size === 100 ? "100 grams" : "serving";

        if (food.default_unit && food.default_unit.amount && serving_size) {
            let { amount, grams, milliliters } = food.default_unit;
            let uom = inflectUnitOfMeasure(amount, food.default_unit);

            if (food.serving_unit === "ml" && milliliters) {
                calories = (calories / serving_size) * milliliters;
            } else if (grams) {
                calories = (calories / serving_size) * grams;
            } else {
                amount = 1;
                uom = "serving";
            }

            servingDescription = (
                <span>
                    <SmartFraction value={amount} showZero={true} /> {uom}
                </span>
            );
        }

        return (
            <li className="results-mini-card" onClick={this.onClick}>
                {food.image ? (
                    <ImgResized className="thumb" src={food.image} width={100} height={100} />
                ) : (
                    <i className="icon-logo3 thumb" />
                )}
                <span
                    className="title"
                    title={[food.brand_name, food.pretty_name || food.name].filter((v) => v).join(" - ")}
                >
                    {food.pretty_name || food.name}
                </span>

                <span className="l2">
                    {food.brand_name ? <span className="brand">{food.brand_name}</span> : null}

                    {!hide_nutrition ? (
                        <span className="cals">
                            {roundForHumans(calories)} kcal per {servingDescription}
                        </span>
                    ) : null}
                </span>
            </li>
        );
    };
}
