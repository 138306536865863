'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './AddMealRx.scss';

export default class AddMealRx extends Component {
    static propTypes = {
    };

    render() {
        const { mealType, addMealRx } = this.props;

        return (
            <div className="add-meal-rx">
                <button onClick={addMealRx}>Add {mealType} Limits</button>
            </div>
        );
    }
}
