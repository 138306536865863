'use strict';

import { Component } from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import debounce from 'lodash.debounce';

import UserStore from '../../stores/UserStore';
import AuthStore from '../../stores/AuthStore';

import { getConfig } from '../../utils/Env';
import { getQueryFromParams } from '../../utils/Search';
import { getConfigurationIssues, generateProfileKey, profileKeyComparator,
         getMealSearchParamsForProfile } from '../../pro/utils/Patients';

import allMealTypes from '../../tables/meal-types';

import './ConfigWarning.scss';

export default class ConfigWarning extends Component {
    static propTypes = {
        // Technically, a patient object will work too.
        profile: PropTypes.object,
        className: PropTypes.string,
    };

    static defaultProps = {
        profile: UserStore.getUser(),
    };

    constructor(props) {
        super(props);

        this.state = {

        };

        this.debounceSyncAssets = debounce(this.syncAssets, 750);

        // Don't re-run analysis for three seconds after inhibited
        this.resetInhibitAnalysis = debounce(this.resetInhibitAnalysis, 3000);
    }

    inhibitAnalysis = false

    resetInhibitAnalysis = () => {
        this.inhibitAnalysis = false;
    }

    componentDidMount = () => {
        this.syncAssets();
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        this.debounceSyncAssets();
    }

    syncAssets = async () => {
        const { profile } = this.props;


        if (this.inhibitAnalysis) {
            this.debounceSyncAssets();
            return;
        }

        this.inhibitAnalysis = true;

        if (!profile) {
            return this.resetInhibitAnalysis();
        }

        // If all meals are turned off, we should not prompt, or regenerate.
        const { breakfasts, lunches, dinners, snacks } = profile.preferences || {};
        if (!breakfasts && !lunches && !dinners && !snacks) {
            return this.resetInhibitAnalysis();
        }

        // Has the profile changed since the last time we analyzed it? If not, then we don't need to do anything.
        const { profile_key } = this.state;
        const { isParticipantsChanged = true, isProfileChanged = true } = profile_key ? profileKeyComparator(profile, profile_key) : {};

        if (!(isProfileChanged || isParticipantsChanged)) {
            return this.resetInhibitAnalysis();
        }

        const { warnings, errors } = getConfigurationIssues(this.props.profile);

        // If there are errors, set them immediately and no need to continue.
        if (errors.length) {
            this.setState({warnings: [], errors, profile_key: generateProfileKey(profile)}, this.resetInhibitAnalysis);
            return;
        }

        const qualifying = {};
        for (const { threshold, mealType, plural, prop } of allMealTypes) {
            if (mealType === 'Snack') {
                continue;
            }

            if (!profile.preferences[prop]) {
                continue;
            }

            const params = getMealSearchParamsForProfile(mealType, profile);

            params.size = 0; // we don't need results, we just want to know the total

            const response = await AuthStore.fetch(getConfig('recipe_api') + '/search', {
                method: 'POST',
                headers: {'Content-Type': 'application/json; schema=search/advanced/1'},
                body: JSON.stringify(params),
            }, true);

            qualifying[mealType] = {
                total: response.total,
                params,
            };

            if (response.total && response.total < threshold) {
                warnings.push(`Please note, the selected nutrition prescription combined with food avoidances may result in a reduced variety of ${mealType.toLowerCase()} options.`);
            } else if (!response.total) {
                errors.push(`No ${plural.toLowerCase()} could be found that match your profile, we will not be able to build a meal plan matching your profile.`);
            }
        }

        this.setState({qualifying, warnings, errors, profile_key: generateProfileKey(profile)});

        this.inhibitAnalysis = false;
    }

    render = () => {
        const { className } = this.props;
        const { warnings, errors, qualifying } = this.state;

        const user = UserStore.getUser();

        return (
            <span>
                {errors && errors.length ?
                    <ul className={classNames('config-error', className)}>
                        <li>{errors[0]}</li>
                    </ul>
                : null}
                {warnings && warnings.length > 0 && !(errors && errors.length) ?
                    <ul className={classNames('config-warning', className)}>
                        <li>{warnings[0]}</li>
                    </ul>
                : null}

                {qualifying && user && user.role === 'admin' ?
                    <ul className="matching-dishes">
                        {Object.keys(qualifying).map((mealType, i) => {
                            const mt = allMealTypes.find(m => m.mealType === mealType);

                            return (
                                <li key={i}>
                                    <Link to={{pathname: '/search', query: getQueryFromParams(qualifying[mealType].params)}}>
                                        <em>{qualifying[mealType].total}</em> {mt.plural}
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                : null}

            </span>
        );
    }
}
