'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import debounce from 'lodash.debounce';

import NutrientEntry from './NutrientEntry.react';
import PercentEntry from './PercentEntry.react';

import './NutrientEditor.scss';
import './MacroTotals.scss';
import allNutrients from '../../../../../tables/nutrients';
import { roundForHumans } from '../../../../../utils/Math';

const macroNutrNos = ['203', '204', '205'];

export default class MacroTotals extends Component {
    static propTypes = {
    };

    hasMinMax = (min, max) => {
        const fMin = parseFloat(min),
              fMax = parseFloat(max);

        const sMin = (String(min) || '').trim(),
              sMax = (String(max) || '').trim();

        return {
            min: sMin.length > 0 && typeof fMin === 'number' && !isNaN(fMin),
            max: sMax.length > 0 && typeof fMin === 'number' && !isNaN(fMax),
        }
    }

    computePercentAndSpreadFromRange = (nutrNo, range, kcal) => {

        let mode = 'kcal';
        let percentage = '';

        let { min = '', max = '', per_min = '', per_max = '',
              g_per_kg_min = '', g_per_kg_max = '' } = range;

        let spread = 0.05;

        const has = this.hasMinMax(min, max);

        if (macroNutrNos.includes(nutrNo)) {
            per_min = allNutrients[nutrNo].calories_per_gram * min / kcal.min;
            per_max = allNutrients[nutrNo].calories_per_gram * max / kcal.max;
        }

        if (g_per_kg_min || g_per_kg_max) {
            mode = 'g/kg';
            per_min = g_per_kg_min;
            per_max = g_per_kg_max;

            if (g_per_kg_min && g_per_kg_max) {
                percentage = Math.round((g_per_kg_min + g_per_kg_max) / 2 * 10) / 10;
                spread = (g_per_kg_max - g_per_kg_min) / 2;
            } else if (g_per_kg_min && !g_per_kg_max) {
                percentage = Math.round(g_per_kg_min * 10) / 10;
            } else if (!g_per_kg_min && g_per_kg_max) {
                percentage = Math.round(g_per_kg_max * 10) / 10;
            }
        } else {
            if (has.min && has.max) {
                percentage = Math.round((per_min + per_max) / 2 * 100);
                spread = (per_max - per_min) / 2;
            } else if (has.min && !has.max) {
                percentage = Math.round(per_min * 100);
            } else if (!has.min && has.max) {
                percentage = Math.round(per_max * 100);
            }
        }

        min = String(min);
        max = String(max);

        return { mode, min, max, per_min, per_max, percentage, spread };
    }

    render() {
        const { envelope } = this.props;

        const pro = this.computePercentAndSpreadFromRange('203', envelope['203'], envelope['208']),
              fat = this.computePercentAndSpreadFromRange('204', envelope['204'], envelope['208']),
              cho = this.computePercentAndSpreadFromRange('205', envelope['205'], envelope['208']);

        if (pro.mode !== 'kcal' || fat.mode !== 'kcal' || cho.mode !== 'kcal') {
            return <span />
        }

        const total = (pro.percentage || 0) + (fat.percentage || 0) + (cho.percentage || 0);

        return (
            <div className="nutrient-editor macro-nutrient">

                <div className="col-1">
                    <label></label>
                </div>


                <div className="col-2">
                    <div className="percentage-total entry-container percent-entry" data-mode="total">
                        <input type="number" value={total}
                            ref="input" disabled={true}
                            className="numeric-entry"
                            data-error={false} />
                        <span className="mode-entry-suffix">%</span>
                        <button className="mode-toggle">KCAL</button>
                    </div>
                </div>

                <div className="col-3"/>
                <div className="col-4">
                    {total !== 100 ?
                        <div className="entry-container macro-warning">
                            <i className="icon-warning" /> Macro percentages do not total 100%
                        </div>
                    : null}
                </div>
                <div className="col-5"/>


            </div>
        );
    }
}
