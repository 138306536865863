'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import Select from '../../Widgets/Select.react';
import Popup from '../../../../components/Widgets/Popup.react';

import { isPatientChild } from '../../../utils/Patients';

import './MealPreferences.scss';
import './PatientForm.scss';

export default class MealPreferences extends Component {
    static contextTypes = {
        user: PropTypes.object,
    };

    static propTypes = {
    };

    constructor(props) {
        super(props);

        const { patient  = {}} = props;
        const { preferences = {} } = patient;

        // Use whichever the first of them is that has a non-zero value
        const shopping_freq = preferences.shopping_freq || 3;

        const showAvoidKeywords = preferences.avoid_keywords ? true : false;

        this.state = {
            breakfasts: preferences.breakfasts > 0,
            lunches: preferences.lunches > 0,
            dinners: preferences.dinners > 0,
            snacks: preferences.snacks > 0,
            leftovers_enabled: preferences.leftovers_enabled ? true : false,
            max_leftover_days: preferences.max_leftover_days,
            limit_tags: preferences.limit_tags || [],
            shopping_freq,

            skill_level: preferences.skill_level,
            breakfast_keywords: preferences.breakfast_keywords,
            lunch_keywords: preferences.lunch_keywords,
            dinner_keywords: preferences.dinner_keywords,
            snack_keywords: preferences.snack_keywords,
            avoid_keywords: preferences.avoid_keywords,

            breakfast_max_time: preferences.breakfast_max_time,
            lunch_max_time: preferences.lunch_max_time,
            dinner_max_time: preferences.dinner_max_time,
            meal_kit_providers: preferences.meal_kit_providers || [],
            showAvoidKeywords,

            dirty: false,
            
            breakfast_max_cost_per_serving: preferences.breakfast_max_cost_per_serving,
            lunch_max_cost_per_serving: preferences.lunch_max_cost_per_serving,
            snack_max_cost_per_serving: preferences.snack_max_cost_per_serving,
            dinner_max_cost_per_serving: preferences.dinner_max_cost_per_serving,
        };
    }

    isDirty = () => {
        return this.state.dirty;
    }

    validate = () => {
        const { breakfasts, lunches, dinners } = this.state;
        
        if (!breakfasts && !lunches && !dinners) {
            this.setState({error: 'meal-type'});

            return 'Please select at least one main meal (breakfast, lunch or dinner) to include in plans.';
        }

        const {
            breakfast_max_cost_per_serving,
            lunch_max_cost_per_serving,
            snack_max_cost_per_serving,
            dinner_max_cost_per_serving } = this.state;

        if (breakfast_max_cost_per_serving && breakfast_max_cost_per_serving < 3.25) {
            this.setState({error_field: 'breakfast_max_cost_per_serving'});

            return 'Minimum cost per serving for breakfast is $3.25 USD';
        }

        if (lunch_max_cost_per_serving && lunch_max_cost_per_serving < 5) {
            this.setState({error_field: 'lunch_max_cost_per_serving'});

            return 'Minimum cost per serving for lunch is $5 USD';
        }

        if (dinner_max_cost_per_serving && dinner_max_cost_per_serving < 5) {
            this.setState({error_field: 'dinner_max_cost_per_serving'});

            return 'Minimum cost per serving for dinner is $5 USD';
        }

        if (snack_max_cost_per_serving && snack_max_cost_per_serving < 3) {
            this.setState({error_field: 'snack_max_cost_per_serving'});

            return 'Minimum cost per serving for snack is $3 USD';
        }

        this.setState({error_field: null});
        return true;
    }

    mutate = (patient) => {
        const { shopping_freq, breakfasts, lunches, dinners, snacks, skill_level,
                breakfast_keywords, lunch_keywords, dinner_keywords, snack_keywords, avoid_keywords,
                leftovers_enabled, max_leftover_days, limit_tags,
                breakfast_max_time, lunch_max_time, dinner_max_time, meal_kit_providers, breakfast_max_cost_per_serving, 
                lunch_max_cost_per_serving, snack_max_cost_per_serving, dinner_max_cost_per_serving } = this.state;

        patient.preferences = patient.preferences || {};
        patient.preferences.limit_tags = limit_tags;
        patient.preferences.breakfasts = breakfasts ? shopping_freq : 0;
        patient.preferences.lunches = lunches ? shopping_freq : 0;
        patient.preferences.dinners = dinners ? shopping_freq : 0;
        patient.preferences.snacks = snacks ? shopping_freq : 0;
        patient.preferences.shopping_freq = shopping_freq;
        patient.preferences.leftovers_enabled = leftovers_enabled ? true : false;
        patient.preferences.max_leftover_days = max_leftover_days;

        patient.preferences.breakfast_keywords = breakfast_keywords;
        patient.preferences.lunch_keywords = lunch_keywords;
        patient.preferences.dinner_keywords = dinner_keywords;
        patient.preferences.snack_keywords = snack_keywords;
        patient.preferences.avoid_keywords = avoid_keywords;
        patient.preferences.skill_level = skill_level;

        patient.preferences.breakfast_max_time = breakfast_max_time;
        patient.preferences.lunch_max_time = lunch_max_time;
        patient.preferences.dinner_max_time = dinner_max_time;
        patient.preferences.breakfast_max_cost_per_serving = breakfast_max_cost_per_serving;
        patient.preferences.lunch_max_cost_per_serving = lunch_max_cost_per_serving;
        patient.preferences.snack_max_cost_per_serving = snack_max_cost_per_serving;
        patient.preferences.dinner_max_cost_per_serving = dinner_max_cost_per_serving;
        patient.preferences.meal_kit_providers = meal_kit_providers || [];
        patient.completed = patient.completed || [];

        if (!patient.completed.includes('meals')) {
            patient.completed.push('meals');
        }

        // @todo - need to store family record here as well.

        return patient;
    }

    onChangeMaxCostPerServing = (mealType, event) => {
        let updatedValue;
        if(!event.target.value) {
            updatedValue = null;
        } else {
            updatedValue = parseFloat(event.target.value);
        }
        switch(mealType) {
            case "breakfast":
                this.setState({breakfast_max_cost_per_serving: updatedValue, dirty: true});
                break;
            case "lunch":
                this.setState({lunch_max_cost_per_serving: updatedValue, dirty: true});
                break;
            case "snack":
                    this.setState({snack_max_cost_per_serving: updatedValue, dirty: true});
                break;
            case "dinner":
                this.setState({dinner_max_cost_per_serving: updatedValue, dirty: true});
                break;
        }
    }

    onChangeBreakfastMaxTime = (breakfast_max_time) => {
        this.setState({breakfast_max_time, dirty: true});
    }

    onChangeLunchMaxTime = (lunch_max_time) => {
        this.setState({lunch_max_time, dirty: true});
    }

    onChangeDinnerMaxTime = (dinner_max_time) => {
        this.setState({dinner_max_time, dirty: true});
    }

    onChangeLeftovers = (value) => {
        let { leftovers_enabled, max_leftover_days } = this.props;

        if (value === 'disabled') {
            leftovers_enabled = false;
        } else {
            leftovers_enabled = true;
            max_leftover_days = value;
        }

        this.setState({leftovers_enabled, max_leftover_days});
    }

    onToggleSunbasket = () => {
        let { meal_kit_providers } = this.state;

        if ((meal_kit_providers || []).includes('sunbasket')) {
            meal_kit_providers = [];
        } else {
            meal_kit_providers = ['sunbasket'];
        }

        this.setState({meal_kit_providers, dirty: true});
    }

    toggleLimitTag = (tag) => {
        const { limit_tags } = this.state;

        if (limit_tags.includes(tag)) {
            limit_tags.splice(limit_tags.indexOf(tag), 1);
        } else {
            limit_tags.push(tag);
        }

        this.setState({limit_tags, dirty: true});
    }

    render() {
        const { user } = this.context;
        const { patient } = this.props;
        const { breakfasts, lunches, dinners, snacks, skill_level, shopping_freq,
                error, showAvoidKeywords, limit_tags,
                leftovers_enabled, max_leftover_days,
                breakfast_keywords, lunch_keywords, dinner_keywords, avoid_keywords,
                breakfast_max_time, lunch_max_time, dinner_max_time, meal_kit_providers, 
                breakfast_max_cost_per_serving, lunch_max_cost_per_serving, snack_max_cost_per_serving, 
                dinner_max_cost_per_serving, error_field } = this.state;

        let tabIndex = 1;

        const skillLevelOpts = [
            {value: '', label: 'Any'},
            {value: 'Beginner', label: 'Beginner'},
            {value: 'Intermediate', label: 'Intermediate'},
            {value: 'Advanced', label: 'Advanced'},
        ];

        const planSizeOpts = [
            {value: 1, label: '1 day'},
            {value: 3, label: '3 days'},
            {value: 5, label: '5 days'},
            {value: 7, label: '7 days'},
        ];

        const kidAgeOpts = [
            {value: 0, label: '0 years'},
            {value: 1, label: '1 year'},
            {value: 2, label: '2 years'},
            {value: 3, label: '3 years'},
            {value: 4, label: '4 years'},
            {value: 5, label: '5 years'},
            {value: 6, label: '6 years'},
            {value: 7, label: '7 years'},
            {value: 8, label: '8 years'},
            {value: 9, label: '9 years'},
            {value: 10, label: '10 years'},
        ];

        const leftoverOpts = [
            {label: 'No leftovers', value: 0},
            {label: '1 day max leftovers', value: 1},
            {label: '2 day max leftovers (recommended)', value: 2},
            {label: '3 day max leftovers', value: 3},

            {label: 'Disable Leftovers Engine (high food waste, recommended only for highly restricted diets)', value: 'disabled'},
        ];

        let leftoverOption = max_leftover_days;

        if (!leftovers_enabled) {
            leftoverOption = 'disabled';
        }

        const maxTimeOpts = [
            {label: 'No limit', value: 0},
            {label: 'Under 15 Minutes', value: 900},
            {label: 'Under 30 Minutes', value: 1800},
            {label: 'Under 1 Hour', value: 3600},
        ];

        const dinnerMaxTimeOpts = [
            {label: 'No limit', value: 0},
            {label: 'Under 30 Minutes', value: 1800},
            {label: 'Under 45 Minutes', value: 2700},
            {label: 'Under 1 Hour', value: 3600},
        ];

        return (
            <div className="meal-preferences patient-form">
                <section className="meal-types-container" data-error={error == 'meal-type'}>
                    <p>Choose which meals should be included in plans</p>

                    <button className="meal-type toggle-button"
                        data-active={breakfasts}
                        tabIndex={tabIndex++}
                        onClick={() => this.setState({breakfasts: !breakfasts, dirty: true})}>
                        <i className="icon-breakfast" />
                        Breakfast
                    </button>

                    <button className="meal-type toggle-button"
                        data-active={lunches}
                        tabIndex={tabIndex++}
                        onClick={() => this.setState({lunches: !lunches, dirty: true})}>
                        <i className="icon-lunch2" />
                        Lunch
                    </button>

                    <button className="meal-type toggle-button"
                        data-active={dinners}
                        tabIndex={tabIndex++}
                        onClick={() => this.setState({dinners: !dinners, dirty: true})}>
                        <i className="icon-dinner" />
                        Dinner
                    </button>

                    <button className="meal-type toggle-button"
                        data-active={snacks}
                        tabIndex={tabIndex++}
                        onClick={() => this.setState({snacks: !snacks, dirty: true})}>
                        <i className="icon-snacks" />
                        Snack
                    </button>
                </section>

                <section className="select-meal-preferences">
                    <div className="with-label skill-level">
                        <label>{isPatientChild(patient) ? 'Cooking Skill of Parent or Guardian' : 'Cooking Skill Level'}</label>
                        <Select value={skill_level}
                            placeholder="Skill Level"
                            options={skillLevelOpts}
                            tabIndex={tabIndex++}
                            onChange={skill_level => this.setState({ skill_level, dirty: true })}>
                            <p>Cooking Skill Level</p>
                        </Select>
                    </div>

                    {user && user.practice_type === 'dietetics' ?
                        <div className="with-label plan-size">
                            <label>Meal Plan Size</label>
                            <Select value={shopping_freq}
                                placeholder="Plan Size"
                                options={planSizeOpts}
                                tabIndex={tabIndex++}
                                onChange={shopping_freq => this.setState({ shopping_freq, dirty: true })}>
                                <p>Plan Size Preference</p>
                            </Select>
                        </div>
                        : null}

                    <div className="with-label leftovers-enabled">
                        <label>Leftovers:</label>
                        <Select value={leftoverOption}
                            options={leftoverOpts}
                            showAbove={true}
                            onChange={this.onChangeLeftovers}>
                            <p>Use Leftovers</p>
                        </Select>
                    </div>
                </section>

                <div className="basic-limit-tag">
                    <label>Simple Recipes Only</label>
                    <button className="el-switch1" data-active={limit_tags.includes('Basic')} onClick={() => this.toggleLimitTag('Basic')}
                        title="A focus on short and simplified recipes with less than 9 ingredients" />
                </div>

                <div className="section-sunbasket el-fonts">
                    <p className="t5"><em>INCLUDE MEAL KITS AND READY MADE MEALS</em></p>

                    <div className='toggle-option-sunbasket'>
                        <label>Sunbasket (US Only)</label>

                        <Popup className="el-popup-dark el-popup-sunbasket-tooltip"
                            positionClassName="el-popup-top-left-center"
                            button={<i className="icon-info" />}
                            buttonTitle="">
                            <p className="t6">Sunbasket Meal Delivery service offers meal kits with easy-to-follow directions and ready-made meals featuring the best seasonal ingredients. Average price per serving: $14.75.</p>

                            <br />

                            <p className="t6">If enabled, Sunbasket meals will be recommended by LENA through {patient.first_name}&apos;s in app meal feed.  Please note, Sunbasket meals will not be available for use in the collections and meal plans you share with {patient.first_name} due to availability limitations.</p>
                        </Popup>

                        <button className="el-switch1" data-active={meal_kit_providers.includes('sunbasket')} onClick={this.onToggleSunbasket} />
                    </div>
                </div>

                <section className="keyword-preferences">
                    <p>What does {patient.first_name} like to eat? (optional)</p>

                    <div className="keyword">
                        <label>Breakfast</label>
                        <div className="input-container">
                            <input type="text" placeholder='e.g., oatmeal, smoothies' value={breakfast_keywords}
                                onChange={ev => this.setState({breakfast_keywords: ev.target.value, dirty: true})} />
                        </div>
                    </div>

                    <div className="keyword">
                        <label>Lunch</label>
                        <div className="input-container">
                            <input type="text" placeholder='e.g., salads' value={lunch_keywords}
                                onChange={ev => this.setState({lunch_keywords: ev.target.value, dirty: true})} />
                        </div>
                    </div>

                    <div className="keyword">
                        <label>Dinner</label>
                        <div className="input-container">
                            <input type="text" placeholder='e.g., chicken' value={dinner_keywords}
                                onChange={ev => this.setState({dinner_keywords: ev.target.value, dirty: true})} />
                        </div>
                    </div>

                    {/*<div className="keyword">
                        <label>Snacks</label>
                        <input type="text" value={snack_keywords}
                            onChange={ev => this.setState({snack_keywords: ev.target.value, dirty: true})} />

                        {!snack_keywords ? <p className="example">e.g., apple, cheese</p> : null}
                    </div>*/}
                </section>

                <div className="keyword-preferences">
                    <p>Preparation time limits</p>
                    <div className="keyword">
                        <label>Breakfast</label>
                        <Select disableTypeAhead={true} options={maxTimeOpts} placeholder="No limit" value={breakfast_max_time || null} onChange={this.onChangeBreakfastMaxTime} />
                    </div>

                    <div className="keyword">
                        <label>Lunch</label>
                        <Select showAbove={true} disableTypeAhead={true} options={maxTimeOpts} placeholder="No limit" value={lunch_max_time || null} onChange={this.onChangeLunchMaxTime} />
                    </div>

                    <div className="keyword">
                        <label>Dinner</label>
                        <Select showAbove={true} disableTypeAhead={true} options={dinnerMaxTimeOpts} placeholder="No limit" value={dinner_max_time || null} onChange={this.onChangeDinnerMaxTime} />
                    </div>
                </div>

                <div className="keyword-preferences">
                    <p>Max cost per serving ($ USD)</p>
                    <div className="max-cost-per-serving">
                        <div className="keyword">
                            <label>Breakfast</label>
                            <div className="input-container">
                                <span>$</span>
                                <input type="number" min="3.25" max="99999" placeholder='NO LIMIT' value={breakfast_max_cost_per_serving} step={0.5} onChange={(e) => this.onChangeMaxCostPerServing("breakfast", e)} />
                            </div>
                        </div>

                        <div className="keyword">
                            <label>Lunch</label>
                            <div className="input-container">
                                <span>$</span>
                                <input type="number" min="5" max="99999" placeholder='NO LIMIT' value={lunch_max_cost_per_serving} step={0.5} onChange={(e) => this.onChangeMaxCostPerServing("lunch", e)} />
                            </div>
                        </div>

                        <div className="keyword">
                            <label>Snack</label>
                            <div className="input-container">
                                <span>$</span>
                                <input type="number" min="3" max="99999" placeholder='NO LIMIT' value={snack_max_cost_per_serving} step={0.5} onChange={(e) => this.onChangeMaxCostPerServing("snack", e)} />
                            </div>
                        </div>

                        <div className="keyword">
                            <label>Dinner</label>
                            <div className="input-container">
                                <span>$</span>
                                <input type="number" min="5" max="99999" placeholder='NO LIMIT' value={dinner_max_cost_per_serving} step={0.5} onChange={(e) => this.onChangeMaxCostPerServing("dinner", e)} />
                            </div>
                        </div>
                    </div>
                </div>

                {showAvoidKeywords ?
                    <section className="keyword-preferences extra-avoidances">
                        <p>Anything else that {patient.first_name} does NOT like to eat? (optional)</p>

                        <div className="keyword">
                            <label>Extra Avoidances</label>
                            <input type="text" value={avoid_keywords}
                                onChange={ev => this.setState({avoid_keywords: ev.target.value, dirty: true})} />

                            {!avoid_keywords ? <p className="example">e.g., carrots, banana</p> : null}
                        </div>

                        <p><em>Beta</em> using extra avoidances will reduce the number of available recipes which may result in failure to generate complete meal plans.</p>
                    </section>
                : null}

                {breakfasts && lunches && dinners && !snacks > 0 ?
                    <p data-active={true} className="warning-msg">You have unselected Snacks, but left the other meals selected. This often will cause an error when building a meal plan, so we highly recommend leaving snacks selected.</p>
                : null}
            </div>
        );
    }
}
