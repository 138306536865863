'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { isParticipating } from '../../../utils/Meals';
import { roundForHumans } from '../../../utils/Math';

import './FamilyMembers.scss';

export default class FamilyMembers extends Component {
    renderFamilyMember = (member, i) => {

        const info = [
            <span key="name">member.name</span>,
        ];

        const age = member.birthdate ? moment().diff(member.birthdate, 'year') : null;

        return (
            <tr key={i}>
                <td className="person-info">
                    <em>{member.name}</em>
                    {age ? <span>, {age} year old</span> : null}
                    {member.gender ? <span>, {member.gender}</span>: null}
                    <span><br />Portion size: {member.portion}</span>
                </td>
                <td><button data-active={member.breakfasts ? true : false}></button></td>
                <td><button data-active={member.lunches ? true : false}></button></td>
                <td><button data-active={member.dinners ? true : false}></button></td>
                <td><button data-active={member.snacks ? true : false}></button></td>
            </tr>
        );
    }

    render = () => {
        const { profile } = this.props;

        if (profile.family.length <= 0) {
            return (
                <div className="patient-family-members">
                    <p>{profile.first_name} eats alone.</p>
                </div>
            );
        }

        const age = profile.birthdate ? moment().diff(profile.birthdate, 'year') : null;

        return (
            <div className="patient-family-members">
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Breakfast</th>
                            <th>Lunch</th>
                            <th>Dinner</th>
                            <th>Snack</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/*<tr>
                            <td className="person-info">
                                {profile.name || profile.first_name || profile.email}
                                {age ? <span>, {age} year old</span> : null}
                                {profile.gender ? <span> {profile.gender}</span>: null}
                                <span><br />Portion size: {profile.portion}</span>
                            </td>
                            <td><button data-active={profile.preferences.breakfasts > 0}></button></td>
                            <td><button data-active={profile.preferences.lunches > 0}></button></td>
                            <td><button data-active={profile.preferences.dinners > 0}></button></td>
                            <td><button data-active={profile.preferences.snacks > 0}></button></td>
                        </tr>*/}
                        {profile.family.map(this.renderFamilyMember)}
                    </tbody>
                </table>
            </div>
        );
    }
}
