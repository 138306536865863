'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import $ from 'jquery';
import indexBy from 'lodash.indexby';

import Paginator from '../../Paginator.react';
import GoogleMap from '../../../Widgets/GoogleMap.react';
import SearchKeywords from '../../Filters/SearchKeywords.react';
import FiltersModal from './Restaurants/FiltersModal.react';
import MacrosPieChart from '../../../Widgets/MacrosPieChart.react';
import LocationInfo from '../../../Products/LocationInfo.react';
import ReportIssue from '../../../Widgets/ReportIssue.react';
import VariancesPopup from '../../../Widgets/VariancesPopup.react';

import FoodDetailsModal from '../FoodDetailsModal.react';

import AuthStore from '../../../../stores/AuthStore'
import UserActions from '../../../../actions/UserActions';
import UserStore from '../../../../stores/UserStore';

import allNutrients from '../../../../tables/nutrients';
import Analytics from '../../../../utils/Analytics';
import { fetchDocumentsById } from '../../../../utils/Content';
import { getConfig } from '../../../../utils/Env';
import { roundForHumans, roundForHumansByUnit, getKmBetweenCoordinates } from '../../../../utils/Math';
import { computeDefaultMealRx, getIdealsFromEnvelope,
         transformEnvelopeForRestaurants, convertEnvelopeToFilters } from '../../../../utils/Nutrition'

const nutrMap = {
    '208': {Units: ' ',   NutrDesc: 'calories'},
    '203': {Units: ' g ', NutrDesc: 'protein'},
    '204': {Units: ' g',  NutrDesc: 'fat'},
    '205': {Units: ' g',  NutrDesc: 'carbs'},
    '307': {Units: 'mg',  NutrDesc: 'sodium'},
};

const nutrKeys = {
    '208': {
        Units: '',
        NutrDesc: 'Calories',
    },
    'VEG': {
        Units: ' servings',
        NutrDesc: "Vegetables",
    },
    'FRU': {
        Units: ' servings',
        NutrDesc: "Fruit",
    },
}

import './Restaurants.scss';
import './Restaurants/NutritionAnalysis.scss';

const nutrientWhitelist = [
    "208","203","204","205","307"
];

export default class Restaurants extends Component {
    static propTypes = {
        mode: PropTypes.string,
    };

    static defaultProps = {
        mode: 'restaurants',
    };

    static contextTypes = {
        addSwapContext: PropTypes.object,
        onSelectProducts: PropTypes.func,
        showCreateCustom: PropTypes.func,

        meals: PropTypes.array,
        recipes: PropTypes.object,
        foods: PropTypes.object,

        confirm: PropTypes.func,
        isMobile: PropTypes.bool,
    };

    static childContextTypes = {
        showFoodDetails: PropTypes.func,
    }

    constructor(props, context) {
        super(props, context);

        // Based on our addSwapContext and meals already scheduled on this date,
        // determine two sets of envelopes for this user.
        // 1. The per-meal envelope for mealType considering other meals today - Good Choice
        // 2. The per-meal envelope bare without modifications - Ok Choice
        const { smartChoice, okChoice, rx } = this.getChoiceEnvelopes(context.addSwapContext, context);

        this.state = {
            locationParams: this.getDefaultLocationParams(context.addSwapContext),
            groupedLocations: [],
            locations: [],
            totalLocations: 0,

            productParams: this.getDefaultProductParams(context.addSwapContext),
            smartChoiceProducts: [],
            totalSmartChoices: null,
            products: [],
            totalProducts: 0,

            loading: true,
            loadingMore: false,
            showAutocomplete: true,
            showSearchHere: false,
            showAllowedOnly: false,
            smartChoice,
            okChoice,
            rx,

            selectedLocation: null,
            selectedBrand: null,
            selectedProducts: [],
            selectedNutrients: {},

            selectedProduct: null,
        };

        this.debounceSearchLocations = debounce(this.searchLocations, 400);
        this.debounceSearchProducts = debounce(this.searchProducts, 400);
    }

    getChildContext = () => {
        return {
            showFoodDetails: this.showFoodDetails,
        };
    }

    componentDidMount = () => {
        this.syncSelectedNutrients();
    }

    getChoiceEnvelopes = ({date, mealType, profile}, {meals, recipes, foods}) => {

        const allDayRx = profile.prescriptions.filter(p => p.meal_type === 'all-day')[0];
        const rx = profile.prescriptions.filter(p => p.meal_type === mealType)[0] ||
                   (allDayRx && computeDefaultMealRx(allDayRx, mealType, true));

        // Make two deep clones of the prescription envelope
        const smartChoice = rx && allDayRx ? transformEnvelopeForRestaurants(rx.envelope, allDayRx.envelope) : null,
              okChoice = rx && allDayRx ? transformEnvelopeForRestaurants(rx.envelope, allDayRx.envelope, true) : null;

        return { smartChoice, okChoice, rx };
    }

    /**
     * Converts the parameters we get from the parent AddSwapRecipe modal to a restaurant search parameters
     * @param  {[type]} inputs [description]
     * @return {[type]}        [description]
     */
    getDefaultLocationParams = ({profile, mealType}) => {
        let mealTypeTag = mealType;

        if (mealTypeTag === 'Dinner') {
            mealTypeTag = 'Main Dish';
        }

        const params = {
            types: ['brand_location'],
            terms: '',
            filters: {
                matching_products: {
                    language: profile.language || 'en',
                    tags: [mealTypeTag],
                },
            },
        };

        return params;
    }

    getDefaultProductParams = ({profile, mealType}, limitToRx = false) => {
        let mealTypeTag = mealType;

        if (mealTypeTag === 'Dinner') {
            mealTypeTag = 'Main Dish';
        }

        const params = {
            language: profile.language || 'en',
            types: ['food'],
            terms: '',
            filters: {
                'tags': [mealTypeTag],
            },
            size: 50,
        };

        return params;
    }

    showFoodDetails = (selectedProduct) => {
        this.setState({selectedProduct});
    }

    resetProductParams = () => {
        const { addSwapContext } = this.context;

        this.onChangeProductParams(this.getDefaultProductParams(addSwapContext, false));
    }

    locationSearchKey = null

    onLocationFound = (currentLocation) => {
        this.onSearchHere();

        // Courtesy update of the user store's location, so we have this exact location available for later.
        // when the map isn't being rendered.
        UserActions.updateCurrentLocation(currentLocation.lat, currentLocation.lon);

        Analytics.userLocated({
            'Context': 'Restaurant Search',
            'Latitude': currentLocation.lat,
            'Longitude': currentLocation.lon,
        });
    }

    onMapInitalized = () => {
        this.onSearchHere();
    }

    computeDistanceToLocationResults = (locations) => {
        if (!this.map) {
            return locations;
        }

        const currentLocation = this.map.currentLocation;

        locations.forEach(location => {
            location.distance_km = getKmBetweenCoordinates(
                currentLocation.lat, currentLocation.lon,
                location.location.lat, location.location.lon
            );
        });

        return locations;
    }

    groupLocations = (locations) => {
        let collector = {};

        locations.forEach(location => {
            collector[location.parent] = collector[location.parent] || [];
            collector[location.parent].push(location);
        });

        Object.keys(collector).forEach(brandUuid => {
            // Sort the collector's locations by their distance

            collector[brandUuid] = collector[brandUuid].sort((a, b) => {
                if (a.distance_km < b.distance_km) return -1;
                if (a.distance_km > b.distance_km) return 1;

                return 0;
            });
        });

        // Sort the collector, changing type from object to array
        collector = Object.values(collector).sort((a, b) => {
            if (a[0].distance_km < b[0].distance_km) return -1;
            if (a[0].distance_km > b[0].distance_km) return 1;

            return 0;
        });

        return collector;
    }

    searchLocations = () => {
        // Deep clone parameters
        const locationSearchKey = JSON.stringify(this.state.locationParams);

        if (locationSearchKey === this.locationSearchKey) {
            return;
        }

        this.locationSearchKey = locationSearchKey;
        const params = JSON.parse(locationSearchKey);
        params.size = 250;

        this.setState({loading: true});

        Analytics.searchRestaurantLocations();

        AuthStore.fetch(getConfig('recipe_api') + '/search', {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=search/advanced/1'},
            body: JSON.stringify(params),
        }, true).then(results => {
            const locations = this.computeDistanceToLocationResults(results.elements);

            // Is the map rendered? Clear, then add the markers
            if (this.map) {
                this.map.deleteMarkers();

                // Create a marker for my current location using the blue marker pin
                this.map.createCurrentLocationMarker();

                locations.forEach(establishment => {
                    establishment.marker = this.map.addMarker(
                        {lat: establishment.location.lat, lng: establishment.location.lon},
                        establishment.title,
                        this.renderEstablishmentInfo(establishment),
                    );
                });
            }

            this.setState({
                locations,
                groupedLocations: this.groupLocations(results.elements),
                totalLocations: results.total,
                loading: false,
                showSearchHere: false,
            });

        }, (error) => this.setState({loading: false}));
    }

    massageVariances = (variances) => {
        return (variances || []).filter(variance => {
            const nutrient = nutrKeys[variance.nutrNo] || allNutrients[variance.nutrNo];

            if (!nutrient) {
                return false;
            }

            const { value, min, max } = variance;

            if (min && value < min) {
                let roundedUnder = roundForHumansByUnit(min - value, nutrient.Units);

                if (!roundedUnder) {
                    return false;
                }
            }

            if (max && value > max) {
                let roundedOver = roundForHumansByUnit(value - max, nutrient.Units);

                if (!roundedOver) {
                    return false;
                }
            }

            return true;
        });
    }

    doesEnvelopeMatch = (nutrients, envelope) => {
        let variances = [];

        if (!envelope) {
            return { doesMatch: false, variances };
        }

        Object.keys(envelope).forEach(nutrNo => {
            // Only consider nutrients in our whitelist
            if (!nutrientWhitelist.includes(nutrNo)) {
                return;
            }

            const range = envelope[nutrNo],
                  value = nutrients[nutrNo];

            if (range.min && range.min > value) {

                variances.push({
                    nutrNo,
                    min: range.min,
                    value: value,
                    diff: range.min - value,
                });
            }

            if (range.max && range.max < value) {

                variances.push({
                    nutrNo,
                    max: range.max,
                    value: value,
                    diff: range.max - value,
                });
            }
        });

        variances = this.massageVariances(variances);
        const doesMatch = !variances.length;

        return { doesMatch, variances };
    }

    analyzeResults = (results) => {
        const { addSwapContext } = this.context;
        const { smartChoice, okChoice } = this.state;
        const { profile } = addSwapContext;
        const { avoidances, hide_nutrition = false } = (profile.preferences || {});

        results.forEach(product => {
            if (!(product && product.nutrients && product.nutrients.values)) {
                return;
            }

            const avoids = avoidances.filter(tag => (product.ingredient_tags || []).includes(tag));
            const smart = this.doesEnvelopeMatch(product.nutrients.values, smartChoice),
                  ok    = this.doesEnvelopeMatch(product.nutrients.values, okChoice);

            product.isSmartChoice = hide_nutrition ? false : (avoids.length == 0 && smart.doesMatch);
            product.isOkChoice    = hide_nutrition ? false : (avoids.length == 0 && ok.doesMatch);
            product.variances     = hide_nutrition ? false : smart.variances;
            product.avoids        = hide_nutrition ? false : avoids;
        });

        results.sort((a, b) => {
            if (a.isSmartChoice && !b.isSmartChoice) return -1;
            if (!a.isSmartChoice && b.isSmartChoice) return 1;

            if (a.isOkChoice && !b.isOkChoice) return -1;
            if (!a.isOkChoice && b.isOkChoice) return 1;

            return 0;
        });

        return results;
    }

    productSearchKey = null

    addExclusionFilters = (results, filters = {}) => {
        filters['!uuid'] = filters['!uuid'] || [];
        results.forEach(r => {
            if (!filters['!uuid'].includes(r.uuid)) {
                filters['!uuid'].push(r.uuid);
            }
        });

        return filters
    }

    searchProducts = () => {
        const { selectedLocation } = this.state;
        const { profile } = this.context.addSwapContext;

        // Deep clone parameters
        const productSearchKey = JSON.stringify(this.state.productParams);

        if (productSearchKey === this.productSearchKey) {
            return;
        }

        this.productSearchKey = productSearchKey;
        const params = JSON.parse(productSearchKey);

        // Add smart choices filter & exclusion filters
        const { smartChoice } = this.state;
        params.filters = smartChoice ? convertEnvelopeToFilters(smartChoice, params.filters) : params.filters;

        params.filters['!ingredient_tags'] = (profile.preferences.avoidances);

        this.setState({loading: true});
        AuthStore.fetch(getConfig('recipe_api') + '/search', {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=search/advanced/1'},
            body: JSON.stringify(params),
        }).then(results => {
            this.setState({
                smartChoiceProducts: this.analyzeResults(results.elements),
                totalSmartChoices: results.total,
                loading: false,
            }, () => {
                if (results.elements.length < params.size) {
                    this.searchProductsFallback();
                } else {
                    this.syncSelectedNutrients();
                }
            });
        }, (error) => this.setState({loading: false}));

        Analytics.viewRestaurantMenu({
            'Context': 'Add/Swap',
            location_uuid: selectedLocation.uuid,
            brand_uuid: selectedLocation.parent,
        });
    }

    searchProductsFallback = () => {
        const params = JSON.parse(this.productSearchKey);

        params.filters = this.addExclusionFilters(this.state.smartChoiceProducts, params.filters);

        this.setState({loading: true});
        AuthStore.fetch(getConfig('recipe_api') + '/search', {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=search/advanced/1'},
            body: JSON.stringify(params),
        }).then(results => {
            this.setState({
                products: this.analyzeResults(results.elements),
                totalProducts: results.total,
                loading: false,
            }, this.syncSelectedNutrients);
        }, (error) => this.setState({loading: false}));
    }

    showMoreProducts = () => {
        const { profile } = this.context.addSwapContext;
        const params = JSON.parse(JSON.stringify(this.state.productParams));

        this.setState({loadingMore: true});
        const { smartChoiceProducts, products } = this.state;
        // Add smart choices filter & exclusion filters
        params.filters = this.addExclusionFilters(smartChoiceProducts, params.filters);
        params.filters = this.addExclusionFilters(products, params.filters);
        params.filters = convertEnvelopeToFilters(this.state.smartChoice, params.filters);

        params.filters['!ingredient_tags'] = (profile.preferences.avoidances);

        AuthStore.fetch(getConfig('recipe_api') + '/search', {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=search/advanced/1'},
            body: JSON.stringify(params),
        }).then(results => {
            this.setState({
                smartChoiceProducts: this.state.smartChoiceProducts.concat(this.analyzeResults(results.elements)),
                loadingMore: false,
            });

            if (results.elements.length < params.size) {
                this.searchProductsFallback();
            } else {
                this.syncSelectedNutrients();
            }

        }, (error) => this.setState({loading: false}));
    }

    showMoreProductsFallback = () => {
        const params = JSON.parse(JSON.stringify(this.state.productParams));

        this.setState({loadingMore: true});
        const { smartChoiceProducts, products } = this.state;
        params.filters = this.addExclusionFilters(smartChoiceProducts, params.filters);
        params.filters = this.addExclusionFilters(products, params.filters);

        AuthStore.fetch(getConfig('recipe_api') + '/search', {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=search/advanced/1'},
            body: JSON.stringify(params),
        }).then(results => {
            this.setState({
                products: this.state.products.concat(this.analyzeResults(results.elements)),
                loadingMore: false,
            });
        }, (error) => this.setState({loading: false}));
    }

    realizeMap = (map) => {
        this.map = map;
    }

    onSearchHere = () => {
        if (!this.map) {
            return;
        }

        const bounds = this.map.getBounds();

        if (!bounds) {
            return;
        }

        const { locationParams } = this.state;

        locationParams.filters = locationParams.filters || {};
        locationParams.filters.geo_bounds = locationParams.filters.geo_bounds || {};

        // Transform top/right & bottom/left <= to => top/left & bottom/right
        const ne = bounds.getNorthEast(),
              sw = bounds.getSouthWest();

        locationParams.filters.geo_bounds.top = ne.lat();
        locationParams.filters.geo_bounds.left = sw.lng();
        locationParams.filters.geo_bounds.bottom = sw.lat();
        locationParams.filters.geo_bounds.right = ne.lng();

        this.setState({locationParams}, this.searchLocations);
    }

    onPlaceChanged = () => {
        this.onSearchHere();
    }

    onBoundsChanged = (bounds) => {
        this.setState({showSearchHere: true});
    }

    onChangeLocationParams = (locationParams) => {
        this.setState({locationParams}, this.debounceSearchLocations);
    }

    onChangeProductParams = (productParams) => {
        this.setState({productParams}, this.debounceSearchProducts);
    }

    onFocusSearch = () => {
        this.setState({showAutocomplete: true});
    }

    onBlurSearch = () => {
        this.setState({showAutocomplete: false});
    }

    onSelectBrandLocation = (selectedLocation) => {
        const { productParams } = this.state;
        const { confirm } = this.context;

        productParams.terms = '';
        productParams.filters.brand_uuid = selectedLocation.parent;

        this.setState({selectedLocation, productParams}, () => {
            const $scrollable = $(this.scrollable);

            $('.add-swap-scroll-container').animate({scrollTop: 0}, 250, null); // cheep hack! (but it works)
            $scrollable.animate({scrollLeft: $scrollable.innerWidth()}, 250, null, () => {
                setTimeout(() => {
                    confirm(
                        <div className="nutrition-disclaimer">
                            <h2>Nutrition Disclaimer</h2>
                            <p>Nutrition info on this menu is estimated. Please check ingredients before purchasing to ensure any of your avoidances are not included. Menu items may change without notice and not be available in all locations.</p>
                            <p>PLEASE NOTE: Some nutrition and allergen data are not available for restaurant foods. Your nutrition profile may include parameters that are not supported in this feature due to limited data availability. Please exercise care when consuming restaurant food.</p>
                        </div>,
                        accept => false,
                        null,
                        {acceptText: 'Got it!', rejectText: null},
                    );
                }, 1000);
            });

            this.searchProducts();
        });

        fetchDocumentsById([selectedLocation.parent]).then(docs => {
            const selectedBrand = docs[0];

            if (selectedBrand) {
                this.setState({selectedBrand});
            }
        });
    }

    returnToLocations = () => {
        const $scrollable = $(this.scrollable);

        $scrollable.animate({scrollLeft: 0}, 250, null, () => {
            setTimeout(() => {
                this.setState({selectedLocation: null, selectedBrand: null});
            }, 250)
        });
    }

    realizeScroller = (scrollable) => {
        this.scrollable = scrollable;
    }

    showFiltersModal = () => {
        Analytics.advancedSearch({'Context': 'Restaurant Search'});
        this.setState({isFiltersModalOpen: true});
    }

    closeModal = () => {
        this.setState({isFiltersModalOpen: false, selectedProduct: null});
    }

    renderEstablishmentInfo = (location) => {
        const { title, address1, address2, distance_km, yelp_price, matching_products } = location;
        const { units_mode = 'english' } = UserStore.getUser() || {};

        return (
            <div className="establishment-info" onClick={() => this.onSelectBrandLocation(location)}>
                <p className="matching">{matching_products} {matching_products === 1 ? 'dish' : 'dishes'}</p>
                <h3>{title}</h3>
                <p>
                    {units_mode === 'metric' ? <span>{roundForHumans(distance_km)}km - {address1}</span> : null}
                    {units_mode === 'english' ? <span>{roundForHumans(distance_km * 1.60934)}mi - {address1}</span> : null}
                    <span className="yelp-price"> - <em>{('$').repeat(yelp_price)}</em></span>
                </p>
            </div>
        );
    }

    renderLocationGroupResult = (group, i) => {
        const closest = group[0];
        const { units_mode = 'english' } = UserStore.getUser() || {};

        return (
            <div key={i} className="location-result-item" onClick={() => this.onSelectBrandLocation(closest)}>
                <span className="matching">{closest.matching_products} {closest.matching_products === 1 ? 'dish' : 'dishes'}</span>

                <h3>{closest.title}</h3>
                <p>
                    {units_mode === 'metric'
                        ? <span>{roundForHumans(closest.distance_km)}km</span>
                        : <span>{roundForHumans(closest.distance_km * 1.60934)}mi</span>
                    }
                    <span>- {closest.address1}</span>
                    <span className="yelp-price"> - <em>{('$').repeat(closest.yelp_price)}</em>{('$').repeat(4-closest.yelp_price)}</span>
                </p>
            </div>
        );
    }

    syncSelectedNutrients = () => {
        const { selectedProductPortions, smartChoice, smartChoiceProducts, products } = this.state;
        const { profile, mealType } = this.context.addSwapContext;

        const selectableProductUuids = smartChoiceProducts.map(r => r.uuid).concat(products.map(r => r.uuid));

        // Ensure that selectedProducts uuids are always included in either smartChoiceProducts or products array
        const selectedProducts = this.state.selectedProducts.filter(uuid => selectableProductUuids.includes(uuid));

        const index = indexBy(products.concat(smartChoiceProducts), 'uuid');
        const selectedNutrients = {};

        // First, aggregate the nutrients from the selected products
        selectedProducts.forEach(uuid => {
            const product = index[uuid];

            if (!(product && product.nutrients && product.nutrients.values)) {
                return;
            }

            const { logged_portion = 1, logged_unit, logged_amount, logged_grams } = (selectedProductPortions && selectedProductPortions[product.uuid]) || {};

            // Aggregate nutrients from the selected products. We ignore the users portion size here
            // because products are not sold in variable portion sizes. You eat what you get from the store.
            Object.keys(product.nutrients.values).forEach(nutrNo => {

                const value = product.nutrients.values[nutrNo] || 0;
                selectedNutrients[nutrNo] = selectedNutrients[nutrNo] || 0;

                if (logged_grams) {
                    selectedNutrients[nutrNo] += value / product.grams_per_serving * logged_grams;
                } else {
                    selectedNutrients[nutrNo] += value * logged_amount;
                }
            });

        });

        // Second, find or generate the rx for this profile / mealType
        const allDayRx = profile.prescriptions.filter(p => p.meal_type === 'all-day')[0];

        if (!allDayRx) {
            this.setState({selectedProducts, selectedNutrients, remaining: {}});
            return;
        }
        const rx = profile.prescriptions.filter(p => p.meal_type === mealType)[0] ||
                   (allDayRx && computeDefaultMealRx(allDayRx, mealType, true));

        // Third, count the remaining amounts left / over for macros and calories.
        const remaining = {};
        [208, 203, 204, 205, 307].forEach(nutrNo => {
            // If the user is not tracking this macro, we can't calculate how much should remain. Skip it.
            if (!smartChoice[nutrNo]) {
                return;
            }

            // If for some weird reason the min is filled in, but the max isn't, we can subsitute the min value for
            // the max value. Don't tell anyone tho ;)
            const max = smartChoice[nutrNo].max || smartChoice[nutrNo].min;
            const value = (selectedNutrients[nutrNo] || 0);

            remaining[nutrNo] = {
                percent: value < max ? Math.round(value / max * 100) : 100,
                diff: max - value,
            };
        });

        this.setState({selectedProducts, selectedNutrients, remaining});
    }

    toggleProduct = (uuid) => {
        let { selectedProducts } = this.state;

        if (selectedProducts.includes(uuid)) {
            selectedProducts.splice(selectedProducts.indexOf(uuid), 1);
        } else {
            selectedProducts.push(uuid);
        }

        this.setState({selectedProducts}, this.syncSelectedNutrients);

        return false;
    }

    addSelectedProducts = () => {
        const { smartChoiceProducts, products, selectedProducts, selectedLocation } = this.state;
        const { onSelectProducts } = this.context;

        if (!(selectedProducts && selectedProducts.length)) {
            return;
        }

        const allProducts = smartChoiceProducts.concat(products);

        onSelectProducts(
            selectedProducts.map(uuid => allProducts.find(p => p.uuid === uuid)),
            selectedLocation
        );
    }

    renderProductResult = (product, i) => {
        const { selectedProducts } = this.state;
        const { isMobile } = this.context;
        const { date, profile } = this.context.addSwapContext
        const { hide_nutrition = false, rd_override = false } = (profile && profile.preferences) || {};

        let choiceType = 'normal';

        if (product.isSmartChoice) {
            choiceType = 'smart';
        } else if (product.isOkChoice) {
            choiceType = 'ok';
        }

        const kcalPerServing = (product.nutrients && product.nutrients.values && product.nutrients.values[208]) || 0;
        let kcal = kcalPerServing;
        const variancesPopupPositionClass = isMobile ? "el-popup-top-right-center" : "el-popup-right-bottom";

        return (
            <li key={i} className="product-result-item" data-choice-type={choiceType}
                data-selected={selectedProducts && selectedProducts.includes(product.uuid)}>
                <button className="select-product-btn"
                    data-selected={selectedProducts && selectedProducts.includes(product.uuid)}
                    onClick={(ev) => this.toggleProduct(product.uuid)}>
                    &nbsp;
                </button>

                <div className="product-stats">
                    {(product?.variances?.length || product?.avoids?.length) ? <VariancesPopup contextName="Restaurant Meal" variances={product.variances} avoidances={product.avoids} positionClass={variancesPopupPositionClass}/> : null}

                    {choiceType !== 'normal' ?
                        <span className="choice-type">
                            {choiceType} choice
                        </span>
                    : null}

                    {!(hide_nutrition || rd_override) ? <span className="kcal">{roundForHumans(kcal)}</span> : null}
                </div>

                <div className="product-result-container">
                    <h4 onClick={() => this.showFoodDetails(product)}>{product.name}</h4>
                    {product.description ? <p onClick={() => this.showFoodDetails(product)}>{product.description}</p> : product.description}
                    <p onClick={() => this.showFoodDetails(product)}>
                        {product.serving_description ?
                            <span>Serving Size: {product.serving_description}</span>
                        : null}
                        {product.grams_per_serving && !(hide_nutrition || rd_override) ?
                            <span>{product.grams_per_serving} grams</span>
                        : null}
                    </p>
                </div>
            </li>
        );
    }

    showCreateCustom = () => {
        const { showCreateCustom } = this.context;

        showCreateCustom({
            defaultCustomType: 'restaurant',
        });
    }

    renderSelectedLocation = (selectedLocation) => {
        const { loading, loadingMore, selectedBrand, selectedNutrients, selectedProducts, remaining, rx,
                products, productParams, totalProducts, smartChoiceProducts, totalSmartChoices } = this.state;
        const { profile } = this.context.addSwapContext;

        if (!selectedLocation) {
            return null;
        }

        const currentLocation = (this.map && this.map.currentLocation);
        const defaultNutrients = getIdealsFromEnvelope(rx?.envelope || {}, 1);
        const { hide_nutrition = false, rd_override = false } = profile?.preferences || {};

        return (
            <div className="slider-panel product-results">
                <header>
                    <button className="return-to-locations-btn" onClick={this.returnToLocations}>
                        <i className="icon-chevron-left" />Return to Restaurant List
                    </button>
                </header>

                <LocationInfo brand={selectedBrand} location={selectedLocation} currentLocation={currentLocation} />

                {!(hide_nutrition || rd_override) ? <section className="nutrition-analysis">
                    <h3>Recommended Meal Limits:</h3>

                    {remaining[208] && remaining[208].diff > 0 ?
                        <div className="calories-left">
                            <em>{Math.round(remaining[208].diff)}</em> cals left
                        </div>
                    : null}

                    {remaining[208] && remaining[208].diff < 0 ?
                        <div className="calories-over">
                            <em>{Math.abs(Math.round(remaining[208].diff))}</em> cals over
                        </div>
                    : null}

                    <MacrosPieChart nutrients={selectedProducts && selectedProducts.length ? selectedNutrients : defaultNutrients} />

                    {remaining[205] && remaining[205].diff > 0 ?
                        <div className="macro-left">
                            <em>CARBS</em>
                            <div className="small-progress-bar">
                                <div className="small-progress-bar-fill" style={{width: (remaining[205].percent + '%')}}></div>
                            </div>
                            {Math.round(remaining[205].diff)} g left
                        </div>
                    : null}

                    {remaining[205] && remaining[205].diff < 0 ?
                        <div className="macro-over">
                            <em>CARBS</em>
                            <div className="small-progress-bar">
                                <div className="small-progress-bar-fill" style={{width: '100%'}}></div>
                            </div>
                            {Math.abs(Math.round(remaining[205].diff))} g over
                        </div>
                    : null}

                    {remaining[203] && remaining[203].diff > 0 ?
                        <div className="macro-left">
                            <em>PROTEIN</em>
                            <div className="small-progress-bar">
                                <div className="small-progress-bar-fill" style={{width: (remaining[203].percent + '%')}}></div>
                            </div>
                            {Math.round(remaining[203].diff)} g left
                        </div>
                    : null}
                    {remaining[203] && remaining[203].diff < 0 ?
                        <div className="macro-over">
                            <em>PROTEIN</em>
                            <div className="small-progress-bar">
                                <div className="small-progress-bar-fill" style={{width: '100%'}}></div>
                            </div>
                            {Math.abs(Math.round(remaining[203].diff))} g over
                        </div>
                    : null}


                    {remaining[204] && remaining[204].diff > 0 ?
                        <div className="macro-left">
                            <em>FAT</em>
                            <div className="small-progress-bar">
                                <div className="small-progress-bar-fill" style={{width: (remaining[204].percent + '%')}}></div>
                            </div>
                            {Math.round(remaining[204].diff)} g left
                        </div>
                    : null}
                    {remaining[204] && remaining[204].diff < 0 ?
                        <div className="macro-over">
                            <em>FAT</em>
                            <div className="small-progress-bar">
                                <div className="small-progress-bar-fill" style={{width: '100%'}}></div>
                            </div>
                            {Math.abs(Math.round(remaining[204].diff))} g over
                        </div>
                    : null}

                    {remaining[307] && remaining[307].diff > 0 ?
                        <div className="macro-left">
                            <em>SODIUM</em>
                            <div className="small-progress-bar">
                                <div className="small-progress-bar-fill" style={{width: (remaining[307].percent + '%')}}></div>
                            </div>
                            {Math.round(remaining[307].diff)} mg left
                        </div>
                    : null}
                    {remaining[307] && remaining[307].diff < 0 ?
                        <div className="macro-over">
                            <em>SODIUM</em>
                            <div className="small-progress-bar">
                                <div className="small-progress-bar-fill" style={{width: '100%'}}></div>
                            </div>
                            {Math.abs(Math.round(remaining[307].diff))} mg over
                        </div>
                    : null}
                </section> : null}

                <section className="product-results-list" data-showing-nutrients={!(hide_nutrition || rd_override)}>
                    <header>
                        <SearchKeywords params={productParams}
                            className="product-keyword-search"
                            onChangeParams={this.onChangeProductParams}
                            placeholder="Search menu items" />

                        <button className="open-filters-btn" onClick={this.showFiltersModal}>
                            <i className="icon-settings4" />
                        </button>
                    </header>

                    <ul>
                        {smartChoiceProducts.map(this.renderProductResult)}
                        {products.map(this.renderProductResult)}
                    </ul>

                    <footer>
                        <Paginator loading={loading}
                            loadingMore={loadingMore}
                            total={totalSmartChoices}
                            results={smartChoiceProducts}
                            showMore={this.showMoreProducts} />

                        <Paginator loading={loading}
                            loadingMore={loadingMore}
                            total={totalProducts}
                            results={products}
                            showMore={this.showMoreProductsFallback} />
                    </footer>
                </section>

                <footer>
                    <ReportIssue location={selectedLocation} />
                    <button className="cancel-btn" onClick={this.returnToLocations}>Cancel</button>
                    <button disabled={selectedProducts.length ? false : true} className="ok-btn" onClick={this.addSelectedProducts}>
                        Add{selectedProducts.length ? (' (' + selectedProducts.length + ')') : null}
                    </button>
                </footer>
            </div>

        );
    }

    renderFiltersModal = () => {
        const { productParams, isFiltersModalOpen } = this.state;

        if (!isFiltersModalOpen) {
            return;
        }

        return <FiltersModal params={productParams}
            onChangeParams={this.onChangeProductParams}
            closeModal={this.closeModal} />
    }


    renderProductDetailsModal = () => {
        const { selectedProduct, selectedLocation } = this.state;

        if (!selectedProduct) {
            return;
        }

        const onSelectProduct = (product) => {
            this.toggleProduct(product.uuid);
            this.closeModal();
        }

        return <FoodDetailsModal closeModal={this.closeModal}
                    hideFoodUnitSelector={true}
                    food={selectedProduct}
                    location={selectedLocation}
                    onSelectFood={onSelectProduct} />
    }

    render() {
        const { loading, locations, groupedLocations, locationParams,
                selectedLocation, showAutocomplete, showSearchHere } = this.state;

        return (
            <div className="brands-db-location-search" ref={this.realizeScroller}
                data-product-results={selectedLocation ? true : false}>
                <div className="location-inner-slider">
                    {!selectedLocation ? (
                        <div className="slider-panel location-results">
                            <div className="outer-map-container">
                                <SearchKeywords params={locationParams}
                                    onFocus={this.onFocusSearch}
                                    className="map-keywords-filter"
                                    onChangeParams={this.onChangeLocationParams}
                                    placeholder="Search" />

                                <GoogleMap ref={this.realizeMap}
                                    onBoundsChanged={this.onBoundsChanged}
                                    onPlaceChanged={this.onPlaceChanged}
                                    onMapInitalized={this.onMapInitalized}
                                    onLocationFound={this.onLocationFound}
                                    onSearchHere={this.onSearchHere}
                                    showSearchHere={showSearchHere}
                                    showAutocomplete={showAutocomplete} />
                            </div>

                            <div className="outer-results-container">
                                <div className="inner-results-container">
                                    <ReportIssue />

                                    {loading ?
                                        <div className="loading">
                                            <h2>Loading...</h2>
                                            <i className="icon-spinner" />
                                        </div>
                                    : null}

                                    {groupedLocations.map(this.renderLocationGroupResult)}

                                    {!loading && locations.length == 0 && locationParams.terms ?
                                        <div className="no-results-found">
                                            <h2>Sorry, we couldn't find {locationParams.terms}</h2>
                                            <p>We don’t currently have this store’s meal information. If you would like to still eat foods from this store, tell us what you will be eating there so we can track nutrition information.</p>

                                            <footer>
                                                <button className="create-meal-btn" onClick={this.showCreateCustom}>Create a Meal</button>
                                            </footer>
                                        </div>
                                    : null}

                                    {!loading && locations.length == 0 && !locationParams.terms ?
                                        <div className="no-results-found">
                                            <h2>Sorry, we couldn't find anything in this area</h2>
                                            <p>Thanks for your patience. We are working on adding new restaurants every day.</p>
                                            <p>In the meantime, create a custom meal for your favorite local restaurant. This will allow you to track this meal in your planner and log.</p>
                                            <footer>
                                                <button className="create-meal-btn" onClick={this.showCreateCustom}>Create a Custom Meal</button>
                                            </footer>
                                        </div>

                                    : null}

                                </div>
                            </div>
                        </div>)
                    : null }
                    {this.renderSelectedLocation(selectedLocation)}
                </div>

                {this.renderFiltersModal()}
                {this.renderProductDetailsModal()}
            </div>
        );
    }
}
