'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import Browser from '../Search/Browser.react';
import SearchKeywords from '../Search/Filters/SearchKeywords.react';

import './SavedRecipes.scss';

export default class SavedRecipes extends Component {

    static propTypes = {
    };

    static defaultProps = {
    };

    static contextTypes = {
    };

    constructor(props) {
        super(props);

        this.state = {
            params: {
                types: ['recipe'],
                filters: {
                    is_owner: true,
                    library: true,
                },
                size: 20,
            },

            isAdvancedFiltersOpen: false,
        };
    }

    onShowAdvancedFilters = () => {
        this.setState({isAdvancedFiltersOpen: true});
    }

    onHideAdvancedFilters = () => {
        this.setState({isAdvancedFiltersOpen: false});
    }

    onExecuteSearch = (params) => {
    }

    onChangeParams = (params) => {
        this.setState({params});
    }

    render = () => {
        const { closeModal, modalTitle, setCreateMode } = this.props;
        const { position, isAdvancedFiltersOpen, params } = this.state;
        const { isMobile } = this.context;

        return (
            <div className="saved-recipes">
                <header>
                    <SearchKeywords params={params}
                        onChangeParams={this.onChangeParams}
                        className="full-browser-keyword-search"
                        placeholder="Search My Saved Recipes" />

                    <button className="advanced-filters-btn" onClick={this.onShowAdvancedFilters}>
                        <i className="feather feather-filter" />
                    </button>
                </header>

                {setCreateMode ?
                    <div className="new-recipe-btn-container">
                        <button className=" el-medium-btn el-medium-wide-btn el-raspberry-btn el-btn-icon-left" onClick={setCreateMode}>
                            <i className="icon-plus-thin"/>
                            new recipe
                        </button>
                    </div>
                : null}

                <Browser contextName="Saved Recipes"
                    params={params}
                    noResulsNoTermsCopy={"You haven’t added any recipes."}
                    onChangeParams={this.onChangeParams}
                    allowedTypes={['recipe']}
                    inhibitSearchOnMount={false}
                    showTypePicker={false}
                    onShowAdvancedFilters={this.onShowAdvancedFilters}
                    onHideAdvancedFilters={this.onHideAdvancedFilters}
                    onExecuteSearch={this.onExecuteSearch}
                    isAdvancedFiltersOpen={isAdvancedFiltersOpen} />
            </div>
        );
    }

}
