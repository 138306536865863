import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class ProfileFilter extends Component {

    toggleProfileFilter = () => {
        const { params, profile, onChangeParams } = this.props;

        if (profile) {
            const { diets, avoidances } = profile.preferences || {};

            delete params.profile;

            params.filters['tags'] = (params.filters['tags'] || []).filter(tag => !diets?.includes(tag));
            params.filters['!ingredient_tags'] = (params.filters['!ingredient_tags'] || []).filter(avoid => !avoidances?.includes(avoid));
        }

        onChangeParams(params);
    };

    render() {
        const { params, profile } = this.props;

        if (!profile || !params.profile) {
            return null;
        }

        return (
            <div className="param-item" onClick={this.toggleProfileFilter}>
                {params.profile.name} <i className="icon-close-x" />
            </div>
        );
    }
}
