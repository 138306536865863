'use strict';

import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from 'react-modal';
import debounce from 'lodash.debounce';
import store from 'store';

import TryItOutForm from '../../../../components/TryItOut/TryItOutForm.react';
import Prescription from '../../../components/Patients/Editor/Prescription.react';

import UserActions from '../../../../actions/UserActions';
import UserStore from '../../../../stores/UserStore';
import allConditions from '../../../../tables/conditions.js';

import { processVirtualPlans } from '../../../../utils/Plans';
import Analytics from '../../../../utils/Analytics';
import { getConfig } from '../../../../utils/Env';
import AuthStore from '../../../../stores/AuthStore';

import { getConfigurationIssues, getPatientDemographic, getParamsForProfile } from '../../../utils/Patients';

import './TryItOutModal.scss';
import '../../Modals/Modals.scss';
import modalStyles from '../../../../jsx-styles/modals';

const needCalorieEstimate = ['Pregnancy', 'Lactation', 'Overweight\/Obesity'];

export default class TryItOutModal extends Component {
    static propTypes = {
        page: PropTypes.string,
        profile: PropTypes.object,
    };

    static contextTypes = {
        router: PropTypes.object,
        location: PropTypes.object,
    };

    static defaultProps = {
        page: 'try_it_out'
    };

    constructor(props) {
        super(props);
        this.tryItOut = createRef();
        this.prescription = createRef();
        const { uuid } = UserStore.getUser();

        this.state = {
            profile: props.profile || null,
            error: false,
            working: false,
            flash: 0,
            page: props.page,
            user_uuid: uuid,
        }

        this.tickFlash = debounce(this.tickFlash, 200);
    }

    onGenerateMealPlan = (plan, altUser) => {
        UserActions.setAlternateUser(altUser);

        this.context.router.push({pathname: `/menus/${plan.uuid}/customize`, query: {virtual: 1, altPatient: 1}});
    }

    onReviewPrescription = () => {
        const profile = this.tryItOut.current.getProfileFromState();

        this.setState({profile, page: 'prescription'})
    }

    tickFlash = () => {
        let { flash } = this.state;

        flash++;

        if (flash > 2) {
            return this.setState({flash: 0});
        }

        this.setState({flash}, this.tickFlash);
    }

    validate = (altUser) => {
        const { birthdate, gender, conditions } = altUser;

        // Inapproriate condition
        let inapp = false;
        conditions.forEach(name => {
            const condition = allConditions.filter(cd => cd.name === name)[0];

            if (!condition) {
                return;
            }

            // We have to use some extra defaults here if gender and/or birthdate aren't defined,
            // then use a 29 year old female as the defaults
            const demographic = getPatientDemographic(
                {
                    ...altUser,
                    gender: gender || 'female',
                    birthdate: birthdate || moment().subtract(29, 'years').format(),
                },
                condition.demographics
            );

            if (!condition.templates[demographic]) {
                inapp = condition;
            }
        });

        if (inapp) {
            const name = inapp.special_name || inapp.consumer_name || inapp.name;

            const error = `We're sorry! ${name} is not compatible with your selection. Please revise to continue.`;

            Analytics.tryItOutError(error);
            return {error};
        }

        // Configuration error
        const { errors } = getConfigurationIssues(altUser);

        if (errors.length) {
            Analytics.tryItOutError(errors[0]);
            return {error: errors[0]};
        }

        return false;
    }

    getMealPlan = (skipLoadEstimator = false) => {

        let { profile, user_uuid } = this.state;

        if (skipLoadEstimator !== true && needCalorieEstimate.includes(profile.conditions[0])) {
            return this.tryItOut.current.showEnergyEstimator(true);
        }

        let alert = false;

        if (false !== (alert = this.validate(profile))) {
            this.setState(alert);
            return;
        }

        // Generate a meal plan for that alternate user
        this.setState({error: false, working: true});

        if (this.prescription && this.prescription.current.mutate) {
            profile = this.prescription.current.mutate(profile);
        }

        const params = getParamsForProfile(profile, 'generator');

        params.size = 1;

        AuthStore.fetch(getConfig('recipe_api') + '/plan-generator', {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=vplan/parameters/1'},
            body: JSON.stringify(params),
        }, true).then(
            response => {
                if (!(response.elements && response.elements.length)) {
                    Analytics.tryItOutError('Meal Plan could not be generated');
                    this.setState({error: 'A meal plan could not be generated. Please revise your selections and try again.', working: false});
                    return;
                }

                // Lastly, redirect to the generated meal plan and tell the customizer to use the alternate user.
                const plans = processVirtualPlans(response.elements);

                store.remove(`pro-try-it-out-${user_uuid}`);

                this.onGenerateMealPlan(plans[0], profile);
            },
            error => {
                Analytics.tryItOutError(error.message);
                this.setState({error: error.message, working: false});
            }
        );
    }

    render() {
        const { closeModal } = this.props;
        const { location } = this.context;
        const { error, flash, profile, page, working, user_uuid } = this.state;

        const isEmptyStateDashboard = this.props.page == 'prescription';
        const ctaText = profile && page == 'prescription'  ? "GENERATE MEAL PLAN" : "NEXT: REVIEW PRESCRIPTION";

        let conditionsList = allConditions.filter(c => c.on_pro);

        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                className="el-modal el-modal2"
                contentLabel="Try It Out"
                style={modalStyles.largeSquareModal}
                closeTimeoutMS={250}>

                <div className="el-modal-container el-modal2-container try-it-out-modal-container">
                    <div className="modal-badge">
                        Meal Plan Wizard
                    </div>

                    <header className="modal-header">
                        <h2>{page == 'try_it_out'  ? "Show Me a Meal Plan For" : "Review Nutrition Prescription"}</h2>
                        <button className="el-modal-close-x" onClick={closeModal}>
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>
                    </header>

                    <div className="el-modal-body-container el-modal2-body-container el-fonts" data-prescription={page == 'prescription'}>
                        {profile && page == 'prescription' ? <Prescription ref={this.prescription} isMealPlanWizard={true} patient={profile}/> : null}
                        {!profile || page == 'try_it_out' ?
                            <TryItOutForm working={working} location={location} ref={this.tryItOut}
                                onReviewPrescription={this.onReviewPrescription} conditionsList={conditionsList}
                                needCalorieEstimate={needCalorieEstimate} storageKey={`pro-try-it-out-${user_uuid}`} tryItMode="pro" />
                        : null}
                    </div>

                    <footer>
                        {error ?
                            <p className="error-msg">{error}</p>
                        : null}
                        {profile && page == 'prescription' ?
                            <button className="el-modal-cancel-btn" onClick={() => isEmptyStateDashboard ? closeModal() : this.setState({page: 'try_it_out'})}>
                                BACK
                            </button>
                        : null}
                        <button data-flash={flash} className="el-modal-ok-btn" onClick={profile && page == 'prescription'  ? this.getMealPlan : this.onReviewPrescription}>
                             {working ? <span>Calculating <i className="icon-spinner" /></span> : ctaText}
                        </button>
                    </footer>
                </div>
            </Modal>
        );
    }
}
