'use strict';

import '../utils/Polyfills';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import { Router, Route, Redirect, IndexRoute, browserHistory } from 'react-router';
import uuid from 'uuid';
import cookie from 'cookie-monster';

// initalize date localization
import moment from 'moment-timezone';
import momentLocalizer from 'react-widgets-moment';
moment.locale('en');
momentLocalizer();

// Setup localstorage plugin
import store from 'store';
import storeExpire from 'store/plugins/expire';
store.addPlugin(storeExpire);

// Primary Pages
import Root from './Root.react';
import Dashboard from './pages/Dashboard.react';

// Miscellaneous classes
import AuthActions from '../actions/AuthActions';
import ABTest from '../utils/ABTest';
import Analytics from '../utils/Analytics';
import { setConfig, cleanupLocalStorage, getMref, setMref } from '../utils/Env';
import Sentry from '../utils/Sentry';
import SessionRecorder from '../utils/SessionRecorder';

const DEVICE_ID_COOKIE = 'device-id';

class Pro extends Component {
    static childContextTypes = {
        instance_id: PropTypes.string,
    };

    constructor(props) {
        super(props);

        // eslint-disable-next-line no-undef
        __webpack_public_path__ = props.asset_path + '/';

        const instance_id = uuid.v4();

        const device_id = cookie.getItem(DEVICE_ID_COOKIE) || uuid.v4();
        cookie.setItem(DEVICE_ID_COOKIE, device_id, {
            expires: moment().add(10, 'years').toDate().toUTCString(),
            domain: props.self_host.replace('pro.', '.'),
            path: '/',
            secure: true,
        });

        // Configure environmental configuration
        setConfig('instance_id', instance_id);
        setConfig('device_id', device_id);

        Object.keys(props).forEach((key) => {
            setConfig(key, props[key]);
        });

        setConfig('www_host', props.self_host.replace(/pro\./, 'www.'));
        setConfig('pro_host', props.self_host.replace(/www\./, 'pro.'));

        // Setup global error state handler
        window.onerror = function (msg, url, line, col, error) {
            try {
                Analytics.trackUncaughtException(error);
            } catch (exp) {
                // we threw an additional exception, ignore it, nothing we can do.
            }
        };

        // Pro doesn't have much state
        this.state = {
            instance_id,
        };

        let history = browserHistory;

        // Did props tell  us to use a different history mechanism?
        switch (props.history_method) {
            case 'hash':
                // eslint-disable-next-line no-undef
                history = hashHistory;
                break;
        }

        // Init Sentry for error log and distributed tracing
        Sentry.setup({
            routingInstrumentation: [history, this.routes],
        });

        AuthActions.setup();
    }

    getChildContext = () => {
        return {
            instance_id: this.state.instance_id,
        };
    };

    onPageUpdate = (args) => {
        $('body').removeClass('ReactModal__Body--open').removeAttr('aria-hidden');
    };

    lastRoute = null;

    resetScroll = (param) => {
        const newRoute = param.routes[param.routes.length - 1];

        if (!this.lastRoute || (this.lastRoute && newRoute != this.lastRoute)) {
            $(window).scrollTop(0);
            this.lastRoute = newRoute;
        }
    };

    onEnterApp = (nextState) => {
        let hasQueryParams = nextState && nextState.location && nextState.location.query;

        // Record the merchant reference in localstorage and cookie. We'll need this when the user subscribes.
        if (hasQueryParams && nextState.location.query.mref) {
            setMref(nextState.location.query.mref);
        }

        if (hasQueryParams && nextState.location.query.ref) {
            store.set('referred_by', nextState.location.query.ref, new Date().getTime() + 1000 * 3600 * 24);
        }

        if (hasQueryParams && nextState.location.query.email) {
            store.set('default-email', nextState.location.query.email);
        }

        const mvars = store.get('m_var') || {};
        let isNewCampaign = false;

        // creating an object we can throw any future marketing variables into
        // using this for analytics and cohorts
        if (
            hasQueryParams &&
            nextState.location.query.utm_campaign &&
            (!mvars || !mvars.campaign || nextState.location.query.utm_campaign != mvars.campaign)
        ) {
            isNewCampaign = true;
            let marketing_variables = {
                source: nextState.location.query.utm_source,
                campaign: nextState.location.query.utm_campaign || null,
                medium: nextState.location.query.utm_medium || null,
                content: nextState.location.query.utm_content || null,
                sref: nextState.location.query.sref || null,
            };
            store.set('m_var', marketing_variables);
        }

        Analytics.initialize();
        Analytics.launchApp(isNewCampaign);
        ABTest.initializeTests();

        // Cleanup local storage now, then again every 15 minutes.
        cleanupLocalStorage();
        setInterval(
            () => {
                cleanupLocalStorage();
            },
            15 * 60 * 1000
        );

        return true;
    };

    render() {
        const { resetScroll } = this;

        SessionRecorder.config(browserHistory, true);

        return (
            <Router history={browserHistory} onUpdate={this.onPageUpdate}>
                <Route path="/" component={Root} onEnter={this.onEnterApp}>
                    <IndexRoute component={Dashboard} onEnter={resetScroll} />

                    <Route
                        path="new-account"
                        onEnter={resetScroll}
                        getComponent={(l, cb) =>
                            require.ensure([], (require) => cb(null, require('./pages/Register.react').default))
                        }
                    />

                    <Route
                        path="clients"
                        onEnter={resetScroll}
                        getComponent={(l, cb) =>
                            require.ensure([], (require) => cb(null, require('./pages/Patients/Search.react').default))
                        }
                    />

                    <Route
                        path="messages(/:jid)"
                        onEnter={resetScroll}
                        getComponent={(l, cb) =>
                            require.ensure([], (require) => cb(null, require('./pages/Messages.react').default))
                        }
                    />

                    <Route
                        path="recover-account"
                        onEnter={resetScroll}
                        getComponent={(l, cb) =>
                            require.ensure([], (require) => cb(null, require('../pages/RecoverAccount.react').default))
                        }
                    />

                    <Route
                        path="clients/:uuid"
                        onEnter={resetScroll}
                        component={require('./pages/Patients/Details.react').default}
                    >
                        <IndexRoute
                            onEnter={resetScroll}
                            getComponent={(l, cb) =>
                                require.ensure([], (require) =>
                                    cb(null, require('./pages/Patients/Activity.react').default)
                                )
                            }
                        />
                        <Route
                            path="activity"
                            onEnter={resetScroll}
                            getComponent={(l, cb) =>
                                require.ensure([], (require) =>
                                    cb(null, require('./pages/Patients/Activity.react').default)
                                )
                            }
                        />
                        <Route
                            path="messages"
                            onEnter={resetScroll}
                            getComponent={(l, cb) =>
                                require.ensure([], (require) =>
                                    cb(null, require('./pages/Patients/Messages.react').default)
                                )
                            }
                        />
                        <Route
                            path="meals"
                            onEnter={resetScroll}
                            getComponent={(l, cb) =>
                                require.ensure([], (require) =>
                                    cb(null, require('./pages/Patients/Meals.react').default)
                                )
                            }
                        />
                        <Route
                            path="log"
                            onEnter={resetScroll}
                            getComponent={(l, cb) =>
                                require.ensure([], (require) =>
                                    cb(null, require('./pages/Patients/DailyLog.react').default)
                                )
                            }
                        />
                        <Route
                            path="notes"
                            onEnter={resetScroll}
                            getComponent={(l, cb) =>
                                require.ensure([], (require) =>
                                    cb(null, require('./pages/Patients/Notes.react').default)
                                )
                            }
                        />
                        <Route
                            path="nutrition"
                            onEnter={resetScroll}
                            getComponent={(l, cb) =>
                                require.ensure([], (require) =>
                                    cb(null, require('./pages/Patients/Nutrition.react').default)
                                )
                            }
                        />
                        <Route
                            path="care-plan"
                            onEnter={resetScroll}
                            getComponent={(l, cb) =>
                                require.ensure([], (require) =>
                                    cb(null, require('./pages/Patients/CarePlan.react').default)
                                )
                            }
                        />
                        <Route
                            path="preferences"
                            onEnter={resetScroll}
                            getComponent={(l, cb) =>
                                require.ensure([], (require) =>
                                    cb(null, require('./pages/Patients/Preferences.react').default)
                                )
                            }
                        />
                        <Route
                            path="settings"
                            onEnter={resetScroll}
                            getComponent={(l, cb) =>
                                require.ensure([], (require) =>
                                    cb(null, require('./pages/Patients/Settings.react').default)
                                )
                            }
                        />
                    </Route>

                    <Redirect from="patients/:uuid/messages" to="clients/:uuid/messages" />
                    <Redirect from="patients/:uuid/meals" to="clients/:uuid/meals" />
                    <Redirect from="patients/:uuid/log" to="clients/:uuid/log" />
                    <Redirect from="patients/:uuid/notes" to="clients/:uuid/notes" />
                    <Redirect from="patients/:uuid/nutrition" to="clients/:uuid/nutrition" />
                    <Redirect from="patients/:uuid/care-plan" to="clients/:uuid/care-plan" />
                    <Redirect from="patients/:uuid/preferences" to="clients/:uuid/preferences" />
                    <Redirect from="patients/:uuid/settings" to="clients/:uuid/settings" />
                    <Redirect from="patients/:uuid/activity" to="clients/:uuid/activity" />
                    <Redirect from="patients/:uuid" to="clients/:uuid" />
                    <Redirect from="patients" to="clients" />

                    <Redirect from="dietitians" to="team" />
                    <Route
                        path="team"
                        onEnter={resetScroll}
                        getComponent={(l, cb) =>
                            require.ensure([], (require) =>
                                cb(null, require('./pages/Dietitians/Search.react').default)
                            )
                        }
                    />

                    <Route
                        path="accept-invite"
                        onEnter={resetScroll}
                        getComponent={(l, cb) =>
                            require.ensure([], (require) => cb(null, require('./pages/AcceptInvite.react').default))
                        }
                    />

                    <Route
                        path="unsubscribe"
                        onEnter={resetScroll}
                        getComponent={(l, cb) =>
                            require.ensure([], (require) => cb(null, require('../pages/Unsubscribe.react').default))
                        }
                    />

                    <Route
                        path="learn"
                        onEnter={resetScroll}
                        getComponent={(l, cb) =>
                            require.ensure([], (require) => cb(null, require('./pages/LearnPro.react').default))
                        }
                    />

                    <Route
                        path="client-tutorials"
                        onEnter={resetScroll}
                        getComponent={(l, cb) =>
                            require.ensure([], (require) => cb(null, require('./pages/ClientTutorials.react').default))
                        }
                    />

                    <Route
                        path="menus/:uuid"
                        onEnter={resetScroll}
                        getComponent={(l, cb) =>
                            require.ensure([], (require) => cb(null, require('../pages/Menus/Viewer.react').default))
                        }
                    />

                    <Route
                        path="menus/:uuid/customize"
                        onEnter={resetScroll}
                        getComponent={(l, cb) =>
                            require.ensure([], (require) => cb(null, require('./pages/Menus/Customizer.react').default))
                        }
                    />

                    <Route
                        path="recipes/:uuid"
                        onEnter={resetScroll}
                        getComponent={(l, cb) =>
                            require.ensure([], (require) => cb(null, require('../pages/Recipes/Details.react').default))
                        }
                    />

                    <Redirect from="recipes" to="search" />

                    {/* - MY ACCOUNT PAGES - */}
                    <Route
                        path="my-account"
                        onEnter={resetScroll}
                        component={require('./pages/MyAccount.react').default}
                    >
                        <Route
                            path="settings"
                            onEnter={resetScroll}
                            getComponent={(l, cb) =>
                                require.ensure([], (require) =>
                                    cb(null, require('./pages/MyAccount/UserMeta.react').default)
                                )
                            }
                        />

                        <Route
                            path="notifications"
                            onEnter={resetScroll}
                            getComponent={(l, cb) =>
                                require.ensure([], (require) =>
                                    cb(null, require('./pages/MyAccount/Notifications.react').default)
                                )
                            }
                        />

                        <Route
                            path="practice"
                            onEnter={resetScroll}
                            getComponent={(l, cb) =>
                                require.ensure([], (require) =>
                                    cb(null, require('./pages/MyAccount/Practice.react').default)
                                )
                            }
                        />

                        <Redirect from="subscription" to="membership" />
                        <Redirect from="membership" to="/membership" />

                        <Route
                            path="professional"
                            onEnter={resetScroll}
                            getComponent={(l, cb) =>
                                require.ensure([], (require) =>
                                    cb(null, require('./pages/MyAccount/ProfessionalInfo.react').default)
                                )
                            }
                        />

                        <IndexRoute
                            onEnter={resetScroll}
                            getComponent={(l, cb) =>
                                require.ensure([], (require) =>
                                    cb(null, require('./pages/MyAccount/UserMeta.react').default)
                                )
                            }
                        />
                    </Route>

                    <Route
                        path="membership"
                        onEnter={resetScroll}
                        toolTitle="Membership"
                        getComponent={(l, cb) =>
                            require.ensure([], (require) => cb(null, require('./pages/Membership.react').default))
                        }
                    />

                    <Route
                        path="toolkit"
                        onEnter={resetScroll}
                        getComponent={(l, cb) =>
                            require.ensure([], (require) => cb(null, require('./pages/Toolkit.react').default))
                        }
                    />

                    <Route
                        path="nutrition-academy"
                        onEnter={resetScroll}
                        getComponent={(l, cb) =>
                            require.ensure([], (require) => cb(null, require('./pages/NutritionAcademy.react').default))
                        }
                    />

                    <Route
                        path="export-grocery"
                        onEnter={resetScroll}
                        getComponent={(l, cb) =>
                            require.ensure([], (require) => cb(null, require('./pages/ExportGrocery.react').default))
                        }
                    />

                    <Route
                        path="food-banks"
                        onEnter={resetScroll}
                        toolTitle="Food Bank Finder"
                        getComponent={(l, cb) =>
                            require.ensure([], (require) => cb(null, require('../pages/FoodBanks.react').default))
                        }
                    />

                    <Route
                        path="invite"
                        onEnter={resetScroll}
                        getComponent={(l, cb) =>
                            require.ensure([], (require) => cb(null, require('./pages/Invite.react').default))
                        }
                    />

                    <Route
                        path="favorites"
                        onEnter={resetScroll}
                        toolTitle="Favorites"
                        getComponent={(l, cb) =>
                            require.ensure([], (require) => cb(null, require('../pages/Favorites.react').default))
                        }
                    />

                    <Route
                        path="search"
                        onEnter={resetScroll}
                        toolTitle="Search for Recipes"
                        getComponent={(l, cb) =>
                            require.ensure([], (require) => cb(null, require('../pages/Search.react').default))
                        }
                    />

                    <Route
                        path="redeem-ceu"
                        onEnter={resetScroll}
                        toolTitle="Redeem CEU"
                        getComponent={(l, cb) =>
                            require.ensure([], (require) => cb(null, require('./pages/RedeemCEU.react').default))
                        }
                    />
                </Route>
            </Router>
        );
    }
}

window.Pro = Pro;
window.React = React;
window.ReactDOM = ReactDOM;
window.moment = moment;
window.setMref = setMref;
window.getMref = getMref;

export default Sentry.withProfiler(Pro);
