'use strict';

import assign from 'object-assign';
import { EventEmitter } from 'events';
import qs from 'qs';
import cookie from 'cookie-monster';
import store from 'store';
import moment from 'moment';
import * as Sentry from '@sentry/react';

import UserConstants from '../constants/UserConstants';
import AuthConstants from '../constants/AuthConstants';
import AppDispatcher from '../dispatcher/AppDispatcher';
import Analytics from '../utils/Analytics';
import MealStore from './MealStore';
import GroceryStore from './GroceryStore';

import { getConfig } from '../utils/Env';
import SessionRecorder from '../utils/SessionRecorder';

const CHANGE_EVENT = 'change';
const COOKIE_KEY_PREFIX = 'auth-tokens-';
const TOKEN_KEY_PREFIX = 'auth-tokens-';

let _auth = {
    links: store.get(COOKIE_KEY_PREFIX + 'links'),
    storage: 'cookie',
};

function setToken(name, token, expires) {
    if (_auth.storage === 'local') {
        store.set(TOKEN_KEY_PREFIX + name, token, expires.toDate().getTime());
    } else if (_auth.storage === 'memory') {
        _auth.tokens = _auth.tokens || {};
        _auth.tokens[name] = token;

        // Also set the localstorage, but we'll use a fallback.
        store.set(TOKEN_KEY_PREFIX + name, token, expires.toDate().getTime());
    } else {
        cookie.setItem(TOKEN_KEY_PREFIX + name, token, {
            secure: true,
            path: '/',
            expires: expires.toDate().toUTCString(),
        });
    }
}

function getToken(name) {
    if (_auth.storage === 'local') {
        return store.get(TOKEN_KEY_PREFIX + name);
    } else if (_auth.storage === 'memory') {
        _auth.tokens = _auth.tokens || {};
        return _auth.tokens[name] || store.get(TOKEN_KEY_PREFIX + name);
    } else {
        return cookie.getItem(TOKEN_KEY_PREFIX + name);
    }
}

function removeToken(name) {
    if (_auth.storage === 'local') {
        store.remove(TOKEN_KEY_PREFIX + name);
    } else if (_auth.storage === 'memory') {
        _auth.tokens = _auth.tokens || {};
        delete _auth.tokens[name];
        store.remove(TOKEN_KEY_PREFIX + name);
    } else {
        const expires = new Date(0).toUTCString();
        cookie.setItem(TOKEN_KEY_PREFIX + name, '', { expires, path: '/' });
    }
}

function deauthorize() {
    const token = getToken('token'),
        refresh = getToken('refresh');

    if (!token && !refresh) {
        return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
        fetch(getConfig('users_api') + '/tokens/revoke', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; schema=collection/jwt/1' },
            body: JSON.stringify({ tokens: [token, refresh].filter((v) => v) }),
        })
            .then((response) => {
                if (response.ok) {
                    response.json().then(resolve);
                } else {
                    response.json().then(reject);
                }
            })
            .catch(() => reject());
    });
}

var AuthStore = assign({}, EventEmitter.prototype, {
    emitChange: function () {
        this.emit(CHANGE_EVENT);
    },

    setStorage: function (storage) {
        _auth.storage = storage;
    },

    getStorage: function () {
        return _auth.storage;
    },

    addChangeListener: function (callback) {
        this.on(CHANGE_EVENT, callback);
    },

    removeChangeListener: function (callback) {
        this.removeListener(CHANGE_EVENT, callback);
    },

    getLinks: function () {
        const token = getToken('token');

        if (token) {
            return _auth.links;
        }

        _auth.links = null;

        return null;
    },

    getToken: function () {
        return getToken('token');
    },

    setToken: function (name, value, expires) {
        setToken(name, value, expires);
    },

    getRefresh: function () {
        return getToken('refresh');
    },

    getTfaAuth: function () {
        return getToken('tfa_auth');
    },

    fetch: function (url, request, anonOk = false, token = false, abortController = null) {
        if (!token) {
            token = getToken('token');
        }

        // If we're not logged in, don't even bother firing the request, just reject it.
        if (!anonOk && !token) {
            return Promise.reject('not logged in');
        }

        // This saves a lot of boilerplate.
        if (!anonOk && (!url || (typeof url === 'object' && !url.url))) {
            return Promise.reject('probably also not logged in');
        }

        return new Promise((resolve, reject) => {
            // @todo - this could use some work - perhaps a URL object
            if (typeof url === 'object') {
                // Has a query param, hash or whatnot
                url =
                    url.url +
                    (url.query ? '?' + qs.stringify(url.query) : '') +
                    (url.fragment ? '#' + qs.stringify(url.fragment) : '');
            }

            // Make sure request is initalized
            request = request || {};

            // Initialize headers if not already initialized
            request.headers = request.headers || {};

            if (token) {
                request.headers.Authorization = 'bearer ' + token;
            }
            if (abortController) {
                request.signal = abortController.signal;
            }

            const urlObject = new URL(url);
            const path = urlObject.pathname;

            // Get the current active transaction, if it exists
            let transaction = Sentry.getCurrentHub().getScope().getTransaction();

            // If no active transaction, start a new one (optional)
            if (!transaction) {
                transaction = Sentry.startTransaction({
                    name: `${request.method || 'GET'} ${path}`,
                    op: 'http.client',
                });
            }
            const span = transaction.startChild({ op: 'http.client', description: `Fetching data from ${url}` });
            // Set transaction on scope to associate with errors and get included span instrumentation
            Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(span));

            fetch(url, request)
                .then((response) => {
                    span.setHttpStatus(response.status);

                    if (abortController && abortController.signal.aborted) {
                        return;
                    }

                    if (response.ok) {
                        // If we get a response back immediately, we're good
                        try {
                            response.json().then(resolve, reject);
                        } catch (exp) {
                            Sentry.captureException(exp, span.getTraceContext());
                            reject(
                                <span>
                                    Oops! Something went wrong. Please try again or contact EatLove support at
                                    <a href="mailto:support@eatlove.is?subject=EatLove Crashed"> support@eatlove.is</a>
                                </span>
                            );
                        }

                        return;
                    }

                    var contentType = response.headers.get('content-type');

                    if (contentType && contentType.includes('application/json')) {
                        return response.json().then((json) => {
                            if (json && json.message === 'Forbidden' && token) {
                                // whoa, has our token been revoked? We need to reauthenticate.
                                // Log the user out so they can log back in.
                                AppDispatcher.handleViewAction({
                                    actionType: AuthConstants.AUTH_DEAUTHORIZE,
                                });

                                AppDispatcher.handleViewAction({
                                    actionType: UserConstants.USER_LOGOUT,
                                });

                                span.setData('response_json', json);
                                Sentry.captureMessage(json.message, span.getTraceContext());
                            } else {
                                MealStore.checkForMealsUpdateError(url, request, json);
                                GroceryStore.checkForGroceryUpdateError(url, request, json);
                            }

                            reject(json);
                        });
                    }

                    if (contentType && contentType.includes('text/html')) {
                        response.text().then((text) => {
                            span.setData('text', text);
                            Sentry.captureMessage('Failed to Fetch', span.getTraceContext());
                            reject({ error: 'Failed to Fetch', text });
                        });

                        return;
                    }

                    Sentry.captureMessage('Failed to Fetch', span.getTraceContext());
                    return reject({ message: 'Failed to Fetch' });
                })
                .catch((exp) => {
                    if (exp.name === 'AbortError') {
                        return;
                    }

                    span.setStatus('unknown_error');
                    Sentry.captureException(exp, span.getTraceContext());

                    reject(
                        <span>
                            Oops! Something went wrong. Please try again or contact EatLove support at
                            <a href="mailto:support@eatlove.is?subject=EatLove Crashed"> support@eatlove.is</a>
                        </span>
                    );
                })
                .finally(() => {
                    span.finish();
                });
        });
    },
});

AppDispatcher.register(function (payload) {
    switch (payload.action.actionType) {
        case AuthConstants.AUTH_AUTHENTICATE:
            //eslint-disable-next-line no-case-declarations
            const { auth } = payload.action;

            // Set cookies/storage and local variables at the same time
            setToken('token', auth.token, moment(auth.token_expires));
            setToken('refresh', auth.refresh, moment(auth.refresh_expires));

            if (auth.tfa_auth) {
                setToken('tfa_auth', auth.tfa_auth, moment(auth.tfa_auth_expires));
            }

            store.set(COOKIE_KEY_PREFIX + 'links', (_auth.links = auth.links));

            AuthStore.emitChange();
            break;

        case AuthConstants.AUTH_DEAUTHORIZE:
            // @todo - uncomment this line and it won't log you out each time there's a JS error on initial render.
            // return;

            /*
             * Remove the current user from Analytics before any cleaning.
             * Try to request the deauthorization of the current tokens (token and refreshtoken).
             * Whether or not we succeed in revoking the tokens, we clean the current user's data and guarantee the client logout.
             */
            Analytics.logout();
            deauthorize().finally(() => {
                _auth.links = null;
                // Delete the cookies too
                removeToken('token');
                removeToken('refresh');

                store.set(COOKIE_KEY_PREFIX + 'links', null);

                AuthStore.emitChange();

                Sentry.setUser(null);

                SessionRecorder.stop();

                if (SessionRecorder.wasRecorded) {
                    window.location.reload();
                }
            });
            break;

        default:
            return;
    }
});

export default AuthStore;
