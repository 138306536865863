import React from 'react';
import PropTypes from 'prop-types';
import fontVariables from '@sass/styles/typography/_icon-font-variables';
import fontVariablesFeather from '@sass/styles/typography/_feather-font-variables';

const fontsFeather = fontVariablesFeather.map((item) => `feather-${item}`);

// List of available icons
export const iconList = [...Object.keys(fontVariables), ...fontsFeather];

/**
 * Icon component to render a specified icon.
 *
 * @param {IconNameType} name - The name of the icon to render.
 * @returns {JSX.Element} The rendered icon.
 */
const Icon = ({ name }) => {
    let iconName = 'icon-box';
    let paths = false;
    // Add additional class before chosen class name when collection is for feather icons
    if (name.startsWith('feather-')) {
        if (fontsFeather.includes(name)) {
            iconName = `feather ${name}`;
        }
    } else {
        if (fontVariables[name]) {
            iconName = `icon-${name}`;
            if (fontVariables[name]?.paths) {
                paths = fontVariables[name].paths;
            }
        }
    }

    return <i className={iconName}>{paths && paths.map((path) => <span key={path} className={path} />)}</i>;
};

Icon.propTypes = {
    name: PropTypes.oneOf(iconList).isRequired,
};

export default Icon;
