'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import ImgResized from '../../Widgets/ImgResized.react';
import FavoriteButton from '../../Widgets/FavoriteButton.react';
import PublisherSubmenu from '../../Publisher/Dashboard/PublisherSubmenu.react';

import './BrandCard.scss';
import './RecipeCard.scss';

export default class BrandCard extends Component {
    static propTypes = {
        brand: PropTypes.object,
    };

    static contextTypes = {
        isPublisher: PropTypes.bool,
        showResultsFavoriteBtn: PropTypes.bool,
        onSelectBrand: PropTypes.func,
    };

    onClick = (ev) => {
        const { onSelectBrand } = this.context;
        const { brand } = this.props;

        if (ev.button == 1 || ev.ctrlKey) {
            return;
        }

        onSelectBrand(brand);
    }

    link = (element) => {
        const { brand } = this.props;
        const { isPublisher } = this.context;

        if (isPublisher) {
            return <Link to={`/admin/brands/${brand.uuid}`}>{element}</Link>
        }

        return <span onClick={this.onClick}>{element}</span>
    }

    render() {
        const { isPublisher, showResultsFavoriteBtn } = this.context;
        const { brand } = this.props;

        return (
            <div className="recipe-card brand-card">
                {this.link(brand.image
                    ? <ImgResized className="recipe-card-image" src={brand.image} width={300} height={300} />
                    : <div className="recipe-card-image" onClick={this.onClick}>
                        <h2>{brand.category}</h2>
                      </div>
                )}
                {this.link(<div className="recipe-card-text">
                    <h3>{brand.title}</h3>
                    {isPublisher ?
                        <div className={brand.status === 'live' ? 'publish-status live' : 'publish-status'}>
                            {brand.status === 'live' ? 'live' : 'draft'}
                        </div>
                    : null}
                </div>)}
                {isPublisher
                    ? <PublisherSubmenu resource={brand} />
                : null}
                {showResultsFavoriteBtn ?
                    <FavoriteButton brand={brand} />
                : null}
            </div>
        );
    }
}
