'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import ImgResized from '../../../../Widgets/ImgResized.react';
import { fetchDocumentsById } from '../../../../../utils/Content';

import './CardAbstract.scss';
import allProviders from '../../../../../tables/fulfillment-providers';

export default class ProductCard extends Component {

    static contextTypes = {
        user: PropTypes.object,
        showFoodDetails: PropTypes.func,
        showRecipeDetails: PropTypes.func,
        onSelectFood: PropTypes.func,
        onSelectRecipe: PropTypes.func,
    }

    onSelectProduct = () => {
        const { product } = this.props;
        const { onSelectFood, onSelectRecipe } = this.context;
        const { params: { sort_by, sort_order }, searchRanking} = this.props;

        fetchDocumentsById([product.food_uuid]).then(documents => {
            if (documents[0]?.type === 'food' ) {
                onSelectFood(documents[0], undefined, undefined, undefined, undefined, undefined, {searchRanking, sortBy: sort_by, sortOrder: sort_order, source: 'Smart Choices'});
            } else if (documents[0]?.type === 'recipe') {
                onSelectRecipe(documents[0], undefined, undefined, undefined, {sortBy: sort_by, sortOrder: sort_order, source: 'Smart Choices'});
            }
        });
    };

    showProductDetails = () => {
        const { product } = this.props;

        const { showFoodDetails, showRecipeDetails } = this.context;

        fetchDocumentsById([product.food_uuid]).then(documents => {
            if (documents[0]?.type === 'food' ) {
                showFoodDetails(documents[0]);
            } else if (documents[0]?.type === 'recipe') {
                showRecipeDetails(documents[0]);
            }
        });
    }

    render = () => {
        const { product } = this.props;
        const { showFoodDetails } = this.context;
        const { user } = this.context;
        const { capabilities } = user;

        return (
            <div className="card-abstract quick-product-card">
                <ImgResized className="card-image" src={product.image} width={470} height={356} onClick={this.showProductDetails} />

                {(product.tags || []).includes('Grab & Go') ?
                    <div className="card-overlay" onClick={() => showFoodDetails(product)} >
                        Store Bought
                    </div>
                : null}

                <div className="card-info">
                    {product.store && allProviders[product.store] && <img className="provider-logo" src={allProviders[product.store].logo} />}

                    <h3><em>{product.name}</em></h3>

                    <button onClick={this.onSelectProduct}>
                        select meal
                        {(product.protection !== 'public' && !capabilities.meal_planner) ? <i className="icon-lock" /> : null}
                    </button>
                </div>
            </div>
        );
    }
}
