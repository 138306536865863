'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import { fetchDocumentsById } from '../../../../../utils/Content';
import allMealKitBrands from '../../../../../tables/meal-kit-brands';

import ImgResized from '../../../../Widgets/ImgResized.react';

import './ComboCard.scss';
import './CardAbstract.scss';

export default class ComboCard extends Component {

    static contextTypes = {
        user: PropTypes.object,

        showComboDetails: PropTypes.func,
        onSelectCombo: PropTypes.func,
        addSwapContext: PropTypes.object,
    }

    onSelectCombo = () => {
        const { combo } = this.props;
        const { onSelectCombo, addSwapContext } = this.context;
        const { params: { sort_by, sort_order }, searchRanking} = this.props;

        fetchDocumentsById([combo.main_dish, combo.side_dish]).then(documents => {
            let mainDish, sideDish;

            documents.forEach(doc => {
                if (combo.main_dish === doc.uuid) {
                    mainDish = doc;
                }

                if (combo.side_dish === doc.uuid) {
                    sideDish = doc;
                }
            });

            onSelectCombo(combo, mainDish, sideDish, addSwapContext.participants, undefined, undefined, {searchRanking, sortBy: sort_by, sortOrder: sort_order, source: 'Smart Choices'});
        });
    }

    render = () => {
        const { combo } = this.props;
        const { user, showComboDetails } = this.context;
        const { capabilities } = user;

        return (
            <div className="card-abstract combo-card">
                <ImgResized className="card-image" src={combo.main_image} width={470} height={356} onClick={() => showComboDetails(combo)} />

                {(combo.tags || []).includes('Grab & Go') ?
                    <div className="card-overlay" onClick={() => showComboDetails(combo)} >
                        Store Bought
                    </div>
                : null}

                <div className="card-info">
                    {combo.main_brand_uuid && allMealKitBrands[combo.main_brand_uuid] ?
                        <img height="21" className="provider-logo" src={allMealKitBrands[combo.main_brand_uuid].logo} />
                    : null}

                    <h3><em>{combo.main_title}</em> + <em>{combo.side_title}</em></h3>

                    <button onClick={this.onSelectCombo}>
                        select meal
                        {(combo.protection !== 'public' && !capabilities.meal_planner) ? <i className="icon-lock" /> : null}
                    </button>
                </div>
            </div>
        );
    }
}
