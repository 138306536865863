'use strict'

import { Component } from 'react';
import PropTypes from 'prop-types';

import ImgResized from '../../Widgets/ImgResized.react';

import { roundForHumans } from '../../../utils/Math';

import './CardAbstract.scss';

export default class RecipeCard extends Component {
    static propTypes = {
        recipe: PropTypes.object,
    };

    static contextTypes = {
        addSwapContext: PropTypes.object,
        user: PropTypes.object,

        showRecipeDetails: PropTypes.func,
        onSelectRecipe: PropTypes.func,
    };

    onClick = () => {
        const { onSelectRecipe, showRecipeDetails, addSwapContext } = this.context;
        const { recipe, organicRanking, searchRanking, resultType, searchTerm, sortBy, sortOrder } = this.props;

        if (onSelectRecipe && addSwapContext) {
            onSelectRecipe(recipe, addSwapContext.participants, undefined, undefined, {organicRanking, searchRanking, resultType, term: searchTerm, sortBy, sortOrder});
        } else if (onSelectRecipe) {
            onSelectRecipe(recipe, undefined, undefined, undefined, {organicRanking, searchRanking, resultType, term: searchTerm, sortBy, sortOrder});
        } else if (showRecipeDetails) {
            showRecipeDetails(recipe);
        }
    }

    render = () => {
        const { recipe } = this.props;
        const { user = {} } = this.context;
        const { hide_nutrition = false } = user.preferences || {};

        let calories = (recipe.nutrients && recipe.nutrients.values && recipe.nutrients.values[208]) || 0;

        return (
            <li className="results-mini-card" onClick={this.onClick}>
                {recipe.image
                    ? <ImgResized className="thumb" src={recipe.image} width={100} height={100} />
                    : <i className="icon-logo3 thumb" />
                }
                <span className="title">{recipe.title}</span>

                {!hide_nutrition ?
                    <span className="cals">{roundForHumans(calories)} kcal per serving</span>
                : null}
            </li>
        );
    }
}
