'use strict';

import React, { useState } from 'react';

const TextArea = ({
    className = 'el-labeled-input',
    textareaClassName = '',
    counterClassName = 'el-input-char-limit',
    maxLength,
    defaultValue = '',
    ...props
}) => {
    const [value, setValue] = useState(defaultValue);

    const handleChange = (e) => {
        let inputValue = e.target.value;
        if (maxLength && inputValue.length > maxLength) {
            inputValue = inputValue.slice(0, maxLength);
        }
        setValue(inputValue);

        if (props.onChange) {
            props.onChange(e);
        }
    };

    return (
        <div className={className} data-error={value.length >= maxLength}>
            <textarea {...props} className={textareaClassName} value={value} onChange={handleChange} />
            {maxLength && (
                <p className={counterClassName}>
                    {value.length}/{maxLength}
                </p>
            )}
        </div>
    );
};

export default TextArea;
