'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import './DocumentType.scss';

export default class DocumentTypeParams extends Component {
    static propTypes = {
        allowedTypes: PropTypes.array,
    };

    static contextTypes = {
        user: PropTypes.object,
        hideProductTypeButtons: PropTypes.bool,
    };

    onClickAll = () => {
        const { params, allowedTypes, onChangeParams } = this.props;

        params.types = allowedTypes.slice();
        params.filters = params.filters || {};
        delete params.filters.product_type;
        delete params.filters.is_owner;

        onChangeParams(params);
    }

    onClickRecipes = () => {
        const { params, allowedTypes, onChangeParams } = this.props;

        params.types = ['recipe'];
        params.filters = params.filters || {};
        delete params.filters.product_type;
        delete params.filters.is_owner;

        onChangeParams(params);
    }

    onClickCustomFoodsAndRecipes = () => {
        const { params, allowedTypes, onChangeParams } = this.props;

        params.types = ['recipe', 'food'];
        params.filters = params.filters || {};
        params.filters.is_owner = true;

        delete params.filters.product_type;

        onChangeParams(params);
    }

    onClickCollections = () => {
        const { params, allowedTypes, onChangeParams } = this.props;

        params.types = ['collection'];
        params.filters = params.filters || {};
        delete params.filters.product_type;
        delete params.filters.is_owner;

        onChangeParams(params);
    }

    onClickMealPlans = () => {
        const { params, allowedTypes, onChangeParams } = this.props;

        params.types = ['plan'];
        params.filters = params.filters || {};
        delete params.filters.product_type;
        delete params.filters.is_owner;

        onChangeParams(params);
    }

    onClickFoods = () => {
        const { params, allowedTypes, onChangeParams } = this.props;

        params.types = ['food'];
        params.filters = params.filters || {};
        delete params.filters.product_type;
        delete params.filters.is_owner;

        onChangeParams(params);
    }

    onClickProductType = (product_type) => {
        const { params, onChangeParams } = this.props;

        params.types = ['food'];
        params.filters = params.filters || {};
        params.filters.product_type = product_type;
        delete params.filters.is_owner;

        onChangeParams(params);

    }

    onClickRestaurants = () => {
        this.onClickProductType('Restaurant Dish');
    }

    onClickMealKits = () => {
        this.onClickProductType('Meal Kit');
    }

    onClickReadyMadeMeals = () => {
        this.onClickProductType('Ready Made Meal');
    }

    render() {
        const { user, hideProductTypeButtons } = this.context;
        const { params, allowedTypes } = this.props;
        const { types = [], filters = {} } = params;

        // If we're only allowing foods & recipes, don't show the interface.
        if (!(allowedTypes.includes('food'))) {
            return <span />
        }

        let isAllSelected = types.length === allowedTypes.length && !filters.product_type;

        let isRecipesSelected = types.includes('recipe') && types.length === 1 && !filters.product_type;
        let isCustomFoodsAndRecipesSelected = types.includes('recipe') && types.includes('food') && types.length === 2 && !filters.product_type;
        let isCollectionsSelected = types.includes('collection') && types.length === 1 && !filters.product_type;
        let isMealPlansSelected = types.includes('plan') && types.length === 1 && !filters.product_type;

        let isFoodsSelected = types.includes('food') && types.length === 1 && !filters.product_type;

        let isRestaurantSelected = types.includes('food') && types.length === 1 &&
                                   filters.product_type === 'Restaurant Dish';

        let isMealKitsSelected = types.includes('food') && types.length === 1 &&
                                   filters.product_type === 'Meal Kit';

        let isReadyMadeMealsSelected = types.includes('food') && types.length === 1 &&
                                   filters.product_type === 'Ready Made Meal';

        return (
            <div className="doc-type-params">
                <ul>
                    <li className="all">
                        <button onClick={this.onClickAll} data-active={isAllSelected}>All</button>
                    </li>
                    {allowedTypes.includes('recipe') && allowedTypes.includes('food') ?
                        <li className="recipes" onClick={this.onClickCustomFoodsAndRecipes}>
                            <button data-active={isCustomFoodsAndRecipesSelected}>My Custom Foods and Recipes</button>
                        </li>
                    : null}
                    {allowedTypes.includes('recipe') ?
                        <li className="recipes" onClick={this.onClickRecipes}>
                            <button data-active={isRecipesSelected}>Recipes</button>
                        </li>
                    : null}
                    {allowedTypes.includes('collection') ?
                        <li className="collections" onClick={this.onClickCollections}>
                            <button data-active={isCollectionsSelected}>Collections</button>
                        </li>
                    : null}
                    {allowedTypes.includes('plan') ?
                        <li className="plans" onClick={this.onClickMealPlans}>
                            <button data-active={isMealPlansSelected}>Meal Plans</button>
                        </li>
                    : null}
                    {allowedTypes.includes('food') ?
                        <li className="foods">
                            <button data-active={isFoodsSelected} onClick={this.onClickFoods}>Foods</button>
                        </li>
                    : null}
                    {allowedTypes.includes('food') && !hideProductTypeButtons ?
                        <li>
                            <button data-active={isRestaurantSelected} onClick={this.onClickRestaurants}>Restaurant Meals</button>
                        </li>
                    : null}
                    {allowedTypes.includes('food') && !hideProductTypeButtons && user?.preferences?.meal_kit_providers?.includes('sunbasket') ?
                        <li>
                            <button data-active={isMealKitsSelected} onClick={this.onClickMealKits}>Meal Kits</button>
                        </li>
                    : null}
                    {allowedTypes.includes('food') && !hideProductTypeButtons && user?.preferences?.meal_kit_providers?.includes('sunbasket') ?
                        <li>
                            <button data-active={isReadyMadeMealsSelected} onClick={this.onClickReadyMadeMeals}>Ready Made Meals</button>
                        </li>
                    : null}
                </ul>
            </div>
        );
    }
}
