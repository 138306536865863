'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

export default class TermsParams extends Component {
    clearTerms = () => {
        const { params, onChangeParams } = this.props;

        delete params.terms;
        params.sort_by = 'published';

        onChangeParams(params, true);
    }

    render() {
        const { params = {} } = this.props

        if (!params.terms) {
            return <span />;
        }

        return (
            <div className="param-item" onClick={this.clearTerms}>
                Keywords: {params.terms} <i className="icon-close-x" />
            </div>
        );
    }
}
