'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Modal from 'react-modal';
import indexBy from 'lodash.indexby';
import classNames from 'classnames';

import SpringHero from '../../../components/MealDetails/SpringHero.react';
import RecipeIngredients from '../../../components/Modules/RecipeIngredients.react';
import RecipeInstructions from '../../../components/Modules/RecipeInstructions.react';
import MyTabs from '../../../components/Widgets/Tabs.react';
import SharePopup from '../../../components/Widgets/SharePopup.react';
import RecipeToPdfButton from '../../../components/Recipes/RecipeToPdfButton.react';
import NutritionInfo from '../../../components/Products/NutritionInfo.react';
import HowMuchToEat from '../../../components/Recipes/HowMuchToEat.react';
import Dropdown from '../../../pro/components/Widgets/Dropdown.react';
import Merchant from '../../../components/Recipes/Merchant.react';
import EditMealServingsModal from '../../../components/Recipes/EditMealServingsModal.react';
import FoodIngredients from '../../../components/Modules/FoodIngredients.react';
import Alert from '../../../components/Widgets/Alert/Alert.react';

import UserStore from '../../../stores/UserStore';
import MealStore from '../../../stores/MealStore';

import { getBatchInfo as getBatchInfoMeals } from '../../../utils/Meals';
import { getBatchInfo as getBatchInfoProMeals } from '../../../utils/proMeals';

import { toHuman } from '../../../utils/Duration';
import { inquire } from '../../../utils/Enforcer';
import { getNutrientsToDisplay } from '../../../pro/utils/Patients';
import { getDefaultParticipantsForRecipe } from '../../../utils/Meals';
import { fetchDocumentsById } from '../../../utils/Content';
import { getConfig } from '../../../utils/Env';
import { ScreenService } from '../../../utils/ScreenService';

import nutrKeys from '../../../tables/nutrient-order';

import './ComboDetailsModal.scss';
import '../../../components/FeedModals.scss';
import MealTitle from '../../Meals/MealTitle.react';

const sections = ['first', 'second', 'third', 'fourth', 'fifth'];

export default class ComboDetailsModal extends Component {
    static propTypes = {
        uuid: PropTypes.string,

        combo: PropTypes.object,

        editableLeftovers: PropTypes.bool,
        editableParticipants: PropTypes.bool,
        hideScheduleButton: PropTypes.bool,
        hidePrintBtn: PropTypes.bool,

        defaultScaling: PropTypes.number,
        defaultParticipants: PropTypes.array,

        onChangeParticipants: PropTypes.func,
        sourceMetaData: PropTypes.object
    };

    static defaultProps = {
        editableLeftovers: true,
        editableParticipants: true,
    };

    static contextTypes = {
        isMobile: PropTypes.bool,
        breakpoint47em: PropTypes.bool,
        addSwapContext: PropTypes.object,
        isPro: PropTypes.bool,
    };

    constructor(props, context) {
        super(props, context);

        const user = UserStore.getUser();
        const profile = this.getProfileFromProps(props, context, user);
        const meals = MealStore.getMeals();

        const { defaultParticipants, defaultScaling } = props;

        this.state = {
            loading: false,
            synced: false,
            user,
            profile,

            dishIndex: 0,

            combo: props.combo,
            mainDish: null,
            mainDetails: null,
            mainMerchant: null,
            sideDish: null,
            sideDetails: null,
            sideMerchant: null,
            dirty: false,

            participants: defaultParticipants,
            scaling: defaultScaling,
            sideScaling: null,
            allMeals: meals,
        };
    }

    componentDidMount = () => {
        ScreenService.keepAwake();
        UserStore.addChangeListener(this.onUserStoreChange);

        const { combo, uuid } = this.props;

        if (combo) {
            this.setState({ combo }, this.syncAssets);
        } else if (uuid) {
            this.loadCombo(this.props.uuid);
        }
    };

    componentWillUnmount = () => {
        ScreenService.allowSleep();
        UserStore.removeChangeListener(this.onUserStoreChange);
    };

    getProfileFromProps = (props, context, user) => {
        if (context.profile) {
            return context.profile;
        }

        if (context.addSwapContext && context.addSwapContext.profile) {
            return context.addSwapContext.profile;
        }

        let {
            preferences = { diets: [], avoidances: [], hide_nutrition: false },
            conditions = ['General Healthy Diet'],
            prescriptions = [],
            family = [],
            portion = 1,
            ...rest
        } = user || {};

        return {
            preferences,
            conditions,
            prescriptions,
            family,
            portion,
            ...rest,
        };
    };

    onUserStoreChange = () => {
        const user = UserStore.getUser();
        const profile = this.getProfileFromProps(this.props, this.context, user);

        this.setState({ user, profile }, this.syncAssets);
    };

    loadCombo = (uuid) => {
        this.setState({ loading: true, synced: false });

        const inquiries = [
            { action: 'publish', resource: uuid },
            { action: 'write', resource: uuid },
        ];

        inquire(inquiries).then((decisions) => {
            if (!decisions) {
                this.setState({ canWrite: false });
                return;
            }

            let canPublish = decisions.filter((d) => d.action == 'publish')[0].decision === 'permit';
            let canWrite = decisions.filter((d) => d.action == 'write')[0].decision === 'permit';

            this.setState({ canPublish, canWrite });
        });

        return fetchDocumentsById([uuid]).then((combos) => {
            if (!combos || (combos && !combos[0])) {
                this.setState({ error: 'Combo not found' });
                return;
            }

            const combo = combos[0];
            const newState = { combo };

            this.setState(newState, this.syncAssets);
        });
    };

    syncAssets = () => {
        let { combo, participants, scaling, sideScaling, profile } = this.state;
        const toLoad = [];
        const newState = { synced: true, loading: false };
        const { mainDish, sideDish } = this.state;

        if (!combo) {
            return;
        }

        if (!mainDish || (mainDish && mainDish.uuid !== combo.main_dish)) {
            toLoad.push(combo.main_dish);
        }

        if (!sideDish || (sideDish && sideDish.uuid !== combo.side_dish)) {
            toLoad.push(combo.side_dish);
        }

        if (toLoad.length === 0) {
            this.setState(newState);
            return;
        }

        fetchDocumentsById(toLoad).then((documents) => {
            documents.forEach((doc) => {
                if (doc.uuid === combo.main_dish) {
                    newState.mainDish = doc;
                }

                if (doc.uuid === combo.side_dish) {
                    newState.sideDish = doc;
                }
            });

            let uuidsToFetch = [newState.mainDish?.details, newState.sideDish?.details];

            if (newState.mainDish.merchant) {
                uuidsToFetch.push(newState.mainDish.merchant.uuid);
            }

            if (newState.sideDish.merchant) {
                uuidsToFetch.push(newState.sideDish.merchant.uuid);
            }
            if (!participants) {
                newState.participants = participants = getDefaultParticipantsForRecipe(newState.mainDish, profile);
            }

            const neededPerMeal = participants.reduce((total, member) => total + member.portion, 0);

            if (!scaling && participants) {
                newState.scaling = scaling = Math.ceil(neededPerMeal / newState.mainDish.servings);
            }

            if (!sideScaling && scaling && participants) {
                // How many days of the main dish do we have? Match that with the side dish
                const totalMainDays = Math.floor((scaling * newState.mainDish.servings) / neededPerMeal);
                const totalSideServingsNeeded = totalMainDays * neededPerMeal;

                newState.sideScaling = sideScaling = Math.ceil(totalSideServingsNeeded / newState.sideDish.servings);
            }

            if (newState.mainDish?.subrecipes) {
                uuidsToFetch = uuidsToFetch.concat(newState.mainDish.subrecipes.map((sr) => sr.recipe));
                uuidsToFetch = uuidsToFetch.concat(newState.mainDish.subrecipes.map((sr) => sr.details));
            }

            if (newState.sideDish?.subrecipes) {
                uuidsToFetch = uuidsToFetch.concat(newState.sideDish.subrecipes.map((sr) => sr.recipe));
                uuidsToFetch = uuidsToFetch.concat(newState.sideDish.subrecipes.map((sr) => sr.details));
            }

            uuidsToFetch = uuidsToFetch.filter((v) => v);

            if (!uuidsToFetch.length) {
                this.setState(newState);
                return;
            }

            fetchDocumentsById(uuidsToFetch).then((details) => {
                const allDetails = {};

                details.forEach((doc) => {
                    allDetails[doc.uuid] = doc;

                    if (doc.uuid === newState.mainDish.details) {
                        newState.mainDetails = doc;
                    }

                    if (doc.uuid === newState.sideDish.details) {
                        newState.sideDetails = doc;
                    }

                    if (newState.mainDish.merchant && doc.uuid === newState.mainDish.merchant.uuid) {
                        newState.mainMerchant = doc;
                    }

                    if (newState.sideDish.merchant && doc.uuid === newState.sideDish.merchant.uuid) {
                        newState.sideMerchant = doc;
                    }
                });

                newState.allDetails = allDetails;

                this.setState(newState);
            });
        });
    };

    onChangeParticipants = (participants, scaling) => {
        const { mainDish, sideDish } = this.state;
        const { onChangeParticipants } = this.props;

        const needed = participants.reduce((total, member) => total + member.portion, 0);

        const totalDays = Math.floor((scaling * mainDish.servings) / needed);
        const totalSideServingsNeeded = needed * totalDays;
        const sideScaling = Math.ceil(totalSideServingsNeeded / sideDish.servings);

        this.setState({ participants, scaling, sideScaling, isEditingParticipants: false, dirty: true });
        onChangeParticipants && onChangeParticipants(participants, scaling);
    };

    closeParticipantModal = () => {
        this.setState({ isEditingParticipants: false });
    };

    showEditMealServings = () => {
        this.setState({ isEditingParticipants: true });
    };

    scrollToDishIndex = (dishIndex) => {
        this.setState({ dishIndex });
    };

    renderEditMealServingsModal = () => {
        const { profile, combo, scaling, mainDish, participants, isEditingParticipants, allMeals, dirty } = this.state;
        const { editableParticipants } = this.props;
        const { addSwapContext, isPro } = this.context;

        if (!mainDish || !isEditingParticipants) {
            return;
        }

        let inheritedParticipants = participants;
        let inheritedScaling = scaling;

        if (addSwapContext?.mode === "replace") {
            if (isPro) {
                const { offset, mealType, plan } = addSwapContext
                const batchInfoProMeals = getBatchInfoProMeals(offset, mealType, profile, plan);

                if (batchInfoProMeals) {
                    const needed = batchInfoProMeals.participants.reduce((total, member) => total + member.portion, 0);
                    const totalNeeded = needed * batchInfoProMeals.totalDays;
                    inheritedScaling = Math.ceil(totalNeeded / mainDish.servings);
                    inheritedParticipants = batchInfoProMeals.participants;
                }
            } else {
                const { date, mealType } = addSwapContext
                const batchInfoMeals = getBatchInfoMeals(date.format('YYYY-MM-DD'), mealType, profile, allMeals);

                if (batchInfoMeals) {
                    const needed = batchInfoMeals.participants.reduce((total, member) => total + member.portion, 0);
                    const totalNeeded = needed * batchInfoMeals.totalDays;
                    inheritedScaling = Math.ceil(totalNeeded / mainDish.servings);
                    inheritedParticipants = batchInfoMeals.participants;
                }
            }
        }

        return (
            <EditMealServingsModal
                closeModal={this.closeParticipantModal}
                content={mainDish}
                scaling={dirty ? scaling : inheritedScaling}
                profile={profile}
                participants={dirty ? participants : inheritedParticipants}
                editableParticipants={editableParticipants}
                onChangeParticipants={this.onChangeParticipants}
            />
        );
    };

    renderRecipe = (recipe, details, scaling, merchant, i) => {
        const { participants, profile, dishIndex, allDetails } = this.state;
        const { editMealBatches } = this.context;

        return (
            <>
                <div className="details-section first">
                    <header>
                        <div className="section-title">
                            <div className="t2 with-merchant">
                                {merchant ? (
                                    <Dropdown
                                        className="merchant-dropdown"
                                        button={
                                            <>
                                                <span>Recipe by</span> <em>{merchant.name}</em>{' '}
                                                <i className="feather feather-info" />
                                            </>
                                        }
                                    >
                                        <Merchant merchant={merchant} />
                                    </Dropdown>
                                ) : null}
                            </div>
                        </div>
                    </header>

                    <div className="section-body">
                        <MealTitle item={recipe} readOnly />

                        <RecipeIngredients
                            recipe={recipe}
                            details={details}
                            subdetails={allDetails}
                            scaling={scaling}
                            profile={profile}
                        />
                    </div>
                </div>

                <div className="details-section second">
                    <header>
                        <div className="section-title">
                            <p className="t2">Instructions</p>
                        </div>
                    </header>

                    <div className="section-body">
                        <RecipeInstructions recipe={recipe} details={details} subdetails={allDetails} user={profile} />
                    </div>
                </div>

                <div className="details-section third">
                    <header>
                        <div className="section-title">
                            <p className="t2">How Much to Eat</p>
                        </div>
                    </header>

                    <div className="section-body">
                        <HowMuchToEat
                            profile={profile}
                            recipe={recipe}
                            participants={participants}
                            scaling={scaling}
                            onClick={this.showEditMealServings}
                        />
                    </div>
                </div>
            </>
        );
    };

    renderFood = (food) => {
        const { dishIndex, profile } = this.state;

        const dishTitles = {
            'Restaurant Dish': (
                <>
                    <span>Order from</span> <em>{food.brand_name}</em>
                </>
            ),
            'Ready Made Meal': food.brand_name ? (
                <>
                    <span>Ready Made Meal by</span> <em>{food.brand_name}</em>
                </>
            ) : (
                'Ready Made Meal'
            ),
            'Brand Name Food': <span>Store Bought Item</span>,
            Food: <></>,
            UGC: <span>Custom Food</span>,
            'Meal Kit': food.brand_name ? (
                <>
                    <span>Meal kit by</span> <em>{food.brand_name}</em>
                </>
            ) : (
                'Meal kit'
            ),
        };

        let dishTitle = dishTitles[food.product_type] || food.product_type;

        return (
            <>
                <section className={classNames('details-section', sections[this.section++])}>
                    <header>
                        <div className="section-title">
                            <p className="t2">
                                <span>DISH {parseInt(dishIndex) + 1}: </span>
                                {dishTitle}
                            </p>
                        </div>
                    </header>

                    <div className="section-body">
                        <h5 className="meal-title">{food.pretty_name || food.name}</h5>

                        {food.ingredients ? (
                            <>
                                <p className="p3">This meal includes the following ingredients:</p>
                                <FoodIngredients food={food} profile={profile} />
                            </>
                        ) : null}

                        {food.description ? <p className="t3 description">{food.description}</p> : null}

                        {food.modifications ? (
                            <p className="t3 modifications">
                                <em>Recommended modifications:</em> {food.modifications}
                            </p>
                        ) : null}

                        {food.nutrition_disclaimer ? (
                            <Alert type="warning" title="Nutrition Disclaimer">
                                {food.nutrition_disclaimer}
                            </Alert>
                        ) : null}

                        {food.gtin_upc?.length ? (
                            <p className="t3">
                                <em>Barcode:</em> {food.gtin_upc.join(', ')}
                            </p>
                        ) : null}
                    </div>
                </section>

                {food.preparation ? (
                    <div className={classNames('details-section', sections[this.section++])}>
                        <header>
                            <div className="section-title">
                                <p className="t2">Dish {parseInt(dishIndex) + 1} Instructions</p>
                            </div>
                        </header>

                        <div className="section-body">
                            <RecipeInstructions recipe={food} details={food} user={profile} />
                        </div>
                    </div>
                ) : null}
            </>
        );
    };

    renderNutritionTabs = () => {
        const { dishIndex, profile, combo, mainDish, sideDish } = this.state;

        const { hide_nutrition = false, rd_override = false } = (profile && profile.preferences) || {};

        if (!mainDish || !sideDish) {
            return;
        }

        if (hide_nutrition && rd_override !== true) {
            return;
        }

        const toDisplay = getNutrientsToDisplay(profile, combo.nutrients.values);
        const nutrNos = Object.keys(toDisplay).sort((a, b) => {
            const aI = nutrKeys.indexOf(a),
                bI = nutrKeys.indexOf(b);

            if (aI > bI) return 1;
            if (aI < bI) return -1;
            return 0;
        });

        return (
            <div className="details-section third">
                <header>
                    <div className="section-title">
                        <p className="t2">Nutrition Information</p>
                    </div>
                </header>

                <div className="section-body">
                    <MyTabs defaultTab="Combined" className="pill-tabs">
                        <section data-title="Combined">
                            <NutritionInfo nutrients={combo.nutrients.values} profile={profile} />
                        </section>

                        <section data-title="Main Dish">
                            <NutritionInfo nutrients={mainDish.nutrients.values} profile={profile} />
                        </section>

                        <section data-title="Side Dish">
                            <NutritionInfo nutrients={sideDish.nutrients.values} profile={profile} />
                        </section>
                    </MyTabs>
                </div>
            </div>
        );
    };

    renderMealDetails = () => {
        const {
            dishIndex,
            mainDish,
            mainDetails,
            scaling,
            mainMerchant,
            sideDish,
            sideDetails,
            sideScaling,
            sideMerchant,
            profile,
        } = this.state;

        if (dishIndex === 0 && mainDish && mainDish.type === 'recipe') {
            return this.renderRecipe(mainDish, mainDetails, scaling, mainMerchant);
        }

        if (dishIndex === 1 && sideDish && sideDish.type === 'recipe') {
            return this.renderRecipe(sideDish, sideDetails, sideScaling, sideMerchant);
        }

        if (dishIndex === 0 && mainDish && mainDish.type === 'food') {
            return this.renderFood(mainDish, scaling);
        }

        if (dishIndex === 1 && sideDish && sideDish.type === 'food') {
            return this.renderFood(sideDish, sideScaling);
        }

        return (
            <div className="nutrition-summary">
                <div className="summary-container">Loading...</div>
            </div>
        );
    };

    renderCallToAction = () => {
        const { onSelectCombo, hideScheduleButton, sourceMetaData } = this.props;
        const { combo, mainDish, sideDish, scaling, participants } = this.state;

        const content = [];

        if (onSelectCombo) {
            return (
                <button
                    className="el-toolbar-green-pill-btn select-btn"
                    onClick={() => onSelectCombo(combo, mainDish, sideDish, participants, scaling, null, sourceMetaData)}
                >
                    select meal
                </button>
            );
        }

        if (hideScheduleButton) {
            return;
        }

        return (
            <button
                className="el-toolbar-green-pill-btn schedule-btn"
                onClick={() => this.setState({ isScheduling: true })}
            >
                schedule it
            </button>
        );
    };

    renderControlBar = () => {
        const { combo, mainDish, scaling, canPublish } = this.state;
        const { hidePrintBtn } = this.props;
        const { isMobile } = this.context;

        if (!combo || !mainDish) {
            return;
        }

        return (
            <div className={classNames('control-bar-container el-toolbar1', !isMobile ? 'el-toolbar1-dark' : null)}>
                {!hidePrintBtn ? (
                    <RecipeToPdfButton
                        scaling={scaling}
                        recipe={mainDish}
                        className="el-toolbar-popup"
                        positionClassName="el-popup-top-left"
                        button={
                            <>
                                <i className="feather feather-printer" /> <em>print</em>
                            </>
                        }
                    />
                ) : null}

                <SharePopup className="el-toolbar-popup" recipe={mainDish} />

                {this.renderCallToAction()}
            </div>
        );
    };

    renderEditMealServingsButton = () => {
        const { combo, mainDish, sideDish, scaling, participants } = this.state;
        const { editableLeftovers } = this.props;

        if (!participants || !mainDish) {
            return;
        }

        const servings = mainDish.servings || 1;
        let needed = participants ? Math.ceil(participants.reduce((total, member) => total + member.portion, 0)) : null;
        let yeild = (scaling || 1) * servings;
        const totalParticipants = participants?.length || 1;
        const totalLeftovers = Math.floor(yeild / needed) - 1;

        return (
            <div className="edit-meal-servings" onClick={this.showEditMealServings}>
                <section className="serves">
                    <span>Serves</span>{' '}
                    <span>
                        {totalParticipants} {totalParticipants === 1 ? 'person' : 'people'}
                    </span>
                </section>

                {totalLeftovers > 0 ? (
                    <section className="serves leftovers">
                        <span>Leftovers</span>
                        <span>
                            {totalLeftovers} {totalLeftovers === 1 ? 'day' : 'days'}
                        </span>
                    </section>
                ) : null}

                <section className="timings">
                    {mainDish.total_time ? (
                        <p>
                            {toHuman(mainDish.total_time, true)}
                            <br />
                            prep/cook
                        </p>
                    ) : null}
                </section>
            </div>
        );
    };

    renderModalBody = () => {
        const { isMobile, breakpoint47em } = this.context;
        const { loading, synced, profile, dishIndex, mainDish, sideDish } = this.state;
        const { hideEditBtns } = this.props;

        if (loading || !synced) {
            return (
                <div className="details-loading el-fonts">
                    <h4>Loading food</h4>
                    <i className="icon-spinner2" />
                </div>
            );
        }

        const markerPos = breakpoint47em ? 150 + dishIndex * 350 : 65 + dishIndex * 250;

        const meals = [];

        if (mainDish.type == 'recipe') {
            meals.push({ meal_type: 'fresh', recipe_uuid: mainDish.uuid });
        } else if (mainDish.type === 'food') {
            meals.push({ meal_type: 'food', food_uuid: mainDish.uuid });
        }

        if (sideDish.type == 'recipe') {
            meals.push({ meal_type: 'fresh', recipe_uuid: sideDish.uuid, side_dish: true });
        } else if (sideDish.type === 'food') {
            meals.push({ meal_type: 'food', food_uuid: sideDish.uuid, side_dish: true });
        }

        const contents = indexBy([mainDish, sideDish], 'uuid');

        return (
            <>
                {!isMobile ? (
                    <div className="details-header">
                        {this.renderEditMealServingsButton()}

                        <header>{this.renderControlBar()}</header>
                    </div>
                ) : null}

                <SpringHero
                    meals={meals}
                    dishIndex={dishIndex}
                    profile={profile}
                    hideEditBtns={true}
                    recipes={contents}
                    foods={contents}
                    breakpoint47em={breakpoint47em}
                    scrollToDishIndex={this.scrollToDishIndex}
                />

                {this.renderMealDetails()}
                {this.renderNutritionTabs()}
            </>
        );
    };

    render = () => {
        const { isMobile } = this.context;
        const { closeModal } = this.props;
        const { mainDish } = this.state;

        this.section = 0;

        return (
            <>
                {mainDish ? (
                    <Helmet
                        title={mainDish.title + ' | EatLove'}
                        meta={[
                            { name: 'description', content: mainDish.description },
                            { name: 'twitter:title', content: mainDish.title },
                            { name: 'twitter:description', content: mainDish.description },
                            { name: 'twitter:image', content: mainDish.image },
                            { property: 'og:title', content: mainDish.title },
                            { property: 'og:type', content: mainDish.page_type },
                            {
                                property: 'og:url',
                                content: 'https://' + getConfig('self_host') + '/recipes/' + mainDish.uuid,
                            },
                            { property: 'og:image', content: mainDish.image },
                            { property: 'og:description', content: mainDish.description },
                        ]}
                    />
                ) : null}
                <Modal
                    isOpen={true}
                    onRequestClose={closeModal}
                    closeModal={closeModal}
                    className="el-modal el-modal1"
                    overlayClassName="el-modal-overlay"
                    contentLabel="Meal Details"
                >
                    <div className="el-modal-container el-modal1-container content-details-modal-container">
                        <header>
                            <button className="el-modal-back-btn" onClick={closeModal}>
                                <span>Back</span>
                            </button>

                            <h2>Meal Information</h2>

                            {isMobile ? (
                                <>
                                    {this.renderEditMealServingsButton()}

                                    <div className="el-modal-controls el-modal1-controls">
                                        <header>{this.renderControlBar()}</header>
                                    </div>
                                </>
                            ) : null}
                        </header>

                        <div className="el-modal-body-container el-modal1-body-container el-fonts">
                            {this.renderModalBody()}
                        </div>
                    </div>
                </Modal>

                {this.renderEditMealServingsModal()}
            </>
        );
    };
}
