'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import Dropdown from '../../Dropdown.react';

import './SearchTypeSelector.scss';
import './SearchTypeModal.scss';

export default class SearchTypeSelector extends Component {
    static propTypes = {
        usePopover: PropTypes.bool,
        allowedModes: PropTypes.array,
    };

    static defaultProps = {
        allowedModes: [
            'smart-choices', 'restaurants', 'store',
            'favorites', 'ingredients', 'browser',
            'create-custom', 'use-own-recipe',
        ],
    }

    static contextTypes = {
        user: PropTypes.object,
        isMobile: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
            drawer: false,
        };
    }

    closeModal = () => {
        this.setState({drawer: false}, () => {
            setTimeout(() => this.setState({isModalOpen: false}), 500);
        });
    }

    onChange = (mode) => {
        const { onChange } = this.props;

        this.closeModal();

        this.refs.popover && this.refs.popover.closeDropdown();

        onChange && onChange(mode.value);
    }

    render() {
        const { value, allowedModes } = this.props;
        const { user } = this.context;

        let modeOpts = [
            {value: 'smart-choices',  icon: 'icon-lightbulb',            label: 'Smart Choices'},
            {value: 'restaurants',    icon: 'icon-restaurant-meals',     label: 'Restaurant Meals'},
            {value: 'favorites',      icon: 'icon-heart2',               label: 'Choose from Favorites'},
            {value: 'ingredients',    icon: 'icon-seach-by-ingredients', label: 'Search by Ingredients'},
            {value: 'browser',        icon: 'icon-search5',              label: 'Search Foods and Recipes'},
            {value: 'use-own-recipe', icon: 'icon-add-circle-outline2',  label: 'Use Your Own Recipe'},
            {value: 'create-custom',  icon: 'icon-pencil-notebook',      label: 'Create Custom'},
        ];

        if (allowedModes) {
            modeOpts = modeOpts.filter(opt => allowedModes.includes(opt.value));
        }

        const mode = modeOpts.find(m => m.value === value) || modeOpts[0];

        if (!mode) {
            return <span />
        }

        return (
            <div className="search-type-popover">
                <Dropdown button={<span><i className={mode.icon} />{mode.label}</span>} ref="popover">
                    <ul className="search-types-list">
                        {modeOpts.map((m, i) => (
                            <li key={i} className="search-type-option" data-active={m.value === value}>
                                <button onClick={() => this.onChange(m)}>
                                    <i className={m.icon} />
                                    {m.label}
                                </button>
                            </li>
                        ))}
                    </ul>
                </Dropdown>
            </div>
        );
    }
}
