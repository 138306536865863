'use strict';

import AppDispatcher from '../dispatcher/AppDispatcher';
import { EventEmitter } from 'events';
import LocalStorage from 'store';
import assign from 'object-assign';
import debounce from 'lodash.debounce';
import omit from 'lodash.omit';
import moment from 'moment';
import chunk from 'lodash.chunk';
import indexBy from 'lodash.indexby';
import * as Sentry from '@sentry/react';

import AuthStore from './AuthStore';
import UserStore from './UserStore';
import UserConstants from '../constants/UserConstants';
import MealConstants from '../constants/MealConstants';

const CHANGE_EVENT = 'change';
const DELTA_QUEUE_KEY = 'meal-store-delta-queue';

let _store = {
    meals: [],
    orders: [],
    _indexByUuids: {},

    dateStart: null,
    dateEnd: null,

    loadedDateStart: null,
    loadedDateEnd: null,

    loginComplete: false,
};

let _synchronizing = false;

function processLoadResponse(response) {
    if (!(response && response.elements)) {
        return;
    }

    response.elements.forEach(meal => {

        // Is this meal currently dirty in the stack? Don't overwrite it.
        const existingMeal = _store.meals.find((m, i) => {
            return m && meal && m.uuid === meal.uuid;
        });

        // Don't reload updating or dirty meals, that just means that the server hasn't gotten to them yet
        // or we haven't sent them to the server yet.
        if (existingMeal && existingMeal.updating) {
            return;
        }

        // Is this record currently being updated? Use that copy instead. Server will be updated later.
        const deltaQueue = (LocalStorage.get(DELTA_QUEUE_KEY) || []);
        const queuedUpdate = deltaQueue.find(qmeal => qmeal.uuid === meal.uuid);

        if (queuedUpdate) {
            meal = queuedUpdate;
        }

        // Re-index the meals and store them in memory
        if (typeof _store._indexByUuids[meal.uuid] == 'number') {
            // Update the record
            _store.meals[_store._indexByUuids[meal.uuid]] = meal;
        } else if (existingMeal) {
            let i = _store.meals.indexOf(existingMeal);

            if (i != -1) { // This should never not be true
                _store.meals[i] = meal;
                _store._indexByUuids[meal.uuid] = i;
            }
        } else {
            // Store the record in the store and update the ID index.
            let len = _store.meals.push(meal);

            _store._indexByUuids[meal.uuid] = len - 1;
        }
    });
}

function loadMealsByDateRange(dateStart, dateEnd) {
    const url = UserStore.getLinks().meals;
    const query = {
        startDate: moment(dateStart).format('YYYY-MM-DD'),
        endDate: moment(dateEnd).format('YYYY-MM-DD'),
    };

    return AuthStore.fetch({url, query}).then(
        results => {
            // Remember that we've loaded these dates.
            if (!_store.loadedDateStart ||
                (_store.loadedDateStart && _store.loadedDateStart.isSameOrAfter(dateStart, 'day'))) {
                _store.loadedDateStart = moment(dateStart);
            }

            if (!_store.loadedDateEnd ||
                (_store.loadedDateEnd && _store.loadedDateEnd.isSameOrBefore(dateEnd, 'day'))) {
                _store.loadedDateEnd = moment(dateEnd);
            }

            return processLoadResponse(results);
        },
        error => false
    );
}

function loadMealsById(uuids) {
    const url = UserStore.getLinks().meals;
    let promises = [];

    // Exclude any UUIDs that are already loaded
    const existingUuids = _store.meals.map(m => m.uuid);

    uuids = uuids.filter(uuid => !existingUuids.includes(uuid));

    // How many uuids are we talking? We need to chunk in less than the
    // server can hold in each request. We can fire all them at once though
    // and the browser wil manage how many it wants to send at once.
    chunk(uuids, 50).forEach(uuids => {
        promises.push(AuthStore.fetch({url, query: {uuids}}).then(processLoadResponse));
    });

    return Promise.all(promises);
}

function updateIndex() {
    const idx = {};

    _store.meals.forEach((meal, i) => {
        idx[meal.uuid] = i;
    });

    _store._indexByUuids = idx;
}

let abortController = null;
async function realAsyncSendMealsToServer(meals) {
    const user = UserStore.getUser();

    if (!user) {
        return;
    }

    // do we have abort controller already ? if yes, abort.
    if (abortController) {
        abortController.abort();
    }

    // create abort controller
    abortController = new AbortController();

    try {
        await AuthStore.fetch(UserStore.getLinks().meals, {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=collection/meal/1'},
            body: JSON.stringify(meals.map(meal => omit(meal, 'links')))
        }, false, false, abortController);
    } catch(error) {
    } finally {

        // always reset abort controller in the end
        abortController = null;
    }
}

function sendMealsToServer(meals) {
    const user = UserStore.getUser();

    // Don't try this in multi-threading land kids.
    if (_synchronizing || !user) {
        return;
    }

    _synchronizing = true;

    // Unset the dirty flag on all these right away (so if they change again
    // before we get a response back, they'll be updated again on the next bounce)
    meals.forEach(m => m.updating = true);

    return AuthStore.fetch(UserStore.getLinks().meals, {
        method: 'POST',
        headers: {'Content-Type': 'application/json; schema=collection/meal/1'},
        body: JSON.stringify(meals.map(meal => omit(meal, 'links')))
    }).then(
        (results) => {
            // This must be turned off before firing the secondary syncs (because
            // they'll turn it back on). Thank gods for single threaded JS.
            _synchronizing = false;

            meals.forEach(m => delete m.updating);

            // Remove these meals from the delta queue, we don't want to send that update again
            let deltaQueue = LocalStorage.get(DELTA_QUEUE_KEY) || [];
            meals.forEach(meal => {
                // We want to remove the FIRST instance of the meal in the queue, not any more than that.
                const qmeal = deltaQueue.filter(qmeal => qmeal.uuid === meal.uuid)[0];

                if (qmeal) {
                    deltaQueue.splice(deltaQueue.indexOf(qmeal), 1);
                }
            });
            LocalStorage.set(DELTA_QUEUE_KEY, deltaQueue);

            // Are there any dirty meal left in the queue? Immediately flush them (do not wait for debounce)
            realSynchronizeMeals();
        },
        (error) => {
            _synchronizing = false;

            // We're no longer updating the meal, remove that flag.
            meals.forEach(m => delete m.updating);

            // Retry once after 5 seconds.
            setTimeout(realSynchronizeMeals, 5000);
        }
    );
}

function deleteMealsByUuid(uuids) {
    const user = UserStore.getUser();

    if (!user) {
        return;
    }

    return AuthStore.fetch(UserStore.getLinks().meals, {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json; schema=multi/delete/1'},
        body: JSON.stringify({uuids}),
    });
}

function realClearDeletedMeals() {
    // Remove these from our list
    _store.meals = _store.meals.filter(meal => !meal.deleted);

    // Update the index
    updateIndex();

    // Emit an event change
    MealStore.emitChange();
}

function realSynchronizeMeals() {
    let dirtyMeals = (LocalStorage.get(DELTA_QUEUE_KEY) || []);

    if (dirtyMeals.length > 0) {
        sendMealsToServer(dirtyMeals);
    }
}

function ensureDateRangeLoaded(newDateStart, newDateEnd) {
    newDateStart = moment(newDateStart);
    newDateEnd   = moment(newDateEnd);

    // Is new date start BEFORE our current date start? Load from new date start to old date start.
    // Is new date end AFTER our current date end? Load from old date end to new date end.
    const needNewDateStart = !_store.dateStart || (_store.dateStart && newDateStart.isBefore(_store.dateStart, 'day')),
          needNewDateEnd   = !_store.dateEnd || (_store.dateEnd && newDateEnd.isAfter(_store.dateEnd, 'day'));

    if (needNewDateStart && needNewDateEnd) {
        // Reload everything between the new date start to end.
        _store.dateStart = newDateStart;
        _store.dateEnd = newDateEnd;

        return loadMealsByDateRange(newDateStart, newDateEnd);
    } else if (needNewDateStart && !needNewDateEnd) {
        const oldDateStart = _store.dateStart;

        _store.dateStart = newDateStart;

        // Reload everything from new date start to old date start
        return loadMealsByDateRange(newDateStart, oldDateStart);
    } else if (!needNewDateStart && needNewDateEnd) {

        const oldDateEnd = _store.dateEnd;

        _store.dateEnd = newDateEnd;

        // Reload everything from old date end to new date end
        return loadMealsByDateRange(oldDateEnd, newDateEnd);
    }

    return null;
}

function initializeLoadedDates() {
    const weekAgo = moment().subtract(1, 'week'),
          inThreeWeeks = moment().add(3, 'week');

    // If we get meals back from a COMPETE_LOGIN call, then we assume we're loaded up to -1 week to +3 weeks.
    _store.loadedDateStart = _store.loadedDateStart || weekAgo;
    _store.dateStart = _store.dateStart || weekAgo;
    _store.loadedDateEnd = _store.loadedDateEnd || inThreeWeeks;
    _store.dateEnd = _store.dateEnd || inThreeWeeks;


    return { weekAgo, inThreeWeeks };
}

const synchronizeMeals = debounce(realSynchronizeMeals, 500);
const clearDeletedMeals = debounce(realClearDeletedMeals, 7000);
const asyncSendMealsToServer = debounce(realAsyncSendMealsToServer, 500);

var MealStore = assign({}, EventEmitter.prototype, {
    getMeals: function() {
        return _store.meals.slice();
    },

    getOrders: function() {
        return _store.orders.slice();
    },

    getOrdersForMeals(meals, includeSubmitted = false) {
        const orders = {};

        const ordersIndex = indexBy(_store.orders, 'uuid');

        meals.forEach(meal => {
            if (!meal.orders?.length) {
                return;
            }

            meal.orders.forEach(uuid => {
                if (!ordersIndex[uuid]) {
                    return;
                }

                if (!includeSubmitted && ordersIndex[uuid]?.status === 'SUBMITTED') {
                    return;
                }

                orders[uuid] = ordersIndex[uuid];
            })
        });

        return Object.values(orders);
    },

    deleteMealsFromDeltaQueue(meals) {
        let deltaQueue = LocalStorage.get(DELTA_QUEUE_KEY) || [];

        meals.forEach(meal => {

            const mealsToRemove = deltaQueue.filter(qmeal => qmeal.uuid === meal.uuid);

            mealsToRemove.forEach(mealToRemove => {
                deltaQueue.splice(deltaQueue.indexOf(mealToRemove), 1);

            });
        });
        LocalStorage.set(DELTA_QUEUE_KEY, deltaQueue);
    },

    checkForMealsUpdateError: function(url, request, json) {
        // On meals update error evict from Mealstore delta queue
        if (url.endsWith("/meals") && ["POST", "DELETE"].includes(request.method) && json.message && json.message.startsWith("Entity #")) {
            const mealIndex = json.message.match(/\d+/g)[0];
            const meals = JSON.parse(request.body);

            MealStore.deleteMealsFromDeltaQueue([meals[mealIndex]]);

            Sentry.withScope((scope) => {
                const sentryError = new Error();
                sentryError.name = 'Meal is evicted due to a validation error';
                sentryError.message = json.message;

                scope.setExtras(meals[mealIndex]);
                Sentry.captureException(sentryError)
            });
        }
    },

    isRangeLoaded: function(dateStart, dateEnd) {
        if (!_store.loadedDateStart || !_store.loadedDateEnd) {
            return false;
        }

        return _store.loadedDateStart.isSameOrBefore(dateStart, 'day') &&
               _store.loadedDateEnd.isSameOrAfter(dateEnd, 'day');
    },

    isLoginComplete: function() {
        return _store.loginComplete;
    },

    getMealById: function(uuid) {
        if (typeof _store._indexByUuids[uuid] !== 'undefined') {
            return _store.meals[_store._indexByUuids[uuid]];
        }

        return false;
    },

    getMealsById: function(uuids) {
        return uuids.map(uuid => _store.meals[_store._indexByUuids[uuid]])
    },

    emitChange: function() {
        this.emit(CHANGE_EVENT);
    },

    addChangeListener: function(callback) {
        this.on(CHANGE_EVENT, callback);
    },

    removeChangeListener: function(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }
});

export default MealStore;

AppDispatcher.register((payload) => {
    let meals = [];
    let uuids = [];
    let promise = null;

    switch (payload.action.actionType) {
        case UserConstants.USER_COMPLETE_LOGIN:
            meals = payload.action.meals;

            _store.loginComplete = true;

            const { weekAgo, inThreeWeeks } = initializeLoadedDates();

            if (meals) {
                // Strip out any meals from -1 to 3 weeks so the incoming meals can replace them
                _store.meals = _store.meals.filter(meal => !(
                    moment(meal.date).isBetween(weekAgo, inThreeWeeks, 'day')
                ));

                updateIndex();

                // Easiest way to ingest new meals into the store... this keeps the index consistent
                processLoadResponse({elements: meals});
            }

            // Merge orders, overwrite old ones with new ones
            _store.orders = Object.values(Object.assign(
                indexBy(_store.orders, 'uuid'),
                indexBy(payload.action.orders || [], 'uuid')
            ));

            // Let everyone know that we're loaded now.
            MealStore.emitChange();

            break;

        case UserConstants.USER_LOGOUT:
            _store.loaded = false;
            _store.meals = [];
            _store.dateStart = null;
            _store.dateEnd = null;
            updateIndex();
            LocalStorage.remove(DELTA_QUEUE_KEY);
            MealStore.emitChange();
            break;

        case MealConstants.MEALS_LOAD_BY_IDS:
            loadMealsById(payload.action.uuids).then(() => {
                MealStore.emitChange();
            });
            break;

        case MealConstants.MEALS_ENSURE_DATE_RANGE:
            promise = ensureDateRangeLoaded(
                payload.action.dateStart,
                payload.action.dateEnd
            );

            // If a change was made, a promise will be returned
            if (promise) {
                promise.then(() => {
                    updateIndex();
                    MealStore.emitChange();
                });
            }
            break;

        case MealConstants.MEALS_UPSERT_WITHOUT_QUEUE:
            meals = payload.action.meals;

            // Prepend any new items to the meals store and update existing ones
            // (even if the object itself has changed)
            const mealsToUpsert = [];
            meals.forEach(meal => {
                if (typeof _store._indexByUuids[meal.uuid] == 'number') {
                    _store.meals[_store._indexByUuids[meal.uuid]] = meal;
                } else {
                    mealsToUpsert.push(meal);
                }
            });
            _store.meals = mealsToUpsert.concat(_store.meals);

            // Update the index
            updateIndex();

            // Emit an event change
            MealStore.emitChange();

            asyncSendMealsToServer(meals);
            break;

        case MealConstants.MEALS_UPSERT:
            meals = payload.action.meals;

            // And add them to the delta queue if they're not there already
            let deltaQueue = (LocalStorage.get(DELTA_QUEUE_KEY) || [])
            meals.forEach(meal => {
                // Find this meal by UUID in the deltaQueue
                const qmeal = deltaQueue.filter(qmeal => qmeal.uuid === meal.uuid)[0];

                // Append to queue if: not already queued OR queued and already sent to server
                if (!qmeal || (qmeal && qmeal.updating)) {
                    deltaQueue.push(meal);
                } else {
                    // Otherwise just update the existing entry (to be extra sure its in there)
                    const i = deltaQueue.indexOf(qmeal);
                    deltaQueue[i] = meal;
                }
            });
            LocalStorage.set(DELTA_QUEUE_KEY, deltaQueue);

            // Update the index
            updateIndex();

            // Prepend any new items to the meals store and update existing ones
            // (even if the object itself has changed)
            const mealsToAdd = [];
            meals.forEach(meal => {
                if (typeof _store._indexByUuids[meal.uuid] == 'number') {
                    _store.meals[_store._indexByUuids[meal.uuid]] = meal;
                } else {
                    mealsToAdd.push(meal);
                }
            });
            _store.meals = mealsToAdd.concat(_store.meals);

            // Update the index
            updateIndex();

            // Emit an event change
            MealStore.emitChange();

            // And trigger a sync to the server
            synchronizeMeals();
            break;

        case MealConstants.MEALS_CLEAR:
            meals = payload.action.meals;

            // Reduce to an array of meal UUIDs
            let uuids = meals.map(m => m.uuid);

            // Filter the meals out of the meal store. They're gone immediately.
            _store.meals = _store.meals.filter(m => !uuids.includes(m.uuid));

            // Update the index
            updateIndex();

            // Emit an event change (to update any listening clients about the 'deleted' flags)
            MealStore.emitChange();
            break;

        case MealConstants.MEALS_DELETE:
            meals = payload.action.meals;

            // Reduce to an array of meal UUIDs
            uuids = meals.map(m => m.uuid);

            // Set the 'deleted' flag
            _store.meals.forEach(meal => {
                if (uuids.includes(meal.uuid)) {
                    meal.deleted = true;
                }
            });

            // Tell the server to delete immediately
            deleteMealsByUuid(uuids);

            // After 5 seconds of not deleting meals, remove everything still deleted from the local list.
            clearDeletedMeals();

            // Emit an event change (to update any listening clients about the 'deleted' flags)
            MealStore.emitChange();
            break;

        case MealConstants.MEALS_FEED_REGEN:
            let { mealsToDelete } = payload.action;

            const mUuidsToDelete = mealsToDelete.map(({uuid}) => uuid);

            // Collect leftover uuids
            const leftoverUuids = _store.meals.filter(({parent_uuid}) => parent_uuid && mUuidsToDelete.includes(parent_uuid))
                                              .map(({uuid}) => uuid);

            const allUuidsToDelete = mUuidsToDelete.concat(leftoverUuids);

            _store.meals = _store.meals.filter(({uuid}) => !allUuidsToDelete.includes(uuid));

            updateIndex();

            MealStore.emitChange();
            break;

        // Meal freshening comes from the Grocery list resource and auto-population.
        case MealConstants.MEALS_HYDRATE:
            meals = payload.action.meals;
            // We'll use the index so be sure it's up-to-date
            updateIndex();

            initializeLoadedDates();

            meals.forEach(meal => {
                if (typeof _store._indexByUuids[meal.uuid] == 'number') {
                    _store.meals[_store._indexByUuids[meal.uuid]] = meal;
                } else {
                    _store.meals.push(meal);
                }

                if (!(_store.loadedDateStart && _store.loadedDateStart.isBefore(meal.date))) {
                    _store.loadedDateStart = moment(meal.date);
                }

                if (!(_store.loadedDateEnd && _store.loadedDateEnd.isAfter(meal.date))) {
                    _store.loadedDateEnd = moment(meal.date);
                }
            });

            // Merge orders, overwrite old ones with new ones
            _store.orders = Object.values(Object.assign(
                indexBy(_store.orders, 'uuid'),
                indexBy(payload.action.orders || [], 'uuid')
            ));

            // Update the index once more
            updateIndex();

            // Emit an event change
            MealStore.emitChange();
            break;
    }
});
