'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { pluralize, singularize } from 'inflected';

import SmartFraction from '../Widgets/SmartFraction.react';
import IngredientWarning from '../Widgets/IngredientWarning.react';

import { smartCeil, roundForHumans, humanizeGrams, humanizeMilliliters, isSingular, inflectUnitOfMeasure } from '../../utils/Math';

import './RecipeIngredients.scss';

export default class FoodIngredients extends Component {

    static propTypes = {
        food: PropTypes.object,
        profile: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {};
    }

    renderIngredient = (ingredient, key) => {
        return (
            <li key={key} className="ingredient">
                {ingredient.ingredient}
            </li>
        );
    }

    renderIngredientGroup = (group, key) => {
        return (
            <ul key={key}>
                {group.title ? <li><h5>{group.title}</h5></li> : <span />}
                {group.items.map((item, i) => this.renderIngredient(item, i))}
            </ul>
        );
    }

    render = () => {
        const { food } = this.props;

        return (
            <div className="ingredients-container el-fonts">

                <ul className="ingredients-list">
                    {food.ingredients.map((group, i) => this.renderIngredientGroup(group, i))}
                </ul>
            </div>
        );
    }
}
