'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import $ from 'jquery';

import Select from '../../pro/components/Widgets/Select.react';
import DateSelector from '../../pro/components/Widgets/DateSelector.react';

import UserStore from '../../stores/UserStore';

import energyFormulas from '../../utils/EnergyCalculator';
import { cmToFeetInches } from '../../pro/utils/Patients';
import { getPortionSizes, calculateNearestPortionSize } from '../../tables/portions';

import './HouseholdMemberContent.scss';
import './PreferencesForm.scss';
import { calculateTargetEnergy } from '../../utils/AdvanceEnergyCalc';

const mifflin = energyFormulas.filter((f) => f.prop === 'mifflin')[0];

const heightFeetOpts = [
    { value: 1, label: '1 ft.' },
    { value: 2, label: '2 ft.' },
    { value: 3, label: '3 ft.' },
    { value: 4, label: '4 ft.' },
    { value: 5, label: '5 ft.' },
    { value: 6, label: '6 ft.' },
    { value: 7, label: '7 ft.' },
    { value: 8, label: '8 ft.' },
];

const heightInchOpts = [
    { value: 0, label: '0 in.' },
    { value: 1, label: '1 in.' },
    { value: 2, label: '2 in.' },
    { value: 3, label: '3 in.' },
    { value: 4, label: '4 in.' },
    { value: 5, label: '5 in.' },
    { value: 6, label: '6 in.' },
    { value: 7, label: '7 in.' },
    { value: 8, label: '8 in.' },
    { value: 9, label: '9 in.' },
    { value: 10, label: '10 in.' },
    { value: 11, label: '11 in.' },
];

export default class HouseholdMemberContent extends Component {
    static propTypes = {
        member: PropTypes.object,
        profile: PropTypes.object,
        closeModal: PropTypes.object,
    };

    static contextTypes = {
        confirm: PropTypes.func,
    };

    constructor(props) {
        super(props);

        const { member } = props;

        let {
            name = '',
            birthdate,
            gender,
            pregnant = false,
            lactating = false,
            due_date = null,
            fetus_count = null,

            height_cm = '',
            weight_kg = '',
            goal_weight_kg = '',

            activity_level = '',
            target_energy_kcal = '',
            portion,
        } = member;

        const height = cmToFeetInches(height_cm || 0);

        this.state = {
            name,
            birthdate,
            gender,
            pregnant,
            lactating,
            due_date,
            fetus_count,

            height_cm,
            height_feet: height.feet,
            height_inches: height.inches,
            weight_kg,
            weight_lbs: weight_kg ? Math.round(weight_kg * 2.20462) : '',
            goal_weight_kg,
            goal_weight_lbs: Math.round(goal_weight_kg * 2.20462) || '',

            activity_level,
            target_energy_kcal,
            portion,

            dirty: false,
        };
    }

    calc = () => {
        const { profile } = this.props;
        const {
            gender,
            pregnant,
            lactating,
            birthdate,
            weight_kg,
            height_cm,
            activity_level,
            goal_weight_kg,
            fetus_count,
            due_date,
        } = this.state;
        const profileParams = {
            gender,
            pregnant,
            lactating,
            birthdate,
            weight_kg,
            height_cm,
            activity_level,
            goal_weight_kg,
            fetus_count,
            due_date,
        };
        profileParams.portion_resolution = profile.preferences.portion_resolution
            ? profile.preferences.portion_resolution
            : 0.25;

        if (!(gender && birthdate && weight_kg && height_cm && activity_level)) {
            return;
        }

        const { target_energy_kcal, portion } = calculateTargetEnergy(profileParams);

        this.setState({ target_energy_kcal, portion });
    };

    onChangeName = (ev) => {
        this.setState({ name: ev.target.value, dirty: true });
    };

    onChangeHeightFeet = (height_feet) => {
        const { height_inches } = this.state;

        const feet = parseInt(height_feet) || 0,
            inches = parseInt(height_inches) || 0;

        const height_cm = Math.round((feet * 12 + inches) * 2.54) || '';

        this.setState({ height_feet, height_cm, dirty: true }, this.calc);
    };

    onChangeHeightInches = (height_inches) => {
        const { height_feet } = this.state;

        const feet = parseInt(height_feet) || 0,
            inches = parseInt(height_inches) || 0;

        const height_cm = Math.round((feet * 12 + inches) * 2.54) || '';

        this.setState({ height_inches, height_cm, dirty: true }, this.calc);
    };

    onChangeHeightCm = (ev) => {
        const height_cm = ev.target.value;
        const english = cmToFeetInches(height_cm) || { feet: '', inches: '' };

        this.setState({ height_cm, height_feet: english.feet, height_inches: english.inches, dirty: true }, this.calc);
    };

    onChangeWeightLbs = (ev) => {
        const weight_lbs = ev.target.value;
        const weight_kg = Math.round(weight_lbs * 0.453592 * 10) / 10 || '';

        this.setState({ weight_lbs, weight_kg, dirty: true }, this.calc);
    };

    onChangeWeightKg = (ev) => {
        const weight_kg = ev.target.value;

        const weight_lbs = Math.round((weight_kg / 0.453592) * 10) / 10 || '';

        this.setState({ weight_kg, weight_lbs, dirty: true }, this.calc);
    };

    onChangeActivityLevel = (activity_level) => {
        this.setState({ activity_level, dirty: true }, this.calc);
    };

    onChangeGoalWeightLbs = (ev) => {
        const goal_weight_lbs = ev.target.value;
        const goal_weight_kg = Math.round(goal_weight_lbs * 0.453592 * 10) / 10 || '';

        this.setState({ goal_weight_lbs, goal_weight_kg, dirty: true }, this.calc);
    };

    onChangeGoalWeightKg = (ev) => {
        const goal_weight_kg = ev.target.value;
        const goal_weight_lbs = Math.round((goal_weight_kg / 0.453592) * 10) / 10 || '';

        this.setState({ goal_weight_kg, goal_weight_lbs, dirty: true }, this.calc);
    };

    onTogglePregnant = () => {
        this.setState({ pregnant: !this.state.pregnant, dirty: true }, this.calc);
    };

    onToggleLactating = () => {
        this.setState({ lactating: !this.state.lactating, dirty: true }, this.calc);
    };

    onChangeDueDate = (due_date) => {
        this.setState({ due_date, dirty: true }, this.calc);
    };

    onChangeFetusCount = (fetus_count) => {
        this.setState({ fetus_count, dirty: true }, this.calc);
    };

    onChangeGender = (gender) => {
        let { pregnant, lactating, fetus_count } = this.state;

        if (gender !== 'female') {
            pregnant = false;
            lactating = false;
            fetus_count = false;
        }

        this.setState({ gender, pregnant, lactating, fetus_count, dirty: true }, this.calc);
    };

    onChangeBirthdate = (birthdate) => {
        this.setState({ birthdate, dirty: true }, this.calc);
    };

    portionResolution = () => {
        return this.props.profile.preferences.portion_resolution
            ? this.props.profile.preferences.portion_resolution
            : 0.25;
    };

    onChangeEnergyNeeds = (energyInKCal) => {
        const { standard_calories = 2000, inhibit_deadzone = false } =
            UserStore.getUser()?.features?.energy_estimator || {};

        const nearestPortionSize = calculateNearestPortionSize(
            energyInKCal,
            this.portionResolution(),
            standard_calories,
            inhibit_deadzone
        );

        this.setState({ portion: nearestPortionSize, dirty: true, target_energy_kcal: energyInKCal });
    };

    validate = () => {
        const { name, birthdate, gender, height_cm, weight_kg, activity_level, portion } = this.state;
        const { member } = this.props;

        if (!name) {
            return { error: 'name', alert: 'Please enter name' };
        }

        if (member.type === 'adult') {
            if (!birthdate) {
                return { error: 'birthdate', alert: 'Please select birthdate' };
            }

            if (!gender) {
                return { error: 'gender', alert: 'Please select a gender' };
            }

            if (!height_cm) {
                return { error: 'height', alert: 'Please enter height' };
            }

            if (!weight_kg) {
                return { error: 'weight', alert: 'Please enter weight' };
            }

            if (!activity_level) {
                return { error: 'activity_level', alert: 'Please select an activity level' };
            }
        }

        if (!portion) {
            return {
                error: 'portion',
                alert: 'Please select a portion size or fill in everything else to calculate it.',
            };
        }

        return true;
    };

    apply = () => {
        let {
            name,
            birthdate,
            gender,
            pregnant,
            lactating,
            due_date,
            fetus_count,

            height_cm,
            weight_kg,
            goal_weight_kg,

            activity_level,
            target_energy_kcal,
            portion,
            dirty,
        } = this.state;

        const { member, onChange } = this.props;
        const { confirm } = this.context;

        let alert;
        if (true !== (alert = this.validate())) {
            $(this.scrollable).animate({ scrollTop: 0 }, 250); // reset scroll to top

            this.setState(alert);
            return;
        }

        if (!dirty) {
            this.props.closeModal();
            return;
        }

        member.name = name;
        member.birthdate = birthdate;
        member.gender = gender;
        member.pregnant = gender === 'female' ? pregnant : false;
        member.lactating = gender === 'female' ? lactating : false;
        member.due_date = gender === 'female' ? due_date : null;
        member.fetus_count = gender === 'female' ? fetus_count : 0;

        member.height_cm = height_cm;
        member.weight_kg = weight_kg;
        member.goal_weight_kg = goal_weight_kg;

        member.activity_level = activity_level;
        member.target_energy_kcal = target_energy_kcal;
        member.portion = portion;

        onChange(member);

        confirm('Your changes have been saved', this.props.closeModal, false, { rejectText: null });

        this.setState({ dirty: false });
    };

    confirmDeleteMember = () => {
        const { confirm } = this.context;
        const { member, onDeleteFamilyMember } = this.props;

        confirm(
            <div>
                <h1>Delete Household Member?</h1>

                <p>This will permanently delete {member.name} from your account, along with any associated data. </p>
                <p>This cannot be undone.</p>

                <p>Are you sure?</p>
            </div>,
            (accept) => onDeleteFamilyMember(member),
            (reject) => false
        );
    };

    render() {
        const { member, profile } = this.props;
        const {
            name,
            birthdate,
            gender,
            pregnant,
            lactating,
            fetus_count,

            height_cm,
            height_feet,
            height_inches,
            weight_kg,
            weight_lbs,
            goal_weight_kg,
            goal_weight_lbs,

            activity_level,
            target_energy_kcal,
            portion,

            error,
            alert,
            dirty,
        } = this.state;

        const { standard_calories = 2000, inhibit_deadzone = false } =
            UserStore.getUser()?.features?.energy_estimator || {};

        const portionResolution = this.portionResolution();
        const recommendedPortion =
            true === this.validate() && target_energy_kcal > 0
                ? calculateNearestPortionSize(
                      target_energy_kcal,
                      portionResolution,
                      standard_calories,
                      inhibit_deadzone
                  )
                : null;
        const portionSizeOpts = getPortionSizes(portionResolution, recommendedPortion);

        return (
            <>
                <header>
                    <h2>{dirty ? '*' : ''}Edit Household Member</h2>

                    <button className="el-modal-close-x" onClick={this.props.closeModal}>
                        <i className="icon-close-x" />
                        <span className="assistive-text">Close Modal</span>
                    </button>
                </header>

                <div className="el-modal-body-container el-modal2-body-container el-fonts inner-slider modal-utils preferences-form household-member-content">
                    <div className="household-member-form">
                        <div className="with-label name" data-error={error === 'name'}>
                            <label>Name</label>
                            <input type="text" placeholder="Name" value={name} onChange={this.onChangeName} />
                        </div>

                        {member.type === 'adult' ? (
                            <div className="with-label gender" data-error={error === 'gender'}>
                                <label>Birth Sex</label>

                                <div className="toggle-container">
                                    <button
                                        className="toggle-btn female-btn"
                                        data-active={gender == 'female'}
                                        onClick={() => this.onChangeGender('female')}
                                    >
                                        <i className="icon-female-sign" />
                                        Female
                                    </button>
                                    <button
                                        className="toggle-btn male-btn"
                                        data-active={gender == 'male'}
                                        onClick={() => this.onChangeGender('male')}
                                    >
                                        <i className="icon-male-sign" />
                                        Male
                                    </button>
                                </div>
                            </div>
                        ) : null}

                        {gender === 'female' && member.type === 'adult' ? (
                            <div className="with-label child-bearing-container">
                                <label></label>
                                <button
                                    className="meal-type toggle-btn breakfasts"
                                    data-active={pregnant}
                                    onClick={() =>
                                        this.setState({ pregnant: !pregnant, lactating: false, dirty: true }, this.calc)
                                    }
                                >
                                    <i className="icon-pregnant" />
                                    Pregnant
                                </button>

                                <button
                                    className="meal-type toggle-btn breakfasts"
                                    data-active={lactating}
                                    onClick={() =>
                                        this.setState(
                                            { pregnant: false, lactating: !lactating, dirty: true },
                                            this.calc
                                        )
                                    }
                                >
                                    <i className="icon-mother-child" />
                                    Breast feeding
                                </button>
                            </div>
                        ) : null}

                        {member.type === 'adult' && gender === 'female' && pregnant ? (
                            <div className="with-label how-many-babies" data-error={error === 'fetus_count'}>
                                <label>How many are you expecting?</label>

                                <div className="toggle-container">
                                    <button
                                        className="toggle-btn male-btn"
                                        data-active={fetus_count == 1}
                                        onClick={() => this.onChangeFetusCount(1)}
                                    >
                                        Single
                                    </button>
                                    <button
                                        className="toggle-btn female-btn"
                                        data-active={fetus_count == 2}
                                        onClick={() => this.onChangeFetusCount(2)}
                                    >
                                        Twins
                                    </button>
                                </div>
                            </div>
                        ) : null}

                        {member.type === 'adult' ? (
                            <div className="with-label birthdate" data-error={error === 'birthdate'}>
                                <label>{name || 'Family Member'}&apos;s Birth Date</label>
                                <DateSelector initialDate={birthdate} onChange={this.onChangeBirthdate} />
                            </div>
                        ) : null}

                        {member.type === 'adult' ? (
                            <div className="with-label height" data-error={error === 'height'} data-testid="height">
                                <label>Height</label>

                                {profile.units_mode === 'english' ? (
                                    <Select
                                        className="height-feet"
                                        value={height_feet}
                                        placeholder="feet"
                                        options={heightFeetOpts}
                                        onChange={this.onChangeHeightFeet}
                                    >
                                        <p>feet.</p>
                                    </Select>
                                ) : null}

                                {profile.units_mode === 'english' ? (
                                    <Select
                                        className="height-inches"
                                        value={height_inches}
                                        placeholder="inches"
                                        options={heightInchOpts}
                                        onChange={this.onChangeHeightInches}
                                    >
                                        <p>inches.</p>
                                    </Select>
                                ) : null}

                                {profile.units_mode === 'metric' ? (
                                    <div className="with-units height-cm">
                                        <input
                                            type="number"
                                            placeholder="Height"
                                            value={height_cm}
                                            onChange={this.onChangeHeightCm}
                                        />

                                        <label>cm.</label>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}

                        {member.type === 'adult' ? (
                            <div className="with-label weight" data-testid="weight">
                                {gender === 'female' && pregnant ? (
                                    <label>Pre-Pregnancy Weight</label>
                                ) : (
                                    <label>Current Weight</label>
                                )}

                                {profile.units_mode === 'english' ? (
                                    <div className="with-units weight-lbs">
                                        <input
                                            type="number"
                                            data-testid="weight-lbs"
                                            data-error={error === 'weight'}
                                            value={weight_lbs}
                                            onChange={this.onChangeWeightLbs}
                                        />
                                        <label>Lbs.</label>
                                    </div>
                                ) : null}

                                {profile.units_mode === 'metric' ? (
                                    <div className="with-units weight-kg">
                                        <input
                                            type="number"
                                            data-testid="weight-kg"
                                            data-error={error === 'weight'}
                                            value={weight_kg}
                                            onChange={this.onChangeWeightKg}
                                        />

                                        <label>Kg.</label>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}

                        {member.type === 'adult' && !pregnant ? (
                            <div className="with-label weight" data-testid="goal-weight">
                                <label>
                                    Goal Weight <em>(optional)</em>
                                </label>
                                {profile.units_mode === 'english' ? (
                                    <div className="with-units">
                                        <input
                                            type="number"
                                            value={goal_weight_lbs}
                                            onChange={this.onChangeGoalWeightLbs}
                                            data-testid="goal-weight-lbs"
                                        />
                                        <label>lbs.</label>
                                    </div>
                                ) : null}

                                {profile.units_mode === 'metric' ? (
                                    <div className="with-units">
                                        <input
                                            type="number"
                                            value={goal_weight_kg}
                                            onChange={this.onChangeGoalWeightKg}
                                            data-testid="goal-weight-kg"
                                        />
                                        <label>kg.</label>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}

                        {member.type === 'adult' ? (
                            <div className="with-label activity-level" data-testid="activity">
                                <label>Activity Level</label>

                                <Select
                                    showAbove
                                    options={mifflin.activity_levels}
                                    value={activity_level}
                                    data-error={error == 'activity_level'}
                                    placeholder="Activity Level"
                                    onChange={this.onChangeActivityLevel}
                                />
                            </div>
                        ) : null}

                        <div className="with-label portion">
                            <label>Portion Size:</label>
                            <Select
                                optionClassName="sub-label-short"
                                options={portionSizeOpts}
                                value={portion}
                                onChange={(portion) => this.setState({ portion, dirty: true })}
                            />
                        </div>

                        <div className="with-label energy-needs">
                            <label>Energy Needs</label>
                            <input
                                type="text"
                                value={target_energy_kcal}
                                data-error={error === 'target_energy_kcal'}
                                onChange={(ev) => this.onChangeEnergyNeeds(ev.target.value)}
                            />
                        </div>

                        <div>
                            <button className="sub-action-btn" onClick={this.confirmDeleteMember}>
                                delete {name} from your account
                            </button>
                        </div>
                    </div>
                </div>

                <footer>
                    {alert ? <p className="error-msg">{alert}</p> : null}
                    <div>
                        <button className="el-modal-cancel-btn" onClick={this.props.closeModal}>
                            Cancel
                        </button>

                        {dirty ? (
                            <button className="el-modal-ok-btn" onClick={this.apply}>
                                Save
                            </button>
                        ) : null}
                    </div>
                </footer>
            </>
        );
    }
}
