"use strict";

import React from "react";
import Select from "../../pro/components/Widgets/Select.react";

import "./ActivityLevelSelector.scss";

const ActivityLevelSelector = ({
    value,
    options,
    dataError,
    onChange,
    className = "",
    tabIndex = 1,
    placeholder = "Choose level",
    title = "Choosing your level of activity",
    showAbove = false
}) => {
    return (
        <div className={"activity-level-selector with-label " + className}>
            <label>Activity Level</label>

            <div className="activity-level-group">
                <Select
                    options={options}
                    tabIndex={tabIndex}
                    value={value}
                    data-error={dataError}
                    placeholder={placeholder}
                    showAbove={showAbove}
                    onChange={onChange}>
                        <p>{title ? title : 'Activity Level'}</p>
                </Select>
            </div>
        </div>
    );
};

export default ActivityLevelSelector;
