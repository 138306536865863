import CreateCustom from "./CreateCustom.react";
import Favorites from "./Favorites.react";
import FullBrowser from "./FullBrowser.react";
import SmartChoices from "./SmartChoices.react";
import Restaurants from "./Restaurants.react";
import UseOwnRecipe from "./UseOwnRecipe.react";
import QuickLogAiModal from '../../../Recipes/Editor/QuickLogAiModal.react'
import React from 'react';

const allModes = ['create-custom', 'smart-choices', 'favorites', 'browser', 'restaurants', 'store', 'ingredients', 'use-own-recipe', 'quick-log-ai'];

export const SelectRecipe = ({
    mode,
    params,
    onChangeParams,
    profile,
    allowedTypes,
    modalTitle,
    useComputedParams,
    fullBrowserParams,
    onChangeMode,
    closeModal,
    onSelectFood,
    createCustomParams,
    mealType,
    onLogFoods,
}) => {
    return (
        <>
            {!allModes.includes(mode) ?
                <div className="el-modal-body-container el-modal1-body-container el-fonts" onClick={(ev) => ev.stopPropagation()}>
                    <section className="inner-slider">
                        <p className="t2">Nothing to see here. Please move along.</p>
                    </section>
                </div>
            : null}
            {mode === 'create-custom' ?
            <CreateCustom profile={profile} closeModal={closeModal}
                onSelectFood={onSelectFood}
                className={"el-modal1-body-container"}
                {...createCustomParams} /> :

            <div className="el-modal-body-container el-modal1-body-container el-fonts" onClick={(ev) => ev.stopPropagation()}>
                    {mode === 'smart-choices' ?
                        <SmartChoices params={params}
                            showSearchTerms={true}
                            onChangeParams={onChangeParams} 
                            mealType={mealType} />
                    : null}

                    {mode === 'favorites' ?
                        <Favorites params={params}
                            profile={profile}
                            allowedTypes={allowedTypes}
                            onChangeParams={onChangeParams} />
                    : null}

                    {mode === 'browser' ?
                        <FullBrowser params={params}
                            profile={profile}
                            modalTitle={modalTitle}
                            allowedTypes={allowedTypes}
                            useComputedParams={useComputedParams}
                            {...fullBrowserParams}
                            onChangeParams={onChangeParams}
                            onChangeMode={onChangeMode} />
                    : null}

                    {mode === 'restaurants' ?
                        <Restaurants params={params}
                            profile={profile}
                            closeModal={closeModal}
                            onChangeParams={onChangeParams} />
                    : null}

                    {mode === 'store' ?
                        <Restaurants params={params}
                            profile={profile}
                            closeModal={closeModal}
                            mode="store"
                            onChangeParams={onChangeParams} />
                    : null}

                    {mode === 'ingredients' ?
                        <SmartChoices params={params}
                            showSearchTerms={true}
                            searchTermsPlaceholder="Enter ingredients: ex. chicken, apple"
                            searchTermsProp="food_names"
                            inhibitSearchOnMount={true}
                            onChangeParams={onChangeParams} 
                            mealType={mealType} />
                    : null}

                    {mode === 'use-own-recipe' ?
                        <UseOwnRecipe profile={profile} closeModal={closeModal} />
                    : null}

{/*mode === 'quick-log-ai' ?
    <QuickLogAiModal
        onLogFoods={onLogFoods}
        showModal={true} onCloseModal={() => onChangeMode('browser')}
    />
: null*/}
            </div>}
        </>
    );
}
