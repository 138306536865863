import React, { useState, useRef, useEffect } from 'react';
import './NumberedTextArea.scss';

const NumberedTextArea = ({ value, onChange, ...props }) => {
    const [lines, setLines] = useState(['']);
    const textAreaRef = useRef(null);
    const lineNumbersRef = useRef(null);

    const calculateLines = (text) => {
        const lines = text.split('\n');
        setLines(lines);
    };

    const handleScroll = () => {
        if (textAreaRef.current && lineNumbersRef.current) {
            lineNumbersRef.current.scrollTop = textAreaRef.current.scrollTop;
        }
    };

    const handleChange = (e) => {
        const text = e.target.value;
        calculateLines(text);
        if (onChange) onChange(e);
    };

    useEffect(() => {
        calculateLines(value || '');
    }, [value]);

    return (
        <div className="numbered-textarea">
            <ol className="line-numbers" ref={lineNumbersRef} aria-hidden="true">
                {lines.map((line, index) => (
                    <li key={index} className="line-number">
                        <span className="line-content">{line}</span>
                    </li>
                ))}
            </ol>
            <textarea
                {...props}
                ref={textAreaRef}
                value={value}
                onChange={handleChange}
                onScroll={handleScroll}
            />
        </div>
    );
};

export default NumberedTextArea;
