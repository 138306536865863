'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import DrawerMenu from '../../pro/components/Widgets/DrawerMenu.react';
import BoardStore from '../../stores/BoardStore';
import { getPrimaryMeal } from '../../utils/Meals';

import './PastMenu.scss';

export default class PastMenu extends Component {

    static contextTypes = {
        user: PropTypes.object,
        router: PropTypes.object,
        onFavoriteMeal: PropTypes.func,
        startAddMeal: PropTypes.func,
        startRepeatMeal: PropTypes.func,
        startRescheduleMeal: PropTypes.func,
        recipes: PropTypes.object,
        foods: PropTypes.object,
    };

    render() {
        const { user } = this.context;
        const { inhibit_swap = false, rd_override = false } = (user && user.preferences) || {};
        const { date, mealType, meals, primary } = this.props;
        const { router, startAddMeal, onFavoriteMeal, startRescheduleMeal, startRepeatMeal, recipes, foods } = this.context;

        const allowedModes = ['restaurants', 'store', 'favorites', 'browser','use-own-recipe', 'create-custom'];

        const { content } = getPrimaryMeal([primary], recipes, foods);

        const { uuid } = content || {};

        const isBoarded = uuid ? BoardStore.getBoardsByResourceId(uuid).length > 0 : false;

        return (
            <DrawerMenu key="past-ellipsis" menuTitle="More Options" button={<i className="icon-ellipsis2" />}
                className="ellipsis-menu-btn" modalClassName="feed-past-menu">
                <div className="feed-menu-content">
                    <button className="menu-item add-food" onClick={() => startAddMeal(date, mealType, {defaultMode: 'browser', allowedModes, auto_log: true})}>
                        Add more food <i className="icon-add-circle-outline2" />
                    </button>
                    {primary.meal_type !== 'leftover' && (!inhibit_swap || rd_override) ?
                        <button className="menu-item reschedule" onClick={() => startRepeatMeal(meals)}>
                            Repeat meal <i className="icon-calendar7" />
                        </button>
                    : null}
                    {(!inhibit_swap || rd_override) ?
                        <button className="menu-item reschedule" onClick={() => startRescheduleMeal(meals)}>
                            Reschedule <i className="icon-calendar7" />
                        </button>
                    : null}
                    <button className="menu-item view-details" onClick={() => router.push(`/log/${date.format('YYYY-MM-DD')}`)}>
                        View daily log <i className="icon-pencil-notebook" />
                    </button>
                    {primary.meal_type !== 'product' ? <button className="menu-item favorites" onClick={() => onFavoriteMeal(primary)}>
                        {isBoarded ? "Remove from favorites" : "Add to favorites"} <i className="icon-heart2" />
                    </button> : null}
                </div>
            </DrawerMenu>
        );
    }
}
