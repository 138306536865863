'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import { fetchDocumentsById } from '../../../utils/Content';

export default class Calories extends Component {

    constructor(props) {
        super(props);

        this.state = {
            merchant: null,
        };
    }

    componentDidMount = () => {
        const { params } = this.props;

        if (params && params.filters && params.filters['merchant.uuid']) {
            this.loadMerchant(params.filters['merchant.uuid']);
        }
    }

    loadMerchant = (uuid) => {
        if (!uuid) {
            return;
        }

        fetchDocumentsById([uuid]).then(documents => {
            this.setState({merchant: documents[0]});
        });
    }

    clearMerchant = () => {
        const { params, onChangeParams } = this.props;

        params.filters = params.filters || {};
        delete params.filters['merchant.uuid'];

        onChangeParams(params, true);
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        const { params } = nextProps;
        const { merchant } = this.state;

        if (params && params.filters && params.filters['merchant.uuid']) {
            if (!merchant || (merchant && merchant.uuid !== params.filters['merchant.uuid'])) {
                this.loadMerchant(params.filters['merchant.uuid']);
            }
        }
    }

    render() {
        const { params = {} } = this.props
        const { merchant } = this.state;

        if (!(params && params.filters && params.filters['merchant.uuid'])) {
            return <span />;
        }

        if (!merchant || (merchant && merchant.uuid !== params.filters['merchant.uuid'])) {
            return <span />;
        }

        return (
            <div className="param-item" onClick={this.clearMerchant}>
                {merchant.name} <i className="icon-close-x" />
            </div>
        );
    }
}
