import React, { useState } from "react";
import PropTypes from "prop-types";

import "./TooltipText.scss";

const TooltipText = ({ text, tooltip }, context) => {
    const [isTooltipActive, setIsTooltipActive] = useState(false);

    const showTooltip = () => {
        setIsTooltipActive(true);
    };

    const hiddenTooltip = () => {
        setIsTooltipActive(false);
    };

    const changeTooltipActive = () => {
        setIsTooltipActive(!isTooltipActive);
    };

    return (
        <div className="tooltip-container">
            <div
                onMouseEnter={!context?.isMobile ? showTooltip : null}
                onMouseLeave={!context?.isMobile ? hiddenTooltip : null}
                onClick={context?.isMobile ? changeTooltipActive : null}
                className={`text-ellipsis ${
                    isTooltipActive ? "show-tooltip" : ""
                }`}
            >
                {text}
            </div>
            {isTooltipActive ? (
                <div className="tooltip">{tooltip ? tooltip : text}</div>
            ) : null}
        </div>
    );
};

TooltipText.contextTypes = {
    isMobile: PropTypes.bool,
};

export default TooltipText;
