'use strict';

import AppDispatcher from '../dispatcher/AppDispatcher';
import { EventEmitter } from 'events';
import LocalStorage from 'store';
import assign from 'object-assign';
import debounce from 'lodash.debounce';
import omit from 'lodash.omit';
import indexBy from 'lodash.indexby';

import MealActions from '../actions/MealActions';
import UserConstants from '../constants/UserConstants';
import GroceryConstants from '../constants/GroceryConstants';

import AuthStore from './AuthStore';
import UserStore from './UserStore';
import { getMaxListeners } from 'process';

const CHANGE_EVENT = 'change';
const DELTA_QUEUE_KEY = 'grocery-store-delta-queue';

let _store = {
    groceries: [],
    loading: false,
    loaded: false,
    _indexByUuids: {},
};

let _synchronizing = false;
let _refreshEnabled = false;
let _refreshInt = false;
let _refreshCount = 0;

function getGroceriesMealUuids() {
    let uuids = {};

    _store.groceries.forEach(grocery => {
        if (!grocery.meal_uuids) {
            return;
        }

        const gUuids = grocery.meal_uuids.split(',').filter(v => v);

        gUuids.forEach(uuid => {
            uuids[uuid] = true;
        });
    });

    return Object.keys(uuids);
}

function updateIndex() {
    const idx = {};

    _store.groceries.forEach((grocery, i) => {
        idx[grocery.uuid] = i;
    });

    _store._indexByUuids = idx;
}

function loadGroceries() {
    _store.loading = true;

    return AuthStore.fetch(UserStore.getLinks().groceries).then(
        results => {
            _store.loaded = true;
            _store.loading = false;
            _store.groceries = (results && results.elements) || [];
        },
        error   => _store.loading = false,
    );
}

function sendGroceriesToServer(groceries) {
    const user = UserStore.getUser();

    // Don't try this in multi-threading land kids.
    if (_synchronizing || !user) {
        return;
    }

    // Unset the updating flag on all these right away (so if they change again
    // before we get a response back, they'll be updated again on the next bounce)
    groceries.forEach(g => g.updating = true);

    return AuthStore.fetch(UserStore.getLinks().groceries, {
        method: 'POST',
        headers: {'Content-Type': 'application/json; schema=collection/grocery/1'},
        body: JSON.stringify(groceries.map(grocery => omit(grocery, 'links', 'updating'))),
    }).then(
        results => {
            groceries.forEach(g => delete g.updating);

            // This must be turned off before firing the secondary syncs (because
            // they'll turn it back on). Thank gods for single threaded JS.
            _synchronizing = false;

            // Remove these groceries from the delta queue, we don't want to send that update again
            let deltaQueue = LocalStorage.get(DELTA_QUEUE_KEY) || [];
            groceries.forEach(grocery => {
                // We want to remove the FIRST instance of the grocery in the queue, not any more than that.
                const qgrocery = deltaQueue.find(qgrocery => qgrocery.uuid === grocery.uuid);

                if (qgrocery) {
                    deltaQueue.splice(deltaQueue.indexOf(qgrocery), 1);
                }
            });
            LocalStorage.set(DELTA_QUEUE_KEY, deltaQueue, new Date().getTime() + 1000 * 3600 * 24);

            // Are there any groceries left in the queue? Immediately flush them (do not wait)
            realSynchronizeGroceries();

            return groceries;
        },
        error => {
            _synchronizing = false;

            // We're no longer updating the grocery, remove that flag.
            groceries.forEach(g => delete g.updating);

            // Retry once after 5 seconds.
            setTimeout(realSynchronizeGroceries, 5000);
        }
    );
}

function deleteGroceriesByUuid(uuids) {
    const user = UserStore.getUser();

    if (!user) {
        return;
    }

    return AuthStore.fetch(UserStore.getLinks().groceries, {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json; schema=multi/delete/1'},
        body: JSON.stringify({uuids}),
    });
}

function clearDeletedGroceries() {
    // First, gather a list of the deleted uuids
    const deleted = _store.groceries.filter(grocery => grocery.deleted).map(g => g.uuid);

    // Remove these groceries from the delta queue, we don't want to send any updates for these ever again
    let deltaQueue = LocalStorage.get(DELTA_QUEUE_KEY) || [];
    deltaQueue = deltaQueue.filter(grocery => !grocery.updating && !deleted.includes(grocery.uuid));
    LocalStorage.set(DELTA_QUEUE_KEY, deltaQueue, new Date().getTime() + 1000 * 3600 * 24);

    // Lastly, remove these from our grocery list proper
    _store.groceries = _store.groceries.filter(grocery => !grocery.deleted);
}

function realSynchronizeGroceries() {
    let dirtyGroceries = (LocalStorage.get(DELTA_QUEUE_KEY) || []);

    if (dirtyGroceries.length > 0) {
        sendGroceriesToServer(dirtyGroceries);
    }
}

function refreshGroceries() {
    if (!_refreshEnabled) {
        return;
    }

    _refreshCount++;

    // If we've been on the page for over 300 seconds, bump down to refreshing every 15 seconds instead of 3 seconds.
    let refreshDelay = 10000; // 10 seconds

    if (_refreshCount > 30) { // 300 seconds, 5 minutes
        refreshDelay = 15000; // 15 seconds
    }

    if (_refreshCount > 60) { // 600 seconds, 10 minutes
        refreshDelay = 30000; // 30 seconds
    }

    if (_refreshCount > 90) { // 900 seconds, 15 minutes
        refreshDelay = 90000; // 1.5 minutes
    }

    // Effectively stops the refresh after 20 minutes of being idle
    if (_refreshCount > 120) { // 1200 seconds, 20 minutes
        refreshDelay = 99999999999; // forevs
    }

    // Don't actually sync if we've got something in progress
    let dirtyGroceries = (LocalStorage.get(DELTA_QUEUE_KEY) || []);

    // If we're hidden, don't bother actually querying the server. Just schedule the next iteration to run.
    if (_synchronizing || dirtyGroceries.length || (document && document.hidden)) {
        _refreshInt = setTimeout(() => {
            refreshGroceries();
        }, refreshDelay);

        return;
    }

    _synchronizing = true;

    AuthStore.fetch(UserStore.getLinks().groceries).then(
        results => {
            // As long as we don't have dirty or updating groceries, we can refresh ourselves.
            if (_refreshEnabled &&
                (LocalStorage.get(DELTA_QUEUE_KEY) || []).length == 0 &&
                _store.groceries.filter(g => g.updating || g.deleted).length == 0) {
                _store.groceries = (results && results.elements) || [];

                // Use the silent method to update the MealStore
                if (results && results.meals) {
                    MealActions.hydrateMeals(results.meals, true);
                }

                GroceryStore.emitChange();
            }

            _synchronizing = false;

            // Refresh every available 3 seconds if we're still enabled
            if (_refreshEnabled) {
                _refreshInt = setTimeout(refreshGroceries, refreshDelay);
            }
        },
        error   => {
            _synchronizing = false;

            // Wait 15 seconds before retrying after an error
            if (_refreshEnabled) {
                _refreshInt = setTimeout(refreshGroceries, 15000);
            }
        }
    );
}

const synchronizeGroceries = debounce(realSynchronizeGroceries, 1500);

const GroceryStore = assign({}, EventEmitter.prototype, {
    getGroceries: function () {
        return _store.groceries;
    },

    isLoaded: function () {
        return _store.loaded;
    },

    isLoading: function () {
        return _store.loading;
    },

    isDirty: function () {
        let dirtyGroceries = (LocalStorage.get(DELTA_QUEUE_KEY) || []);

        if (dirtyGroceries.length > 0) {
            return true;
        }

        return false;
    },

    emitChange: function () {
        this.emit(CHANGE_EVENT);
    },

    addChangeListener: function (callback) {
        this.on(CHANGE_EVENT, callback);
    },

    removeChangeListener: function (callback) {
        this.removeListener(CHANGE_EVENT, callback);
    },

    defineMaxListener: function (n) {
        this.setMaxListeners(n);
    },

    deleteGroceriesFromDeltaQueue(groceries) {
        let deltaQueue = LocalStorage.get(DELTA_QUEUE_KEY) || [];

        groceries.forEach(meal => {

            const toRemove = deltaQueue.filter(qmeal => qmeal.uuid === meal.uuid);

            toRemove.forEach(mealToRemove => {
                deltaQueue.splice(deltaQueue.indexOf(mealToRemove), 1);

            });
        });
        LocalStorage.set(DELTA_QUEUE_KEY, deltaQueue);
    },

    checkForGroceryUpdateError: function(url, request, json) {
        // On meals update error evict from Mealstore delta queue
        if (url.endsWith("/groceries") && ["POST", "DELETE"].includes(request.method) && json.message && json.message.startsWith("Entity #")) {
            const mealIndex = json.message.match(/\d+/g)[0];
            const groceries = JSON.parse(request.body);

            GroceryStore.deleteGroceriesFromDeltaQueue([groceries[mealIndex]]);

            //eslint-disable-next-line no-undef
            Sentry.withScope((scope) => {
                const sentryError = new Error();
                sentryError.name = 'Meal is evicted due to a validation error';
                sentryError.message = json.message;

                scope.setExtras(groceries[mealIndex]);
                //eslint-disable-next-line no-undef
                Sentry.captureException(sentryError)
            });
        }
    },

});

GroceryStore.defineMaxListener(500);

export default GroceryStore;

AppDispatcher.register((payload) => {
    let groceries = [];

    switch (payload.action.actionType) {
        case UserConstants.USER_COMPLETE_LOGIN:
            if (payload.action.groceries) {
                groceries = payload.action.groceries;

                _store.loaded = true;
                _store.loading = false;
                _store.groceries = groceries || [];

                GroceryStore.emitChange();
            }
            break;

        case UserConstants.USER_LOGOUT:
            _store.loaded = false;
            _store.loading = false;
            _store.groceries = [];
            LocalStorage.remove(DELTA_QUEUE_KEY);
            GroceryStore.emitChange();
            break;

        case GroceryConstants.GROCERIES_LOAD:
            if (!_store.loaded && !_store.loading) {
                loadGroceries().then(() => {
                    GroceryStore.emitChange();

                    setTimeout(() => {
                        MealActions.loadMealsByIds(getGroceriesMealUuids());
                    });
                });
            }
            break;

        case GroceryConstants.GROCERIES_UPSERT:
            groceries = payload.action.groceries;
            // And add them to the delta queue if they're not there already
            let deltaQueue = (LocalStorage.get(DELTA_QUEUE_KEY) || []);

            groceries.forEach(grocery => {
                // Find this grocery by UUID in the deltaQueue
                const qgrocery = deltaQueue.filter(qgrocery => qgrocery.uuid === grocery.uuid)[0];

                // Append to queue if: not already queued OR queued and already sent to server
                if (!qgrocery || (qgrocery && qgrocery.updating)) {
                    deltaQueue.push(grocery);
                } else {
                    // Otherwise just update the existing entry (to be extra sure its in there)
                    const i = deltaQueue.indexOf(qgrocery);
                    deltaQueue[i] = grocery;
                }
            });
            LocalStorage.set(DELTA_QUEUE_KEY, deltaQueue, new Date().getTime() + 1000 * 3600 * 24);

            // Update the index
            updateIndex();

            // Prepend any new items to the groceries store and update existing ones
            // (even if the object itself has changed)
            const groceriesToAdd = [];
            groceries.forEach(grocery => {
                if (typeof _store._indexByUuids[grocery.uuid] == 'number') {
                    _store.groceries[_store._indexByUuids[grocery.uuid]] = grocery;
                } else {
                    groceriesToAdd.push(grocery);
                }
            });
            _store.groceries = groceriesToAdd.concat(_store.groceries);
            // reset our refresh counter
            _refreshCount = 0;

            // Update the index again
            updateIndex();

            // Emit an event change
            GroceryStore.emitChange();

            // And trigger a sync to the server if one is not already in the queue
            synchronizeGroceries();
            break;

        case GroceryConstants.GROCERIES_DELETE:
            groceries = payload.action.groceries;

            const uuids = groceries.map(g => g.uuid);

            // reset our refresh counter
            _refreshCount = 0;

            // Remove these from our list
            groceries.forEach(grocery => grocery.deleted = true);

            // Remove the deleted groceries immediately
            clearDeletedGroceries();
            updateIndex();

            // Emit an event change
            GroceryStore.emitChange();

            // Tell the server they're gone
            deleteGroceriesByUuid(uuids);
            break;

        case GroceryConstants.GROCERIES_FEED_REGEN:
            // The meal feed was refreshed. This deletes all future meals and clears the grocery list.
            _store.groceries = [];
            _store._indexByUuids = {};

            GroceryStore.emitChange();
            break;

        case GroceryConstants.GROCERIES_REFRESH_START:
            // If we're already enabled, do not re-enable
            _refreshEnabled = true;

            if (!_refreshInt) {
               _refreshCount = 0;
                _refreshInt = setTimeout(refreshGroceries, 3000);
            }
            break;

        case GroceryConstants.GROCERIES_REFRESH_STOP:
            _refreshEnabled = false;

            if (_refreshInt) {
                clearTimeout(_refreshInt);
                _refreshCount = 0;
                _refreshInt = false;
            }
            break;

        case GroceryConstants.GROCERIES_HYDRATE:

            // Merge groceries, overwrite old ones with new ones
            _store.groceries = Object.values(Object.assign(
                indexBy(_store.groceries, 'uuid'),
                indexBy(payload.action.groceries || [], 'uuid')
            ));

            GroceryStore.emitChange();
            break;
    }
});
