'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import BoardPickerModal from './Boards/BoardPickerModal.react';

import BoardStore from '../../stores/BoardStore';
import BoardActions from '../../actions/BoardActions';

import Analytics from '../../utils/Analytics';

import styles from './FavoriteButton.scss';

export default class FavoriteButton extends Component {
    static propTypes = {
        recipe: PropTypes.object,
        plan: PropTypes.object,
        combo: PropTypes.object,
        food: PropTypes.object,
        collection: PropTypes.object,
        onFavorite: PropTypes.func,
        onBeforeUnfavorite: PropTypes.func,

        icon: PropTypes.string,
        className: PropTypes.string,
    };

    static defaultProps = {
        icon: 'icon-heart',
        iconBoarded: 'icon-heart',
        className: 'favorite-btn',
        showIcon: true,
        boardedText: "",
        notBoardedText: "",
    };

    static contextTypes = {
        breakpoint47em: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        const { recipe, plan, combo, product, food, collection } = props;
        const { uuid, type } = recipe || plan || combo || food || collection || {};

        this.state = {
            isPopoverOpen: false,
            firstBoard: null,
            autoPopulateInMealFeed: false,
            saving: false,
            boarded: BoardStore.getBoardsByResourceId(uuid).length > 0,
        };
    }

    componentDidMount = () => {
        const { plan } = this.props;

        if (plan) {
            const resources = BoardStore.getBoardsByResourceId(plan.uuid);

            const resource = resources[0]?.contents?.find(resource => resource.resource_id == plan.uuid);

            if (resource) {
                this.setState({autoPopulateInMealFeed: !resource.exclude_from_lena});
            }
        }
        BoardStore.addChangeListener(this.onBoardStoreChange);
    }


    componentWillUnmount = () => {
        BoardStore.removeChangeListener(this.onBoardStoreChange);
    }

    onBoardStoreChange = () => {
        const { recipe, plan, combo, product, food, collection } = this.props;
        const { uuid, type } = recipe || plan || combo || food || collection || {};
        let { saving } = this.state;

        const boarded = BoardStore.getBoardsByResourceId(uuid).length > 0;
        saving = plan && boarded && saving ? false : saving;

        this.setState({boarded, saving});
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        const { recipe, plan, combo, food, collection } = this.props;

        const curDoc = recipe || plan || combo || food || collection;
        const newDoc = nextProps.recipe || nextProps.plan || nextProps.combo || nextProps.food || nextProps.collection;

        if (newDoc != curDoc) {
            this.setState({firstBoard: null, isPopoverOpen: false});
        }
    }

    onClick = () => {
        const { plan, onFavorite } = this.props;

        if (plan && !plan.links && onFavorite) {
            this.setState({saving: true}, onFavorite);
            return;
        }

        this.applyFavorite();
    }

    applyFavorite = () => {
        let { isPopoverOpen } = this.state;
        const { recipe, plan, combo, product, food, collection, onFavorite, onBeforeUnfavorite } = this.props;
        const { uuid, type } = recipe || plan || combo || food || collection || {};

        const isBoarded = BoardStore.getBoardsByResourceId(uuid).length > 0;

        if (!isPopoverOpen && isBoarded) {

            if (onBeforeUnfavorite && onBeforeUnfavorite()) {
                return;
            }

            this.setState({isPopoverOpen: true});

            return;
        } else if (!isPopoverOpen && !isBoarded) {
            // We're going to add this item to a default board
            const lastBoard = BoardStore.getDefaultBoard();

            const newItem = {
                resource_id: uuid,
                resource_type: type,
            };

            if (lastBoard.links) {
                // Board already exists, just add to it.
                BoardActions.addToBoard(lastBoard, [newItem]);
            } else {
                lastBoard.contents = lastBoard.contents || [];
                lastBoard.contents.push(newItem);


                // Board does not exist, create a whole new one
                BoardActions.upsertBoards([lastBoard]);
            }

            // That's not confusing. It's the Last Board that was used,
            // but we want that one to be the First Board in the list...
            this.setState({ firstBoard: lastBoard });

            Analytics.saveFavorite(lastBoard, recipe || plan || combo || food || collection);
        } else {
            this.setState({isPopoverOpen: false});
        }
    }

    closePopover = () => {
        this.setState({isPopoverOpen: false});
    }

    isPlanInBoard = (board) => {
        const { plan } = this.props

        return (board.contents || []).filter(content => content.resource_id === plan.uuid).length > 0;
    }

    renderBoardPickerModal = () => {
        const { isPopoverOpen, firstBoard } = this.state;
        const { recipe, plan, combo, food, collection, className } = this.props;

        if (!isPopoverOpen) {
            return null;
        }

        return <BoardPickerModal closeModal={this.closePopover}
                    recipe={recipe} plan={plan} combo={combo} food={food} collection={collection}
                    firstBoard={firstBoard} />
    }

    render() {
        const { recipe, plan, combo, collection, food, icon, iconBoarded, className, isMealCard, showIcon, boardedText, notBoardedText } = this.props;
        const { firstBoard, autoPopulateInMealFeed, saving } = this.state;
        let { boarded } = this.state;
        const uuid = (recipe && recipe.uuid) ||
                     (plan && plan.uuid) ||
                     (combo && combo.uuid) ||
                     (collection && collection.uuid) ||
                     (food && food.uuid);

        if (!uuid || !BoardStore.isLoaded()) {
            return <span />
        }

        boarded = boarded || saving;

        const button = (
            <button onClick={this.onClick} className={className} data-boarded={boarded}>
                {isMealCard && showIcon ?
                    (
                    <i className={"icon-heart1"} >
                        <i className={boarded ? iconBoarded : icon} />
                    </i>
                    )
                : null}
                {!isMealCard && showIcon ? <i className={boarded ? iconBoarded : icon} /> : null}
                {!showIcon && boarded ? boardedText : null}
                {!showIcon && !boarded ? notBoardedText : null}
                <span className="assistive-text">Favorite</span>
                {this.props.children}
            </button>
        );

        return (
            <span>
                {this.renderBoardPickerModal()}
                {button}
            </span>
        );
    }
}
