'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import EmptyEnvelopeEditor from './EmptyEnvelopeEditor.react';
import NutrientEditor from './NutrientEditor.react';
import MacroTotals from './MacroTotals.react';

import allNutrients from '../../../../../tables/nutrients';
import nutrKeys from '../../../../../tables/nutrient-order';

import './EnvelopeEditor.scss';

export default class EnvelopeEditor extends Component {
    static propTypes = {
        envelope: PropTypes.object.isRequired,
        conflicts: PropTypes.object,
        title: PropTypes.node,
        startTabIndex: PropTypes.number,
        showPercents: PropTypes.bool,
        nutrientErrors: PropTypes.array,
        mealType: PropTypes.string,
    };

    static defaultProps = {
        title: 'Total Daily Nutrition',
        startTabIndex: 1,
        conflicts: {},
        showPercents: false,
        nutrientErrors: [],
    };

    getMinMaxKcal = () => {
        const { envelope, patient } = this.props;

        const kcal_min = (envelope[208] && envelope[208].min) || patient.target_energy_kcal,
              kcal_max = (envelope[208] && envelope[208].max) || patient.target_energy_kcal;

        return { kcal_min, kcal_max };
    }

    setNutrMin = (nutrNo, value) => {
        const { envelope, onChangeEnvelope } = this.props;

        envelope[nutrNo] = envelope[nutrNo] || {};
        envelope[nutrNo].min = value;

        onChangeEnvelope(envelope, nutrNo);
    }

    setNutrMax = (nutrNo, value) => {
        const { envelope, onChangeEnvelope } = this.props;

        envelope[nutrNo] = envelope[nutrNo] || {};
        envelope[nutrNo].max = value;

        onChangeEnvelope(envelope, nutrNo);
    }

    onChangeNutrient = (nutrNo, range) => {
        const { envelope, onChangeEnvelope } = this.props;

        envelope[nutrNo] = range;

        onChangeEnvelope(envelope, nutrNo);
    }

    renderNutrientEditor = (nutrNo) => {
        const { conflicts, patient, envelope, showPercents, nutrientErrors, mealType } = this.props;
        const { kcal_min, kcal_max } = this.getMinMaxKcal();

        return (
            <NutrientEditor key={nutrNo}
                nutrNo={nutrNo}
                mealType={mealType}
                isError={nutrientErrors.includes(nutrNo)}
                conflict={conflicts[nutrNo]}
                showPercents={showPercents}
                patient={patient}
                weight_kg={patient.weight_kg}
                kcal_min={kcal_min}
                kcal_max={kcal_max}
                range={envelope[nutrNo]}
                onChangeNutrient={this.onChangeNutrient}
                onSetMax={this.setNutrMax}
                onSetMin={this.setNutrMin}
                />
        );
    }

    render() {
        const { envelope, title, onRemoveRx, showPercents } = this.props;
        const { kcal_min, kcal_max } = this.getMinMaxKcal();

        // Sort function for sorting by the order in the definition table
        const defSortCmp = (a, b) => {
            const aI = nutrKeys.indexOf(a),
                  bI = nutrKeys.indexOf(b);

            if (aI > bI) return 1;
            if (aI < bI) return -1;
            return 0;
        };

        // Sorting function for sorting by nutrient name alphabeticaly
        const alphaSortCmp = (a, b) => allNutrients[a].NutrDesc.localeCompare(allNutrients[b].NutrDesc);

        const nutrients = Object.keys(envelope).sort(defSortCmp);
        const macros = nutrients.filter(nutrNo => ['208', '203', '204', '205'].includes(nutrNo));
        const micros = nutrients.filter(nutrNo => !['208', '203', '204', '205'].includes(nutrNo));

        let showMacroTotal = showPercents && macros.includes('203') && macros.includes('204') && macros.includes('205');

        return (
            <div className="envelope-editor" data-edit-mode={this.props["data-edit-mode"]}>
                {macros.map((macro) => this.renderNutrientEditor(macro))}
                {showMacroTotal ?
                    <MacroTotals envelope={envelope}
                        kcal_min={kcal_min}
                        kcal_max={kcal_max} />
                : null}
                {micros.map((micro) => this.renderNutrientEditor(micro))}
                {nutrients.length === 0 ?
                    <EmptyEnvelopeEditor />
                : null}

                {onRemoveRx ?
                    <footer>
                        <button className="remove-rx-btn" onClick={onRemoveRx}>Remove {title}</button>
                    </footer>
                : null}
            </div>
        );
    }
}
