'use strict';

import { Component } from 'react';

export default class PrepTimeParams extends Component {
    static propTypes = {};

    removePrepTime = () => {
        const { params, onChangeParams } = this.props;

        delete params.filters.hands_on_time;

        onChangeParams(params);
    }

    render() {
        const { params } = this.props;
        if (!(params.filters && params.filters.hands_on_time)) {
            return <span />
        }

        const { gte, lte } = params.filters.hands_on_time;
        let label = 'Under 15 minutes';
        if (gte == 901 && lte == 1800) {
            label = 'From 15 to 30 minutes';
        } else if (gte == 1801 && lte == 2700) {
            label = 'From 30 to 45 minutes';
        } else if(gte == 2701 && !lte) {
            label = 'More than 45 minutes';
        }

        return (
            <div className="param-item" onClick={this.removePrepTime}>
                Prep Time: {label} <i className="icon-close-x" />
            </div>
        );
    }
}
