'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DragSource, DropTarget } from 'react-dnd';

import Dropdown from '../../Widgets/Dropdown.react';
import PrescriptionMismatch from './PrescriptionMismatch.react';
import MealDraggable from './MealDraggable.react';

import MealTypes from '../../../../constants/MealTypes';
import UserStore from '../../../../stores/UserStore';

import './MealCell.scss';


@DropTarget(
    [MealTypes.MEAL],
    {
        drop(props, monitor, component) {
            var item = monitor.getItem();

            if (item.meals) {
                component.confirmDrop(item.meals);
            }
        },
    },
    (connect, monitor) => {
        return {
            isOver: monitor.isOver(),
            isOverCurrent: monitor.isOver({ shallow: true }),
            connectDropTarget: connect.dropTarget(),
        };
    }
)
export default class MealCell extends Component {
    static propTypes = {
        profile: PropTypes.object.isRequired,
    };

    static defaultProps = {
    };

    static contextTypes = {
        isMobile: PropTypes.bool,

        mismatches: PropTypes.object,

        startReplaceMeal: PropTypes.func,
        startAddMeal: PropTypes.func,
        rescheduleMeals: PropTypes.func,
        hideWarnings: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            deviations: [],
            allDayMismatch: [],
            isMismatched: false,
            isOverLimits: false,
            isDailyMismatched: false,
            isDailyOverLimit: false,
        };
    }

    componentDidMount = () => {
        this.syncState(this.props, this.context);
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        this.syncState(nextProps, nextContext);
    }

    syncState = (props, context) => {
        const { meals, mealType, offset } = props || this.props;
        const { mismatches } = context || this.context;
        const deviations = (mismatches && mismatches[offset] && mismatches[offset][mealType]) || [];
        const allDayMismatch = (mismatches && mismatches[offset] && mismatches[offset]['all-day']) || [];
        let isMismatched = meals.length > 0 && deviations.length > 0;
        let isOverLimits = deviations.filter(miss => miss.value > miss.max).length > 0;
        let isDailyMismatched = allDayMismatch.length > 0;
        let isDailyOverLimit = allDayMismatch.filter(miss => miss.value > miss.max).length > 0;

        this.setState({
            deviations, allDayMismatch, isMismatched, isOverLimits, isDailyMismatched, isDailyOverLimit
        });
    }

    confirmDrop = (confirmMealsDrop) => {
        const { meals, mealType, offset } = this.props;
        const { rescheduleMeals } = this.context;

        rescheduleMeals(confirmMealsDrop, offset, mealType);
    }

    renderAddButton = (meals) => {
        const { inhibit_swap = false } = UserStore.getPreferences();
        const { offset, mealType } = this.props;
        const { startAddMeal } = this.context;
        const { deviations, allDayMismatch, isMismatched, isOverLimits, isDailyMismatched, isDailyOverLimit } = this.state;

        if (((meals && meals.length)) || inhibit_swap) {
            return;
        }

        return (
            <button className="add-recipe-btn" onClick={() => startAddMeal(offset, mealType)} key="add" title="Add a recipe here">
                <i className="icon-plus-thin" />
            </button>
        );
    }

    renderActionButton = () => {
        const { deviations, isOverLimits } = this.state;
        const { meals, mealType, offset } = this.props;
        const { startAddMeal, startReplaceMeal } = this.context;

        if (!deviations.length) {
            return;
        }

        let button = <button className="sub-action-btn" onClick={() => startReplaceMeal(meals[0])}>swap {mealType}</button>

        if (!isOverLimits) {
            button = <button className="sub-action-btn" onClick={() => startAddMeal(offset, mealType, {defaultMode: 'browser'})}>add dish</button>
        }

        return (
            <footer className="mismatch-actions">{button}</footer>
        );
    }

    renderMismatches = () => {
        const { hide_nutrition = false, inhibit_swap = false } = UserStore.getPreferences();
        const { mealType, offset, profile } = this.props;
        const { isMismatched, deviations } = this.state;
        const { isMobile, hideWarnings } = this.context;

        if (hide_nutrition || !isMismatched || profile?.type === "alternate-patient" || hideWarnings) {
            return;
        }

        return (
            <Dropdown button={<i className="icon-warning1" title="Click to see details" />} key="desktop-mismatch">
                <div>
                    <PrescriptionMismatch mealType={mealType} mismatches={deviations} />
                    {inhibit_swap ? null : this.renderActionButton()}
                </div>
            </Dropdown>
        );
    }

    render() {
        const { isOver, meals, offset, mealType, isOverCurrent, connectDropTarget, profile } = this.props;

        const { inhibit_swap = false } = UserStore.getPreferences();

        return connectDropTarget(
            <div className="day-column">
                <div className="customizer-meal-cell" data-offset={offset} data-meal-type={mealType} data-hover={isOver}>
                    {meals && meals.length ?
                        <MealDraggable profile={profile} offset={offset} mealType={mealType} meals={meals} hideIcons={inhibit_swap} disableDnD={inhibit_swap} />
                    : null}
                    {this.renderAddButton(meals)}
                    {meals && meals.length > 0 ? this.renderMismatches(meals) : null}
                </div>
            </div>
        );
    }
}
