'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { Plugins } from '@capacitor/core';
const { AndroidPermissions, IOSPermissions } = Plugins;


import AuthStore from '../../../../stores/AuthStore';
import Alert from '../../../Widgets/Alert/Alert.react';

import { getConfig } from '../../../../utils/Env';
import Analytics from '../../../../utils/Analytics';

import Quagga from "@ericblade/quagga2";

const RAGE_SCANNING_THRESHOLD = 2;

import './BarcodeScanner.scss';
import { isNumericBarcode, validateBarcodeLength } from '../../../../utils/Barcode';

export default class BarcodeScanner extends Component {

    static propTypes = {
        onBarcodeFound: PropTypes.func,
        onBarcodeNotFound: PropTypes.func,
        barcodeContext: PropTypes.string,
    };

    static defaultProps = {
        barcodeContext: 'Foods Db Admin',
    };

    static contextTypes = {
        confirm: PropTypes.func,
        router: PropTypes.object,
        location: PropTypes.object,
        isAndroid: PropTypes.bool,
        iPhone: PropTypes.bool,
        isMobile: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.rageScanningThreshold = RAGE_SCANNING_THRESHOLD;

        this.state = {
            scanningMobileWeb: false
        };
    }


    closeCamera = () => {
        this.setState({scanningMobileWeb: false});
        Quagga.stop();
        Quagga.offDetected(this.onQuaggaDetected);
    }

    requestCameraPermission = async () => {
        const { isAndroid, iPhone } = this.context;

        try {
            let result = null;

            if (isAndroid) {
                result = await AndroidPermissions.requestPermission({
                    permission: 'CAMERA',
                });
            }

            if (iPhone) {
                result = await IOSPermissions.requestPermission({
                    permission: 'CAMERA',
                });
            }

            return result;

        } catch (error) {
            throw error;
        }
    };

    startCaptureBarcode = async () => {
        const { barcodeContext } = this.props;
        const { scanningMobileWeb } = this.state;


        this.setState({
            isModalOpen: true,
            barcode: '',
            error: null,
            scanningMobileWeb: false,
            isErrorModalOpen: false
        });

        let cameraPermissionResult;

        if (scanningMobileWeb) {
            Quagga.stop();
            Quagga.offDetected(this.onQuaggaDetected);
        }

        if(this.canBarcodeScan()) {
            try {
                cameraPermissionResult = await this.requestCameraPermission();
            } catch (error) {
                cameraPermissionResult = (error?.message || error);
            }
        }

        Analytics.startBarcodeCapture({
            "Context": barcodeContext,
            "Camera Permissions": cameraPermissionResult,
        });
    };

    closeModal = () => {
        this.setState({isModalOpen: false, error: null});
    }

    focus = () => {
        if (this.refs?.input?.focus) {
            this.refs.input.focus();
        }
    }

    findScannedBarcode = async (barcode, isManualEntry = false) => {
        const { onBarcodeFound, onBarcodeNotFound, barcodeContext } = this.props;

        const setErrorState = (message, type) => {
            this.setState({ isErrorModalOpen: true, errorMessage: message, errorType: type });
        };

        const logAnalytics = (type, value) => {
            if (type === 'nonNumeric') {
                if (isManualEntry) {
                    Analytics.enteredNonNumericBarcode({ 'Entered Value': value });
                } else {
                    Analytics.scannedNonNumericBarcode({ 'Scanned Value': value });
                }
            } else if (type === 'invalidLength') {
                if (isManualEntry) {
                    Analytics.enteredInvalidLengthBarcode({ 'Entered Value': value });
                } else {
                    Analytics.scannedInvalidLengthBarcode({ 'Scanned Value': value });
                }
            }
        };

        if (!isNumericBarcode(barcode)) {
            const errorMessage = isManualEntry
                ? "Invalid code entered. Please find a numerical barcode similar to the example shown below."
                : "Invalid code scanned. Please find and scan a numerical barcode similar to the example shown below.";
            logAnalytics('nonNumeric', barcode);
            setErrorState(errorMessage, isManualEntry ? 'entry-error' : 'scan-error');
            return;
        }

        if (!validateBarcodeLength(barcode)) {
            const errorMessage = isManualEntry
                ? "Invalid barcode length entered. Please ensure the barcode has 14 or fewer digits."
                : "Invalid barcode length scanned. Please ensure the barcode has 14 or fewer digits.";
            logAnalytics('invalidLength', barcode);
            setErrorState(errorMessage, isManualEntry ? 'entry-error' : 'scan-error');
            return;
        }

        this.setState({working: true});

        let results;

        try {
            // First search for the exact match barcode.
            results = await AuthStore.fetch(getConfig('recipe_api') + '/search', {
                method: 'POST',
                headers: {'Content-Type': 'application/json; schema=search/advanced/1'},
                body: JSON.stringify({types: ['food'], filters: {gtin_upc: barcode}, size: 1, include_library: true}),
            });

            this.setState({working: false});
        } catch (error) {
            // catch left intentionally blank, if anything at all goes wrong trying to find the barcode, fallback
            // to allowing the user to enter it manually.
            Analytics.barcodeScanError({
                "Error": error?.message || error,
                "Context": barcodeContext,
                "Barcode": barcode,
                "Manual Entry": isManualEntry
            });
            this.setState({working: false, error: (error?.message || error)});
        }

        if (results?.elements?.length) {
            onBarcodeFound && onBarcodeFound(results.elements[0], barcode);
            this.setState({isModalOpen: false});

            Analytics.scannedBarcodeFound({
                "Food UUID": results.elements[0].uuid,
                "Context": barcodeContext,
                "Barcode": barcode,
                "Manual Entry": isManualEntry
            });
        } else {
            onBarcodeNotFound && onBarcodeNotFound(barcode, isManualEntry);

            Analytics.scannedBarcodeNotFound({
                "Context": barcodeContext,
                "Barcode": barcode,
                "Manual Entry": isManualEntry
            });
        }
    }

    scanBarcode = async () => {
        const { user, confirm } = this.context;
        const { barcodeContext } = this.props;
        const { date, isScanning } = this.state;

        if (isScanning) {
            return;
        }

        this.setState({isScanning: true});

        let cameraPermissionResult;

        try {
            cameraPermissionResult = await this.requestCameraPermission();
        } catch (error) {
            cameraPermissionResult = (error?.message || error);
        }

        Analytics.startBarcodeScan({
            "Context": barcodeContext,
            "Camera Permissions": cameraPermissionResult
        });

        window.cordova.plugins.barcodeScanner.scan(
            result => {
                this.setState({isScanning: false});

                if (result?.text) {
                    this.findScannedBarcode(result.text);
                }
            },
            error => {
                this.setState({isScanning: false});

                this.startCaptureBarcode();

                Analytics.barcodeScanError({
                    "Context": barcodeContext,
                    "Error": error,
                    "Camera Permissions": cameraPermissionResult
                });
            },
            {
                preferFrontCamera : false, // iOS and Android
                showFlipCameraButton : false, // iOS and Android
                showTorchButton : true, // iOS and Android
                torchOn: false, // Android, launch with the torch switched on (if available)
                saveHistory: false, // Android, save scan history (default false)
                prompt : "Place a barcode inside the scan area", // Android
                resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
                // formats : "QR_CODE,PDF_417", // default: all but PDF_417 and RSS_EXPANDED
                // orientation : "landscape", // Android only (portrait|landscape), default unset so it rotates with the device
                disableAnimations : false, // iOS
                disableSuccessBeep: false // iOS and Android
            }
        );
    }

    onQuaggaDetected = async (upc) => {
        const error = upc.codeResult.decodedCodes.some(code => code.error > (1/6));

        if (error) {
            return;
        }

        Quagga.stop();
        Quagga.offDetected(this.onQuaggaDetected);

        await this.findScannedBarcode(upc.codeResult.code);
        this.setState({scanningMobileWeb: false});
    }

    onMobileWebBarcodeScan = async () => {
        await Quagga.init({
            numOfWorkers: navigator.hardwareConcurrency || 4,
            locate: true,
            inputStream : {
                name : "Live",
                type : "LiveStream",
                target: document.querySelector('#quaggaTarget'),
                constraints: {
                    width: {min: 640},
                    height: {min: 480},
                },
            },
            decoder : {
                readers : [
                    {
                        format: "code_128_reader",
                        config: {}
                    },
                    {
                        format: 'upc_reader',
                        config: {}
                    },
                    {
                        format: "ean_reader",
                        config: {
                            supplements: [
                                'ean_5_reader', 'ean_2_reader'
                            ]
                        }
                    }
                ],
            },
            locator: {
                halfSample: true,
                patchSize: "medium",
            }
        },
        (err) => {
            if (err) {
              console.log({f: 'Quagga error', err});
              return;
            }

            Quagga.start();
        });

        Quagga.onDetected(this.onQuaggaDetected);
    }

    retryBarcodeScan = (canBarcodeScan = true) => {
        const { isMobile } = this.context;

        this.setState({isErrorModalOpen: false});

        if (this.canBarcodeScan()) {
            this.scanBarcode();
        } else if (isMobile) {
            this.setState({scanningMobileWeb: true});
        } else {
            this.startCaptureBarcode();
        }
    }

    captureBarcode = async () => {
        let { barcode = '' } = this.state;
        barcode = barcode.trim();

        if (!barcode) {
            this.setState({error: 'Please enter a barcode number'});
            return;
        }

        await this.findScannedBarcode(barcode, true);
        this.setState({isModalOpen: false});
    }

    onChangeBarcode = (ev) => {
        this.setState({barcode: ev.target.value});
    }

    renderCaptureBarcodeModal = () => {
        const { isModalOpen, barcode, error, working } = this.state;

        if (!isModalOpen) {
            return null;
        }

        return (
            <Modal isOpen={true}
                onRequestClose={this.closeModal}
                contentLabel="Confirmation"
                className="el-modal el-modal3 el-modal3-centered"
                overlayClassName="el-modal-overlay"
                closeTimeoutMS={250}>

                <div className="el-modal-container el-modal3-container">
                    <header>
                        <h2>ENTER BARCODE NUMBER</h2>
                        <button className="el-modal-close-x" onClick={this.closeModal}>
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>
                    </header>
                    <div className="el-modal-body-container el-modal3-body-container el-form el-fonts">
                        <section className="barcode-capture-form">
                            <div>
                                <i className="icon-barcode2"/>
                                <input ref="input" type="text" pattern="\d*" autoFocus value={barcode} onChange={this.onChangeBarcode} placeholder="e.g. 012345678902"/>
                            </div>
                            <p className="barcode-instuctions">Enter all numbers shown on the product barcode. Include leading and trailing zeros and numbers shown to the left and right of the barcode.</p>

                            {error ? <Alert type="error">{error}</Alert> : null}
                        </section>
                    </div>
                    <footer>
                        <button className="el-modal-cancel-btn" onClick={this.closeModal}>Cancel</button>
                        <button disabled={working} className="el-modal-ok-btn" onClick={this.captureBarcode}>{working ? <span>Searching...</span> : 'Submit'}</button>
                    </footer>
                </div>
            </Modal>
        );
    }

    renderMobileWebScannerModal = () => {
        const { scanningMobileWeb, working } = this.state;

        if (!scanningMobileWeb) {
            return;
        }

        return (
            <Modal isOpen={true}
                onAfterOpen={this.onMobileWebBarcodeScan}
                onAfterClose={this.closeCamera}
                contentLabel="Confirmation"
                className="el-modal el-modal3 el-modal3-photo-lightbox"
                overlayClassName="el-modal-overlay"
                closeTimeoutMS={250}>
                <div className="el-modal-container el-modal3-container el-modal3-photo-lightbox-body-container mobile-web-barcode-modal">
                    <header>
                        <div className="close-x-circle">
                            <button className="el-modal-close-x" onClick={this.closeCamera}>
                                <i className="icon-x" />
                            </button>
                        </div>
                    </header>
                    <div id="quaggaTarget">
                        <button className="el-medium-btn el-medium-wide-btn el-gray-btn" onClick={this.startCaptureBarcode}>
                            Enter Barcode Manually
                        </button>

                        {working ? <i className="icon-spinner2" /> : null}
                    </div>
                </div>
            </Modal>
        );
    }

    closeErrorModal = () => {
        const { scanningMobileWeb } = this.state;
        this.setState({isErrorModalOpen: false});

        if (scanningMobileWeb) {
            Quagga.stop();
            Quagga.offDetected(this.onQuaggaDetected);
        }
    }

    renderErrorModal = () => {
        const { isErrorModalOpen, errorMessage } = this.state;

        if (!isErrorModalOpen) {
            return;
        }

        return (
            <Modal isOpen={true}
                contentLabel="Confirmation"
                className="el-modal el-modal3 el-modal3-centered"
                overlayClassName="el-modal-overlay"
                closeTimeoutMS={250}>
                <div className="el-modal-container el-modal3-container">
                    <header>
                        <div className="close-x-circle">
                            <button className="el-modal-close-x" onClick={this.closeErrorModal}>
                                <i className="icon-x" />
                            </button>
                        </div>
                    </header>
                    <div className="el-modal-body-container el-modal3-body-container el-fonts barcode-scanner-error-modal">
                        <p className="t1">
                            {errorMessage ? errorMessage : null}
                        </p>

                        <div className="example-barcode">
                            <svg enableBackground="new 0 0 231.488 163.551" version="1.1" viewBox="0 0 231.488 163.551" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
                                <path d="M21,144v-144m4,144v-144m6,133.2v-133.2" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="1.7"/>
                                <line fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="3.7" x1="38" x2="38" y1="133.2" y2="0"/>
                                <line fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="7.7" x1="46" x2="46" y1="133.2" y2="0"/>
                                <line fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="1.7" x1="53" x2="53" y1="133.2" y2="0"/>
                                <line fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5.7" x1="61" x2="61" y1="133.2" y2="0"/>
                                <line fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="1.7" x1="67" x2="67" y1="133.2" y2="0"/>
                                <line fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="3.7" x1="72" x2="72" y1="133.2" y2="0"/>
                                <path d="M81,133.2v-133.2m10,133.2v-133.2m4,133.2v-133.2m6,133.2v-133.2m8,133.2v-133.2m4,144v-144m4,144v-144m4,133.2v-133.2m6,133.2v-133.2" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="1.7"/>
                                <line fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5.7" x1="137" x2="137" y1="133.2" y2="0"/>
                                <line fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="1.7" x1="143" x2="143" y1="133.2" y2="0"/>
                                <line fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="5.7" x1="151" x2="151" y1="133.2" y2="0"/>
                                <line fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="1.7" x1="159" x2="159" y1="133.2" y2="0"/>
                                <path d="M164,133.2v-133.2m8,133.2v-133.2m6,133.2v-133.2m6,133.2v-133.2" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="3.7"/>
                                <path d="M191,133.2v-133.2m6,133.2v-133.2m8,144v-144m4,144v-144" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="1.7"/>
                                <path d="M2.297,140.12v-1.617c1.523-0.148,2.586-0.396,3.188-0.744s1.051-1.17,1.348-2.467h1.664v16.711h-2.25v-11.883h-3.950z" fill="#1F1E21"/>
                                <path d="M29.646,148.23c0.52-1.07,1.533-2.043,3.041-2.918l2.25-1.301c1.008-0.586,1.715-1.086,2.121-1.501,0.641-0.65,0.961-1.394,0.961-2.231,0-0.979-0.293-1.755-0.879-2.33s-1.367-0.863-2.344-0.863c-1.445,0-2.445,0.549-3,1.646-0.297,0.588-0.461,1.402-0.492,2.444h-2.144c0.023-1.461,0.293-2.652,0.809-3.574,0.914-1.625,2.527-2.438,4.84-2.438,1.922,0,3.326,0.52,4.213,1.559s1.33,2.195,1.33,3.469c0,1.344-0.473,2.492-1.418,3.445-0.547,0.555-1.527,1.227-2.941,2.016l-1.605,0.891c-0.766,0.422-1.367,0.824-1.805,1.207-0.781,0.68-1.273,1.434-1.477,2.262h9.164v1.992h-11.520c0.078-1.45,0.377-2.71,0.896-3.78z" fill="#1F1E21"/>
                                <path d="M43.912,150.83c-0.895-1.09-1.342-2.416-1.342-3.979h2.203c0.094,1.086,0.297,1.875,0.609,2.367,0.547,0.883,1.535,1.324,2.965,1.324,1.109,0,2-0.297,2.672-0.891s1.008-1.359,1.008-2.297c0-1.156-0.354-1.965-1.061-2.426s-1.689-0.691-2.947-0.691c-0.141,0-0.283,0.002-0.428,0.006s-0.291,0.01-0.439,0.018v-1.863c0.219,0.023,0.402,0.039,0.551,0.047s0.309,0.012,0.48,0.012c0.789,0,1.438-0.125,1.945-0.375,0.891-0.438,1.336-1.219,1.336-2.344,0-0.836-0.297-1.48-0.891-1.934s-1.285-0.68-2.074-0.68c-1.406,0-2.379,0.469-2.918,1.406-0.297,0.516-0.465,1.25-0.504,2.203h-2.086c0-1.25,0.25-2.312,0.75-3.188,0.859-1.562,2.371-2.344,4.535-2.344,1.711,0,3.035,0.381,3.973,1.143s1.406,1.865,1.406,3.311c0,1.031-0.277,1.867-0.832,2.508-0.344,0.398-0.789,0.711-1.336,0.938,0.883,0.242,1.572,0.709,2.068,1.4s0.744,1.537,0.744,2.537c0,1.602-0.527,2.906-1.582,3.914s-2.551,1.512-4.488,1.512c-1.983-0.01-3.423-0.55-4.317-1.64z" fill="#1F1E21"/>
                                <path d="M63.96,152.01v-4.102h-7.359v-2.062l7.688-10.664h1.781v10.887h2.473v1.84h-2.473v4.102h-2.110zm-0.035-5.94v-7.622l-5.379,7.622h5.379z" fill="#1F1E21"/>
                                <path d="M72.952,147.73c0.141,1.203,0.699,2.035,1.676,2.496,0.5,0.234,1.078,0.352,1.734,0.352,1.25,0,2.176-0.398,2.777-1.195s0.902-1.68,0.902-2.648c0-1.172-0.357-2.078-1.072-2.719s-1.572-0.961-2.572-0.961c-0.727,0-1.35,0.141-1.869,0.422s-0.963,0.672-1.33,1.172l-1.828-0.105,1.278-9.035h8.718v2.039h-7.137l-0.715,4.664c0.391-0.297,0.762-0.52,1.113-0.668,0.625-0.258,1.348-0.387,2.168-0.387,1.539,0,2.844,0.496,3.914,1.488s1.605,2.25,1.605,3.773c0,1.586-0.49,2.984-1.471,4.195s-2.545,1.816-4.693,1.816c-1.367,0-2.576-0.385-3.627-1.154s-1.639-1.951-1.764-3.545h2.193z" fill="#1F1E21"/>
                                <path d="M94.922,136.62c0.738,0.973,1.107,1.975,1.107,3.006h-2.086c-0.125-0.664-0.324-1.184-0.598-1.559-0.508-0.703-1.277-1.055-2.309-1.055-1.18,0-2.117,0.545-2.812,1.635s-1.082,2.65-1.16,4.682c0.484-0.711,1.094-1.242,1.828-1.594,0.672-0.312,1.422-0.469,2.25-0.469,1.406,0,2.633,0.449,3.68,1.348s1.57,2.238,1.57,4.02c0,1.523-0.496,2.873-1.488,4.049s-2.406,1.764-4.242,1.764c-1.57,0-2.926-0.596-4.066-1.787s-1.711-3.197-1.711-6.018c0-2.086,0.254-3.855,0.762-5.309,0.977-2.781,2.762-4.172,5.355-4.172,1.875-0.01,3.182,0.47,3.920,1.45zm-1.582,12.83c0.551-0.746,0.826-1.627,0.826-2.643,0-0.859-0.246-1.678-0.738-2.455s-1.387-1.166-2.684-1.166c-0.906,0-1.701,0.301-2.385,0.902s-1.025,1.508-1.025,2.719c0,1.062,0.311,1.955,0.932,2.678s1.482,1.084,2.584,1.084c1.109,0,1.939-0.38,2.490-1.12z" fill="#1F1E21"/>
                                <path d="M110.53,135.51v1.84c-0.539,0.523-1.256,1.434-2.15,2.73s-1.686,2.695-2.373,4.195c-0.68,1.461-1.195,2.793-1.547,3.996-0.227,0.773-0.52,2.02-0.879,3.738h-2.332c0.531-3.203,1.703-6.391,3.516-9.562,1.07-1.859,2.195-3.465,3.375-4.816h-9.281v-2.121h11.670z" fill="#1F1E21"/>
                                <path d="M122.28,142.19c-0.617-0.625-0.926-1.438-0.926-2.438,0-1.25,0.452-2.324,1.357-3.223s2.189-1.348,3.852-1.348c1.607,0,2.868,0.424,3.781,1.271,0.912,0.848,1.369,1.838,1.369,2.971,0,1.047-0.266,1.895-0.796,2.543-0.297,0.367-0.758,0.727-1.383,1.078,0.698,0.32,1.247,0.688,1.647,1.102,0.744,0.781,1.117,1.797,1.117,3.047,0,1.477-0.496,2.729-1.488,3.756s-2.395,1.541-4.207,1.541c-1.633,0-3.014-0.443-4.143-1.33s-1.693-2.174-1.693-3.861c0-0.992,0.242-1.85,0.727-2.572s1.203-1.275,2.156-1.658c-0.59-0.25-1.05-0.55-1.38-0.88zm6.81,7.59c0.645-0.527,0.967-1.314,0.967-2.361,0-1.086-0.332-1.91-0.996-2.473s-1.516-0.844-2.555-0.844c-1.008,0-1.83,0.287-2.467,0.861s-0.955,1.369-0.955,2.385c0,0.875,0.291,1.631,0.873,2.268s1.482,0.955,2.701,0.955c0.98-0.01,1.79-0.28,2.44-0.80zm-0.39-8.30c0.521-0.521,0.783-1.141,0.783-1.861,0-0.626-0.25-1.202-0.748-1.727-0.5-0.524-1.26-0.787-2.281-0.787-1.014,0-1.746,0.263-2.199,0.787-0.452,0.524-0.678,1.14-0.678,1.844,0,0.791,0.292,1.409,0.877,1.855,0.585,0.447,1.275,0.67,2.07,0.67,0.93,0.01,1.66-0.25,2.18-0.77z" fill="#1F1E21"/>
                                <path d="M137.18,147.94c0.062,1.164,0.512,1.969,1.348,2.414,0.43,0.234,0.914,0.352,1.453,0.352,1.008,0,1.867-0.42,2.578-1.26s1.215-2.545,1.512-5.115c-0.469,0.742-1.049,1.264-1.74,1.564s-1.436,0.451-2.232,0.451c-1.617,0-2.896-0.503-3.838-1.51s-1.412-2.303-1.412-3.887c0-1.521,0.465-2.86,1.395-4.016s2.301-1.732,4.113-1.732c2.445,0,4.133,1.101,5.062,3.303,0.516,1.21,0.773,2.725,0.773,4.544,0,2.053-0.309,3.872-0.926,5.457-1.023,2.639-2.758,3.958-5.203,3.958-1.641,0-2.887-0.43-3.738-1.289s-1.277-1.938-1.277-3.234h2.154zm5.49-4.27c0.691-0.55,1.037-1.511,1.037-2.884,0-1.232-0.311-2.15-0.932-2.756-0.621-0.604-1.412-0.906-2.373-0.906-1.031,0-1.85,0.345-2.455,1.035s-0.908,1.613-0.908,2.768c0,1.092,0.266,1.96,0.797,2.604s1.379,0.965,2.543,0.965c0.83,0,1.59-0.28,2.28-0.83z" fill="#1F1E21"/>
                                <path d="M159.19,137.91c0.75,1.383,1.125,3.277,1.125,5.684,0,2.281-0.34,4.168-1.02,5.66-0.984,2.141-2.594,3.211-4.828,3.211-2.016,0-3.516-0.875-4.5-2.625-0.82-1.461-1.23-3.422-1.23-5.883,0-1.906,0.246-3.543,0.738-4.91,0.922-2.547,2.59-3.82,5.004-3.82,2.18,0,3.75,0.89,4.71,2.68zm-2.12,11.18c0.649-0.969,0.975-2.773,0.975-5.414,0-1.906-0.235-3.475-0.704-4.705-0.47-1.23-1.382-1.846-2.735-1.846-1.244,0-2.154,0.584-2.729,1.752-0.576,1.168-0.863,2.889-0.863,5.162,0,1.711,0.184,3.086,0.552,4.125,0.563,1.586,1.526,2.379,2.888,2.379,1.10,0,1.97-0.48,2.62-1.45z" fill="#1F1E21"/>
                                <path d="M164.28,140.12v-1.617c1.523-0.148,2.586-0.396,3.188-0.744s1.051-1.17,1.348-2.467h1.664v16.711h-2.25v-11.883h-3.946z" fill="#1F1E21"/>
                                <path d="M177.63,148.23c0.52-1.07,1.533-2.043,3.041-2.918l2.25-1.301c1.008-0.586,1.715-1.086,2.121-1.501,0.641-0.65,0.961-1.394,0.961-2.231,0-0.979-0.293-1.755-0.879-2.33s-1.367-0.863-2.344-0.863c-1.445,0-2.445,0.549-3,1.646-0.297,0.588-0.461,1.402-0.492,2.444h-2.145c0.023-1.461,0.293-2.652,0.809-3.574,0.914-1.625,2.527-2.438,4.84-2.438,1.922,0,3.326,0.52,4.213,1.559s1.33,2.195,1.33,3.469c0,1.344-0.473,2.492-1.418,3.445-0.547,0.555-1.527,1.227-2.941,2.016l-1.605,0.891c-0.766,0.422-1.367,0.824-1.805,1.207-0.781,0.68-1.273,1.434-1.477,2.262h9.164v1.992h-11.52c0.09-1.45,0.39-2.71,0.91-3.78z" fill="#1F1E21"/>
                                <path d="M192.26,142.19c-0.617-0.625-0.926-1.438-0.926-2.438,0-1.25,0.452-2.324,1.357-3.223s2.189-1.348,3.852-1.348c1.607,0,2.868,0.424,3.781,1.271,0.912,0.848,1.369,1.838,1.369,2.971,0,1.047-0.266,1.895-0.796,2.543-0.297,0.367-0.758,0.727-1.383,1.078,0.698,0.32,1.247,0.688,1.647,1.102,0.744,0.781,1.117,1.797,1.117,3.047,0,1.477-0.496,2.729-1.488,3.756s-2.395,1.541-4.207,1.541c-1.633,0-3.014-0.443-4.143-1.33s-1.693-2.174-1.693-3.861c0-0.992,0.242-1.85,0.727-2.572s1.203-1.275,2.156-1.658c-0.59-0.25-1.05-0.55-1.38-0.88zm6.81,7.59c0.645-0.527,0.967-1.314,0.967-2.361,0-1.086-0.332-1.91-0.996-2.473s-1.516-0.844-2.555-0.844c-1.008,0-1.83,0.287-2.467,0.861s-0.955,1.369-0.955,2.385c0,0.875,0.291,1.631,0.873,2.268s1.482,0.955,2.701,0.955c0.98-0.01,1.79-0.28,2.44-0.80zm-0.39-8.30c0.521-0.521,0.783-1.141,0.783-1.861,0-0.626-0.25-1.202-0.748-1.727-0.5-0.524-1.26-0.787-2.281-0.787-1.014,0-1.746,0.263-2.199,0.787-0.452,0.524-0.678,1.14-0.678,1.844,0,0.791,0.292,1.409,0.877,1.855,0.585,0.447,1.275,0.67,2.07,0.67,0.93,0.01,1.65-0.25,2.18-0.77z" fill="#1F1E21"/>
                                <path d="M230,137.91c0.75,1.383,1.125,3.277,1.125,5.684,0,2.281-0.34,4.168-1.02,5.66-0.984,2.141-2.594,3.211-4.828,3.211-2.016,0-3.516-0.875-4.5-2.625-0.82-1.461-1.23-3.422-1.23-5.883,0-1.906,0.246-3.543,0.738-4.91,0.922-2.547,2.59-3.82,5.004-3.82,2.18,0,3.75,0.89,4.71,2.68zm-2.12,11.18c0.649-0.969,0.975-2.773,0.975-5.414,0-1.906-0.235-3.475-0.704-4.705-0.47-1.23-1.382-1.846-2.735-1.846-1.244,0-2.154,0.584-2.729,1.752-0.576,1.168-0.863,2.889-0.863,5.162,0,1.711,0.184,3.086,0.552,4.125,0.563,1.586,1.526,2.379,2.888,2.379,1.10,0,1.97-0.48,2.62-1.45z" fill="#1F1E21"></path>
                            </svg>
                        </div>
                    </div>

                    <footer>
                        <button className="el-modal-cancel-btn" onClick={this.closeErrorModal}>Cancel</button>
                        <button className="el-modal-ok-btn" onClick={this.retryBarcodeScan}>Retry</button>
                    </footer>
                </div>
            </Modal>
        );
    }

    onClickBarcodeIcon = () => {
        const { isMobile } = this.context;
        const { onClickBarcodeIcon } = this.props;

        onClickBarcodeIcon && onClickBarcodeIcon();

        if (this.canBarcodeScan()) {
            this.scanBarcode();
        } else if (isMobile) {
            this.setState({scanningMobileWeb: true});
        } else {
            this.startCaptureBarcode();
        }
    }

    canBarcodeScan = () => {
        return  window.cordova && window.cordova.plugins && window.cordova.plugins.barcodeScanner;
    }

    render = () => {
        const { isScanning } = this.state;
        const { className, children } = this.props;

        return (
            <>
                <button className={className} onClick={this.onClickBarcodeIcon}>
                    <i className={isScanning ? "icon-spinner2" : "icon-barcode2"} />
                </button>
                {this.renderMobileWebScannerModal()}
                {this.renderCaptureBarcodeModal()}
                {this.renderErrorModal()}
            </>
        );
    }
}
