'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import './Tabs.scss';

export default class Tabs extends Component {
    static propTypes = {
    };

    static defaultProps = {
        className: 'generic-tabs',
    };

    constructor(props) {
        super(props);

        this.state = {
            tab: props.defaultTab,
        };
    }

    setTab = (tab) => {
        this.setState({tab});
    }

    render() {
        const { tab } = this.state;
        const { children, className, tabsTitle } = this.props;

        return (
            <div className={className}>
                <header>
                    <section className="tabs" data-tab-state={tab} data-has-title={tabsTitle ? true : false}>
                        {tabsTitle ? <h2>{tabsTitle}</h2> : null}
                        {children.map((child, key) => {
                            if (!(child && child.props['data-title'])) {
                                return null;
                            }

                            const tabTitle = child.props['data-title'];

                            return (
                                <div key={key}>
                                    <button className={tabTitle === tab ? "tab-btn active-tab-btn" : "tab-btn"} data-tab={tabTitle}
                                        onClick={() => this.setState({tab: tabTitle})}>
                                        {tabTitle}
                                    </button>
                                </div>
                            );
                        })}
                    </section>
                </header>

                <section className="tab-contents" data-tab-state={tab}>
                    {children.map((child, key) => {
                        if (!(child && child.props['data-title'])) {
                            return null;
                        }

                        const tabTitle = child.props['data-title'];

                        return (
                            <div key={key} className={tabTitle === tab ? 'active' : 'inactive'}>
                                {tabTitle === tab || child.props['data-persistent'] ? child : null}
                            </div>
                        );
                    })}
                </section>
            </div>
        );
    }
}
