'use strict';

import allConditions from '../tables/conditions';

export function conditionSortCompare (a, b) {
    const aName = a.consumer_name || a.name;
    const bName = b.consumer_name || b.name;
    const aPos = a.position, bPos = b.position;

    if (typeof aPos === 'number' && typeof bPos !== 'number') return -1;
    if (typeof aPos !== 'number' && typeof bPos === 'number') return 1;

    if (aPos > bPos) return 1;
    if (aPos < bPos) return -1;

    return aName.localeCompare(bName);
}

export function conditionNamesToConsumerName(conditions) {
    return conditions.map(name => {
        const condition = allConditions.filter(c => c.name === name)[0];

        return condition ? (condition.consumer_name || condition.name) : name;
    });
}

export function conditionNamesToSpecialName(conditions) {
    return conditions.map(name => {
        const condition = allConditions.filter(c => c.name === name)[0];

        return condition ? (condition.special_name || condition.consumer_name || condition.name) : name;
    });
}
