'use strict';

export default {
    'barcode2': {},
    'barbell': {},
    'strawberry': {},
    'muscles': {},
    'messages': {},
    'messages2': {},
    'messages3': {},
    'bowl': {},
    'bowl1': {},
    'bowl2': {},
    'olive-branch': {},
    'personalize': {},
    'brain': {},
    'plate': {},
    'plate1': {},
    'plate2': {},
    'plate3': {},
    'leaf2-outline': {},
    'money-plant': {},
    'leaf2': {},
    'unlocked': {},
    'lock': {},
    'color': {},
    'undo': {},
    'redo': {},
    'list-unordered': {},
    'list-ordered': {},
    'strikethrough': {},
    'underline': {},
    'italic': {},
    'bold': {},
    'monospace': {},
    'outdent': {},
    'indent': {},
    'image': {},
    'font-size': {},
    'align-center': {},
    'align-justify': {},
    'align-left': {},
    'align-right': {},
    'hierarchy': {},
    'bed': {},
    'clock1': {},
    'clock2': {},
    'clock3': {},
    'clock4': {},
    'clock5': {},
    'exercise': {},
    'jumprope': {},
    'link': {},
    'exclude': {},
    'phone1': {},
    'phone2': {},
    'phone3': {},
    'barcode': {},
    'clipboard': {},
    'clipboard2': {},
    'blender': {},
    'delivery-truck': {},
    'smartphone': {},
    'target2': {},
    'target': {},
    'paperclip': {},

    'male2': {},
    'male3': {},
    'male4': {},
    'male-sign': {},
    'female': {},
    'female2': {},
    'female3': {},
    'female4': {},
    'female-sign': {},

    'give': {},
    'dashboard': {},
    'dashboard1': {},
    'toolkit': {},
    'toolkit1': {},
    'patients': {},
    'patients1': {},
    'library': {},
    'library2': {},
    'library3': {},
    'library4': {},
    'learn': {},
    'profiles': {},
    'spinner': {},
    'spinner2': {},
    'page-paperclip': {},
    'logo2': {paths: ['path1', 'path2']},
    'logo3': {},
    'curly-que': {},
    'calendar': {},
    'calendar5': {},
    'calendar6': {},
    'calendar7': {},
    'calendar71': {},
    'prescription-rx': {},

    'amazon': {},
    'apple-logo': {},
    'facebook': {},
    'facebook1': {},
    'facebook2': {},
    'google': {paths: ['path1', 'path2', 'path3', 'path4']},
    'google-plus': {},
    'instagram': {},
    'linkedin': {},
    'microsoft': {paths: ['path1', 'path2', 'path3', 'path4']},
    'pinterest': {},
    'pinterest2': {},
    'twitter': {},
    'twitter2': {},
    'yahoo': {paths: ['path1', 'path2']},
    'youtube': {},


    'apple': {},
    'apple1': {},
    'apple2': {},
    'expand1': {},
    'collapse': {},
    'clock': {},
    'stopwatch': {},
    'time': {},
    'time1': {},
    'timer': {},
    'schedule': {},
    'schedule2': {},
    'schedule3': {},
    'location-pin': {},
    'silverware': {},
    'silverware1': {},
    'silverware2': {},
    'silverware3': {},
    'silverware4': {},
    'silverware5': {},
    'heart': {},
    'heart-border': {},
    'heart1': {},
    'heart2': {},
    'heart3': {},
    'stacks': {},
    'view-plans': {},
    'paper': {},
    'pencil-notebook': {},
    'pencil': {},
    'pencil1': {},
    'pencil2': {},
    'pencil3': {},
    'email': {},
    'email1': {},
    'email2': {},
    'email3': {},
    'email4': {},
    'email5': {},
    'email-heart1': {},
    'email-heart2': {},
    'print': {},
    'print2': {},
    'print3': {},
    'trash': {},
    'trash-can': {},
    'trash-can2': {},
    'trash-can3': {},
    'trash-can4': {},
    'arrow-pointer': {},
    'back-arrow': {},
    'right-arrow': {},
    'up-arrow': {},
    'down-arrow': {},
    'up-caret': {},

    'arrow-up': {},
    'arrow-down': {},
    'arrow-left': {},
    'arrow-right': {},

    'arrow-up1': {},
    'arrow-down1': {},
    'arrow-left1': {},
    'arrow-right1': {},

    'chevron-left-double': {},
    'chevron-right-double': {},
    'chevron-right': {},
    'chevron-left': {},
    'chevron-up': {},
    'chevron-down': {},
    'chevron-right1': {},

    'share-arrow': {},
    'pulldown-arrows': {},

    'breakfast': {},
    'breakfast2': {},
    'breakfast-white': {},

    'lunch': {},
    'lunch2': {},
    'lunch3': {paths: ['path1', 'path2', 'path3', 'path4', 'path5']},
    'lunch-white': {paths: ['path1', 'path2', 'path3', 'path4', 'path5', 'path6', 'path7', 'path8']},

    'dinner': {},
    'dinner-white': {},

    'salad': {},
    'snacks': {},
    'snacks2': {},
    'snack3': {paths: ['path1', 'path2', 'path3', 'path4', 'path5', 'path6']},

    'snack-white': {paths: ['path1', 'path2', 'path3', 'path4', 'path5', 'path6', 'path7', 'path8', 'path9', 'path10']},
    'check-circle': {},
    'check-circle2': {},
    'minus-circle-outline': {},
    'minus-circle-solid': {},
    'add-circle-outline2': {},
    'recently-used': {},
    'restaurant-meals': {},
    'seach-by-ingredients': {},
    'search': {},
    'search1': {},
    'search2': {},
    'search3': {},
    'search4': {},
    'search5': {},
    'search-patient-lens': {},
    'search-patient': {},
    'lightbulb': {},
    'store-bought-meals': {},
    'camera': {},
    'added-to-list': {},
    'add-to-cart': {},
    'bolt': {},
    'ellipsis': {},
    'ellipsis1': {},
    'ellipsis2': {},
    'menu': {},
    'menu2': {},
    'menus': {},
    'servings': {},
    'skip': {},
    'swap': {},
    'pasta-rice-beans': {},
    'deli': {},
    'beverages2': {},
    'frozen-foods': {},
    'liquor': {},
    'liquor2': {},
    'liquor3': {},
    'produce': {},
    'produce2': {},
    'baking': {},
    'baking1': {},
    'baking2': {},
    'baking3': {},
    'bakery': {},
    'canned-goods': {},
    'cereals': {},
    'condiment2': {},
    'dairy': {},
    'dairy2': {},
    'ethnic': {},
    'pasta': {},
    'seafood': {},
    'warning': {},
    'warning1': {},
    'warning2': {},
    'warning3': {},
    'warning4': {},
    'warning5': {},
    'warning6': {},
    'change-menu': {},
    'invite-friend': {},
    'person': {},
    'leaf': {},
    'vegetable': {},
    'user': {},
    'sort-by': {},
    'bean': {},
    'sugar': {},
    'sugar2': {},
    'plan': {},
    'groceries': {},
    'salt': {},
    'home': {},
    'home1': {},
    'home2': {},
    'grains': {},
    'book-eatlove': {},
    'generate-more': {},
    'fruit': {},
    'cook': {},
    'collection-icon': {},
    'wheat': {},
    'check': {},
    'close-x': {},
    'check2': {},
    'onion': {},
    'tags2': {},
    'grid-view': {},
    'budget': {},
    'money-bag': {},
    'money-bag2': {},
    'crab': {},
    'minus-square-solid': {},
    'add-square-solid': {},
    'add-circle-solid': {},
    'add-circle-outline': {},
    'radio-unchecked': {},
    'radio-checked': {},
    'minus-square-outline': {},
    'add-square-outline': {},
    'hourglass': {},
    'grocery-list': {},
    'plant': {},
    'coffee': {},
    'soup-pot': {},
    'international': {},
    'eggplant': {},
    'star-full': {},
    'money-cash': {},
    'ewg': {},
    'steam-pot': {},
    'steak': {},
    'bread': {},
    'bookmark': {},
    'notification': {},
    'notification1': {},
    'notification2': {},
    'line-graph': {},
    'cupcake': {},
    'international1': {},
    'list1': {},
    'list2': {},
    'list-view': {},
    'list-view1': {},
    'candy': {},
    'avocado1': {},
    'family': {},
    'cherries': {},
    'star-half': {},
    'pie-chart': {},
    'trophy1': {},
    'carrot': {},
    'couple': {},
    'pan': {},
    'ribbon': {},
    'share': {},
    'analyze': {},
    'breastfeed': {},
    'receipt': {},
    'book': {},

    'checkbox-box': {},
    'checkbox-checked': {},
    'checkbox-checked1': {},
    'checkbox-unchecked': {},
    'bellpepper': {},
    'trophy': {},
    'icecream': {},
    'beverages': {},
    'help': {},
    'tomato': {},
    'fodmap': {},
    'rice': {},
    'moon': {},
    'award1': {},
    'coins': {},
    'knife1': {},
    'remove-circle-outline': {},
    'beet': {},
    'stats': {},
    'pets': {},
    'knife': {},
    'funnel': {},
    'dollar-sign': {},
    'add-person': {},
    'fish': {},
    'fish1': {},
    'fish2': {},
    'wheat1': {},
    'bullet': {},
    'watermelon': {},
    'cake': {},
    'plus-thick': {},
    'pregnant': {},
    'olives': {},
    'cart': {},
    'cart1': {},
    'cart2': {},
    'cart3': {},


    'select': {},
    'reload': {},
    'star-empty': {},
    'condiment1': {},
    'jar': {},
    'eggplant2': {},
    'gauge': {},
    'gauge-10': {},
    'gauge-20': {},
    'gauge-30': {},
    'gauge-40': {},
    'gauge-50': {},
    'gauge-60': {},
    'gauge-70': {},
    'gauge-80': {},
    'gauge-90': {},
    'gauge-100': {},
    'pear1': {},
    'platter': {},
    'radio-unselected': {},
    'logo': {},
    'avocado': {},
    'lettuce': {},
    'pan1': {},
    'eggs': {},
    'chard': {},
    'nutro1': {},
    'bellpepper1': {},
    'pear': {},
    'award': {},
    'treenuts': {},
    'strike': {},
    'recipe': {},
    'grid-view1': {},
    'minus-thick': {},
    'list': {},
    'meal-plan': {},
    'condiment': {},
    'expand': {},
    'plus-thin': {},
    'minus-thin': {},
    'tupperware': {},
    'bellpepper2': {},
    'info': {},
    'corn': {},
    'nutro': {},
    'raddish': {},
    'eggplant1': {},
    'close-box': {},
    'measuring-cup': {},
    'radio-selected': {},
    'soy': {},
    'herbs': {},
    'veggiebowl': {},
    'adults': {},
    'kids': {},
    'phone': {},
    'x': {},
    'gluten-free': {},
    'high-blood': {},
    'lose-weight': {},
    'pediatric-nutrition': {},
    'pregnancy': {},
    'reduce-inflamation': {},
    'renal-disease': {},
    'sports-nutrition': {},
    'food-allergies': {},
    'eat-more-plants': {},
    'baby-bottle': {},
    'rx': {},
    'intestine': {},
    'diabetes': {},
    'diabetes1': {},
    'diabetes2': {},

    'high-cholesterol': {},
    'increase-energy': {},
    'mother-child': {},
    'osteoporosis': {},

    'settings': {},
    'settings1': {},
    'settings2': {},
    'settings3': {},
    'settings4': {},
    'settings5': {},

    'smile': {},
    'scale-weight': {},
};
