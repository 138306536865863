'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Combobox } from 'react-widgets';
import debounce from 'lodash.debounce';
import classNames from 'classnames';

import Item from './PatientSelect/PatientSelectItem.react';

import { getConfig } from '../../../utils/Env';
import AuthStore from'../../../stores/AuthStore';
import UserStore from '../../../stores/UserStore';

import '../../../components/Widgets/ReactWidgets.scss';

export default class PatientSelect extends Component {
    static propTypes = {
        onSelect: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            value: '',
            results: null,
            loading: false,
        };

        this.onChange = debounce(this.executeSearch, 150);
    }

    onChange() {}

    executeSearch = () => {
        const { practice } = UserStore.getUser();

        const url = getConfig('users_api') + practice.links.patients.href;
        const query = {
            terms: this.state.value,
            embed: ['preferences', 'prescriptions', 'family'],
            size: 100
        };

        this.setState({loading: true});

        AuthStore.fetch({url, query}).then(
            data => this.setState({results: data.elements, loading: false}),
            error => this.setState({loading: false}),
        );
    }

    onChangeText = (value) => {
        // The user typed in the box
        if (typeof value == 'string') {
            this.setState({value}, this.onChange);
        }

        // The user selected an item from the dropdown.
        if (typeof value == 'object') {
            const { onSelect } = this.props;

            onSelect && onSelect(value);
        }
    }

    onSearchPatientToggle = (open) => {
        if (!open) {
            return;
        }

        const { results, loading } = this.state;

        if (results === null && !loading) {
            this.executeSearch();
        }
    }

    render = () => {
        const { className } = this.props;
        const { loading, value, results } = this.state;
        const user = UserStore.getUser();

        return (
            <Combobox onChange={this.onChangeText}
                className={classNames("patient-select", className)}
                busy={loading}
                itemComponent={Item}
                value={value}
                placeholder={user.practice_type === 'dietetics' ? "Add recipient: search" : 'Search for a client'}
                onToggle={this.onSearchPatientToggle}
                data={results || []} />
        );
    }
}
