'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from 'react-modal';
import { pluralize, singularize } from 'inflected';

import SmartFraction from '../../../../components/Widgets/SmartFraction.react';
import ImgResized from '../../../../components/Widgets/ImgResized.react';
import Popup from '../../../../components/Widgets/Popup.react';

import { smartCeil, humanizeGrams, humanizeMilliliters, inflectUnitOfMeasure, roundForHumans } from '../../../../utils/Math';
import { flattenIngredients } from '../../../../utils/Recipe';

import UserStore from '../../../../stores/UserStore';

import groceryCategories from '../../../../tables/grocery-categories';
import modalStyles from '../../../../jsx-styles/modals';
import './GroceriesModal.scss';
import '../../Modals/Modals.scss';

export default class GroceriesModal extends Component {
    static propTypes = {
    };

    static contextTypes = {
        user: PropTypes.object,
        foods: PropTypes.object,
        recipes: PropTypes.object,
        details: PropTypes.object,
    };

    renderByDisplayMode = (grocery) => {
        const { profile } = this.props;
        const { foods } = this.context;
        const { preferences = {} } = profile;
        let icon = [];
        let className = "grocery";
        let food;

        // For weights, we need to pluralize or suffixize the FIRST word (not the last)
        let avoidances = [];

        if (grocery.food_uuid && foods[grocery.food_uuid] && preferences.avoidances) {
            food = foods[grocery.food_uuid];
            avoidances = preferences.avoidances.filter(tag => (food.tags || []).indexOf(tag) != -1);
        }

        if (avoidances.length > 0) {
            className += " avoid";
            icon.push(
                <abbr className="avoid-warning" key="avoid-warning" title="This ingredient is on the avoidance list">
                    <i className="icon-warning3"/>
                </abbr>
            );
        }

        let description = [];

        if (grocery.description) {
            description = grocery.description.split(' ');

            // If there is a parenthesis in there, we inflect the first word
            if (grocery.description.indexOf('(') != -1) {
                description[0] = grocery.quantity == 1
                          ? singularize(description[0])
                          : pluralize(description[0]);
            } else {
                // Otherwise we inflect the last word
                description[description.length - 1] = grocery.quantity == 1
                          ? singularize(description[description.length - 1])
                          : pluralize(description[description.length - 1]);
            }
        }

        if ((food?.tags || []).indexOf('BUYORG') != -1 && !preferences.hide_ewg) {
            icon.push(
                <abbr className="buyorg" key={2} title="Buy Organic">
                    <i className="icon-ewg" />
                </abbr>
            );
        }

        if (grocery.exclude_from_groceries) {
            icon.push(
                <abbr key={3} title="Excluded from Groceries">
                    <i className="icon-minus-circle-solid" />
                </abbr>
            );
        }

        if (grocery.display_mode == 'prefix') {
            return (
                <p className={className}>
                    {description.join(' ')} <strong>{grocery.name}</strong>{icon}
                </p>
            );
        }

        if (grocery.display_mode == 'prefix-of') {
            return (
                <p className={className}>
                    {description.join(' ')} of <strong>{grocery.name}</strong>{icon}
                </p>
            );
        }

        if (grocery.display_mode == 'suffix') {
            return (
                <p className={className}>
                    <strong>{grocery.name}</strong>
                    {description.join(' ')}
                    {icon}
                </p>
            );
        }

        if (grocery.display_mode == 'dash-suffix') {
            return (
                <p className={className}>
                    <strong>{grocery.name}</strong> - {description.join(' ')}{icon}
                </p>
            );
        }

        if (grocery.name) {
            let split = grocery.name.split(' ');

            // Inflect the last word of the groceries name
            split[split.length - 1] = grocery.quantity == 1
                           ? singularize(split[split.length - 1])
                           : pluralize(split[split.length - 1]);

            return (
                <p className={className}>
                    <strong>{split.join(' ')}</strong>{icon}
                </p>
            );
        }

        return (
            <p className={className}>
                <strong>{grocery.uuid}</strong>{icon}
            </p>
        );
    }

    getAmountAndUnitFromRecipeMeal = (meal, food) => {
        const { recipes, details } = this.context;
        const recipe = recipes[meal.recipe_uuid];

        if (!recipe) {
            return 0;
        }

        const detail = details[recipe.details];

        if (!detail) {
            return 0;
        }

        let grams = 0, milliliters = 0;

        let unit = null;

        flattenIngredients({detail}).forEach(ingredient => {
            if (food && ingredient.food && ingredient.food.uuid === food.uuid) {
                if (ingredient.grams) {
                    grams += (ingredient.grams) * (meal.scaling || 1); // ignoring refuse because the in-recipe unit doesn't need to account for it.
                }

                if (ingredient.milliliters) {
                    milliliters += (ingredient.milliliters) * (meal.scaling || 1);
                }

                if (!unit && ingredient.food.unit_amount) {
                    unit = {
                        amount: ingredient.food.unit_amount,
                        description: ingredient.food.unit_description,
                        grams: ingredient.food.unit_grams,
                        milliliters: ingredient.food.unit_milliliters,
                    };
                }
            }

            if (ingredient.recipe && recipes[ingredient.recipe.uuid]) {
                // If we have a subrecipe, determine how many batches of that we need to make
                // then loop through all of its ingredients
                let subrecipe = recipes[ingredient.recipe.uuid];
                let subdetails = details[subrecipe.details];

                // Protect against missing data.
                if (!(subrecipe && subdetails)) {
                    return;
                }

                // This is the amount in grams of the subrecipes yield needed. We use this
                // to determine how many batches of the subrecipe is needed (which is our subscaling factor)
                let gramsNeeded = ingredient.grams * (meal.scaling || 1);

                // Determine how many batches of the subrecipe we need to make for this meal
                let scaling = gramsNeeded / (subrecipe.grams_per_serving * (subrecipe.servings || 1));
                scaling = scaling > 0 ? Math.ceil(scaling) : Math.floor(scaling);

                // Now loop through subdetails ingredients and add grams where matching
                flattenIngredients({subdetails}).forEach(subingredient => {
                    if (food && subingredient.food && subingredient.food.uuid === food.uuid) {
                        if (subingredient.grams) {
                            grams += (subingredient.grams * (1 / (1 - (food.refuse || 0) / 100))) * (scaling || 1);
                        }

                        if (subingredient.milliliters) {
                            milliliters += (subingredient.milliliters * (1 / (1 - (food.refuse || 0) / 100))) * (scaling || 1);
                        }

                        if (!unit && subingredient.food.unit_amount) {
                            unit = {
                                uuid: subingredient.food.uuid,
                                amount: subingredient.food.unit_amount,
                                description: subingredient.food.unit_description,
                                grams: subingredient.food.unit_grams,
                                milliliters: subingredient.food.milliliters,
                            };
                        }
                    }
                });
            }
        });

        if (!unit && food && food.default_unit) {
            unit = Object.assign({}, food.default_unit);
        }

        return { unit, grams, milliliters };
    }

    getAmountAndUnitFromFoodMeal = (meal, food) => {
        let unit = (food.units || []).find(fu => fu.description === meal.logged_unit) || food.default_unit;

        return {
            unit,
            grams: meal.logged_grams,
            milliliters: meal.logged_milliliters,
            scaling: meal.scaling,
        };
    }

    renderAmountFromUnit(unit, grams, milliliters, units_mode, scaling) {
        let amount = (milliliters && (<span>{humanizeMilliliters(milliliters)}</span>)) ||
                     (grams && (<span>{humanizeGrams(grams)}</span>)) ||
                     '';

        if (unit && unit.grams > 0) {
            let t = smartCeil(grams / (unit.grams / unit.amount));

            let content = [
                units_mode === 'english' ? <SmartFraction value={t} key={1} /> : t,
            ];

            if (unit.description) {
                let desc = inflectUnitOfMeasure(t, unit);

                content.push(<span key={2}>&nbsp;{desc}</span>);
            }

            amount = (
                <span className="amount">{content}</span>
            );
        } else if (unit && unit.milliliters) {
            let t = smartCeil(milliliters / (unit.milliliters / unit.amount));

            let content = [
                units_mode === 'english' ? <SmartFraction value={t} key={1} /> : t,
            ];

            if (unit.description) {
                let desc = inflectUnitOfMeasure(t, unit);

                content.push(<span key={2}>{desc}</span>);
            }

            amount = (
                <span className="amount">{content}</span>
            );
        } else if (scaling) {
            amount = (
                <span className="amount">{scaling}</span>
            );
        }

        return amount;
    }

    renderGroceryRecipeMeal = (meal, key, recipe, food) => {
        const { user, showMealDetails } = this.context;
        let { grams, milliliters, unit } = this.getAmountAndUnitFromRecipeMeal(meal, food);

        const amount = this.renderAmountFromUnit(unit, grams, milliliters, user.units_mode);

        return (
            <li key={key}>
                <strong>{recipe.title}</strong>&nbsp;
                on day {parseInt(meal.offset) + 1} needs <strong>{amount} ({milliliters ? <>{roundForHumans(milliliters)}ml</> : <>{roundForHumans(grams)}g</>})</strong>
            </li>
        );
    }

    renderGroceryFoodMeal = (meal, key, food) => {
        const { user, showMealDetails } = this.context;
        let { grams, milliliters, unit, scaling } = this.getAmountAndUnitFromFoodMeal(meal, food);

        const amount = this.renderAmountFromUnit(unit, grams, milliliters, user.units_mode, scaling);

        return (
            <li key={key}>
                <strong>{meal.meal}</strong>&nbsp;
                on day {parseInt(meal.offset) + 1} needs <strong>{amount} ({milliliters ? <>{roundForHumans(milliliters)}ml</> : <>{roundForHumans(grams)}g</>})</strong>
            </li>
        );
    }

    renderGroceryMeal = (grocery, meal, key) => {
        // Tally up how much of this ingredient is needed in this meal
        const { recipes, foods } = this.context;
        const food = grocery.food_uuid && foods[grocery.food_uuid];
        const recipe = recipes[meal.recipe_uuid];

        if (recipe && food) {
            // grocery linked to a food through a recipe
            return this.renderGroceryRecipeMeal(meal, key, recipe, food);
        } else if (!recipe && food) {
            // food linked directly to a meal
            return this.renderGroceryFoodMeal(meal, key, food);
        }
    }

    renderMeals = (grocery) => {
        // Sort meals by offset
        let groceryMeals = grocery.meals.sort((a, b) => a.offset - b.offset);

        if (!groceryMeals.length) {
            return null;
        }

        return (
            <section className="mapped-meals">
                <h4><em>{grocery.name}</em> is needed by:</h4>
                <ul>
                    {groceryMeals.map((meal, i) => this.renderGroceryMeal(grocery, meal, i))}
                </ul>
            </section>
        );
    }

    renderGrocery = (grocery, key) => {
        const { profile } = this.props;
        const { user } = this.context;

        let quantity = <i className="icon-warning" />;

        if (!isNaN(grocery.quantity) && typeof grocery.quantity == 'number') {
            quantity = (profile && profile.units_mode === 'metric')
                     ? roundForHumans(grocery.quantity)
                     : <SmartFraction value={grocery.quantity} />;
        }

        const grams = !isNaN(grocery.grams_needed) && typeof grocery.grams_needed == 'number'
                    ? Math.round(grocery.grams_needed)
                    : null;

        const milliliters = !isNaN(grocery.milliliters_needed) && typeof grocery.milliliters_needed == 'number'
                          ? Math.round(grocery.milliliters_needed)
                          : null;

        let pkg_used = <i className="icon-warning" />;
        let pkg_amt = <i className="icon-warning" />;
        let pkg_unit = null;

        if (!isNaN(grocery.milliliters_needed) && typeof grocery.milliliters_needed == 'number' &&
            !isNaN(grocery.quantity) && typeof grocery.quantity == 'number' && grocery.quantity > 0 &&
            !isNaN(grocery.pkg_milliliters) && typeof grocery.pkg_milliliters == 'number' && grocery.pkg_milliliters > 0) {
            pkg_used = Math.round(grocery.milliliters_needed / (grocery.pkg_milliliters * grocery.quantity) * 100);
            pkg_amt = Math.round(grocery.pkg_milliliters * grocery.quantity);
        } else if (!isNaN(grocery.grams_needed) && typeof grocery.grams_needed == 'number' &&
            !isNaN(grocery.quantity) && typeof grocery.quantity == 'number' && grocery.quantity > 0 &&
            !isNaN(grocery.pkg_grams) && typeof grocery.pkg_grams == 'number' && grocery.pkg_grams > 0) {
            pkg_used = Math.round(grocery.grams_needed / (grocery.pkg_grams * grocery.quantity) * 100);
            pkg_amt = Math.round(grocery.pkg_grams * grocery.quantity);
        }

        const excess = !isNaN(grocery.excess) && typeof grocery.excess == 'number'
                     ? (Math.round(grocery.excess * 100) / 100)
                     : <i className="icon-warning" />;

        const price = !isNaN(grocery.price) && typeof grocery.price == 'number'
                    ? '$' + grocery.price.toFixed(2)
                    : <i className="icon-warning" />;

        const used_price = !isNaN(grocery.used_price) && typeof grocery.price == 'number'
                    ? '$' + grocery.used_price.toFixed(2)
                    : <i className="icon-warning" />;

        return (
            <tr key={key}>
                <td className="img-cell">
                    <Popup positionClassName="el-popup-left-center" className="el-popup-dark" button={grocery.image ? <ImgResized src={grocery.image} width={32} height={32} /> : <i className="icon-logo" />}>
                        {this.renderMeals(grocery)}
                    </Popup>
                </td>
                <td className="value-cell">{quantity}</td>
                <td className="grocery-cell">
                    {UserStore.getDnt() || user?.role === 'admin'
                        ? <a target="_blank" href={`/admin/foods/${grocery.food_uuid}`}>
                            {this.renderByDisplayMode(grocery)}
                          </a>
                        : this.renderByDisplayMode(grocery)
                    }
                </td>
                <td>{grocery.category}</td>

                {UserStore.getDnt() || user?.role === 'admin' ?
                    <>
                        <td className="value-cell">
                            {grams ? <span>{grams} <em>g</em></span> : null}
                            {milliliters ? <span>{milliliters} <em>ml</em></span> : null}
                            {!grams && !milliliters ?
                                <i className="icon-warning" />
                            : null}
                        </td>
                        <td className="value-cell">{pkg_amt} <em>{grocery.serving_unit}</em></td>
                        <td className="value-cell">{pkg_used} <em>%</em></td>
                        <td className="value-cell">{excess} <em>g</em></td>
                        <td className="value-cell">{grocery.refuse} <em>%</em></td>
                        <td className="value-cell">{used_price} <em></em></td>
                        <td className="value-cell">{price} <em></em></td>
                    </>
                : null}
            </tr>
        );
    }

    getAggregates = (groceries) => {
        let price = 0, used_price = 0, grams = 0, excess = 0, purchased= 0;

        groceries.forEach(grocery => {
            if (!isNaN(grocery.price) && typeof grocery.price == 'number') {
                price += grocery.price;
            }

            if (!isNaN(grocery.grams_needed) && typeof grocery.grams_needed == 'number') {
                grams += grocery.grams_needed;
            }

            if (!isNaN(grocery.excess) && typeof grocery.excess == 'number') {
                excess += grocery.excess;
            }

            if (!isNaN(grocery.pkg_grams) && typeof grocery.pkg_grams == 'number' &&
                !isNaN(grocery.quantity) && typeof grocery.quantity == 'number') {
                purchased += grocery.quantity * grocery.pkg_grams;
            }

            if (!isNaN(grocery.used_price) && typeof grocery.used_price == 'number') {
                used_price += grocery.used_price;
            }
        });

        return {
            price: price.toFixed(2),
            grams: Math.round(grams),
            excess: Math.round(excess),
            used: Math.round(grams / purchased * 100),
            used_price: used_price.toFixed(2),
            purchased: Math.round(purchased),
        };
    }

    renderTotals(totals, title) {
        const { user } = this.context;

        return (
            <tr className="totals-row">
                <th></th>
                <th></th>
                <th></th>
                {UserStore.getDnt() || user?.role === 'admin' ?
                    <>
                        <th className="value-cell"><strong>{title}</strong></th>
                        <th className="value-cell">{totals.grams} <em>g</em></th>
                        <th className="value-cell">{totals.purchased} <em>g</em></th>
                        <th className="value-cell">{totals.used} <em>%</em></th>
                        <th className="value-cell">{totals.excess} <em>g</em></th>
                        <th></th>
                        <th className="value-cell">${totals.used_price} <em></em></th>
                        <th className="value-cell">${totals.price} <em></em></th>
                    </>
                : null}
            </tr>
        );
    }

    render() {
        const { closeModal, groceries } = this.props;
        const { user } = this.context;

        const freshCategories = groceryCategories.filter(c => c.fresh).map(c => c.label);

        const fresh = groceries.filter(g => freshCategories.indexOf(g.category) != -1);
        const pantry = groceries.filter(g => freshCategories.indexOf(g.category) == -1);

        const freshTotals = this.getAggregates(fresh);
        const pantryTotals = this.getAggregates(pantry);
        const totals = this.getAggregates(groceries);

        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                className="wide-modal customizer-groceries-modal"
                style={modalStyles.largeSquareModal}
                contentLabel="Meal Plan Groceries"
                closeTimeoutMS={250}>

                <div className="wide-modal-container customizer-groceries-container">
                    <header>
                        <button onClick={closeModal}>
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>
                    </header>

                    <div className="modal-scroll-container">

                <table>
                    <tbody>
                        <tr>
                            <td colSpan={12}><h3>Fresh Ingredients: {fresh.length} total</h3></td>
                        </tr>
                        <tr>
                            <th></th>
                            <th className="value-cell">Qty</th>
                            <th>Grocery</th>
                            <th>Category</th>
                            {UserStore.getDnt() || user?.role === 'admin' ?
                                <>
                                    <th className="value-cell">Needed</th>
                                    <th className="value-cell">To Purchase</th>
                                    <th className="value-cell">% Used</th>
                                    <th className="value-cell">Excess</th>
                                    <th className="value-cell">Refuse %</th>
                                    <th className="value-cell">Est.$/Used</th>
                                    <th className="value-cell">Est.$/Pkg</th>
                                </>
                            : null}
                        </tr>
                        {fresh.map(this.renderGrocery)}

                        <tr>
                            <td colSpan={12}><h3>Pantry Ingredients: {pantry.length} total</h3></td>
                        </tr>
                        <tr>
                            <th></th>
                            <th className="value-cell">Qty</th>
                            <th>Grocery</th>
                            <th>Category</th>
                            {UserStore.getDnt() || user?.role === 'admin' ?
                                <>
                                    <th className="value-cell">Needed</th>
                                    <th className="value-cell">To Purchase</th>
                                    <th className="value-cell">% Used</th>
                                    <th className="value-cell">Excess</th>
                                    <th className="value-cell">Refuse %</th>
                                    <th className="value-cell">Est.$/Used</th>
                                    <th className="value-cell">Est.$/Pkg</th>
                                </>
                            : null}
                        </tr>
                        {pantry.map(this.renderGrocery)}
                    </tbody>
                </table>

                    </div>
                </div>
            </Modal>
        );
    }
}
