'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import $ from 'jquery';

import { getConfig } from '../../utils/Env';

import './AmazonLogin.scss';

let _libraryLock = false;
let _isLoaded = false;

export default class LoginWithAmazon extends Component {
    static propTypes = {
        buttonText: PropTypes.node,
    };

    static defaultProps = {
        buttonText: 'Login with Amazon',
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };
    }

    amazonRoot = null;

    onAmazonLoginReady = () => {
        _isLoaded = true;

        //eslint-disable-next-line no-undef
        amazon.Login.setClientId(getConfig('amazon_client_id'));

        this.setState({loading: false});
    }

    realizeAmazonLogin = (el) => {
        this.amazonRoot = el;
        if (el && !_libraryLock && !_isLoaded) {
            this.setState({loading: true}, () => {
                window.onAmazonLoginReady = this.onAmazonLoginReady;

                _libraryLock = true;

                // Load the Login with Amazon SDK
                (function(d) {
                    var a = d.createElement('script');
                    a.type = 'text/javascript';
                    a.async = true;
                    a.id = 'amazon-login-sdk';
                    a.src = 'https://api-cdn.amazon.com/sdk/login1.js';
                    d.getElementById('amazon-root').appendChild(a);
                })(document);
            });
        }
    }

    onAmazonAuthorize = (response) => {
        const { callback } = this.props;

        callback && callback(response);
    }

    onClickLoginWithAmazon = () => {
        if (!_isLoaded) {
            return;
        }

        //eslint-disable-next-line no-undef
        amazon.Login.authorize({scope: 'profile'}, this.onAmazonAuthorize);
    }

    render() {
        const { buttonText } = this.props;
        const { loading } = this.state;

        return (
            <button className="amazon-login-btn" ref={this.realizeAmazonLogin}
                onClick={this.onClickLoginWithAmazon}
                data-working={!this.state.loading && _isLoaded}>
                <i className="icon-amazon" /> {buttonText}
            </button>
        );
    }
}
