'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import UserStore from '../../../../../stores/UserStore';
import NutrientFilters from '../../../../Admin/Combos/NutrientFilters.react';

import './FiltersModal.scss';
import modalStyles from '../../../../../jsx-styles/modals';

export default class FiltersModal extends Component {


    constructor(props) {
        super(props);

        this.state = {
            // Make a deep copy of the original parameters
            initialParams: JSON.parse(JSON.stringify(props.params)),
        };
    }

    revertAndClose = () => {
        const { onChangeParams, closeModal } = this.props;
        const { initialParams } = this.state;

        onChangeParams(initialParams);
        closeModal();
    }

    toggleTag = (tag) => {
        const { params, onChangeParams } = this.props;

        params.filters = params.filters || {};
        params.filters.tags = params.filters.tags || [];

        if (params.filters.tags.includes(tag)) {
            params.filters.tags.splice(params.filters.tags.indexOf(tag), 1);
        } else {
            params.filters.tags.push(tag);
        }

        onChangeParams(params);
    }

    render() {
        const { params, onChangeParams, closeModal } = this.props;
        const { hide_nutrition = false } = UserStore.getPreferences();

        let tags = (params && params.filters && params.filters.tags) || [];

        return (
            <Modal isOpen={true}
                closeModal={closeModal}
                contentLabel="Add/Swap Recipe"
                className="product-filters-modal"
                style={modalStyles.minimalOverlay}
                closeTimeoutMS={250}>
                <div className="product-filters-container">
                    <header>
                        <button className="close-modal-btn" onClick={closeModal}>
                            <i className="icon-chevron-left" />
                        </button>
                        <h1>Filter Results</h1>
                    </header>
                    <div className="top-half-circle">&nbsp;</div>

                    <section className="panel">
                        <div className="with-label">
                            <label>Meal</label>

                            <ul className="toggle-group">
                                <li>
                                    <button className="toggle-btn" data-active={tags.includes('Breakfast')}
                                        onClick={() => this.toggleTag('Breakfast')}>
                                        Breakfast
                                    </button>
                                </li>
                                <li>
                                    <button className="toggle-btn" data-active={tags.includes('Lunch')}
                                        onClick={() => this.toggleTag('Lunch')}>
                                        Lunch
                                    </button>
                                </li>
                                <li>
                                    <button className="toggle-btn" data-active={tags.includes('Main Dish')}
                                        onClick={() => this.toggleTag('Main Dish')}>
                                        Dinner
                                    </button>
                                </li>
                                <li>
                                    <button className="toggle-btn" data-active={tags.includes('Snack')}
                                        onClick={() => this.toggleTag('Snack')}>
                                        Snack
                                    </button>
                                </li>
                            </ul>
                        </div>

                        <div className="with-label">
                            <label>Dish Type</label>

                            <ul className="toggle-group">
                                <li>
                                    <button className="toggle-btn" data-active={tags.includes('Appetizer')}
                                        onClick={() => this.toggleTag('Appetizer')}>
                                        Appetizer
                                    </button>
                                </li>
                                <li>
                                    <button className="toggle-btn" data-active={tags.includes('Beverage')}
                                        onClick={() => this.toggleTag('Beverage')}>
                                        Beverage
                                    </button>
                                </li>
                                <li>
                                    <button className="toggle-btn" data-active={tags.includes('Bread')}
                                        onClick={() => this.toggleTag('Bread')}>
                                        Bread
                                    </button>
                                </li>
                                <li>
                                    <button className="toggle-btn" data-active={tags.includes('Condiment/Sauce')}
                                        onClick={() => this.toggleTag('Condiment/Sauce')}>
                                        Condiment/Sauce
                                    </button>
                                </li>
                                <li>
                                    <button className="toggle-btn" data-active={tags.includes('Dessert')}
                                        onClick={() => this.toggleTag('Dessert')}>
                                        Dessert
                                    </button>
                                </li>
                                <li>
                                    <button className="toggle-btn" data-active={tags.includes('Salad')}
                                        onClick={() => this.toggleTag('Salad')}>
                                        Salad
                                    </button>
                                </li>
                                <li>
                                    <button className="toggle-btn" data-active={tags.includes('Sandwich')}
                                        onClick={() => this.toggleTag('Sandwich')}>
                                        Sandwich
                                    </button>
                                </li>
                                <li>
                                    <button className="toggle-btn" data-active={tags.includes('Soup')}
                                        onClick={() => this.toggleTag('Soup')}>
                                        Soup
                                    </button>
                                </li>
                            </ul>
                        </div>

                    </section>

                    <section className="panel">
                        {!hide_nutrition ?
                            <NutrientFilters params={params} onChangeParams={onChangeParams}
                                nutrientWhitelist={['208', '203', '204', '205', '307']} />
                        : null}
                    </section>

                    <footer>
                        <button className="cancel-btn" onClick={this.revertAndClose}>Cancel</button>
                        <button className="ok-btn" onClick={closeModal}>Apply</button>
                    </footer>
                </div>
            </Modal>
        );
    }
}
