"use strict";

import React, { useState } from "react";

import "./PostalCodeInput.scss";

const PostalCodeInput = ({ value, onChange, onError, onEnterKeyDown, autoFocus, showError }) => {
    const [postalCode, setPostalCode] = useState(value);
    const [error, setError] = useState(false);

    const validationMessage = "Must be 3-25 characters and only letters and numbers.";

    const handlePostalCode = (e) => {
        setPostalCode(e.target.value);

        const isValid = e.target.checkValidity();
        setError(!isValid ? validationMessage : null);
        onError && onError(error);

        if (isValid) {
            onChange && onChange(e);
        }
    };

    const handleEnterKeyDown = (e) => {
        onEnterKeyDown && e.key == "Enter" && onEnterKeyDown();
    };

    return (
        <>
            <div className="el-form ">
                <div className="postal-code-field">
                    <input
                        type="text"
                        id="postal-code"
                        name="postal-code"
                        value={postalCode}
                        onChange={handlePostalCode}
                        onKeyDown={handleEnterKeyDown}
                        minLength={3}
                        maxLength={25}
                        pattern="[0-9a-zA-Z ]{3,25}"
                        title={validationMessage}
                        aria-label={validationMessage}
                        placeholder="Enter Postal Code"
                        onFocus={(e) => autoFocus && e.currentTarget.select()}
                        autoFocus={autoFocus}
                    />
                </div>
                {showError && error && (
                    <span id="postal-code-error" className="error-msg">
                        {validationMessage}
                    </span>
                )}
            </div>
        </>
    );
};

export default PostalCodeInput;
