'use strict';

import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import indexBy from 'lodash.indexby';
import debounce from 'lodash.debounce';
import omit from 'lodash.omit';
import moment from 'moment';
import store from 'store';
import uuidGen from 'uuid';

import EditImage from './Editor/EditImage.react';
import EditServings from './Editor/EditServings.react';
import EditTimings from './Editor/EditTimings.react';
import EditTags from './Editor/EditTags.react';
import EditIngredientGroup from './Editor/EditIngredientGroup.react';
import EditInstructionGroup from './Editor/EditInstructionGroup.react';
import NutritionAnalysisModal from './Editor/NutritionAnalysisModal.react';
import Alert from '../Widgets/Alert/Alert.react';

import UserStore from '../../stores/UserStore';
import BoardStore from '../../stores/BoardStore';
import BoardActions from '../../actions/BoardActions';
import AuthStore from '../../stores/AuthStore';

import Analytics from '../../utils/Analytics';
import { getConfig } from '../../utils/Env';
import { inquire } from '../../utils/Enforcer';
import { createNewDocument, fetchDocumentsById, updateCachedDocuments, getRecipeEventProperties } from '../../utils/Content';
import { flattenIngredients, getDetailsPrototype, ensureDetailsPopulated, getAssetsForRecipe,
         getIngredientGroupPrototype, getInstructionGroupPrototype } from '../../utils/Recipe';
import { computeDefaultMealRx, compareNutrientsToEnvelope } from '../../utils/Nutrition';
import './Editor.scss';

export default class RecipeEditor extends Component {

    static propTypes = {
        uuid: PropTypes.string,

        saveDirtyBtnText: PropTypes.string,
        saveCleanBtnText: PropTypes.string,
        profile: PropTypes.object.isRequired,

        saveAsCopy: PropTypes.bool,
        importSuccess: PropTypes.bool,
        importFailure: PropTypes.bool,
        recipe: PropTypes.object,
        details: PropTypes.object,
    };

    static defaultProps = {
        saveDirtyBtnText: 'save recipe',
        saveCleanBtnText: 'close',

        saveAsCopy: false,
        importSuccess: false,
        importFailure: false,
    };

    static contextTypes = {
        addSwapContext: PropTypes.object,
        showUpgradeForm: PropTypes.func,
        isPro: PropTypes.bool,
        isAndroid: PropTypes.bool
    };

    static childContextTypes = {
        recipe: PropTypes.object,
        details: PropTypes.object,
        foods: PropTypes.object,

        subrecipes: PropTypes.object,
        subdetails: PropTypes.object,
        products: PropTypes.object,

        profile: PropTypes.object,

        synchronizeAssets: PropTypes.func,
        hideProductTypeButtons: PropTypes.bool,
        editSessionId: PropTypes.string,
    };

    constructor(props, context) {
        super(props, context);

        const user = UserStore.getUser();

        this.state = {
            recipe: props.recipe || null,
            details: props.details || null,
            dirty: !!props.recipe,

            foods: {},
            subrecipes: {},
            subdetails: {},
            products: {},
            mismatches: {},

            lastSaved: null,

            user,

            // can cancel until the first save, then it's just 'close'
            canCancel: true,

            saveAsCopy: props.saveAsCopy,

            editSessionId: props.editSessionId || uuidGen.v4().substring(0, 8),
        };

        this.debounceStoreBackup = debounce(this.storeBackup, 500);
        this.analyzeAndBackup = debounce(this.analyzeAndBackup, 500);
        this.resetLastSaved = debounce(this.resetLastSaved, 5000);

        this.lastIngredientGroup = createRef();
        this.lastInstruction = createRef();
    }

    getChildContext = () => {
        const { recipe, details, foods, subrecipes, subdetails, products, editSessionId } = this.state;
        const { profile } = this.props;

        return {
            recipe,
            details,
            foods,
            subrecipes,
            subdetails,
            products,

            profile,

            hideProductTypeButtons: false,
            editSessionId,

        };
    }

    componentDidMount = () => {
        UserStore.addChangeListener(this.onUserStoreChange);
        this.loadRecipe();

        const { usedFromContext, uuid } = this.props;
        const { editSessionId } = this.state;

        if (usedFromContext) {
            Analytics.startUseOwnRecipe({
                'Context': usedFromContext,
                'Edit Session ID': editSessionId,
                'Recipe UUID': uuid,
            });
        }
    }

    componentWillUnmount = () => {
        UserStore.removeChangeListener(this.onUserStoreChange);
    }

    onUserStoreChange = () => {
        const user = UserStore.getUser();

        this.setState({user});
    }

    loadRecipe = () => {
        const { uuid, recipe } = this.props;

        if (!uuid) {

            if (!recipe) {
                this.createEmptyRecipe();
            } else {
                this.analyzeRecipe();
            }

            return;
        }

        this.setState({loading: true});

        // First, inquire to see if we have write and publish permission.
        const inquiries = [
            {action: 'publish', resource: uuid},
            {action: 'write',   resource: uuid},
        ];

        inquire(inquiries).then(decisions => {
            if (!decisions) {
                this.setState({canWrite: false});
                return;
            }

            let canPublish = decisions.filter(d => d.action == 'publish')[0].decision === 'permit';
            let canWrite   = decisions.filter(d => d.action == 'write')[0].decision === 'permit';

            this.setState({canPublish, canWrite}, () => {
                this.loadRecipeData(uuid);
            });
        });
    }

    syncAssets = async (callback) => {
        const { recipe, details } = this.state;

        let { foods, subrecipes, subdetails, brands, products } = await getAssetsForRecipe(recipe, details);

        const { mismatches } = this.calculateMismatches(recipe, details);

        this.setState({subrecipes, subdetails, foods, brands, products, mismatches}, callback);
    }

    loadRecipeData = async (uuid) => {
        const { isPro } = this.context;
        const { canWrite, saveAsCopy } = this.state;

        const first = await fetchDocumentsById([uuid]);
        const recipe = JSON.parse(JSON.stringify(first[0])); // deeeeep copy

        if (!(recipe && recipe.type == 'recipe')) {
            this.setState({error: 'cannot edit, not a recipe', recipe: null, details: null});

            return;
        }

        if (saveAsCopy) {
            recipe.title = 'Duplicate of ' + recipe.title;
            recipe.status = 'draft';
        }

        // Load the details document
        let uuidsToLoad = [recipe.details];

        // Intentioanlly pre-load subrecipes (these will get synced, but its a bit faster)
        // to pre-load them here
        if (recipe.subrecipes) {
            uuidsToLoad = uuidsToLoad.concat(recipe.subrecipes.map(s => s.recipe))
                                     .concat(recipe.subrecipes.map(s => s.details));
        }

        const second = await fetchDocumentsById(uuidsToLoad);
        let details = second.find(d => d.uuid == recipe.details);
        details = JSON.parse(JSON.stringify(details));
        details = ensureDetailsPopulated(details);

        let subrecipes = indexBy(second.filter(d => d.type == 'recipe'), 'uuid');
        let subdetails = indexBy(second.filter(d => d.type == 'recipe_details'), 'uuid');

        if (!canWrite || recipe.status === 'live') {
            if (isPro) {
                recipe.title = recipe.title + ' 2.0';
            } else {
                recipe.title = 'My ' + recipe.title;
            }
        }

        // Do we have a backup we can restore?
        const backup = store.get('recipe-edited-' + recipe.uuid);

        this.setState({
            recipe, details,
            subrecipes, subdetails,
            loading: false,
            backup,
            dirty: saveAsCopy ? true : false
        }, this.syncAssets);
    }

    calculateMismatches = (recipe, details) => {
        const { profile } = this.props;
        const mismatches = {};

        const ingredients = flattenIngredients({details});

        // If there are no ingredients, don't display a warning.
        if (ingredients.length === 0) {
            return { mismatches };
        }

        const nutrients = (recipe.nutrients || {}).values || {};
        const tags = (recipe.tags || []);
        const mealTypeTagMap = {
            'Breakfast': 'Breakfast',
            'Lunch': 'Lunch',
            'Dinner': 'Main Dish',
            'Snack': 'Snack',
        };

        // Find the daily rx
        const allDayRx = (profile.prescriptions || []).filter(p => p.meal_type === 'all-day')[0];

        // Provide each item with a copy of the per-meal prescription (useful later)
        const rxIndex = indexBy(profile.prescriptions || [], 'meal_type');
        Object.keys(mealTypeTagMap).forEach(mealType => {
            if (rxIndex[mealType]) return;

            rxIndex[mealType] = allDayRx ? computeDefaultMealRx(allDayRx, mealType, true) : {envelope:{}};
        });

        Object.keys(mealTypeTagMap).forEach(mealType => {
            const tag = mealTypeTagMap[mealType];

            if (!tags.includes(tag) || !rxIndex[mealType].envelope) {
                return;
            }

            mismatches[mealType] = compareNutrientsToEnvelope(nutrients, rxIndex[mealType].envelope, ['FRU']);
        });

        return { mismatches };
    }

    storeBackup = () => {
        const { recipe, details } = this.state;

        // If the recipe doesn't have a UUID yet, we use a constant instead.
        const backupKey = 'recipe-edited-' + (recipe.uuid ? recipe.uuid : 'new');

        const payload = {
            recipe, details,
            ts: moment().format(),
        };

        // expires in 7 days
        store.set(backupKey, payload, new Date().getTime() + 1000 * 3600 * 24 * 7);
    }

    removeBackup = () => {
        const { recipe } = this.state;

        // If the recipe doesn't have a UUID yet, we use a constant instead.
        const backupKey = 'recipe-edited-' + (recipe.uuid ? recipe.uuid : 'new');

        // remove the backup from localstorage and do remove from state.
        store.remove(backupKey);
        this.setState({ backup: null });
    }

    restoreFromBackup = () => {
        const { backup } = this.state;

        const { recipe, details } = backup;

        // don't remove the backup from localstorage, but do remove it from state.
        this.setState({recipe, details, dirty: true, backup: null}, this.analyzeRecipe);
    }

    analyzeRecipe = () => {
        return new Promise(async (accept, reject) => {
            // Is there a previous abort controller? Abort the request
            let { abortController } = this.state;
            abortController && abortController.abort('abortRequest');

            if ('AbortController' in window) {
                abortController = new AbortController();
                this.setState({ abortController });
            }

            let analyzed = null;

            const { recipe, details } = this.state;

            try {
                // Post the recipe to the recipe analyzer endpoint. It will fix shit for us, analyze it and give it back.
                analyzed = await AuthStore.fetch(getConfig('recipe_api') + '/analyze-recipe', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json; schema=recipe/full/1' },
                    body: JSON.stringify({
                        recipe: omit(this.state.recipe, 'links', 'uuid', 'type', 'details', 'status', 'nutrients'),
                        details: omit(this.state.details, 'links', 'uuid', 'type', 'recipe', 'status'),
                    }),
                }, false, false, abortController);

            } catch (error) {
                this.setState({abortController: null});
                return;
            }

            recipe.ingredient_tags = analyzed.recipe.ingredient_tags;
            recipe.nutrients = analyzed.recipe.nutrients;
            details.ingredients = analyzed.details.ingredients;

            this.setState({ recipe, details, abortController: null }, () => {
                this.syncAssets(accept);
            });
        });
    }

    analyzeAndBackup = async () => {
        await this.analyzeRecipe();
        const { dirty } = this.state;

        if (dirty) {
            this.storeBackup();
        }
    }

    createEmptyRecipe = () => {
        const { profile } = this.props;
        const { addSwapContext } = this.context;

        const recipe = {language: profile.language, title: '', tags: [], ingredient_tags: [], extratags: ["Lunch", "Main Dish"], servings: 1};
        const details = getDetailsPrototype();

        if (addSwapContext) {
            // Because we use the "Main Dish" tag for dinner...
            if (addSwapContext.mealType === 'Dinner') {
                if (!recipe.extratags.includes('Main Dish')) {
                    recipe.extratags.push('Main Dish');
                }
            } else if (addSwapContext.mealType && !recipe.extratags.includes(addSwapContext.mealType)) {
                recipe.extratags.push(addSwapContext.mealType);
            }
        }

        // Do we have a backup to restore?
        const backup = store.get('recipe-edited-new');

        this.setState({recipe, details, backup, dirty: true}, this.analyzeRecipe);
    }

    validate = () => {
        const { recipe, details } = this.state;

        recipe.title = (recipe.title || '').trim();
        if (!recipe.title) {
            this.setState({errorField: 'title', error: 'Meal name is required'});
            return false;
        }

        if (!recipe.servings || recipe.servings <= 0) {
            this.setState({errorField: 'servings', error: 'Please select how many servings this recipe makes'});
            return false;
        }

        const ingredients = flattenIngredients({details});

        if (ingredients.length === 0) {
            this.setState({errorField: 'ingredients', error: 'Please add at least one ingredient'});
            return false;
        }

        // Do the tags include breakfast lunch, dinner or snack?

        return true;
    }

    resetLastSaved = () => {
        this.setState({lastSaved: null});
    }

    overwriteRecipeAndDetails = async (recipe, details) => {
        recipe.extratags = (recipe.extratags || []).filter(v => v && typeof v === 'string');

        const combined = await AuthStore.fetch(getConfig('recipe_api') + recipe.links.self.href, {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=recipe/full/1'},
            body: JSON.stringify({
                recipe: omit(recipe, 'links', 'uuid', 'type', 'details', 'status', 'nutrients'),
                details: omit(details, 'links', 'uuid', 'type', 'recipe', 'status'),
            }),
        });

        return combined;
    }

    storeNewRecipeAndDetails = async (recipe, details) => {
        delete recipe.owner;
        delete details.owner;

        recipe.extratags = (recipe.extratags || []).filter(v => v && typeof v === 'string');

        // Are we creating a copy from a new recipe? Ensure the parent_uuid is saved
        if (recipe.uuid) {
            recipe.parent_uuid = recipe.uuid
        }

        const combined = await createNewDocument(
            'recipe',
            {
                recipe: omit(recipe, 'links', 'uuid', 'type', 'details', 'status'),
                details: omit(details, 'links', 'uuid', 'type', 'recipe', 'status'),
            },
            'recipe/full/1'
        );

        return combined;
    }

    shouldOverwriteRecipe = (recipe) => {
        const { user, canWrite, saveAsCopy } = this.state;

        if (saveAsCopy) {
            return false;
        }

        if (!canWrite) {
            return false;
        }

        // We never overwrite a live recipe
        if (recipe.status === 'live') {
            return false;
        }

        // the document must be saved new
        if (!recipe.links || !recipe.uuid) {
            return false;
        }

        // We only ever overwrite our own recipes
        return recipe.owner === user.uuid;
    }

    addRecipeToFavorites = (recipe) => {
        const lastBoard = BoardStore.getDefaultBoard();
        const newItem = {
            resource_id: recipe.uuid,
            resource_type: recipe.type,
        };

        if (lastBoard.links) {
            // Board already exists, just add to it.
            BoardActions.addToBoard(lastBoard, [newItem]);
        } else {
            lastBoard.contents = lastBoard.contents || [];
            lastBoard.contents.push(newItem);

            // Board does not exist, create a whole new one
            BoardActions.upsertBoards([lastBoard]);
        }
    }

    removeStartNumber = (str) => str
        .replace(/^[0-9{1,}]\)\W+/,'')
        .replace(/^[0-9{1,}]\.\W+/,'');

    onSaveRecipe = async () => {
        if (!this.validate()) {
            return;
        }

        const { onSaveRecipe, savedOwnRecipeContext } = this.props;
        const { user, dirty } = this.state;
        const { capabilities } = user || {};
        const { synchronizeAssets, showUpgradeForm } = this.context;

        if (!capabilities.create_content) {
            showUpgradeForm({feature: 'create_content'});
            return;
        }

        let { recipe, details } = this.state;

        // Removes the leading number. Examples: "1." and "1)".
        details.preparation = details.preparation
            .map(prep => ({...prep, items: [...prep.items?.map(item => ({ text: this.removeStartNumber(item.text) }))]}));


        // If the recipe is not dirty, do not store a new copy.
        if (!dirty) {
            return onSaveRecipe(recipe, details);
        }

        if (!user) {
            this.setState({error: 'You must be logged in to save changes to this recipe', saving: false});
            return false;
        }

        this.setState({saving: true});

        let saved = null;
        let newState = {
            lastSaved: moment(),
            saveError: false,
            saving: false,
            canCancel: false,
            saveAsCopy: false,
        };

        const backupKey = 'recipe-edited-' + (recipe.uuid ? recipe.uuid : 'new')

        try {
            // Should I overwrite to this recipe?
            if (this.shouldOverwriteRecipe(recipe)) {
                saved = await this.overwriteRecipeAndDetails(recipe, details);

            } else {
                // Save a new copy of the recipe
                saved = await this.storeNewRecipeAndDetails(recipe, details);

                // of course we can save our own recipe!
                newState.canWrite = true;

                // Save the new recipe in our favorites, so we can find it later.
                this.addRecipeToFavorites(saved.recipe);
            }
        } catch (exp) {
            if (exp && exp.message && exp.field) {
                this.setState({error: `${exp.field} - ${exp.message}`, saving: false});
            } else {
                this.setState({error: (exp && exp.message) || exp || 'unknown error', saving: false});
            }

            return;
        }

        // Remove the backup.
        store.remove(backupKey);

        recipe = saved.recipe;
        details = saved.details;

        updateCachedDocuments([recipe, details]);
        synchronizeAssets && synchronizeAssets();

        this.setState({recipe, details, backup: null, ...newState}, this.syncAssets);
        this.resetLastSaved();

        onSaveRecipe(recipe, details);

        const { editSessionId } = this.state;

        if (savedOwnRecipeContext) {
            Analytics.savedOwnRecipe({
                'Context': savedOwnRecipeContext || 'Edit Recipe',
                'Edit Session ID': editSessionId,
                ...getRecipeEventProperties(recipe, details),
            });
        }
    }

    onChangeTitle = (ev) => {
        const { recipe } = this.state;

        recipe.title = ev.target.value;

        this.setState({recipe, dirty: true}, this.debounceStoreBackup);
    }

    onChangeServings = (servings) => {
        const { recipe } = this.state;

        recipe.servings = servings;

        this.setState({recipe, dirty: true}, this.analyzeAndBackup);
    }

    onChangeRecipe = (recipe, analyze = false) => {
        this.setState({recipe, dirty: true}, analyze ? this.analyzeAndBackup : null);
    }

    onChangeDetails = (details, analyze = false) => {
        this.setState({details, dirty: true}, analyze ? this.analyzeAndBackup : null);
    }

    onChangeRecipeImage = (image) => {
        const { recipe } = this.state;

        recipe.image = image;
        delete recipe.image_thumb;

        this.setState({recipe, dirty: true}, this.storeBackup);
    }

    addIngredientGroupCallback = () => {
        this.storeBackup();
        this.lastIngredientGroup.current.scrollIntoView({block: "center"});
    }

    addIngredientGroup = (groupIndex) => {
        const { details } = this.state;

        details.ingredients.splice(groupIndex + 1, 0, getIngredientGroupPrototype());

        this.setState({details, dirty: true}, this.addIngredientGroupCallback);
    }

    deleteIngredientGroup = (groupIndex) => {
        const { details } = this.state;

        details.ingredients.splice(groupIndex , 1);

        this.setState({details, dirty: true}, this.storeBackup);
    }

    addInstructionCallback = () => {
        this.storeBackup();
        this.lastInstruction.current.scrollIntoView();
    }

    addInstructionGroup = (groupIndex) => {
        const { details } = this.state;

        details.preparation.splice(groupIndex + 1, 0, getInstructionGroupPrototype());

        this.setState({details, dirty: true}, this.addInstructionCallback);
    }

    deleteInstructionGroup = (groupIndex) => {
        const { details } = this.state;

        details.preparation.splice(groupIndex , 1);

        this.setState({details, dirty: true}, this.storeBackup);
    }

    moveIngredient = (ingredient, afterIngredient, fallbackGroupIndex) => {
        const { details } = this.state;
        let sourceGroupIndex = -1, sourceIndex = -1;
        let destGroupIndex = -1, destIndex = -1;

        details.ingredients.forEach(
            (group, groupIndex) => {
                if (group.items.indexOf(ingredient) != -1) {
                    sourceIndex = group.items.indexOf(ingredient);
                    sourceGroupIndex = groupIndex;
                }

                if (group.items.indexOf(afterIngredient) != -1) {
                    destIndex = group.items.indexOf(afterIngredient);
                    destGroupIndex = groupIndex;
                }
            }
        );

        if (!afterIngredient) {
            destGroupIndex = fallbackGroupIndex;
            destIndex = details.ingredients[destGroupIndex].items.length;
        }

        // If we're not actually moving anything, don't bother resetting state
        if (sourceGroupIndex == destGroupIndex && sourceIndex == destIndex) {
            return;
        }

        if (sourceGroupIndex != -1 && sourceIndex != -1) {
            // Remove the item from its current spot
            details.ingredients[sourceGroupIndex].items.splice(sourceIndex, 1);
        }

        if (destGroupIndex != -1 && destIndex != -1) {
            // Add it to its new spot
            details.ingredients[destGroupIndex].items.splice(destIndex, 0, ingredient);
        }

        // Set the new state, but explicitly do not save right now.
        // The autosave is triggered by the DnD "drop"
        this.setState({details: details, dirty: true});
    }

    onImportRecipeComplete = (recipe, details) => {
        this.setState({recipe, details, dirty: true}, async () => {
            this.storeBackup();
            this.syncAssets();
        });
    }

    renderRecipeForm = (recipe, details) => {
        const { user, error, saving, canCancel, dirty, mismatches, backup, saveAsCopy, editSessionId } = this.state;
        const { saveDirtyBtnText, profile, importSuccess, importFailure } = this.props;
        const { isAndroid } = this.context;
        const { capabilities = {} } = user || {};

        const mealTypeTags = [
            'Breakfast', 'Breakfast Side Dish', 'Lunch', 'Lunch Side Dish', 'Main Dish', 'Side Dish', 'Snack',
            'Appetizer', 'Soup', 'Salad', 'Dessert', 'Beverage', 'Sandwich', 'No Cook'
        ];

        return (
            <>
                <div className="el-list-form">
                    {recipe.uuid && !this.shouldOverwriteRecipe(recipe) && !backup && !saveAsCopy ?
                        <Alert type="info">You can edit this recipe. A personalized copy will be saved to your favorites.</Alert>
                    : null}

                    {recipe.uuid && !this.shouldOverwriteRecipe(recipe) && !backup && saveAsCopy ?
                        <Alert type="info">Saving this recipe will create a new copy in your favorites.</Alert>
                    : null}

                    {backup && !importSuccess && !importFailure ?
                        <Alert dontWrapChildElements={true} type="info" buttonText="restore" onClickButton={this.restoreFromBackup} description={`Restore last unsaved version from ${moment(backup.ts).format('ddd, MMM Do')}?`}>
                            <button className="remove-backup-btn" onClick={this.removeBackup}>
                                <i className="icon-close-x" />
                            </button>
                        </Alert>
                    : null}

                    {importSuccess ?
                        <Alert type="info" title="Next, confirm recipe details." description="Add, edit, or remove ingredients as needed, and ensure grocery items listed match the ingredients." />
                    : null}

                    {importFailure ?
                        <Alert type="error" description="Unable to import recipe, please try again or enter it manually." />
                    : null}

                    <div className="recipe-fields">
                        <div className="el-list-field el-labeled-input">
                            <div className="with-label recipe-title">
                                <label>Recipe Name:</label>
                                <input type="text" value={recipe.title} placeholder="Enter recipe title" onChange={this.onChangeTitle} />
                            </div>
                        </div>

                        <div className="el-list-field el-labeled-input">
                            <div className="with-label recipe-photo">
                                <label>Photo:</label>
                                <EditImage className="feather feather-camera" image={recipe.image} onChangeImage={this.onChangeRecipeImage} popupClassName="recipe-editor-photo-btn-popup" popupPosition="el-popup-top-right" showPopup={isAndroid}/>
                            </div>
                        </div>

                        <div className="el-list-field el-labeled-input">
                            <div className="with-label recipe-servings">
                                <label>This Recipe Makes:</label>
                                <EditServings servings={recipe.servings} onChangeServings={this.onChangeServings} />
                            </div>
                        </div>

                        <div className="el-list-field el-labeled-input">
                            <div className="with-label recipe-timings">
                                <label>Total Time:</label>
                                <EditTimings recipe={recipe} onChangeRecipe={this.onChangeRecipe} />
                            </div>
                        </div>

                        <div className="el-list-field el-labeled-input">
                            <div className="with-label recipe-tags">
                                <label>Meal Type</label>
                                <EditTags recipe={recipe}
                                    onChangeRecipe={this.onChangeRecipe}
                                    sectionTitle="Meal Types"
                                    sectionText={<span>Please select which meals this recipe will be good for.<br />You can select more than one.</span>}
                                    allowedTags={mealTypeTags} />
                            </div>
                        </div>

                        <div className="el-list-field el-labeled-input">
                            <div className="with-label recipe-tags">
                                <label>Skill Level</label>
                                <EditTags recipe={recipe}
                                    onChangeRecipe={this.onChangeRecipe}
                                    sectionTitle="Skill Level"
                                    allowedTags={['Beginner', 'Intermediate', 'Advanced']} />
                            </div>
                        </div>
                    </div>

                    <div className="nutrition-analysis-container">
                        <NutritionAnalysisModal profile={profile} recipe={recipe} mismatches={mismatches} />
                    </div>

                    <section className="edit-recipe-groups ingredients">
                        {details.ingredients.map((group, i) => (
                            <div key={i} ref={i === details.ingredients.length - 1 ? this.lastIngredientGroup : null}>
                                <EditIngredientGroup group={group} groupIndex={i}
                                    recipe={recipe} details={details}
                                    moveIngredient={this.moveIngredient}
                                    addIngredientGroup={this.addIngredientGroup}
                                    deleteIngredientGroup={details.ingredients.length > 1 ? this.deleteIngredientGroup : null}
                                    onChangeDetails={this.onChangeDetails}
                                    onChangeRecipe={this.onChangeRecipe} />
                            </div>
                        ))}
                    </section>

                    <section className="edit-recipe-groups instructions">
                        {details.preparation.map((group, i) => (
                            <div key={i} ref={i === details.preparation.length - 1 ? this.lastInstruction : null}>
                                <EditInstructionGroup group={group} groupIndex={i}
                                    recipe={recipe} details={details}
                                    addInstructionGroup={this.addInstructionGroup}
                                    deleteInstructionGroup={details.preparation.length > 1 ? this.deleteInstructionGroup : null}
                                    onChangeDetails={this.onChangeDetails}
                                    onChangeRecipe={this.onChangeRecipe} />
                            </div>
                        ))}
                    </section>
                </div>

                {error ? <Alert type="error">{error}</Alert> : null}

                <footer className="recipe-editor-footer el-modal1-container-footer">
                    <button className="el-modal-cancel-btn" onClick={this.closeModal}>
                        {canCancel ? 'cancel' : 'close'}
                    </button>

                    <button disabled={saving} className={saving ? "save-btn el-modal-ok-btn el-btn-icon-left" : "save-btn el-modal-ok-btn"}
                        onClick={dirty ? this.onSaveRecipe : this.closeModal}>
                        {saving ? <i className="icon-spinner2" /> : null}
                        {saveDirtyBtnText}
                        {!capabilities.create_content ?
                            <i className="icon-lock" />
                        : null}
                    </button>
                </footer>
            </>
        );
    }

    closeModal = () => {
        const { closeModal } = this.props;

        closeModal();
    }

    render = () => {
        const { loading, recipe, details } = this.state;

        return (
            <div className="recipe-editor">
                {recipe ? this.renderRecipeForm(recipe, details) : null}
                {loading ? <div className="loading"><p>loading...</p><i className="icon-spinner2" /></div> : null}
            </div>
        );
    }
}
