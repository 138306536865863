'use strict';

import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import indexBy from 'lodash.indexby';
import FoodUnitsSelector from '../Foods/FoodUnitsSelector.react';
import { roundForHumans } from '../../utils/Math';
import { getNutrientsForMeals, nutrNoSortCmp } from '../../utils/Nutrition';
import { getPrimaryMeal } from '../../utils/Meals';
import { getNutrientsToDisplay } from '../../pro/utils/Patients';
import allNutrients from '../../tables/nutrients';
import './FoodUnitsSelectorModal.scss';
import Analytics from '../../utils/Analytics';

export default class ChangePortionsModal extends Component {
    static propTypes = {
        food: PropTypes.object,
        meal: PropTypes.object,
        howMuchQuestion: PropTypes.node,
    };

    static defaultProps = {
        howMuchQuestion: "How much did you eat?",
    };

    static contextTypes = {
        user: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
            nutrients: {},
            disableSaveBtn: false,
            howMuchQuestion: this.props.howMuchQuestion,
            food: this.props.food,
            meal: {...this.props.meal},
            onChangeAmount: this.props.onChangeAmount,
            closeChangePortionsModal: this.props.closeChangePortionsModal,
        };

        this.foodUnitsSelectorRef = createRef();
    }

    componentDidMount = () => {
        this.syncAssets();
    }

    syncAssets = () => {
        const { meal, food } = this.state;
        const { user } = this.context;

        const contents = indexBy([food], 'uuid');
        const { titles } = getPrimaryMeal([meal], contents, contents);
        const nutrients = getNutrientsForMeals([meal], contents, user?.portion || 1, null);

        this.setState({nutrients, titles});
    }

    UNSAFE_componentWillReceiveProps = () => {
        this.syncAssets();
    }

    closeModal = () => {
        const { closeModal } = this.props;
        closeModal();
    }

    onSave = () => {
        const { closeModal, meal, profile } = this.props;

        if (meal.logged_amount <= 0) {
            this.setState({ error: 'Please enter a valid amount.' });
            return;
        }

        const prev_logged_amount = meal?.logged_amount;
        const prev_logged_unit = meal?.logged_unit;

        const foodUnitsSelectorInstance = this.foodUnitsSelectorRef.current;

        if (foodUnitsSelectorInstance) {
            const { onChangeAmount } = this.props;
            let { units, logged_portion, logged_unit, logged_amount } = foodUnitsSelectorInstance.state;

            // Parse as a float
            logged_amount = parseFloat(logged_amount) || 0;

            // Figure out the amount of grams or milliliters from the unit and the amount.
            let unit = units.find(unit => unit.description === logged_unit);

            if (unit) {
                onChangeAmount(
                    logged_portion,
                    unit.description,
                    logged_amount,
                    unit.grams ? (unit.grams / unit.amount) * logged_amount : null,
                    unit.milliliters ? (unit.milliliters / unit.amount) * logged_amount : null,
                    unit
                );

                Analytics.changeMealPortionSize({
                    amount: logged_amount,
                    unit: unit.description,
                    prevAmount: prev_logged_amount,
                    prevUnit: prev_logged_unit,
                    patient: profile?.type === 'patient' ? profile.uuid : null,
                });
                foodUnitsSelectorInstance.resetInihibitUpdate();
            } else {
                onChangeAmount(logged_portion, logged_unit, logged_amount, null, null);

                Analytics.changeMealPortionSize({
                    amount: logged_amount,
                    unit: logged_unit,
                    prevAmount: prev_logged_amount,
                    prevUnit: prev_logged_unit,
                    patient: profile?.type === 'patient' ? profile.uuid : null,
                });
                foodUnitsSelectorInstance.resetInihibitUpdate();
            }
        }

        closeModal();
    }

    disableSaveBtn = (value) => {
        this.setState({disableSaveBtn: value});
    }

    onChangeAmount = (portion, unit, amount, grams, milliliters) => {
        const { meal } = this.state;

        meal.logged_portion = portion;
        meal.logged_amount = amount;
        meal.logged_unit = unit;
        meal.logged_grams = grams;
        meal.logged_milliliters = milliliters;

        this.setState({meal: meal});
        this.syncAssets();
    }

    render = () => {
        const { user } = this.context;
        const {
            nutrients, disableSaveBtn, howMuchQuestion,
            food, meal, closeChangePortionsModal
         } = this.state;
        const { hide_nutrition = false, rd_override = false } = (user && user.preferences) || {};

        // Get nutrients to display, do not factor by portion size (already accounted for by getNutrientsForMeals)
        const toDisplay = getNutrientsToDisplay({...user, portion: 1}, nutrients, null);
        const entered = Object.keys(toDisplay).filter(nutrNo => typeof toDisplay[nutrNo].v === 'number');

        const excludeNutrNos = ['208'];

        return (
            <Modal isOpen={true}
                closeModal={this.closeModal}
                contentLabel="How much did you Eat?"
                className="log-portions-modal"
                overlayClassName="feed-modal-overlay log-portions-overlay"
                closeTimeoutMS={250}>
                <div className="log-portions-modal-container">
                    <header>
                        <button className="close-modal-btn" onClick={this.closeModal}>
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>
                    </header>

                    <section className="log-portions-body">
                        {(!hide_nutrition || rd_override === true) && entered.length == 0 ?
                            <div className="nutrients-in-portions">
                                <div className="cal">
                                    <h4>&mdash;</h4>
                                    <h5>calories</h5>
                                </div>

                                <ul className="rest">
                                    <li>This food's nutrition has not been entered yet.</li>
                                </ul>
                            </div>
                        : null}

                        {(!hide_nutrition || rd_override === true) && entered.length > 0 ?
                            <div className="nutrients-in-portions">
                                <div className="cal">
                                    <h4>{roundForHumans(nutrients[208] || 0)}</h4>
                                    <h5>calories</h5>
                                </div>

                                <ul className="rest">
                                    {Object.keys(toDisplay).filter(nutrNo => !excludeNutrNos.includes(nutrNo))
                                                           .sort(nutrNoSortCmp)
                                                           .map((nutrNo, i) => {
                                        const nutr = toDisplay[nutrNo];
                                        return (
                                            <li key={i} data-nutrno={nutrNo}>
                                                <em>{allNutrients[nutrNo].NutrDesc}</em><span>{typeof nutr.v === 'number' ? roundForHumans(nutr.v) : <span>&mdash;</span>} {allNutrients[nutrNo].Units}</span>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        : null}
                    </section>

                    <footer>
                        <h2>{howMuchQuestion}</h2>

                        <ul className="unit-selector-list">
                            <li>
                                <FoodUnitsSelector
                                    ref={this.foodUnitsSelectorRef}
                                    food={food}
                                    meal={meal}
                                    onChangeAmount={this.onChangeAmount}
                                    closeChangePortionsModal={closeChangePortionsModal}
                                    disableSaveBtn={this.disableSaveBtn}
                                    showAbove
                                />
                            </li>
                        </ul>
                        <button className="ok-btn" disabled={disableSaveBtn} onClick={this.onSave}>Ok</button>
                    </footer>
                </div>
            </Modal>
        );
    }
}
