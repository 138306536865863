'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import ImgResized from '../../Widgets/ImgResized.react';
import PublisherSubmenu from '../../Publisher/Dashboard/PublisherSubmenu.react';

import { getBackgroundImageUrl } from '../../../utils/Image';
import { getConfig } from '../../../utils/Env';

import './PageCard.scss';

export default class PageCard extends Component {
    static propTypes = {
        page: PropTypes.object,
    };

    static contextTypes = {
        isPublisher: PropTypes.bool,
        onSelectPage: PropTypes.func,
        showPageDetails: PropTypes.func,
    };

    onClick = (ev) => {
        const { onSelectPage, showPageDetails } = this.context;
        const { page } = this.props;

        // if (ev.button == 1 || ev.ctrlKey) {
        //     return;
        // }

        if (onSelectPage) {
            ev.preventDefault();
            onSelectPage(page);
        } else if (showPageDetails) {
            ev.preventDefault();
            showPageDetails(page);
        }
    }

    link = (element) => {
        const { isPublisher } = this.context;
        const { page } = this.props;

        if (isPublisher) {
            return <Link to={`/admin/pages/${page.uuid}`}>{element}</Link>;
        }

        if (page.url_host === 'www.eatlove.is' || page.url_host === getConfig('www_host')) {
            return <Link to={page.url_path} onClick={this.onClick}>{element}</Link>
        }

        return <a href={`https://${page.url_host}${page.url_path}`}>{element}</a>
    }

    render = () => {
        const { isPublisher } = this.context;
        const { page } = this.props;

        if (!page) {
            return <span />
        }

        const imageProps = {};

        if (page.image_thumb) {
            imageProps.style = {
                backgroundImage: 'url(data:image/jpeg;base64,' + page.image_thumb + ')',
            };

            imageProps['data-has-thumb'] = true;
        } else if (page.image) {
            imageProps.style = {
                backgroundImage: getBackgroundImageUrl(page.image, 300, 180),
            };
        }

        return (
            <div className="page-card">
                {page.image
                    ? this.link(<div className="card-image" {...imageProps} />)
                    : this.link(<div className="card-image"><i className="icon-logo2"><span className="path1" /><span className="path2" /></i></div>)
                }

                {this.link(<div className="card-text">
                    <h3>{page.title}</h3>
                    <p className="tags">{page.tags.join(', ')}</p>
                    <p className="description">{page.description}</p>
                    {isPublisher ?
                        <div className={page.status === 'live' ? 'publish-status live' : 'publish-status'}>
                            {page.status === 'live' ? 'live' : 'draft'}
                        </div>
                    : null}
                </div>)}

                {isPublisher ?
                    <PublisherSubmenu resource={page} />
                : null}
            </div>
        );
    }
}
