'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import allConditions from '../../../tables/conditions';

import './Conditions.scss';

export default class Conditions extends Component {
    static propTypes = {
    };

    renderHumanList = (array) => {
        if (array.length == 1) {
            return array.join('');
        }

        const last = array.pop();

        return array.join(', ') + ' and ' + last;
    }

    renderCondition = (data, i) => {
        const condition = allConditions.filter(c => c.name == data.name)[0];

        if (!condition) {
            return <li key={i}><em>{data.name}</em></li>
        }

        if (condition.update_method === 'pregnancy') {
            return (
                <li key={i}><em>{condition.name}</em>: due date <em>{moment(data.next_update).format('MMMM Do YYYY')}</em></li>
            );
        }

        return (
            <li key={i}><em>{condition.name}</em></li>
        );
    }

    render() {
        const { patient } = this.props;
        const { conditions = [], completed } = patient;

        return (
            <div className="patient-nutrition-prescription patient-conditions">
                <ul>
                    {/*patient.risk_level ?
                        <li><em>Priority Level</em>: {patient.risk_level}</li>
                    : null*/}
                    {conditions.map(this.renderCondition)}
                </ul>
            </div>
        );
    }
}
