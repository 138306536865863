'use react';

import { Component } from 'react';
import PropTypes from 'prop-types';

import './PatientSelectItem.scss';

export default class PatientSelectItem extends Component {
    render = () => {
        const { item } = this.props;

        return (
            <div className="patient-search-combo-item">
                <i className={item.gender === 'male' ? 'icon-male2' : 'icon-female2'} />
                <div className="text-info">
                    <p>{item.first_name} {item.last_name}</p>
                    <p className="conditions">{(item.conditions || []).map(c => c.name).join(', ')}</p>
                </div>
            </div>
        );
    }
}
