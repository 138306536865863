import React from 'react';
import PropTypes from 'prop-types';
import './Button.scss';
import Icon, { iconList } from './Icon.react';

/**
 * Button component
 *
 * @param {Object} props - The component props
 * @param {string} [props.title] - The button title for accessibility
 * @param {Function} [props.onClick] - Click handler function
 * @param {React.ReactNode} [props.children] - The button content
 * @param {boolean} [props.isLoading=false] - Whether the button shows a loading state
 * @param {boolean} [props.disabled=false] - Whether the button is disabled
 * @param {string} [props.dataTestId] - The test ID for testing
 * @param {'default' | small' | 'medium' | 'large'} [props.size='default'] - The button size
 * @param {'default' |'orange' | 'green' | 'raspberry' | 'grayish-blue' | 'gray' | 'white' | 'light-raspberry'} [props.color='default'] - The button color
 * @param {'default' | 'outline'} [props.variant='default'] - The button variant
 * @param {'default' | 'wide' | 'medium-wide'} [props.format='default'] - The button style type (e.g., wide)
 * @param {string} [props.icon] - The name of the icon to display
 * @param {'left' | 'right'} [props.iconPosition='left'] - The position of the icon
 * @param {string} [props.className] - Additional custom class names for the button
 * @returns {JSX.Element} The rendered button component
 */
const Button = ({
    title,
    onClick,
    children,
    isLoading = false,
    disabled = false,
    dataTestId,
    size = 'default',
    color = 'default',
    variant = 'default',
    format = 'default',
    icon,
    iconPosition = 'left',
    className,
}) => {
    const isIconOnly = !children && !icon;

    const actualSize = size === 'default' ? 'medium' : size;
    const actualColor = color === 'default' ? 'raspberry' : color;

    let buttonClasses =
        className ||
        `el-btn el-${actualSize}-btn ${
            variant === 'default' ? `el-${actualColor}-btn` : `el-${actualColor}-outline-btn`
        }`;

    if (!className && format !== 'default') {
        buttonClasses += ` el-${format}-btn`;
    }

    if (!className && isIconOnly) {
        buttonClasses += ' el-btn-icon-alone';
    }

    if (icon && icon.trim() && iconPosition === 'left' && children) {
        buttonClasses += ' el-btn-icon-left';
    }

    return (
        <button data-testid={dataTestId} title={title} onClick={onClick} className={buttonClasses} disabled={disabled}>
            {icon && icon.trim() && iconPosition === 'left' && <Icon name={icon} />}
            {!isIconOnly && children}
            {isLoading && <Icon name="feather-loader" />}
            {icon && icon.trim() && iconPosition === 'right' && <Icon name={icon} />}
        </button>
    );
};

Button.propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.node,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    dataTestId: PropTypes.string,
    size: PropTypes.oneOf(['default', 'small', 'medium', 'large']),
    color: PropTypes.oneOf([
        'default',
        'orange',
        'green',
        'raspberry',
        'grayish-blue',
        'gray',
        'white',
        'light-raspberry',
    ]),
    variant: PropTypes.oneOf(['default', 'outline']),
    format: PropTypes.oneOf(['default', 'wide', 'medium-wide']),
    icon: PropTypes.oneOf([...iconList, '']),
    iconPosition: PropTypes.oneOf(['left', 'right']),
    className: PropTypes.string,
};

Button.defaultProps = {
    isLoading: false,
    disabled: false,
    size: 'default',
    color: 'default',
    variant: 'default',
    format: 'default',
    iconPosition: 'left',
    className: '',
};

export default Button;
