'use strict';

import * as Sentry from '@sentry/react';
import { match } from 'react-router';
import { getConfig } from './Env';
import UserStore from '../stores/UserStore';

const DEBUG = false;
const TARGETS = [/^https:\/\/(.*)\.eatlove.*$/, /^https:\/\/(.*)\.chewba.*$/, 'localhost', 'capacitor://localhost'];

/**
 * Syncs the current user information with Sentry.
 *
 * Retrieves the user from the application store and updates Sentry's user data.
 * Existing values are preserved if not provided by the store.
 *
 * User properties updated include:
 * - `id`: User's UUID, if available.
 * - `email`: User's email address.
 * - `username`: User's name.
 * - `isInternal`: Flag indicating if the user is internal.
 * - `isPowerUser`: Flag indicating if the user is a power user.
 */
function setUserInSentry() {
    const currentUser = Sentry.getCurrentHub().getScope().getUser() || {};
    const userFromStore = UserStore.getUser();
    if (userFromStore) {
        const updatedUser = {
            ...currentUser,
            id: userFromStore.uuid || currentUser.id,
            email: userFromStore.email || currentUser.email,
            username: userFromStore.name || currentUser.name,
            isInternal: userFromStore.is_internal ?? currentUser.isInternal,
            isPowerUser: userFromStore.is_power ?? currentUser.isPowerUser,
        };
        Sentry.setUser(updatedUser);
    }
}

/**
 * Processes Sentry events before they are sent to the server.
 * This function filters out specific error messages and descriptions, preventing irrelevant or known issues
 * from being captured in Sentry.
 *
 * @function beforeSend
 *
 * @param {Object} event - The Sentry event to be processed.
 * @param {Object} hint - Contains additional information about the event, such as the original exception and context.
 * @param {Error} hint.originalException - The original exception object related to the event.
 * @param {Object} hint.captureContext - Context that provides additional data about the event, such as descriptions.
 *
 * @returns {Object|boolean} - Returns the event if it should be sent to Sentry, or `false` if it should be ignored.
 *
 * @todo Refactor this function to improve the event filtering mechanism.
 *       Currently, filtering is based on hardcoded error messages, which is not a scalable or robust solution.
 *       A better approach might involve categorizing errors or leveraging Sentry’s error metadata.
 *       Events should ideally be filtered by a more structured and dynamic criteria rather than specific message text.
 *
 * Technical Debt: This message-based filtering method is prone to breaking if message texts change slightly or if new
 * edge cases arise that aren't covered in the array. The approach should be revisited and improved in the future.
 */
export function beforeSend(event, hint) {
    const { originalException, captureContext } = hint;

    const ignoreMessages = [
        'Load failed',
        'Connection is closing',
        'InvalidStateError: The object is in an invalid state.',
        'The object is in an invalid state.',
        'service-unavailable - No module is handling this query',
        "null is not an object (evaluating 't.socket.write')",
        'The operation couldn’t be completed. (kCLErrorDomain error 1.)',
        'InvalidStateError: The object is in an invalid state.',
        'TimeoutError: No error message',
        'Extension context invalidated.',
        'WebSocket ECONNERROR wss://chat.eatlove.is:5443/ws',
        't.socket is null',
        'An attempt was made to use an object that is not, or is no longer, usable',
        "Failed to execute 'send' on 'WebSocket': Still in CONNECTING state.",
        "Cannot read properties of null (reading 'write')",
        'remote-server-not-found - DNS lookup failed: timeout',
        "null is not an object (evaluating 'e.target.closest')",
        'null has no properties',
    ];

    if (ignoreMessages.includes(originalException?.message) || !originalException?.message) {
        return false;
    }

    // Ignore errors fetching from any of our own services.
    const ignoreDescriptions = [
        'Fetching data from ' + getConfig('users_api') + '/',
        'Fetching data from https://' + getConfig('www_host') + '/',
        'Fetching data from https://' + getConfig('pro_host') + '/',
        'Fetching data from ' + getConfig('recipe_api') + '/',
    ];

    const { description = '' } = captureContext || {};

    if (
        typeof description?.startsWith === 'function' &&
        ignoreDescriptions.some((prefix) => description.startsWith(prefix))
    ) {
        return false;
    }

    return event;
}

/**
 * Initializes Sentry error and performance monitoring with custom configurations.
 *
 * @param {Object} config - Configuration object.
 * @param {Array} config.routingInstrumentation - Array containing routing instrumentation functions.
 */
export function setup({ routingInstrumentation }) {
    const environment = getConfig('environment');
    const release = getConfig('build_number');
    const dsn = getConfig('sentry_dsn');
    const tracesSampleRate = parseFloat(getConfig('sentry_traces_sample_rate') ?? 0.1);
    const profilesSampleRate = parseFloat(getConfig('sentry_profiles_sample_rate') ?? 0.1);

    Sentry.addTracingExtensions();
    Sentry.init({
        dsn,
        environment,
        debug: DEBUG,
        release,
        integrations: [
            new Sentry.BrowserTracing({
                tracePropagationTargets: TARGETS,
                routingInstrumentation: Sentry.reactRouterV3Instrumentation(...[...routingInstrumentation, match]),
            }),
            new Sentry.Integrations.GlobalHandlers({
                onerror: true,
                onunhandledrejection: true,
            }),
            new Sentry.BrowserProfilingIntegration(),
        ],
        beforeSend: beforeSend,
        enableTracing: true,
        tracesSampler: (samplingContext) => {
            setUserInSentry();
            const user = UserStore.getUser();
            return user && (user.is_internal || user.is_power) ? 1.0 : tracesSampleRate;
        },
        tracesSampleRate,
        profilesSampleRate,
    });
}

/**
 * Captures a synchronous function's execution as a Sentry span, attaching it to the currently active global transaction.
 * If no active transaction is found, it executes the function without attaching a span.
 *
 * @param {string} name - The name or operation identifier for the span (e.g., "database_query").
 * @param {Function} func - The synchronous function to execute and capture within the span.
 * @returns {*} - The result of the executed function.
 * @throws {*} - Throws the error from the executed function if it fails.
 * @example
 * captureSpan("database_query", () => {
 *   queryDatabase();
 * });
 */
export function captureSpan(name, func) {
    const activeTransaction = Sentry.getCurrentHub().getScope().getTransaction();

    if (!activeTransaction) {
        return func();
    }

    const span = activeTransaction.startChild({ op: name });

    try {
        const result = func();
        span.finish();
        return result;
    } catch (error) {
        Sentry.captureException(error);
        span.finish();
        throw error;
    }
}

/**
 * Captures an asynchronous function's execution as a Sentry span, attaching it to the currently active global transaction.
 * If no active transaction is found, it executes the async function without attaching a span.
 *
 * @param {string} name - The name or operation identifier for the span (e.g., "api_call").
 * @param {Function} asyncFunc - The asynchronous function to execute and capture within the span.
 * @returns {Promise<*>} - A promise that resolves with the result of the async function.
 * @throws {*} - Throws the error from the async function if it fails.
 * @example
 * await captureAsyncSpan("api_call", async () => {
 *   const data = await fetchDataFromAPI();
 *   return data;
 * });
 */
export async function captureAsyncSpan(name, asyncFunc) {
    const activeTransaction = Sentry.getCurrentHub().getScope().getTransaction();

    if (!activeTransaction) {
        return await asyncFunc();
    }

    const span = activeTransaction.startChild({ op: name });

    try {
        const result = await asyncFunc();
        span.finish();
        return result;
    } catch (error) {
        Sentry.captureException(error);
        span.finish();
        throw error;
    }
}

/**
 * Retrieves the current active Sentry transaction, if available.
 *
 * @returns {Transaction|null} The active Sentry transaction, or null if no active transaction is found.
 */
export function getTransaction() {
    const activeTransaction = Sentry.getCurrentHub().getScope().getTransaction();
    if (!activeTransaction) {
        return null;
    }
    return activeTransaction;
}

// export function captureException (exception) {
//
// }
//
// export function captureMessage (message) {
//
// }

export default {
    setup,
    getTransaction,
    captureSpan,
    captureAsyncSpan,
    // captureException,
    // captureMessage,
    withProfiler: Sentry.withProfiler,
};
