'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import UserStore from '../../stores/UserStore';

import Popup from './Popup.react';
import CopyToClipboard from '../Dashboard/Header/CalendarSync/CopyToClipboard.react';

import { getConfig } from '../../utils/Env';
import Analytics from '../../utils/Analytics';

import './SharePopup.scss';

export default class SharePopup extends Component {
    static propTypes = {
        recipe: PropTypes.object,
        collection: PropTypes.object,
        food: PropTypes.object,
        plan: PropTypes.object,
        path: PropTypes.string,
        buttonText: PropTypes.string,
        popupText: PropTypes.string,
        dropdownBtnClass: PropTypes.string,
        positionClassName: PropTypes.string,
        onClick: PropTypes.func,
        loading: PropTypes.bool,
    };

    static defaultProps = {
        buttonText: "share",
        popupText: "To share, copy and paste this URL into an email, message or social media post.",
        dropdownBtnClass: null,
        loading: false,
    };

    render = () => {
        const { recipe, collection, food, plan, path, className, buttonText, popupText, dropdownBtnClass, positionClassName, onClick, loading } = this.props;

        if (!recipe && !collection && !food && !plan && !path) {
            return <span />
        }

        let shareUrl;

        if (recipe) {
            shareUrl = 'https://' + getConfig('www_host') + `/recipes/${recipe.uuid}`;
        }

        if (collection) {
            shareUrl = 'https://' + getConfig('www_host') + `/collections/${collection.uuid}`;
        }

        if (food) {
            shareUrl = 'https://' + getConfig('www_host') + `/foods/${food.uuid}`;
        }

        if (plan) {
            shareUrl = 'https://' + getConfig('www_host') + `/menus/${plan.uuid}`;
        }

        if (path) {
            shareUrl = path.includes('https://') ? path : 'https://' + path;
        }
        
        return  (
            <Popup positionClassName={positionClassName} dropdownBtnClass={dropdownBtnClass} className={classNames("share-popup", className)} button={<><i onClick={onClick} className="feather feather-share" /> <em>{buttonText}</em></>}>
                {loading ? <i className="icon-spinner"/>
                : (<div className="share-popup-content">
                    <p>{popupText}</p>

                    <CopyToClipboard value={shareUrl} />
                </div>)}
            </Popup>
        );
    }
}
