'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

import './GoogleMap.scss';

import UserStore from '../../stores/UserStore'
import { getConfig } from '../../utils/Env';
import { Geolocation } from '@capacitor/geolocation';

export default class GoogleMap extends Component {
    static propTypes = {
        showAutocomplete: PropTypes.bool,
        defaultZoom: PropTypes.number,
    };

    static defaultProps = {
        showAutocomplete: false,
        defaultZoom: 13,
    };

    constructor(props, context) {
        super(props, context);

        const user = UserStore.getUser();
        const { lat, lon } = (user && user.location && user.location.location) || {};

        this.currentLocation = {lat, lon};

        this.state = {
            keywords: '',

            results: [],
            total: 0,
        };
    }

    componentDidMount = () => {
        this.initializeGoogleMapsJs();
        this.syncCurrentLocation();
    }

    map = null
    service = null
    markers = []
    autocomplete = null

    containerEl = null
    autocompleteEl = null
    infoContentEl = null

    syncCurrentLocation = async () => {
        let position = null;

        try {
            // Do we have our capacitor plugin? This should work nearly everywhere.
            if (Geolocation) {
                position = await Geolocation.getCurrentPosition();

            // Try HTML5 geolocation instead.
            } else if (navigator.geolocation) {

                position = await new Promise((accept) => {
                    navigator.geolocation.getCurrentPosition(loc => {
                        accept(loc);
                    });
                });
            } else {
                return;
            }
        } catch (exp) {
            return; // geolocation failed
        }

        if (position) {
            this.currentLocation = {
                lat: position.coords.latitude,
                lon: position.coords.longitude,
            };
        }

        // Has the map initialized first? Center it to the current location
        if (this.map) {
            // This will trigger a bounds change, which will execute search
            this.map.setCenter({lat: this.currentLocation.lat, lng: this.currentLocation.lon});
        }

        const { onLocationFound } = this.props;
        onLocationFound && onLocationFound(this.currentLocation);
    }

    initializeGoogleMapsJs = () => {
        const jsAsset = "https://maps.googleapis.com/maps/api/js?key=" + getConfig('google_maps_key') + "&libraries=geometry,drawing,places";

        // Is our jsAsset already loaded?
        if (window.google && window.google.maps) {
            return;
        }

        const script = document.createElement('script');
        script.async = false;
        script.src = jsAsset;
        script.addEventListener('load', this.initializeMap.bind(this));

        document.getElementsByTagName('body')[0].appendChild(script);
    }

    realizeMapContainer = (containerEl) => {
        this.containerEl = containerEl;

        this.initializeMap();
    }

    realizeAutocomplete = (autocompleteEl) => {
        this.autocompleteEl = autocompleteEl;

        this.initializeMap();
    }

    realizeInfoWindowContents = (infoContentEl) => {
        this.infoContentEl = infoContentEl;

        this.initializeMap();
    }

    initializeMap = () => {
        const { defaultZoom, onMapInitalized } = this.props;

        if (this.map || !this.containerEl || !this.autocompleteEl || !this.infoContentEl || !(window.google && window.google.maps)) {
            return;
        }

        const { lat, lon } = this.currentLocation || {};

        this.map = new window.google.maps.Map(this.containerEl, {
            center: {lat, lng: lon},
            zoom: defaultZoom,
            fullscreenControl: false,
            disableDefaultUI: true,
            gestureHandling: 'greedy'
        });

        this.map.addListener('bounds_changed', this.onBoundsChanged);
        let idler = this.map.addListener('idle', () => {
            onMapInitalized && setTimeout(onMapInitalized, 50);

            window.google.maps.event.removeListener(idler);
        });

        this.service = new window.google.maps.places.PlacesService(this.map);

        this.infowindow = new window.google.maps.InfoWindow();
        this.infowindow.setContent(this.infoContentEl);
        this.infowindow.addListener('closeclick', this.onCloseInfoWindow);

        this.autocomplete = new window.google.maps.places.Autocomplete(this.autocompleteEl);
        this.autocomplete.bindTo('bounds', this.map);
        this.autocomplete.setFields(['address_components', 'geometry', 'icon', 'name']);
        this.autocomplete.addListener('place_changed', this.onPlaceChanged);

        $(document).on({
            'DOMNodeInserted': function () {
                const elements = $('.pac-item, .pac-item span', this);
                elements.addClass('needsclick');
            }
        }, '.pac-container');
    }

    onCloseInfoWindow = () => {
        this.setState({showInfoWindow: false, loadingInfoWindow: false, placeDetails: null, locationInfo: null});
    }

    createCurrentLocationMarker = (icon = null) => {
        if (!this.map) {
            return false;
        }

        if (icon === null) {
            icon = 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png';
        }

        const { lat, lon } = this.currentLocation || {};

        let marker = {
            marker: new window.google.maps.Marker({
                position: {lat, lng: lon},
                map: this.map,
                title: 'My Location',
                animation: window.google.maps.Animation.DROP,
                icon,
            }),
            infoContents: <p>My Current Location</p>,
        };

        marker.marker.addListener('click', () => this.onShowPlaceInfo(marker));

        this.markers.push(marker);

        return marker;
    }

    addMarker = (position, title, infoContents, icon = null) => {
        if (!this.map) {
            return false;
        }

        if (icon === null) {
            icon = 'https://maps.google.com/mapfiles/ms/icons/red-dot.png';
        }

        let marker = {
            marker: new window.google.maps.Marker({
                position,
                map: this.map,
                title,
                animation: window.google.maps.Animation.DROP,
                icon,
            }),
            infoContents,
        };

        marker.marker.addListener('click', () => this.onShowPlaceInfo(marker));

        this.markers.push(marker);

        return marker;
    }

    deleteMarkers = () => {
        if (!this.markers || !this.map) {
            return;
        }

        this.markers.forEach(({marker}) => {
            marker.setMap(null);
        });

        this.markers = [];
    }

    onShowPlaceInfo = ({marker, infoContents}) => {
        // Get place details for this place ID
        this.infowindow.open(this.map, marker);

        this.setState({showInfoWindow: infoContents});
    }

    getBounds = () => {
        if (!this.map) {
            return false;
        }

        const bounds = this.map.getBounds();

        return bounds;
    }

    onPlaceChanged = () => {
        const place = this.autocomplete.getPlace();
        if (place.geometry) {

            if (place.geometry.viewport) {
                this.map.fitBounds(place.geometry.viewport);
            } else {
                this.map.setCenter(place.geometry.location);
                this.map.setZoom(17);  // Why 17? Because it looks good.
            }

            this.setState({keywords: this.autocompleteEl.value}, this.props.onPlaceChanged);
        }
    }

    onBoundsChanged = () => {
        const { onBoundsChanged } = this.props;

        if (!this.map) {
            return;
        }

        const bounds = this.map.getBounds();

        onBoundsChanged && onBoundsChanged(bounds, this.state.showInfoWindow ? true : false);
    }

    onChangeAutocomplete = (ev) => {
        this.setState({keywords: ev.target.value});
    }

    render = () => {
        const { keywords, showInfoWindow } = this.state;
        const { showAutocomplete, showSearchHere, onSearchHere } = this.props;

        return (
            <div className="google-map-container" data-show-autocomplete={showAutocomplete} data-show-search-here={showSearchHere}>
                <div className="autocomplete-container">
                    <input className="autocomplete" type="text"
                        value={keywords} onChange={this.onChangeAutocomplete}
                        placeholder="Current Location" ref={this.realizeAutocomplete} />
                </div>
                <div className="map-container" ref={this.realizeMapContainer}></div>
                <div data-visible={showInfoWindow ? true : false} className="info-window-contents" ref={this.realizeInfoWindowContents}>
                    {showInfoWindow}
                </div>
                <button className="search-here-btn" onClick={onSearchHere}>Search This Area</button>
            </div>
        );
    }
}
