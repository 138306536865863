'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash.groupby';
import { Link } from 'react-router';

import Select from '../../../pro/components/Widgets/Select.react';
import TestimonialContainer from './TestimonialContainer.react';

import allFeatures from '../../../tables/features';

export default class PermitPicker extends Component {

    static contextTypes = {
        isMobile: PropTypes.bool,
        viewportWidth: PropTypes.number,
        isPro: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        const { permits } = props;
        const paidPermits = permits.filter(p => p.billing_period);
        const selectedPeriod = paidPermits.length > 0 ? paidPermits[0].billing_period : null;

        this.state = {
            selectedPeriod,

            showAllPermits: false,
        };
    }

    renderFeatureBlurb = (key) => {
        const { practice_type = 'fitness' } = this.props.user;
        const feature = allFeatures[key];


        if (!(feature && feature.blurbs && feature.blurbs[practice_type])) {
            return;
        }

        return (
            <div className="feature-blurb">
                <ul>
                    {feature.blurbs[practice_type].map((blurb, i) => (<li key={i}>{blurb}</li>))}
                </ul>
            </div>
        );
    }

    renderPermitCard = (permit, i) => {
        const { user, onSelectPermit, subscription } = this.props;
        const { resourceCounts = {} } = this.state;
        const { capabilities = {}} = user || {};

        let  currentMonthlyPrice;
        if (subscription && subscription.items && subscription.items.data[0].price.recurring.interval === 'month') {
            currentMonthlyPrice = subscription.items.data[0].price.unit_amount/(100*subscription.items.data[0].price.recurring.interval_count);
        } else if (subscription && subscription.items && subscription.items.data[0].price.recurring.interval === 'year') {
            currentMonthlyPrice = subscription.items.data[0].price.unit_amount/(1200*subscription.items.data[0].price.recurring.interval_count);
        }

        return (
            <div key={i} className="permit-card" onClick={() => onSelectPermit(permit)}>
                <h3>{permit.name}</h3>
                {permit.create_patient_limit
                    ? <p>
                        {permit.create_patient_limit} clients
                        {resourceCounts.create_patient && resourceCounts.create_patient > permit.create_patient_limit  ?
                            <span className="too-many"> (you have {resourceCounts.create_patient - permit.create_patient_limit} too many)</span>
                        : null}
                        {permit.create_dietitian_limit ?
                            <span> • {permit.create_dietitian_limit} PROs</span>
                        : null}
                      </p>
                    : <p>
                        {permit.user_practice_type !== "consumer" ? "unlimited clients" : null}
                        {permit.create_dietitian_limit ? <span> • {permit.create_dietitian_limit} PROs</span> : null}
                      </p>
                }
                {permit.price_monthly_usd
                    ? <span>
                        <p className="subscription-price">{currentMonthlyPrice && currentMonthlyPrice <  permit.price_monthly_usd ?
                         `+\$${(permit.price_monthly_usd - currentMonthlyPrice).toFixed(0)}` : `\$${permit.price_monthly_usd.toFixed(0)}`}</p><p>/month, billed {permit.billing_period}</p>
                      </span>
                    : <p>Free forever</p>
                }
                <span className="permit-bubble"><i className="icon-chevron-right" /><i className="icon-chevron-right" /></span>
            </div>
        );
    }

    renderToggle = (paidGroupOpts) => {
        const { selectedPeriod } = this.state;
        const { user } = this.props;
        paidGroupOpts.sort((first,second) => {return first.value.localeCompare(second.value)});
        const percentSavings = {
            'fitness': '16',
            'dietetics': '18',
            'consumer': '46'
        };

        return (
            <div className="billing-toggle">
                {paidGroupOpts.map((obj, index) => <span key={index} onClick={() => this.setState({selectedPeriod: obj.value})} data-active={selectedPeriod === obj.value} data-right-side={index == 1} className="selected-billing-side">{obj.value} {index === 1 ? <span className="savings">{`SAVE ${percentSavings[user.practice_type]}% +`}</span> : null}</span>)}
            </div>
        )
    }

    render = () => {
        const { isMobile, viewportWidth, isPro } = this.context;
        const { feature, permits, renderTestimonials, user, closeModal } = this.props;
        const { selectedPeriod, showAllPermits } = this.state;
        const { capabilities = {} } = user || {};

        const nonCurrentPermits = permits.filter(p => capabilities.name !== p.name || capabilities.billing_period !== p.billing_period);
        const filteredNonCurrentPermits = nonCurrentPermits.filter(p => p.billing_period === selectedPeriod);
        const permitsToShow = filteredNonCurrentPermits && (filteredNonCurrentPermits.length > 2 ? filteredNonCurrentPermits.slice(0, 2) : filteredNonCurrentPermits);
        const otherPermits = filteredNonCurrentPermits.filter(p => !permitsToShow.includes(p));

        if (!(permitsToShow && permitsToShow.length)) {
            return this.renderContactSupportBody();
        }

        const paidPermits = nonCurrentPermits.filter(p => p.billing_period && p.billing_period !== 'enterprise');
        const paidRemaining = paidPermits.length ? groupBy(paidPermits, 'billing_period') : null;
        const paidGroupOpts = paidRemaining ? Object.keys(paidRemaining).map(opt => ({label: 'billed ' + opt, value: opt})) : null;

        const shouldDisplayDropdown = paidGroupOpts && paidGroupOpts.length > 2;

        return (
            <div className="upgrade-membership permit-picker">
                <h1 className="permit-picker-title">{isPro ? "Upgrade to access EatLove PRO premium features" : "Upgrade to access EatLove premium features"}</h1>

                <div className="permit-picker-header">
                {this.renderFeatureBlurb(feature)}
                { shouldDisplayDropdown ? <Select options={paidGroupOpts} onChange={(value) => this.setState({selectedPeriod: value})} value={selectedPeriod} /> :  this.renderToggle(paidGroupOpts)}
                </div>

                <div className="valid-permits">
                    {permitsToShow && permitsToShow.map(this.renderPermitCard)}
                </div>

                {showAllPermits ?
                    <div>
                        <h2>Other membership options</h2>
                        {otherPermits.map(this.renderPermitCard)}
                    </div>
                : null}

                {!showAllPermits && otherPermits.length ?
                    <footer>
                        <button className="sub-action-btn" onClick={() => this.setState({showAllPermits: true})}>more plans</button>
                    </footer>
                :
                    <footer>
                        <Link to={"/membership"} onClick={closeModal}>
                            <p className="sub-action-btn">learn more about membership plans</p>
                        </Link>

                    </footer>
                }

                {user.practice_type !== "fitness" && (isMobile || viewportWidth < 1050) ? <TestimonialContainer  practice_type={user.practice_type} mobileVersion={true}/> : null}
            </div>
        );
    }
}
