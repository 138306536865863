'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import debounce from 'lodash.debounce';

import SearchKeywords from '../Filters/SearchKeywords.react';
import DocumenTypeExpander from '../Dropdowns/DocumentType.react';
import MealTypesExpander from '../Dropdowns/MealTypes.react';
import AvoidancesExpander from '../Dropdowns/Avoidances.react';
import SkillLevelExpander from '../Dropdowns/SkillLevel.react';
import CaloriesExpander from '../Dropdowns/Calories.react';
import GoodSourceExpander from '../Dropdowns/GoodSource.react';
import TotalTimeExpander from '../Dropdowns/TotalTime.react';
import PrepTimeExpander from '../Dropdowns/PrepTime.react';
import TagGroupExpander from '../Dropdowns/TagGroup.react';
import SortExpander from '../Dropdowns/Sort.react';
import LibraryFilter from '../Filters/Library.react';
import PrescriptionFilter from '../Filters/Prescription.react';
import UnpublishedFilter from '../Filters/Unpublished.react';
import NutrientFilters from '../../Admin/Combos/NutrientFilters.react';
import IngredientsFilter from '../Dropdowns/Ingredients.react';
import LanguageFilter from '../Filters/LanguageFilter.react';
import Combobox from '../../../pro/components/Widgets/Combobox.react';

import MerchantExpander from '../Dropdowns/Merchant.react';
import Analytics from '../../../utils/Analytics';
import UserStore from '../../../stores/UserStore';
import AuthStore from '../../../stores/AuthStore';
import { getConfig } from '../../../utils/Env';
import { getMealSearchParamsForProfile } from '../../../pro/utils/Patients';

import allTags from '../../../tables/tags';

import './AdvancedFilters.scss';

export default class AdvancedFilters extends Component {
    static propTypes = {
        params: PropTypes.object,
        onChangeParams: PropTypes.func,
        hideLibraryFilter: PropTypes.bool,
   };

    static contextTypes = {
        profile: PropTypes.object,
        isPublisher: PropTypes.bool,
        isAddSwap: PropTypes.bool,
        isPro: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            // Make a deep copy of the original parameters
            initialParams: JSON.parse(JSON.stringify(props.params)),
            working: false,
            patients: [],
            selectedPatientUuid: null,
            showPatientsList: false,
            searchTerm: '',
        };

        this.onSearchPatients = debounce(this.onSearchPatients, 300);
    }

    componentDidMount = () => {
        const { isPro, profile } = this.context;
        const user = UserStore.getUser();
        const { contextName, params } = this.props;

        Analytics.advancedSearch({ "Context": contextName });

        if (!isPro) {
            return;
        }

        if (profile.uuid === user.uuid) {
            this.setState({ showPatientsList: true });
        }

        if (params.profile) {
            this.setState({ uuid: params.profile.uuid, defaultValue: params.profile.name});
        }


        //Always load patients even if profile is already selected
        this.getPatients(user);

    }

    cancel = () => {
        const { initialParams } = this.state;
        const { onChangeParams, closeModal } = this.props;

        onChangeParams(initialParams);
        closeModal();
    }

    getPatients = (user, searchTerm = '') => {
        const { practice } = user;

        this.setState({ working: true });

        let query; 

        if (searchTerm) {
            query = {
                terms: searchTerm,
                embed: ["preferences"],
            }
        } else {
            query = {
                terms: '',
                following: 'current',
                sort: 'last_updated',
                order: 'asc',                
            }
        }

        AuthStore.fetch({
            url: getConfig('users_api') + practice.links.patients.href, query}).then(
            response => {
                this.setState({
                    patients: response.elements,
                    working: false
                });
            },
            error => {
                this.setState({
                    patients: [],
                    working: false,
                });
            }
        );
    }

    onSearchPatients = (value) => {
        const user = UserStore.getUser();
        this.setState({ searchTerm: value }, () => {
            this.getPatients(user, value);
        });
    }

    onSelectOption = (uuid) => {
        const { patients } = this.state;
        const { onChangeParams, params } = this.props;

        const profile = patients.find(patient => patient.uuid === uuid);

        params.filters = params.filters || {};
        params.filters['tags'] = params.filters['tags'] || [];
        params.filters['!ingredient_tags'] = params.filters['!ingredient_tags'] || [];

        (profile.preferences.diets || []).forEach(diet => {
            if (!params.filters['tags'].includes(diet)) {
                params.filters['tags'].push(diet);
            }
        });

        (profile.preferences.avoidances || []).forEach(avoid => {
            if (!params.filters['!ingredient_tags'].includes(avoid)) {
                params.filters['!ingredient_tags'].push(avoid);
            }
        });

        params.profile = {
            ...profile,
            name: `${profile.first_name} ${profile.last_name}`
        };

        onChangeParams(params);

        this.setState({ selectedPatientUuid: uuid, defaultValue: params.profile.name, openComboBox: false });
    };


    clearSelectedPatient = () => {
        const { onChangeParams, params } = this.props;

        params.filters = {};
        params.filters['tags'] = [];
        params.filters['!ingredient_tags'] = [];

        delete params.profile;

        onChangeParams(params);

        this.setState({ selectedPatientUuid: null, defaultValue: '', openComboBox: true});
    };


    render() {
        const { isPublisher, isAddSwap, isPro } = this.context;
        const { working, patients, showPatientsList, selectedPatientUuid, defaultValue, openComboBox } = this.state;
        const { allowedTypes, onChangeParams, total, loading, closeModal, hideLibraryFilter, showTypePicker, params } = this.props;
        const { hide_nutrition = false } = UserStore.getPreferences();

        const patientOpts = patients.map(patient => ({ value: patient.uuid, label: `${patient.first_name} ${patient.last_name}` }));

        const modalStyles = {
            overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: '100%',
                height: '100%',
                backgroundImage: 'url(https://static.chewba.info/images/overlay-dust-dark-30.png)',
                backgroundRepeat: 'repeat',
                backgroundSize: '4px',
                backgroundColor: 'rgba(25, 25, 25, 0.75)',
                zIndex: 10,
            },
            content : {
            }
        };

        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                contentLabel="Advanced Search"
                className="modal-parent global-search-advanced-filters-modal"
                style={modalStyles}
                closeTimeoutMS={250}>

                <div className="global-search-advanced-filters">
                    <header>
                        <button onClick={closeModal}>
                            <i className="icon-chevron-left" />
                            <span className="assistive-text">Close Modal</span>
                        </button>

                        <h1>Filter Results</h1>
                    </header>

                    <div className="filters-scroll-container">
                        <section className="inner-slider">
                            <h2>INCLUDE</h2>

                            <div className="el-labeled-input">

                            {showPatientsList  ? 
                                <label>Show Smart Choices for</label>
                            : null}

                            {working && showPatientsList ? <span className="loading-patients-spinner"><i className="icon-spinner2"/>{"Loading patients..."}</span> : null}

                            {showPatientsList  ? 
                                (<Combobox 
                                    key={defaultValue}
                                    defaultClassName="el-combobox-container"
                                    value={selectedPatientUuid}
                                    placeholder="---"
                                    onSelectOption={this.onSelectOption}
                                    options={patientOpts}
                                    onChangeTerms={this.onSearchPatients}
                                    defaultValue={defaultValue}
                                    onClearCombobox={!working && defaultValue ? this.clearSelectedPatient : null} 
                                    openByDefault={openComboBox} />
                                )
                            : null}

                            </div>

                            {isPublisher ?
                                <LanguageFilter params={params} onChangeParams={onChangeParams} />
                            : null}

                            {isAddSwap          ? <SearchKeywords params={params} onChangeParams={onChangeParams} /> : null}
                            {!hideLibraryFilter ? <LibraryFilter  params={params} onChangeParams={onChangeParams} /> : null}

                            {isPublisher ?
                                <UnpublishedFilter  params={params} onChangeParams={onChangeParams} />
                            : null}

                            {!isPublisher && !isPro ?
                                <PrescriptionFilter params={params} onChangeParams={onChangeParams}>
                                    Smart Choice
                                </PrescriptionFilter>
                            : null}

                            {isPublisher ?
                                <PrescriptionFilter params={params} onChangeParams={onChangeParams} filterName="strict_rx">
                                    Smart Choice
                                </PrescriptionFilter>
                            : null}

                            {!showTypePicker ?
                                <DocumenTypeExpander  isExpander={true} params={params} onChangeParams={onChangeParams} allowedTypes={allowedTypes} />
                            : null}
                            {!hide_nutrition ? <SortExpander         isExpander={true} params={params} onChangeParams={onChangeParams} /> : null}
                            <MealTypesExpander    isExpander={true} params={params} onChangeParams={onChangeParams} allowedTypes={allowedTypes} />
                            <AvoidancesExpander   isExpander={true} params={params} onChangeParams={onChangeParams} allowedTypes={allowedTypes} />
                            <IngredientsFilter       isExpander={true} params={params} onChangeParams={onChangeParams} allowedTypes={allowedTypes} />
                            <SkillLevelExpander   isExpander={true} params={params} onChangeParams={onChangeParams} allowedTypes={allowedTypes} />
                            <TagGroupExpander     isExpander={true} params={params} onChangeParams={onChangeParams} allowedTypes={allowedTypes} singleSelect={true} group={allTags.cuisine} />
                            <TagGroupExpander     isExpander={true} params={params} onChangeParams={onChangeParams} allowedTypes={allowedTypes} singleSelect={true} group={allTags.equipment}  onlyWorksOn="recipe" />
                            <MerchantExpander     isExpander={true} params={params} onChangeParams={onChangeParams} allowedTypes={allowedTypes} />
                            {!hide_nutrition ? <CaloriesExpander     isExpander={true} params={params} onChangeParams={onChangeParams} allowedTypes={allowedTypes} /> : null}
                            <TagGroupExpander     isExpander={true} params={params} onChangeParams={onChangeParams} allowedTypes={allowedTypes} singleSelect={true} group={allTags.planMisc}   onlyWorksOn="plan" />
                            <TagGroupExpander     isExpander={true} params={params} onChangeParams={onChangeParams} allowedTypes={allowedTypes} singleSelect={true} group={allTags.recipeMisc} onlyWorksOn="recipe" translateTagMap={{'Basic': 'Simple Recipes'}} />
                            <GoodSourceExpander   isExpander={true} params={params} onChangeParams={onChangeParams} allowedTypes={allowedTypes} />
                            <TotalTimeExpander     isExpander={true} params={params} onChangeParams={onChangeParams} allowedTypes={allowedTypes} />
                            <PrepTimeExpander     isExpander={true} params={params} onChangeParams={onChangeParams} allowedTypes={allowedTypes} />

                            {!hide_nutrition ? <NutrientFilters params={params} onChangeParams={onChangeParams} /> : null}
                        </section>
                    </div>

                    <footer>
                        {loading === true ? <p>loading...</p> : null}
                        {loading !== true ? <p>Filter Results: <em>{total} listings</em></p> : null}

                        <button className="cancel" onClick={this.cancel}>Cancel</button>
                        <button className="apply-filters" onClick={closeModal}>Apply</button>
                    </footer>
                </div>

            </Modal>
        );
    }
}
