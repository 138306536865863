'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactMarkdown from 'react-markdown'

import Select from '../../Widgets/Select.react';
import DateSelector from '../../Widgets/DateSelector.react';

import { getConfigurationIssues, getPatientDemographic } from '../../../utils/Patients';

import TaxonomyStore from '../../../../stores/TaxonomyStore';
import TaxonomyActions from '../../../../actions/TaxonomyActions';
import UserStore from '../../../../stores/UserStore';

import allConditions from '../../../../tables/conditions';

//import conditionInfo from '../../../../tables/health-conditions-lifestyle-plans';

import './Conditions.scss';
import './PatientForm.scss';

export default class Conditions extends Component {
    static propTypes = {
    };

    static defaultProps = {
    };

    static contextTypes = {
        showUpgradeForm: PropTypes.func,
    }

    constructor(props) {
        super(props);

        const { patient } = props;

        const conditions = patient.conditions && patient.conditions.length > 0
                         ? patient.conditions
                         : [{}];
        const { fetus_count = 0 } = patient;

        // Get the conditions from the patient;
        this.state = {
            conditions,
            fetus_count,

            dirty: false,

            errors: [],
            keyIndex: TaxonomyStore.getKeyIndex(),
        };
    }

    componentDidMount = () => {
        TaxonomyActions.ensureLoaded();
        TaxonomyStore.addChangeListener(this.onTaxonomyStoreChange);
    }

    componentWillUnmount = () => {
        TaxonomyStore.removeChangeListener(this.onTaxonomyStoreChange);
    }

    onTaxonomyStoreChange = () => {
        this.setState({keyIndex: TaxonomyStore.getKeyIndex()});
    }

    validate = () => {
        const { patient } = this.props;
        const { gender, birthdate } = patient;
        const { conditions, fetus_count } = this.state;
        const errors = [];
        let error = null;

        // Get configuration issues for the current selection
        const issues = getConfigurationIssues({
            ...patient,
            conditions,
            fetus_count,
        });

        const { practice = {} } = UserStore.getUser() || {};
        const conditionNoun = patient.practice_type === 'dietetics' ? 'condition' : 'lifestyle goal';
        const patientNoun = patient.practice_type === 'dietetics' ? 'patient' : 'client';

        if (issues.errors.length > 0) {
            error = issues.errors.join(', ');
            errors[0] = 'condition';

            this.setState({errors});

            return error;
        }

        // Loop through each condition, if the condition has a specific problem, report it
        conditions.forEach((data, i) => {
            errors[i] = false;

            const condition = allConditions.filter(c => c.name === data.name)[0];

            // Ignore custom conditions and show first error instead of last.
            if (!condition || error) {
                errors[i] = 'condition';
                error = `Please choose a ${conditionNoun}. If none apply, try General Healthy Diet`;
                return;
            }

            const demographic = getPatientDemographic({birthdate, gender}, condition.demographics);

            if (!condition.templates[demographic]) {
                errors[i] = 'condition';
                error = `The ${conditionNoun} you selected is not appropriate for your ${patientNoun}. Please revise your selection.`;
                return;
            }

            if (condition.update_method === 'pregnancy' && !fetus_count) {
                errors[i] = 'fetus_count';
                error = 'Number of expecting is required';
                return;
            }
        });

        this.setState({errors});

        return error;
    }

    mutate = (patient) => {
        let { dirty, conditions, fetus_count } = this.state;
        const { originalPatient } = this.props;

        if (!dirty) {
            return patient;
        }

        // Filter out empty conditions
        conditions = conditions.filter(data => data.name);

        patient.conditions = conditions;
        patient.preferences.avoidances = [ ...originalPatient.preferences.avoidances ];

        // Was there a change to conditions? Recalculate the prescription in its entirety.
        // patient.prescriptions = [{meal_type: 'all-day', envelope: {}}];
        patient.target_energy_kcal = null;
        patient.activity_level = null;

        patient.pregnant = false;
        patient.lactating = false;
        patient.due_date = '';
        patient.fetus_count = 0;

        // Convert conditions array to dates. Compute pregnancy trimester in the process.
        let next_updates = conditions.map(data => {
            const condition = allConditions.filter(c => c.name === data.name)[0];

            if (!condition) {
                return false;
            }

            if (condition.name === 'Lactation') {
                patient.lactating = true;
            }

            if (condition.update_method === 'pregnancy') {
                patient.pregnant = true;
                patient.due_date = moment(data.next_update).format('YYYY-MM-DD');
                patient.fetus_count = fetus_count;

                // Need to compute the next trimester date.
                // Starting from: the expected due date, work our way back to now.
                const next_trimester = moment(data.next_update);
                let next_update = next_trimester.format();

                while(moment().isBefore(next_trimester)) {
                    next_update = next_trimester.format();

                    next_trimester.subtract(3, 'months');
                }

                // Set the patient's pregnancy flag

                return next_update;
            }

            return data.next_update;
        }).filter(v => v).sort((a, b) => new Date(b) - new Date(a));

        // Use the first item in the list as the
        // Check to see if we need to do a next_update value on the patient itself.
        patient.next_update = next_updates[0] || patient.next_update || '';

        originalPatient.conditions.forEach(data => {
            const condition = allConditions.filter(c => c.name === data.name)[0];

            if (!condition) {
                return;
            }

            if (condition.avoidances && patient.preferences && patient.preferences.avoidances) {
                condition.avoidances.forEach(avoids => {
                    if (patient.preferences.avoidances.includes(avoids)) {
                        patient.preferences.avoidances.splice(patient.preferences.avoidances.indexOf(avoids), 1);
                    }
                });
            }
        });

        conditions.forEach(data => {
            const condition = allConditions.filter(c => c.name === data.name)[0];

            if (!condition) {
                return;
            }

            if (condition.avoidances) {
                patient.preferences = patient.preferences || {avoidances: []};
                patient.preferences.avoidances = patient.preferences.avoidances || [];

                condition.avoidances.forEach(avoids => {
                    if (!patient.preferences.avoidances.includes(avoids)) {
                        patient.preferences.avoidances.push(avoids);
                    }
                });
            }

            if (condition.update_method && data.next_update) {
                if (!patient.next_update) {
                    patient.next_update = data.next_update;
                } else if (moment(patient.next_update).isAfter(data.next_update)) {
                    patient.next_update = data.next_update;
                }
            }
        });

        if (patient.practice_type === 'dietetics') {
            patient.preferences = patient.preferences || {};
            patient.preferences.inhibit_change_nutrition_profile = true;
        }

        // Register ourself completed
        patient.completed = patient.completed || [];

        if (!patient.completed.includes('conditions')) {
            patient.completed.push('conditions');
        }

        return patient;
    }

    isDirty = () => {
        return this.state.dirty;
    }

    setCondition = (name, i) => {
        const { conditions } = this.state;
        const { showUpgradeForm } = this.context;

        const condition = allConditions.filter(c => c.name === name)[0];

        // remove the condition
        if (!condition) {
            conditions.splice(i, 1);

            this.setState({conditions, dirty: true});
            return;
        }

        const user = UserStore.getUser();
        const { premium_conditions } = user.capabilities || {};

        if (condition.premium && !premium_conditions) {
            showUpgradeForm({feature: 'premium_conditions'});
            return;
        }

        conditions[i] = {name};

        if (condition.update_method === 'default') {
            conditions[i].next_update = moment().add(6, 'weeks').format();
        }

        this.setState({conditions, dirty: true, conditionsDirty: true});
    }

    setConditionData = (newData, i) => {
        const { conditions } = this.state;

        conditions[i] = {...conditions[i], ...newData};

        this.setState({conditions, dirty: true});
    }

    appendCondition = (name) => {
        const { conditions } = this.state;

        const condition = allConditions.filter(c => c.name === name)[0];

        const data = {name};

        if (condition && condition.update_method == 'default') {
            data.next_update = moment().add(3, 'weeks').format();
        }

        conditions.push(data);

        this.setState({conditions, dirty: true, conditionsDirty: true});
    }

    filterInappropriateConditions = (condition) => {
        const { patient } = this.props;

        // Get the demographic for this user and condition
        const demographic = getPatientDemographic(patient, condition.demographics);

        // Does it exist?
        return condition.templates[demographic] ? true : false;
    }

    renderCondition = (condData, i) => {
        const { patient } = this.props;
        const { gender, birthdate, preferences = {}, target_energy_kcal } = patient;
        const { diets = [], avoidances = [] } = preferences;
        const { errors, fetus_count, keyIndex } = this.state;
        const condition = allConditions.find(c => c.name == condData.name);

        let conditionOpts = allConditions.filter(c => c.on_pro);

        const user = UserStore.getUser();
        const { premium_conditions } = user.capabilities || {};

        if (user.practice_type === 'fitness') {
            conditionOpts = allConditions.filter(c => c.trainer);
        }

        const conditionSort = (a, b) => {
            if (a.name === 'General Healthy Diet') return -1;
            if (b.name === 'General Healthy Diet') return 1;

            // if (a.position > b.position) return 1;
            // if (a.position < b.position) return -1;

            return a.name.localeCompare(b.name);
        }

        conditionOpts = conditionOpts.filter(this.filterInappropriateConditions)
                                     .sort(conditionSort)
                                     .map(c => ({
                                        label: <span>
                                                    {user.practice_type === 'dietetics' ? c.name : (c.consumer_name || c.name)}
                                                    {!premium_conditions && c.premium ?
                                                        <i className="icon-lock" />
                                                    : null}
                                                </span>,
                                        value: c.name
                                      }));

        // Did our condition make it into the options for the drop-down? If not, it's selected, but invalid. We
        // Add it, but disable it so it cannot be re-selected once changed
        if (condition && !conditionOpts.find(c => c.value === condition.name)) {
            conditionOpts.unshift({
                label: condition.name,
                value: condition.name,
                disabled: true,
            });
        }

        let expiresInOpts = [
            {label: 'no expiration', value: 0},
            {label: '1 week', value: 7},
            {label: '2 weeks', value: 7 * 2},
            {label: '3 weeks', value: 7 * 3},
            {label: '4 weeks', value: 7 * 4},
            {label: '6 weeks', value: 7 * 6},
            {label: '12 weeks', value: 7 * 12},
            {label: '24 weeks', value: 7 * 24},
        ];

        let expiresIn = 0;

        // Do we have a next_update value already? Make sure we can select it.
        if (condData.next_update) {
            expiresIn = moment(condData.next_update).diff(moment().format('YYYY-MM-DD'), 'days');

            if (expiresIn < 0) {
                expiresInOpts.push({
                    label: 'EXPIRED ' + Math.abs(expiresIn) + ' days ago',
                    value: expiresIn,
                });
            } else if (!expiresInOpts.filter(o => o.value == expiresIn)[0]) {
                expiresInOpts.push({
                    label: expiresIn + ' days',
                    value: expiresIn,
                });
            }

            expiresInOpts = expiresInOpts.sort((a, b) => a.value - b.value);
        }

        let avoidancesList = '';

        if(condition && condition.avoidances && condition.avoidances.length) {
            avoidancesList = condition.avoidances.map(avoids => {
                if (!keyIndex[avoids]) {
                    return null;
                }
                return keyIndex[avoids].title;
            }).filter(v => v).join(', ')
        }

        return (
            <div className="conditions-container" key={i}>
                <div className="with-label full-width condtion-select">
                    <Select value={condData.name}
                        showSearch={true}
                        placeholder={patient.practice_type === 'dietetics' ? 'Choose condition' : 'Choose goal'}
                        onChange={conditionName => this.setCondition(conditionName, i)}
                        options={conditionOpts}>
                    </Select>
                </div>

                {condition && condition.update_method === 'pregnancy' ?
                    <span>
                        <DateSelector label="Expected Due Date"
                            initialDate={condData.next_update}
                            data-error={errors[i] && errors[i] === 'next_update'}
                            delta_years={1}
                            onChange={next_update => this.setConditionData({next_update}, i)} />

                        <div className="with-label fetus-count full-width" data-error={errors[i] && errors[i] === 'fetus_count'}>
                            <label>How many is {patient.first_name} expecting?</label>
                            <button className="toggle-button" data-active={fetus_count === 1}
                                onClick={() => this.setState({fetus_count: 1, dirty: true})}>
                                Single
                            </button>

                            <button className="toggle-button" data-active={fetus_count === 2}
                                onClick={() => this.setState({fetus_count: 2, dirty: true})}>
                                Twins
                            </button>
                        </div>

                    </span>
                : null}
                {condition && condition.background ? (<div className="condition-info">
                        <ReactMarkdown source={condition.background}/>
                        {avoidancesList.length ? <strong className="avoidances-heading">Avoidances:</strong> : null}
                        {avoidancesList.length ? avoidancesList : null}
                    </div>)
                : null}
            </div>
        );
    }

    render() {
        const { patient } = this.props;
        const { conditions, fetus_count } = this.state;

        // Get configuration issues for the current selection
        const { warnings } = getConfigurationIssues({
            ...patient,
            conditions,
            fetus_count,
        });

        const { first_name } = patient;
        const { practice = {} } = UserStore.getUser() || {};

        return (
            <div className="patient-form patient-conditions">
                <h6>Please select a {patient.practice_type === 'dietetics' ? 'prescription' : 'goal'}  that best applies to your {patient.practice_type === 'dietetics' ? 'patient' : 'client'}.</h6>

                {conditions.map(this.renderCondition)}

                {warnings.length > 0 ?
                    <p data-active={true} className="warning-msg">{warnings[0]}</p>
                : null}

            </div>
        );
    }
}
