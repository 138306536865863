'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import AddRecipe from '../../../Recipes/AddRecipe.react';
import SavedRecipes from '../../../Recipes/SavedRecipes.react';

import Analytics from '../../../../utils/Analytics';
import { getConfig } from '../../../../utils/Env';
import AuthStore from '../../../../stores/AuthStore';
import platform from 'platform';

import './UseOwnRecipe.scss';

export default class UseOwnRecipe extends Component {
    static propTypes = {
        profile: PropTypes.object,
        closeModal: PropTypes.func,
    };

    static contextTypes = {
        onSelectRecipe: PropTypes.func,
        isMobile: PropTypes.bool,
        addSwapContext: PropTypes.object,
    };

    static childContextTypes = {
        showCreateCustom: PropTypes.func,
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            isModalOpen: false,
        };
    }

    getChildContext = () => {
        // The Search/Browser's NoResults component calls 'showCreateCustom' when clicking the button to create.
        // In our context here, we want to open the create recipe modal, not the create custom food modal,
        // so we redirect the call to our own openModal function, so the user can create their recipe.
        return {
            showCreateCustom: this.openModal,
        };
    };

    componentDidMount = () => {
        Analytics.startUseOwnRecipe({ Context: 'Add/Swap' });
    };

    onSaveRecipe = (recipe) => {
        const { onSelectRecipe, addSwapContext } = this.context;

        onSelectRecipe(recipe, addSwapContext.participants);
    };

    closeModal = () => {
        this.setState({ isModalOpen: false });
    };

    openModal = () => {
        this.setState({ isModalOpen: true });
    };

    renderRecipeEditor = () => {
        const { profile, closeModal } = this.props;
        const { isModalOpen } = this.state;

        if (!isModalOpen) {
            return;
        }

        return (
            <Modal
                isOpen={true}
                onRequestClose={this.closeModal}
                closeModal={this.closeModal}
                className="el-modal el-modal1"
                overlayClassName="el-modal-overlay"
                contentLabel="Edit Recipe Details"
            >
                <div className="el-modal-container el-modal1-container el-modal1-recipe-editor-container">
                    <header data-has-controls={false}>
                        <button className="el-modal-back-btn" onClick={this.closeModal}>
                            <span>Back</span>
                        </button>

                        <h2>Create Your Own Recipe</h2>
                    </header>

                    <div className="el-modal-body-container el-modal1-body-container el-fonts">
                        <AddRecipe
                            profile={profile}
                            closeModal={this.closeModal}
                            onSaveRecipe={this.onSaveRecipe}
                            savedOwnRecipeContext="Use Own Recipe"
                        />
                    </div>
                </div>
            </Modal>
        );
    };

    render() {
        return (
            <div className="add-swap-search-use-own-recipe">
                <SavedRecipes setCreateMode={this.openModal} />
                {this.renderRecipeEditor()}
            </div>
        );
    }
}
