'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import allNutrients from '../../../tables/nutrients';

export default class NutritionLimits extends Component {
    static propTypes = {
        params: PropTypes.object,
        onChangeParams: PropTypes.func,
    };

    removeNutritionLimits = (key) => {
        const { params, onChangeParams } = this.props;

        params.filters = params.filters || {};
        Object.keys(allNutrients)
              .map(nutrNo => allNutrients[nutrNo])
              .filter(n => n.Filter && n.Filter !== 'calories')
              .forEach(n => delete params.filters[n.Filter]);

        onChangeParams(params);
    }

    render() {
        const { params = {} } = this.props;

        if (!params.filters) {
            return <span />
        }

        // Do we have any other nutrient filters active other than calories?
        const showParam = (Object.keys(allNutrients)
                                 .map(nutrNo => allNutrients[nutrNo])
                                 .filter(n => n.Filter && n.Filter !== 'calories' &&
                                              typeof params.filters[n.Filter] === 'object')
                                 .length) > 0;

        if (!showParam) {
            return <span />
        }

        return (
            <div className="param-item" onClick={this.removeNutritionLimits}>
                Other Nutrition Limits <i className="icon-close-x" />
            </div>
        );
    }
}
