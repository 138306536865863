'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import allTags from '../../../tables/tags';

export default class SkillLevelParam extends Component {

    resetSkill = () => {
        const { params, onChangeParams } = this.props;

        // Remove skill level tags from filter in and filter out tags
        params.filters['!tags'] = (params.filters['!tags'] || []).filter(tag => !allTags.skill.tags.includes(tag));
        params.filters.tags     = (params.filters.tags || []).filter(tag => !allTags.skill.tags.includes(tag));

        onChangeParams(params, true);
    }

    render() {
        const { params = {} } = this.props;
        const { filters = {} } = params;

        const filterInTags  = (filters.tags || []);
        const filterOutTags = (filters['!tags'] || []);

        let skillType = false;

        if (filterOutTags.includes('Intermediate') && filterOutTags.includes('Advanced')) {
            skillType = 'Beginner';
        } else if (!filterOutTags.includes('Intermediate') && filterOutTags.includes('Advanced')){
            skillType = 'Intermediate';
        } else if (filterInTags.includes('Advanced')) {
            skillType = 'Advanced';
        }

        if (!skillType) {
            return <span />;
        }

        return (
            <div className="param-item" onClick={this.resetSkill}>
                {skillType} <i className="icon-close-x" />
            </div>
        );
    }
}
