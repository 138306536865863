'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { pluralize, singularize } from 'inflected';
import { DragSource, DropTarget } from 'react-dnd';

import SmartFraction from '../../Widgets/SmartFraction.react';
import EditIngredientModal from './EditIngredientModal.react';
import NutritionInfoModal from '../../Nutrition/Modals/NutritionInfoModal.react';

import { isSingular, roundForHumans } from '../../../utils/Math';

import './EditIngredient.scss';
import { getContentForIngredient } from '../../../utils/Content';

const ingredientTarget = {
    hover(props, monitor) {
        var item = monitor.getItem();

        if (item.ingredient != props.ingredient) {
            props.moveIngredient(item.ingredient, props.ingredient);
        }
    },
};

const ingredientSource = {
    beginDrag(props) {
        return { ingredient: props.ingredient };
    },
    endDrag(props, monitor, component) {
        if (monitor.didDrop()) {
            // props.autosave();
            // console.log('@todo - we should save here');
        }
    },
    isDragging(props, monitor) {
        return props.ingredient === monitor.getItem().ingredient;
    },
};

@DropTarget('ingredient', ingredientTarget, (connect) => ({
    connectDropTarget: connect.dropTarget(),
}))
@DragSource('ingredient', ingredientSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
}))
export default class EditIngredient extends Component {
    static propTypes = {
        ingredient: PropTypes.object,
        onChangeIngredient: PropTypes.func,
        onRemoveIngredient: PropTypes.func,

        extraAnalyticsProps: PropTypes.object,
    };

    static defaultProps = {};

    static contextTypes = {
        foods: PropTypes.object,
        subrecipes: PropTypes.object,
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            ...this.getStateFromProps(props, context),

            isModalOpen: false,
            showNutritionModal: false,
        };
    }

    UNSAFE_componentWillReceiveProps = (newProps, newContext) => {
        this.setState(this.getStateFromProps(newProps, newContext));
    };

    getStateFromProps = (props, context) => {
        const { ingredient } = props;

        const ingredientCopy = JSON.parse(JSON.stringify(ingredient));

        const verified =
            (ingredientCopy.food && ingredientCopy.food.verified) ||
            (ingredientCopy.recipe && ingredientCopy.recipe.verified);

        const newState = {
            verified,
            ingredient: ingredientCopy,
            grams: ingredient.grams,
            milliliters: ingredient.milliliters,
        };

        return newState;
    };

    openModal = () => {
        this.setState({ isModalOpen: true });
    };

    closeModal = () => {
        this.setState({ isModalOpen: false, showNutritionModal: null });
    };

    showNutrition = () => {
        const { ingredient } = this.props;

        this.setState({ showNutritionModal: ingredient });
    };

    renderEditModal = () => {
        const { isModalOpen } = this.state;
        const { ingredient, onChangeIngredient, onRemoveIngredient, extraAnalyticsProps } = this.props;

        if (!isModalOpen) {
            return;
        }

        return (
            <EditIngredientModal
                ingredient={ingredient}
                onRemoveIngredient={onRemoveIngredient}
                onChangeIngredient={onChangeIngredient}
                closeModal={this.closeModal}
                extraAnalyticsProps={extraAnalyticsProps}
            />
        );
    };

    renderNutritionInfoModal = () => {
        const { showNutritionModal } = this.state;
        const { ingredient, profile } = this.props;
        const { recipes, foods } = this.context;

        if (!showNutritionModal) {
            return;
        }

        const contents = { ...recipes, ...foods };
        let content;
        let nutrients = [];

        if (ingredient.recipe) content = contents[ingredient.recipe.uuid];
        if (ingredient.food) content = contents[ingredient.food.uuid];

        if (content?.nutrients) {
            Object.keys(content?.nutrients?.values).forEach((nutrNo) => {
                if (content.serving_unit === 'g' && ingredient.grams) {
                    nutrients[nutrNo] =
                        (content.nutrients.values[nutrNo] / content.grams_per_serving) * ingredient.grams;
                } else if (content.serving_unit === 'ml' && ingredient.milliliters) {
                    nutrients[nutrNo] =
                        (content.nutrients.values[nutrNo] / content.milliliters_per_serving) * ingredient.milliliters;
                } else {
                    nutrients[nutrNo] = undefined;
                }
            });
        }

        const { amount, unit_of_measure } = ingredient.measurement || {};

        const subtitle = <>{[amount, unit_of_measure, ingredient.ingredient].filter((v) => v).join(' ')}</>;

        return (
            <NutritionInfoModal
                subtitle={subtitle}
                brandName={content?.brand_name}
                profile={profile}
                nutrients={nutrients}
                onClose={this.closeModal}
            />
        );
    };

    render = () => {
        const { recipes, foods } = this.context;
        const { ingredient, profile, isDragging, connectDropTarget, connectDragSource, onSwapIngredient } = this.props;
        let { amount, unit_of_measure } = ingredient.measurement || {};

        const contents = { ...recipes, ...foods };
        let content = getContentForIngredient(ingredient, contents);

        const { preferences = {} } = profile;
        const { hide_nutrition } = preferences;

        if (unit_of_measure) {
            unit_of_measure = isSingular(amount) ? singularize(unit_of_measure) : pluralize(unit_of_measure);
        }

        const isWarning = !(ingredient.food || ingredient.recipe) || !(ingredient.grams || ingredient.milliliters);

        const groceryName = [content?.brand_name, content?.pretty_name || ingredient?.food?.name || content?.name]
            .filter((v) => v)
            .join(' ');

        return connectDropTarget(
            <div className="ingredient" data-dragging={isDragging}>
                {isNaN(amount) && (ingredient.grams > 0 || ingredient.milliliters > 0) ? (
                    <p className="ingredient-quantity" onClick={this.openModal}>
                        {ingredient.grams > 0 ? <>{ingredient.grams}g</> : <>{ingredient.milliliters}ml</>}
                    </p>
                ) : null}

                {isNaN(amount) && !ingredient.grams && !ingredient.milliliters ? (
                    <p className="ingredient-quantity" onClick={this.openModal}>
                        &mdash;
                    </p>
                ) : null}

                {!isNaN(amount) ? (
                    <p className="ingredient-quantity" onClick={this.openModal}>
                        {profile.units_mode === 'metric' && !isNaN(amount) ? (
                            roundForHumans(amount)
                        ) : (
                            <SmartFraction acceptedDenominators={[2, 3, 4, 5, 6, 8]} value={amount} />
                        )}{' '}
                        {unit_of_measure}
                    </p>
                ) : null}

                {connectDragSource(
                    <div className="ingredient-item-name">
                        <p>
                            {ingredient.ingredient}
                            {ingredient.prep_step ? <span>, {ingredient.prep_step}</span> : null}
                        </p>
                        {groceryName ? (
                            <p className="mapped-food t3">
                                <em>grocery: {groceryName.toLowerCase()}</em>
                            </p>
                        ) : null}

                        {!groceryName && !ingredient.recipe ? (
                            <p className="mapped-food t3">
                                <em>grocery:</em>
                                <button
                                    className="select-grocery-item-btn el-medium-btn el-link-no-underline-raspberry-btn"
                                    onClick={() => onSwapIngredient(ingredient)}
                                >
                                    select a grocery item
                                </button>
                            </p>
                        ) : null}
                    </div>
                )}

                <div className="ingredient-controls">
                    {!isWarning && !hide_nutrition ? (
                        <button className="nutrition-info-btn" onClick={() => this.showNutrition()}>
                            <i className="icon-analyze" />
                        </button>
                    ) : null}

                    {isWarning && !hide_nutrition ? (
                        <button className="ingredient-alert-btn" onClick={this.openModal}>
                            <i className="feather feather-alert-triangle" />
                        </button>
                    ) : null}

                    <button className="edit-ingredient-btn" onClick={this.openModal}>
                        <i className="icon-pencil3" />
                    </button>
                </div>

                {this.renderEditModal()}
                {this.renderNutritionInfoModal()}
            </div>
        );
    };
}
